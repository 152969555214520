import CircularProgress from '@material-ui/core/CircularProgress'
import CalendarView from '../calendar-events/components/CalendarView'
import { NewCalendarEventData, useGetCalendarEventsQuery } from '../calendar-events/api'
import { useCalendarEventMutations } from '../calendar-events/hooks/use-calendar-event-mutations'
import { useAppContext } from '../hooks/use-app-context'
import { useAuthUserMembership } from '../memberships/hooks/use-auth-user-membership'
import { useGetOrganisationByIdQuery } from '../organisations/api'
import { useGetTasksQuery } from '../tasks/api'
import { NewTodoData, useGetTodosQuery } from '../todos/api'
import { useTodoMutations } from '../todos/hooks/use-todo-mutations'
import { useAuthUser } from '../users/hooks/use-auth-user'
import {
  getCalendarEventQueryFromContext,
  getTodoQueryFromContext,
  getTaskQueryFromContext,
} from '../context-options/utils'
import ErrorList from '../components/ErrorList'
import { useI18n } from '../hooks'
import { StringMap } from '../types/common'

const queryOptions = { refetchOnMountOrArgChange: true } as const

const useLoader = () => {
  const translations = useTranslations()
  const queryErrors = {} as QueryErrors
  const {
    appContext: { mainContext, subContext },
  } = useAppContext()
  const context = subContext || mainContext
  const organisationId = mainContext?.type === 'org' ? mainContext.id : ''
  const skipFetchingTodos = context?.type === 'supplier'
  const skipFetchingOrganisation = !organisationId

  const calendarEventQuery = getCalendarEventQueryFromContext(context)
  const todoQuery = getTodoQueryFromContext(context)
  const taskQuery = getTaskQueryFromContext(context)

  const {
    data: calendarEvents = [],
    isLoading: isLoadingCalendarEvents,
    isError: isErrorCalendarEvents,
  } = useGetCalendarEventsQuery(calendarEventQuery, queryOptions)
  const {
    data: tasks = [],
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
  } = useGetTasksQuery(taskQuery, queryOptions)
  const {
    data: todos = [],
    isLoading: isLoadingTodos,
    isError: isErrorTodos,
  } = useGetTodosQuery(todoQuery, { ...queryOptions, skip: skipFetchingTodos })
  const {
    data: organisation,
    isLoading: isLoadingOrganisation,
    isError: isErrorOrganisation,
  } = useGetOrganisationByIdQuery(organisationId, { ...queryOptions, skip: skipFetchingOrganisation })

  const isLoading = isLoadingCalendarEvents || isLoadingTasks || isLoadingTodos || isLoadingOrganisation
  const isError = isErrorCalendarEvents || isErrorTasks || isErrorTodos || isErrorOrganisation

  if (isErrorCalendarEvents) queryErrors.calendarEvent = translations.fetchErrorCalendarEvents
  if (isErrorTasks) queryErrors.task = translations.fetchErrorTasks
  if (isErrorTodos) queryErrors.todo = translations.fetchErrorTodos
  if (isErrorOrganisation) queryErrors.organisation = translations.fetchErrorOrganisation

  return {
    isLoading,
    isError,
    context,
    mainContext,
    calendarEvents,
    tasks,
    todos,
    organisation,
    queryErrors,
  }
}

const CalendarRoute = () => {
  const { isLoading, isError, queryErrors, context, mainContext, calendarEvents, tasks, todos, organisation } =
    useLoader()
  const { canCreateCalendarEvents, canCreateTodos } = useAuthUserMembership()
  const { user } = useAuthUser()
  const { createCalendarEvent } = useCalendarEventMutations()
  const { createTodo } = useTodoMutations()
  const translations = useTranslations()

  if (isLoading) return <CircularProgress />
  if (!context || !mainContext) return <ErrorList errors={[translations.contextNotFoundError]} />
  if (isError) return <ErrorList errors={queryErrors} />

  const canAddCalendarEvents = mainContext.type === 'user' || canCreateCalendarEvents
  const canAddTodos = mainContext.type === 'user' || canCreateTodos

  const onAddCalendarEvent = (calendarEventData: NewCalendarEventData) => {
    const newEventData = { ...calendarEventData }
    if (mainContext.type === 'org') newEventData.organisation = mainContext.id
    if (context.type === 'customer') newEventData.customers = [context.id]
    if (context.type === 'member') newEventData.participants = [context.id]
    if (context.type === 'workspace') newEventData.workspaces = [context.id]
    if (context.type === 'supplier') newEventData.suppliers = [context.id]
    return createCalendarEvent(newEventData)
  }

  const onAddTodo = (todoData: NewTodoData) => {
    const newTodoData = { ...todoData }
    if (mainContext.type === 'org') newTodoData.organisation = mainContext.id
    if (context.type === 'customer') newTodoData.customers = [context.id]
    if (context.type === 'member') newTodoData.responsible = [context.id]
    if (context.type === 'workspace') newTodoData.workspaces = [context.id]
    return createTodo(newTodoData)
  }

  return (
    <CalendarView
      title={context.name}
      calendarEvents={calendarEvents}
      tasks={tasks}
      todos={context.type === 'supplier' ? [] : todos}
      calendarWeekendDays={organisation?.weekendDays || user?.weekendDays || []}
      canAddCalendarEvents={canAddCalendarEvents}
      canAddTasks={false}
      canAddTodos={canAddTodos}
      onAddCalendarEvent={onAddCalendarEvent}
      onAddTask={doNothing}
      onAddTodo={onAddTodo}
      showCaption
    />
  )
}

function doNothing() {}

export default CalendarRoute

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translations')
  const translations = t || ({} as StringMap)

  const {
    contextNotFoundError = defaults.contextNotFoundError,
    fetchErrorCalendarEvents = defaults.fetchErrorCalendarEvents,
    fetchErrorTasks = defaults.fetchErrorTasks,
    fetchErrorTodos = defaults.fetchErrorTodos,
    fetchErrorOrganisation = defaults.fetchErrorOrganisation,
  } = translations

  return {
    contextNotFoundError,
    fetchErrorCalendarEvents,
    fetchErrorTasks,
    fetchErrorTodos,
    fetchErrorOrganisation,
  }
}
const defaultTranslations = {
  contextNotFoundError: 'Context not found',
  fetchErrorCalendarEvents: 'Failed to fetch calendar events',
  fetchErrorTasks: 'Failed to fetch tasks',
  fetchErrorTodos: 'Failed to fetch todos',
  fetchErrorOrganisation: 'Failed to fetch organisation',
}
type Translations = typeof defaultTranslations
type QueryErrors = {
  calendarEvent?: string
  task?: string
  todo?: string
  organisation?: string
}
