import { useI18n } from '../../hooks'
import { Paper, Theme, makeStyles } from '@material-ui/core'
import AutoSaveInput from '../AutoSaveInput'
import AutoSaveSelect from '../AutoSaveSelect'
import { countries } from '../../constants/countries'
import { uniqBy } from 'lodash'

const BillingAddress = ({ billingAddress, onChange }: BillingAddressProps) => {
  const translations = useTranslations()
  const classes = useStyles()

  return (
    <Paper elevation={0} className={classes.paper}>
      <AutoSaveInput
        name="company"
        label={translations.companyNameLabel}
        placeholder={translations.companyInputPlaceholder}
        onSave={onChange}
        initialValue={billingAddress.company}
        disabled={false}
        inputProps={{ 'data-test': 'company-name-input' }}
      />
      <AutoSaveInput
        name="street"
        label={translations.streetLabel}
        placeholder={translations.streetInputPlaceholder}
        onSave={onChange}
        initialValue={billingAddress.street}
        disabled={false}
        inputProps={{ 'data-test': 'street-address-input' }}
      />
      <AutoSaveInput
        name="city"
        label={translations.cityLabel}
        placeholder={translations.cityInputPlaceholder}
        onSave={onChange}
        initialValue={billingAddress.city}
        disabled={false}
        inputProps={{ 'data-test': 'city-input' }}
      />
      <AutoSaveInput
        name="postalCode"
        label={translations.postalCodeLabel}
        placeholder={translations.postalCodeInputPlaceholder}
        onSave={onChange}
        initialValue={billingAddress.postalCode}
        disabled={false}
        inputProps={{ 'data-test': 'postal-code-input' }}
      />
      <AutoSaveSelect
        name="country"
        label={translations.countryLabel}
        placeholder={translations.countrySelectPlaceholder}
        onSave={onChange}
        initialValue={billingAddress.country}
        disabled={false}
        options={uniqBy(
          countries.map((country) => ({ label: country.countryName, value: country.countryName })),
          'value'
        )}
        data-test="country-select"
      />
    </Paper>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('org')
  const translations = (t.orgBillingPage || {}) as { [k: string]: string }

  const {
    companyNameLabel = defaults.companyNameLabel,
    companyInputPlaceholder = defaults.companyInputPlaceholder,
    streetLabel = defaults.streetLabel,
    streetInputPlaceholder = defaults.streetInputPlaceholder,
    cityLabel = defaults.cityLabel,
    cityInputPlaceholder = defaults.cityInputPlaceholder,
    postalCodeLabel = defaults.postalCodeLabel,
    postalCodeInputPlaceholder = defaults.postalCodeInputPlaceholder,
    countryLabel = defaults.countryLabel,
    countrySelectPlaceholder = defaults.countrySelectPlaceholder,
  } = translations

  return {
    companyNameLabel,
    companyInputPlaceholder,
    streetLabel,
    streetInputPlaceholder,
    cityLabel,
    cityInputPlaceholder,
    postalCodeLabel,
    postalCodeInputPlaceholder,
    countryLabel,
    countrySelectPlaceholder,
  }
}

const defaultTranslations = {
  companyNameLabel: 'Company (or organization) name',
  companyInputPlaceholder: 'Enter your company name',
  streetLabel: 'Street address',
  streetInputPlaceholder: 'Enter street address',
  cityLabel: 'Postal code',
  cityInputPlaceholder: 'Enter postal code',
  postalCodeLabel: 'City',
  postalCodeInputPlaceholder: 'Enter city',
  countryLabel: 'Country',
  countrySelectPlaceholder: 'Select',
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    '& > form, & > div': {
      padding: theme.spacing(1, 2),
    },
  },
}))

type Translations = typeof defaultTranslations
type BillingAddressProps = {
  billingAddress: {
    company: string
    street: string
    city: string
    postalCode: string
    country: string
  }
  onChange: (value: { [key: string]: string }) => Promise<void>
}

export default BillingAddress
