import api from '../../services/api-service'
import { AxiosRequestConfig } from 'axios'
import { IInvitationResponse } from '../interfaces/invitation-response'
import { InvitationStatusType } from '../interfaces/types'
import { IMembershipResponse } from '../../memberships/interfaces/membership-response'

export const fetchUserInvitations = (userId: string, config: AxiosRequestConfig = {}) => {
  return api.get<IInvitationResponse[]>('/invitations', config)
}

export const accepOrRejectInvitation = (
  invitationId: string,
  invitationData: { status: InvitationStatusType },
  config: AxiosRequestConfig = {}
) => {
  return api.put<{ membership: IMembershipResponse | null; invitation: IInvitationResponse }>(
    `/memberships/${invitationId}/invitation-response`,
    { invitationStatus: invitationData.status },
    config
  )
}
