export const makeMembershipApiRoutes = (membershipId = ':membershipId') => {
  const routes = {
    memberships: () => `/memberships`,
    membershipItem: (id = '') => `/memberships/${id || membershipId}`,
    userMemberships: ($userId = ':userId') => `/users/${$userId}/memberships`,
    orgMemberships: ($orgId = ':orgId') => `/orgs/${$orgId}/memberships`,
    deleteMembership: ($orgId = ':orgId', id = membershipId) => `/orgs/${$orgId}/memberships/${id}`,
    membershipPermissions: (id = membershipId) => `${routes.membershipItem(id)}/permissions`,
    membershipsPermissions: () => `${routes.memberships()}/permissions`,
    memberAbilitiesInResources: (id = membershipId) => `${routes.membershipItem(id)}/abilities-in-resources`,
    memberAssociations: (id = membershipId) => `${routes.membershipItem(id)}/associations`,
    membershipInvitationStatus: (id = membershipId) => `${routes.membershipItem(id)}/invitation-status`,
    membershipCopyPermissions: (id = membershipId) => `${routes.membershipItem(id)}/copy-membership-permissions`,
    transferOwnership: (id = membershipId) => `${routes.membershipItem(id)}/transfer-ownership`,
    orgMembershipPermissions: (orgId = ':orgId') => `/orgs/${orgId}/memberships/permissions`,
    orgMembershipsActivate: (orgId = ':orgId') => `/orgs/${orgId}/memberships/activate`,
    orgMembershipsDeactivate: (orgId = ':orgId') => `/orgs/${orgId}/memberships/deactivate`,
  }
  return routes
}
