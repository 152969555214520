import { Theme, makeStyles } from '@material-ui/core'
import Button, { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'

const LoadingButton = ({ status, children, ...props }: React.PropsWithChildren<LoadingButtonProps>) => {
  const isLoading = status === 'pending'
  const isError = status === 'error'
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const classes = useStyles({ isError, isLoading })

  React.useEffect(() => {
    if (isLoading) {
      buttonRef.current?.classList.remove('Mui-disabled')
    }
    if (isError && !isLoading) {
      buttonRef.current?.classList.add('Mui-disabled')
    }
  }, [isLoading, isError])

  return (
    <Button
      {...props}
      ref={buttonRef}
      disabled={isLoading || isError}
      type={props.onClick ? 'button' : 'submit'}
      className={classes.buttonRoot}
    >
      <span style={{ opacity: isLoading ? 0 : 1, transition: 'opacity 0.3s' }}>{children}</span>
      <CircularProgress
        size={20}
        style={{
          color: 'inherit',
          position: 'absolute',
          left: 'calc(50% - 10px)',
          display: isLoading ? 'block' : 'none',
          transition: 'opacity 0.3s',
        }}
      />
    </Button>
  )
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  buttonRoot: {
    position: 'relative',
    '&:disabled, &[disabled]': {
      boxShadow: 'none',
      cursor: 'not-allowed',
    },
  },
}))

type LoadingButtonProps = ButtonProps & {
  status: 'idle' | 'pending' | 'success' | 'error'
}

type StyleProps = {
  isLoading: boolean
  isError: boolean
}

export default LoadingButton
