import type { ICustomerAPI } from '../interfaces/customer-api'
import * as CustomerAPI from '../customer-api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { SetContextOption } from '../../context-options/store'
import { makeContextOptionFromCustomer } from '../../context-options/utils'

const makeFetchCustomerById = (customerAPI: ICustomerAPI) => {
  return createAsyncThunk('customers/fetchCustomerById', async (customerId: string) => {
    return await customerAPI.fetchCustomerById(customerId)
  })
}

const makeFetchCustomers = (customerAPI: ICustomerAPI) => {
  return createAsyncThunk('customers/fetchCustomers', async (orgId: string) => {
    return await customerAPI.fetchCustomers(orgId)
  })
}

const makeCreateCustomer = (customerAPI: ICustomerAPI) => {
  return createAsyncThunk(
    'customers/createCustomer',
    async ({ orgId, ...customerData }: CreateCustomerThunkArg, { dispatch }) => {
      const customer = await customerAPI.createCustomer(orgId, customerData)
      dispatch(SetContextOption(makeContextOptionFromCustomer(customer)))
      return customer
    }
  )
}

const makeUpdateCustomerInfo = (customerAPI: ICustomerAPI) => {
  return createAsyncThunk('customers/updateInfo', async ({ customerId, ...updateData }: UpdateCustomerInfoThunkArg) => {
    return await customerAPI.updateCustomerInfo(customerId, updateData)
  })
}

const makeUpdateCustomerStatus = (customerAPI: ICustomerAPI) => {
  return createAsyncThunk(
    'customers/updateStatus',
    async ({ customerId, ...updateData }: UpdateCustomerStatusThunkArg) => {
      return await customerAPI.updateCustomerStatus(customerId, updateData)
    }
  )
}

const makeAddContact = (customerAPI: ICustomerAPI) => {
  return createAsyncThunk('customers/addContact', async ({ customerId, ...contactData }: AddContactThunkArg) => {
    return await customerAPI.addContact(customerId, contactData)
  })
}

const makeUpdateContact = (customerAPI: ICustomerAPI) => {
  return createAsyncThunk(
    'customers/updateContact',
    async ({ customerId, contactId, ...updateData }: UpdateContactThunkArg) => {
      return await customerAPI.updateContact(customerId, contactId, updateData)
    }
  )
}

const makeChangeDefaultContact = (customerAPI: ICustomerAPI) => {
  return createAsyncThunk(
    'customers/changeDefaultContact',
    async ({ customerId, contactId }: ChangeDefaultContactThunkArg) => {
      return await customerAPI.changeDefaultContact(customerId, contactId)
    }
  )
}

const makeDeleteContact = (customerAPI: ICustomerAPI) => {
  return createAsyncThunk('customers/deleteContact', async ({ customerId, contactId }: DeleteContactThunkArg) => {
    await customerAPI.deleteContact(customerId, contactId)
    return { customerId, contactId }
  })
}

export const FetchCustomerById = makeFetchCustomerById(CustomerAPI)
export const CreateCustomer = makeCreateCustomer(CustomerAPI)
export const AddCustomerContact = makeAddContact(CustomerAPI)
export const FetchCustomers = makeFetchCustomers(CustomerAPI)
export const UpdateCustomerInfo = makeUpdateCustomerInfo(CustomerAPI)
export const UpdateCustomerStatus = makeUpdateCustomerStatus(CustomerAPI)
export const UpdateCustomerContact = makeUpdateContact(CustomerAPI)
export const ChangeCustomerDefaultContact = makeChangeDefaultContact(CustomerAPI)
export const DeleteCustomerContact = makeDeleteContact(CustomerAPI)

type CreateCustomerThunkArg = {
  orgId: string
} & CustomerAPI.CreateCustomerData

type AddContactThunkArg = {
  customerId: string
} & CustomerAPI.AddContactData

type UpdateCustomerInfoThunkArg = {
  customerId: string
} & CustomerAPI.CustomerInfoUpdateData

type UpdateCustomerStatusThunkArg = {
  customerId: string
} & CustomerAPI.CustomerStatusUpdateData

type UpdateContactThunkArg = {
  customerId: string
  contactId: string
} & CustomerAPI.UpdateContactData

type DeleteContactThunkArg = {
  customerId: string
  contactId: string
}

export type ChangeDefaultContactThunkArg = {
  customerId: string
  contactId: string
}
