import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'

const BASE_API_URL = process.env.REACT_APP_API_URL || `http://localhost:8080`

export const makeApi = (axios: AxiosInstance) => {
  return {
    get: async <T>(url: string, cfg: AxiosRequestConfig = {}): Promise<T> => {
      try {
        const response = await axios.get(url, cfg)
        if (response.data?.success) return response.data.data as T
        throw response
      } catch (error) {
        return handleAxiosError(error as AxiosError)
      }
    },
    post: async <T>(
      url: string,
      body: any,
      cfg: AxiosRequestConfig = {}
    ): Promise<T> => {
      try {
        const response = await axios.post(url, body, cfg)
        if (response.data?.success) return response.data.data as T
        throw response
      } catch (error) {
        return handleAxiosError(error as AxiosError)
      }
    },
    put: async <T>(
      url: string,
      body: any,
      cfg: AxiosRequestConfig = {}
    ): Promise<T> => {
      try {
        const response = await axios.put(url, body, cfg)
        if (response.data?.success) return response.data.data as T
        throw response
      } catch (error) {
        return handleAxiosError(error as AxiosError)
      }
    },
    patch: async <T>(
      url: string,
      body: any,
      cfg: AxiosRequestConfig = {}
    ): Promise<T> => {
      try {
        const response = await axios.patch(url, body, cfg)
        if (response.data?.success) return response.data.data as T
        throw response
      } catch (error) {
        return handleAxiosError(error as AxiosError)
      }
    },
    delete: async <T>(
      url: string,
      cfg: AxiosRequestConfig = {}
    ): Promise<T> => {
      try {
        const response = await axios.delete(url, cfg)
        if (response.data?.success) return response.data.data as T
        if (response.status === 204) return {} as T
        throw response
      } catch (error) {
        return handleAxiosError(error as AxiosError)
      }
    },
  }
}

const handleAxiosError = ({ request, response }: AxiosError) => {
  if (response) {
    if (response.status >= 400 && response.status <= 500) {
      throw new Error(
        response.data?.error || response.data?.message || 'Something went wrong'
      )
    } else {
      // Server response was received, but server responded with an error
      throw new Error('Server error, try again')
    }
  } else if (request) {
    // Request was sent but server did not send any response
    throw new Error('API failure')
  } else {
    // Something failed while setting up the request so even request was not sent to server
    throw new Error('Request failure')
  }
}

export const axiosInstance = axios.create({ baseURL: BASE_API_URL })
export const api = makeApi(axiosInstance)
export default api
