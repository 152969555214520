import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import BillingSummaryCard from './BillingSummaryCard'
import BillingBreakdownTable from './BillingBreakdownTable'
import React from 'react'
import { fetchBillingSummary } from '../../organisations/services/organisation-api'
import { useAsync } from '../../hooks/use-async'
import { values } from 'lodash'
import { Theme, makeStyles } from '@material-ui/core'
import { useI18n } from '../../hooks'

const useBillingSummary = ({ orgId }: { orgId: string }) => {
  const loader = React.useCallback(() => fetchBillingSummary(orgId), [orgId])
  const { data, error, status, isPending } = useAsync(loader)
  return { billingData: data, error, status, isPending }
}

const BillingSummaryPanel = ({ orgId }: { orgId: string }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { billingData, error, isPending } = useBillingSummary({ orgId })

  if (isPending()) return <div>Loading...</div>
  if (error || !billingData) return <div>Error: {error}</div>
  const { total, subtotal, summary, breakdown, discounts } = billingData

  return (
    <div className={classes.container} data-test="section-container">
      <Typography variant="h6">{translations.billingSummaryTitle}</Typography>
      <BillingSummaryCard total={total} subtotal={subtotal} summary={values(summary)} discounts={discounts} />
      <div className={classes.breakdownHeader}>
        <Typography variant="h6">{translations.breakdownTitle}</Typography>
        <a href="https://www.starbrix.app/en/pricing" target="_blank" rel="noreferrer">
          <Button variant="outlined" size="small">
            {translations.viewPricing}
          </Button>
        </a>
      </div>
      <BillingBreakdownTable breakdown={breakdown} />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '& > h6, & > div': {
      marginBottom: theme.spacing(2.5),
    },
  },
  breakdownHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
}))

const defaultTranslations = {
  billingSummaryTitle: 'Your billing summary',
  breakdownTitle: 'Breakdown of your fees by members',
  viewPricing: 'View pricing',
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('org')
  const translations = t?.orgBillingPage || {}

  return {
    billingSummaryTitle: translate('billingSummaryTitle'),
    breakdownTitle: translate('breakdownTitle'),
    viewPricing: translate('viewPricing'),
  }

  function translate(key: keyof Translations) {
    return translations[key] || defaults[key]
  }
}

type Translations = typeof defaultTranslations

export default BillingSummaryPanel
