import { StringMap } from '../../types/common'
import ResourceManager from '../../components/ResourceManager'
import { useI18n } from '../../hooks'
import { useOrgOptions } from '../../options/hooks/use-org-options'
import { useTodoMutations } from '../hooks/use-todo-mutations'
import { TodoViewModel } from '../api/todo'

const TodoWorkspaceWidget = ({ todo, boardId }: TodoWorkspaceWidgetProps) => {
  const translations = useTranslations()
  const { workspaceOptions } = useOrgOptions(todo.organisation?.id || '')
  const { updateWorkspaces } = useTodoMutations()

  const onAddWorkspace = (workspaces: string[]) => {
    const workspace = todo.workspaces.map(($r) => $r.id)
    const newWorkspaces = workspace.concat(workspaces)
    updateWorkspaces(todo.id, {
      workspaces: newWorkspaces,
      boardId,
    })
  }
  const onRemoveWorkspace = (workspaceId: string) => {
    const workspaces = todo.workspaces.filter(($w) => $w.id !== workspaceId).map(($w) => $w.id)
    updateWorkspaces(todo.id, {
      workspaces,
      boardId,
    })
  }

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={workspaceOptions}
      resources={todo.workspaces}
      canAddResource={todo.canUpdate}
      canRemoveResource={todo.canUpdate}
      onAddResources={onAddWorkspace}
      onRemoveResource={onRemoveWorkspace}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection
    />
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('todo')
  const translations = (t?.workspaceWidget || {}) as StringMap
  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: 'Workspaces',
  emptyOptionsMessage: 'No workspaces in this organisation',
  emptyResourcesMessage: 'Todo is not connected to any workspace',
}

type Translations = typeof defaultTranslations

type TodoWorkspaceWidgetProps = {
  todo: TodoViewModel
  workspaceId?: string
  boardId?: string
}

export default TodoWorkspaceWidget
