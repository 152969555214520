import { TimeZoneType } from '../../../constants/timezones'
import { DateTimeService } from '../../../services/date-time-service'
import {
  getCustomerOptions,
  getMembershipOptions,
  getOptionId,
  getOptionName,
  getSupplierOptions,
  getWorkspaceOptions,
} from '../../../options/utils'
import { getStateIconHtml, makeEnumString, mapStatusToTranslations } from '../../utils'
import { ProjectActivityRow } from '../types'
import { Option } from '../../types'
import { sortBy } from 'lodash'
import { isProjectPlannedEndDatePastDue, isProjectPlannedStartDatePastDue } from '../../../projects/utils/date-utils'
import { ProjectViewModel } from '../../../projects/api/project'
import { TreeGridTranslations } from '../../hooks/use-translations'

export const makeProjectActivityRow = ({
  project,
  dateFormat,
  timeZone,
  options,
  translations,
}: MakeProjectActivityRowProps): ProjectActivityRow => {
  const filterOptionByOrganisation = makeFilterOptionByOrganisation(project)
  const { id, title, description, statusDescription, enableTimeComponent } = project
  const dateTimeService = new DateTimeService({ dateFormat, timeZone, enableTimeComponent })
  const dateTimeFormat = dateTimeService.getFormat()
  const status = mapStatusToTranslations(translations)[project.status]

  const customers = project.customers.map((c) => c.id).join(';')
  const customerOptions = getCustomerOptions(options).filter(filterOptionByOrganisation)
  const customerNames = customerOptions.map(getOptionName)
  const customerIds = customerOptions.map(getOptionId)
  const customersEnum = makeEnumString(customerNames)
  const customersEnumKeys = makeEnumString(customerIds)

  const responsible = project.managers.map((r) => r.id).join(';')
  const participants = project.participants.map((r) => r.id).join(';')
  const membershipOptions = getMembershipOptions(options).filter(filterOptionByOrganisation)
  const participantOptions = membershipOptions.filter(({ id }) => !responsible.includes(id))
  const responsibleNames = membershipOptions.map(getOptionName)
  const responsibleIds = membershipOptions.map(getOptionId)
  const responsibleEnum = makeEnumString(responsibleNames)
  const responsibleEnumKeys = makeEnumString(responsibleIds)
  const participantNames = participantOptions.map(getOptionName)
  const participantIds = participantOptions.map(getOptionId)
  const participantsEnum = makeEnumString(participantNames)
  const participantsEnumKeys = makeEnumString(participantIds)

  const suppliers = project.suppliers.map((s) => s.id).join(';')
  const supplierOptions = getSupplierOptions(options).filter(filterOptionByOrganisation)
  const supplierNames = supplierOptions.map(getOptionName)
  const supplierIds = supplierOptions.map(getOptionId)
  const suppliersEnum = makeEnumString(supplierNames)
  const suppliersEnumKeys = makeEnumString(supplierIds)

  const workspaces = project.workspaces.map((w) => w.id).join(';')
  const workspaceOptions = getWorkspaceOptions(options).filter(filterOptionByOrganisation)
  const workspaceNames = workspaceOptions.map(getOptionName)
  const workspaceIds = workspaceOptions.map(getOptionId)
  const workspacesEnum = makeEnumString(workspaceNames)
  const workspacesEnumKeys = makeEnumString(workspaceIds)

  return {
    id,
    type: 'project',
    open: '/external-link-icon.svg',
    activityNumber: project.customProjectNumber || project.projectNumber,
    title,
    description,
    status,
    state: getStateIconHtml(project.state),
    statusDescription,
    statusDescriptionTip: getStatusDescriptionTip(),
    enableTimeComponent,
    plannedStartDate: getPlannedStartDate(),
    plannedStartDateClass: getPlannedStartDateClass(),
    plannedStartDateFormat: dateTimeFormat,
    plannedEndDate: getPlannedEndDate(),
    plannedEndDateClass: getPlannedEndDateClass(),
    plannedEndDateFormat: dateTimeFormat,
    actualStartDate: getActualStartDate(),
    actualStartDateFormat: dateTimeFormat,
    responsible,
    responsibleEnum,
    responsibleEnumKeys,
    participants,
    participantsEnum,
    participantsEnumKeys,
    customers,
    customersEnum,
    customersEnumKeys,
    suppliers,
    suppliersEnum,
    suppliersEnumKeys,
    workspaces,
    workspacesEnum,
    workspacesEnumKeys,
    activityNumberCanEdit: project.canUpdateDetails ? 1 : 0,
    titleCanEdit: project.canUpdateDetails ? 1 : 0,
    descriptionCanEdit: project.canUpdateDetails ? 1 : 0,
    plannedStartDateCanEdit: project.canUpdatePlan ? 1 : 0,
    plannedEndDateCanEdit: project.canUpdatePlan ? 1 : 0,
    statusCanEdit: project.canUpdateStatus ? 1 : 0,
    stateCanEdit: project.canUpdateStatus ? 1 : 0,
    statusDescriptionCanEdit: project.canUpdateStatus ? 1 : 0,
    actualStartDateCanEdit: project.canUpdateStatus ? 1 : 0,
    customersCanEdit: project.canUpdateCustomers && project.isOrgProject ? 1 : 0,
    responsibleCanEdit: project.canUpdateManagers && project.isOrgProject ? 1 : 0,
    participantsCanEdit: project.canUpdateParticipants && project.isOrgProject ? 1 : 0,
    suppliersCanEdit: project.canUpdateSuppliers && project.isOrgProject ? 1 : 0,
    workspacesCanEdit: project.canUpdateWorkspaces && project.isOrgProject ? 1 : 0,
    Height: 48,
    MaxHeight: 48,
  }

  function getPlannedStartDate(): number | string {
    return project.plannedStartDate
      ? dateTimeService.removeTimezoneOffset(project.plannedStartDate, 'UTC').getTime()
      : ''
  }

  function getPlannedEndDate(): number | string {
    return project.plannedEndDate ? dateTimeService.removeTimezoneOffset(project.plannedEndDate, 'UTC').getTime() : ''
  }

  function getActualStartDate(): number | string {
    return project.actualStartDate ? dateTimeService.removeTimezoneOffset(project.actualStartDate, 'UTC').getTime() : ''
  }

  function getPlannedStartDateClass(): string {
    const isPastDue = isProjectPlannedStartDatePastDue({ project, dateTimeService })
    return isPastDue ? 'redText' : ''
  }

  function getPlannedEndDateClass(): string {
    const isPastDue = isProjectPlannedEndDatePastDue({ project, dateTimeService })
    return isPastDue ? 'redText' : ''
  }

  function getStatusDescriptionTip() {
    const sortedUpdates = sortBy(project.statusDescriptionUpdates, 'updatedAt')
    const latestUpdate = sortedUpdates[sortedUpdates.length - 1]
    let tip = ''
    if (latestUpdate) {
      const formatStr = `${dateFormat} HH:mm`
      const { updatedBy, updatedAt } = latestUpdate
      const offsetDate = dateTimeService.removeTimezoneOffset(updatedAt)
      // if there is a latest update
      tip += updatedBy // include the user who made the update
      tip += '&nbsp;&nbsp;&bull;&nbsp;&nbsp;' // some spacing
      tip += dateTimeService.format(offsetDate, formatStr) // and formatted date of update
    }
    return tip
  }
}

function makeFilterOptionByOrganisation(project: ProjectViewModel) {
  return function (option: Option) {
    return option.orgId === project.maintainerId
  }
}

type MakeProjectActivityRowProps = {
  project: ProjectViewModel
  dateFormat: string
  timeZone: TimeZoneType
  options: Option[]
  translations: TreeGridTranslations
}
