import React from 'react'
import * as projectApi from '../api'
import { useProjectMutations } from './use-project-mutations'
import { useLazyGetTasksQuery } from '../../tasks/api'
import { useConfirmDialog } from '../../components/ConfirmDialog'
import { useI18n } from '../../hooks'
import { Status, StringMap } from '../../types/common'
import { isCompleted, isNotStarted } from '../../utils/status'
import { TaskViewModel } from '../../tasks/api/task'
import { ProjectViewModel } from '../api/project'

export const useUpdateProjectStatus = () => {
  const translations = useTranslations()
  const projectActions = useProjectMutations()
  const [getTasks] = useLazyGetTasksQuery()

  const [updatedTasks, setUpdatedTasks] = React.useState<TaskViewModel[]>([])
  const [updatedProject, setUpdatedProject] = React.useState<ProjectViewModel>()

  const onConfirm = async (projectId: string, updateData: projectApi.ProjectStatusUpdateData) => {
    const response = await projectActions.updateStatus(projectId, {
      ...updateData,
      updateProjectTasks: true,
    })
    if (response) {
      const { project, tasks } = response
      setUpdatedProject(project)
      setUpdatedTasks(tasks)
    }
  }

  const onCancel = async (projectId: string, updateData: projectApi.ProjectStatusUpdateData) => {
    const response = await projectActions.updateStatus(projectId, {
      ...updateData,
      updateProjectTasks: false,
    })
    if (response) {
      const { project, tasks } = response
      setUpdatedProject(project)
      setUpdatedTasks(tasks)
    }
  }

  const { confirm: confirmChangeAllToCompleted } = useConfirmDialog({
    onConfirm,
    onCancel,
    primaryActionButtonLabel: translations.changeThisProjectAndItsTasksButtonLabel,
    secondaryActionButtonLabel: translations.changeThisProjectOnlyButtonLabel,
    text: createTranslatedDialogText(translations.dialogText, translations.completed),
    title: translations.dialogTitle,
  })

  const { confirm: confirmChangeAllToNotStarted } = useConfirmDialog({
    onConfirm,
    onCancel,
    primaryActionButtonLabel: translations.changeThisProjectAndItsTasksButtonLabel,
    secondaryActionButtonLabel: translations.changeThisProjectOnlyButtonLabel,
    text: createTranslatedDialogText(translations.dialogText, translations.notStarted),
    title: translations.dialogTitle,
  })

  const updateProjectStatus = async (projectId: string, updateData: projectApi.ProjectStatusUpdateData) => {
    const { status, actualEndDate, actualStartDate } = updateData
    const hasChangedToCompleted = (status && isCompleted(status)) || Boolean(actualEndDate)
    const hasChangedToNotStarted = (status && isNotStarted(status)) || actualStartDate === ''
    const shouldFetchTasks = hasChangedToCompleted || hasChangedToNotStarted
    const tasks = shouldFetchTasks ? await getTasks({ project: projectId }).unwrap() : []

    if (hasChangedToCompleted && shouldConfirmChangeAllToCompleted(projectId, tasks)) {
      return confirmChangeAllToCompleted(projectId, updateData)
    } else if (hasChangedToNotStarted && shouldConfirmChangeAllToNotStarted(projectId, tasks)) {
      return confirmChangeAllToNotStarted(projectId, updateData)
    } else {
      const response = await projectActions.updateStatus(projectId, updateData)
      if (response) {
        const { project, tasks } = response
        setUpdatedProject(project)
        setUpdatedTasks(tasks)
      }
    }
  }

  return {
    updateProjectStatus,
    updatedTasks,
    updatedProject,
  }
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('project')
  const translations = (t?.projectStatusMenu || {}) as StringMap

  const {
    dialogText = defaults.dialogText,
    dialogTitle = defaults.dialogTitle,
    changeThisProjectAndItsTasksButtonLabel = defaults.changeThisProjectAndItsTasksButtonLabel,
    changeThisProjectOnlyButtonLabel = defaults.changeThisProjectOnlyButtonLabel,
    notStarted = defaults.notStarted,
    inProgress = defaults.inProgress,
    completed = defaults.completed,
    rejected = defaults.rejected,
  } = translations

  return {
    dialogText,
    dialogTitle,
    changeThisProjectAndItsTasksButtonLabel,
    changeThisProjectOnlyButtonLabel,
    notStarted,
    inProgress,
    completed,
    rejected,
  }
}

const defaultTranslations = {
  dialogText:
    'This project has subtasks whose current status is not {{status}}. Do you want to change the status of all tasks to {{status}} ?',
  dialogTitle: 'Change status of tasks too?',
  changeThisProjectAndItsTasksButtonLabel: 'Yes, change the project and its tasks',
  changeThisProjectOnlyButtonLabel: 'No, change the project only',
  notStarted: 'Not started',
  inProgress: 'In progress',
  completed: 'Completed',
  rejected: 'Rejected',
}

const createTranslatedDialogText = (text: string, status: string) => {
  return text.replaceAll('{{status}}', status)
}

type Translations = typeof defaultTranslations

function shouldConfirmChangeAllToCompleted(projectId: string, tasks: { status: Status; projectId: string }[]) {
  const projectTasks = tasks.filter((t) => t.projectId === projectId)
  const areAllTasksCompleted = projectTasks.every((t) => isCompleted(t.status))
  return !areAllTasksCompleted
}

function shouldConfirmChangeAllToNotStarted(projectId: string, tasks: { status: Status; projectId: string }[]) {
  const projectTasks = tasks.filter((t) => t.projectId === projectId)
  const areAllTasksNotStarted = projectTasks.every((t) => isNotStarted(t.status))
  return !areAllTasksNotStarted
}
