import * as InvitationAPI from '../services/invitation-api'
import { IInvitationAPI } from '../interfaces/invitation-api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { InvitationStatusType } from '../interfaces/types'
import { SetMembership } from '../../memberships/store'

const makeFetchUserInvitations = (invitationApi: IInvitationAPI) => {
  return createAsyncThunk('invitations/fetchUserInvitations', async (_, { getState }) => {
    const state = getState() as RootState
    const userId = state.auth.user?.id as string
    const invitations = await invitationApi.fetchUserInvitations(userId)
    return { invitations, userId }
  })
}

const makeAcceptOrRejectInvitation = (invitationApi: IInvitationAPI) => {
  return createAsyncThunk(
    'invitations/acceptOrRejectInvitation',
    async ({ invitationId, status }: AcceptOrRejectInvitationThunkArg, { dispatch }) => {
      const response = await invitationApi.accepOrRejectInvitation(invitationId, { status })
      if (response.membership) dispatch(SetMembership(response.membership))
      return response.invitation
    }
  )
}

export const FetchUserInvitations = makeFetchUserInvitations(InvitationAPI)
export const AcceptOrRejectInvitation = makeAcceptOrRejectInvitation(InvitationAPI)

type AcceptOrRejectInvitationThunkArg = {
  status: InvitationStatusType
  invitationId: string
}
