import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { useI18n } from '../hooks'
import { StringMap } from '../types/common'
import { Chip, FormControl, ListItemText, makeStyles, MenuItem, OutlinedInput, Select, Theme } from '@material-ui/core'
import { ChevronDown } from 'react-feather'
import { dateFormatSeparatorOptions, DateFormatSeparatorOptionType } from '../constants/date-format'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const DateFormatSeparatorSelectWidget = ({
  dateFormatSeparator = dateFormatSeparatorOptions[0],
  onDateFormatSeparatorChange = () => {},
  disabled = false,
}: DateFormatSeparatorSelectWidgetProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onDateFormatSeparatorChange({
      dateFormatSeparator: event.target.value as DateFormatSeparatorOptionType,
    })
  }
  const selectorOptions = dateFormatSeparatorOptions

  return (
    <Paper elevation={0}>
      <Box p={4}>
        <Grid container direction="column">
          <Typography variant="subtitle1">{translations.dateFormatSeparatorTitle}</Typography>
          <Grid item>
            <FormControl className={classes.formControl} disabled={disabled}>
              <Select
                labelId="date-format-separater-select"
                id="date-format-separator-select"
                value={dateFormatSeparator}
                onChange={handleChange}
                input={<DateFormateSeparatorInput />}
                MenuProps={MenuProps}
                IconComponent={ChevronDown}
                renderValue={(selected: any) => {
                  return (
                    <div className={classes.chips}>
                      <Chip label={selected} className={classes.chip} />
                    </div>
                  )
                }}
              >
                {selectorOptions.map((separator, index) => (
                  <MenuItem key={index} value={separator}>
                    <ListItemText primary={separator} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

const DateFormateSeparatorInput = (props: any) => {
  const classes = useStyles()
  return <OutlinedInput {...props} className={classes.timeZoneInput} />
}
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 250,
    },
  },
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: { width: '100%' },
  timeZoneInput: {
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    borderRadius: 2,
    background: `${theme.palette.primary.main}21`,
  },
}))

const useTranslations = (defaults = defaultTranslations) => {
  const { translations: t } = useI18n('translation')
  const translations = (t.dateFormatSeparatorSelectWidget || {}) as StringMap
  const { dateFormatSeparatorTitle = defaults.dateFormatSeparatorTitle } = translations

  return { dateFormatSeparatorTitle }
}

const defaultTranslations = {
  dateFormatSeparatorTitle: 'Date format separator',
}

type DateFormatSeparatorSelectWidgetProps = {
  onDateFormatSeparatorChange?: ({
    dateFormatSeparator,
  }: {
    dateFormatSeparator: DateFormatSeparatorOptionType
  }) => void
  dateFormatSeparator?: DateFormatSeparatorOptionType
  disabled?: boolean
}

export default DateFormatSeparatorSelectWidget
