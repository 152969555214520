import { policies } from './policies'
import { Policies } from './policies'

export const buildMakeRestrictedAbilities =
  ({ policies }: { policies: Policies }) =>
  (abilities: string[] = []): RestrictedAbilities => {
    const _abilities: Set<string> = new Set()
    abilities.forEach((ability) => addAbility(ability))

    return {
      getAbilities,
      addAbility,
      removeAbility,
      isRestricted,
    }

    function getAbilities() {
      return Array.from(_abilities)
    }

    function addAbility(ability: string) {
      _abilities.add(ability)
      policies[ability]?.disables?.forEach((ability) => {
        if (!_abilities.has(ability)) addAbility(ability)
      })
    }

    function removeAbility(ability: string) {
      _abilities.delete(ability)
      policies[ability]?.enables?.forEach((ability) => {
        if (_abilities.has(ability)) removeAbility(ability)
      })
    }

    function isRestricted(ability: string) {
      return _abilities.has(ability)
    }
  }

// Everywhere we need to operate on a collection of abilities that will have restriction effect, we should use this
export const makeRestrictedAbilities = buildMakeRestrictedAbilities({ policies })

type RestrictedAbilities = {
  getAbilities: () => string[]
  addAbility: (ability: string) => void
  removeAbility: (ability: string) => void
  isRestricted: (ability: string) => boolean
}
