import BoardColumn from './BoardColumn'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { BoardColumnNameUpdateData, MoveCardData, NewCardData } from '../api'
import { BoardPopulated } from '../api/board'

const Board = ({ board, canAddTasks, canAddTodos, onAddTask, onAddTodo, onMoveCard, onSaveColumnName }: BoardProps) => {
  const handleDragEnd = ({ source, destination, draggableId }: DropResult) => {
    if (!destination) return
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    onMoveCard({
      board,
      boardId: board?.id,
      cardId: draggableId,
      columnId: source.droppableId,
      destColumnId: destination.droppableId,
      position: destination.index,
    })
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div
        style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'nowrap', flexGrow: 1 }}
        data-test="board-container"
      >
        {board.columns.orderedVisibleColumnIds.map((columnId) => {
          const column = board.columns.byId[columnId]
          return (
            <BoardColumn
              board={board}
              column={column}
              canAddTasks={canAddTasks}
              canAddTodos={canAddTodos}
              key={column.id}
              onAddTask={onAddTask}
              onAddTodo={onAddTodo}
              onSaveColumnName={onSaveColumnName}
            />
          )
        })}
      </div>
    </DragDropContext>
  )
}

type BoardProps = {
  board: BoardPopulated
  canAddTasks: boolean
  canAddTodos: boolean
  onAddTask: (cardData: NewCardData) => unknown
  onAddTodo: (cardData: NewCardData) => unknown
  onMoveCard: (moveCardData: MoveCardData & { board: BoardPopulated }) => unknown
  onSaveColumnName: (columnData: BoardColumnNameUpdateData) => unknown
}

export default Board
