import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useI18n, useNetworkStatus } from '../../hooks'
import { useConfirmDialog } from '../../components/ConfirmDialog'
import { makeStyles, Theme } from '@material-ui/core'
import { AlertTriangle } from 'react-feather'
import { StringMap } from '../../types/common'
import type { ISupplierNormalized } from '../interfaces/supplier-normalized'

const SupplierInfoCard = ({ supplier, onChangeStatus }: SupplierInfoCardProps) => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const activationStatus = useNetworkStatus({ resetDelayInMS: 300 })
  const { isActive, initials } = supplier

  const confirmChangeStatusDialog = useConfirmDialog({
    onConfirm: onChangeStatusConfirmed,
    title: isActive ? translations.deactivationConfirmDialogTitle : translations.activationConfirmDialogTitle,
    text: isActive ? translations.deactivationConfirmDialogText : translations.activationConfirmDialogText,
  })

  async function onChangeStatusConfirmed() {
    activationStatus.setStatus('pending')
    try {
      const action: any = await onChangeStatus()
      const requestStatus = action?.meta?.requestStatus || 'rejected'
      activationStatus.setStatus(requestStatus)
    } catch (error) {
      activationStatus.setStatus('rejected')
    }
  }

  let alertTitle = ''
  let alertMessage = ''
  let changeStatusButtonLabel = isActive
    ? translations.deactivateButtonLabel_idle
    : translations.activateButtonLabel_idle

  let activationButtonIcon: null | JSX.Element = null
  let progressIcon = <CircularProgress size={16} />

  if (activationStatus.isPending()) {
    changeStatusButtonLabel = !isActive
      ? translations.activateButtonLabel_pending
      : translations.deactivateButtonLabel_pending
    activationButtonIcon = progressIcon
  }

  if (activationStatus.isRejected()) {
    activationButtonIcon = <AlertTriangle size={20} />

    changeStatusButtonLabel = !isActive
      ? translations.activateButtonLabel_rejected
      : translations.deactivateButtonLabel_rejected

    alertTitle = !isActive ? translations.activationFailedAlertTitle : translations.deactivationFailedAlertTitle

    alertMessage = !isActive ? translations.activationFailedAlertMessage : translations.deactivationFailedAlertMessage
  }

  const activeChip = <Chip label={translations.activeChipLabel} size="small" color="secondary" />
  const inactiveChip = <Chip label={translations.inactiveChipLabel} size="small" disabled />
  const statusChip = isActive ? activeChip : inactiveChip

  return (
    <Card variant="outlined" className={classes.card}>
      <CardHeader
        avatar={
          <Avatar variant="rounded" className={classes.avatar}>
            {initials}
          </Avatar>
        }
        title={supplier.name}
        subheader={
          <span>
            {translations.supplier} {statusChip}
          </span>
        }
        titleTypographyProps={{ variant: 'subtitle1' }}
        subheaderTypographyProps={{
          variant: 'body2',
          color: 'textSecondary',
        }}
      />
      {alertMessage && (
        <CardContent>
          <Alert severity="error">
            <AlertTitle>{alertTitle}</AlertTitle>
            {alertMessage}
          </Alert>
        </CardContent>
      )}
      <CardActions className={classes.cardActions}>
        {supplier.canUpdate && (
          <Button
            variant="outlined"
            fullWidth
            className={classes.button}
            onClick={confirmChangeStatusDialog.confirm}
            startIcon={activationButtonIcon}
            data-test={`change-status-${isActive ? 'deactivate' : 'activate'}`}
          >
            {changeStatusButtonLabel}
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

const defaultTranslations = {
  activateButtonLabel_idle: 'Activate',
  activateButtonLabel_pending: 'Activating',
  activateButtonLabel_rejected: 'Failed',
  activationFailedAlertMessage: 'Failed to deactivate',
  activationFailedAlertTitle: 'Error',
  activationConfirmDialogText: `Do you want to re-activate this supplier`,
  activationConfirmDialogTitle: 'Activate supplier',
  deactivateButtonLabel_idle: 'Deactivate',
  deactivateButtonLabel_pending: 'Deactivating',
  deactivateButtonLabel_rejected: 'Failed',
  deactivationFailedAlertMessage: 'Failed to deactivate',
  deactivationFailedAlertTitle: 'Error',
  deactivationConfirmDialogText: `You will still be able to view this supplier 
    in deactivated suppliers list and you will be able to reactivate
    this supplier later if needed.`,
  deactivationConfirmDialogTitle: 'Deactivate supplier',
  supplier: 'Supplier',
  activeChipLabel: 'Active',
  inactiveChipLabel: 'Inactive',
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const { translations: supplierTranslations } = useI18n('supplier')
  const { translations: customerTranslations } = useI18n('customer')

  const supplierHomePage = (supplierTranslations.supplierHomePage || {}) as StringMap
  const customerHomePage = (customerTranslations.customerHomePage || {}) as StringMap
  const translations = (t || {}) as StringMap

  const { supplier = defaults.supplier } = translations

  const { activeChipLabel = defaults.activeChipLabel, inactiveChipLabel = defaults.inactiveChipLabel } =
    customerHomePage

  const {
    activateButtonLabel_idle = defaults.activateButtonLabel_idle,
    activateButtonLabel_pending = defaults.activateButtonLabel_pending,
    activateButtonLabel_rejected = defaults.activateButtonLabel_rejected,
    activationFailedAlertTitle = defaults.activationFailedAlertTitle,
    activationFailedAlertMessage = defaults.activationFailedAlertMessage,
    activationConfirmDialogTitle = defaults.activationConfirmDialogTitle,
    activationConfirmDialogText = defaults.activationConfirmDialogText,

    deactivateButtonLabel_idle = defaults.deactivateButtonLabel_idle,
    deactivateButtonLabel_pending = defaults.deactivateButtonLabel_pending,
    deactivateButtonLabel_rejected = defaults.deactivateButtonLabel_rejected,
    deactivationFailedAlertTitle = defaults.deactivationFailedAlertTitle,
    deactivationFailedAlertMessage = defaults.deactivationFailedAlertMessage,
    deactivationConfirmDialogTitle = defaults.deactivationConfirmDialogTitle,
    deactivationConfirmDialogText = defaults.deactivationConfirmDialogText,
  } = supplierHomePage

  return {
    activateButtonLabel_idle,
    activateButtonLabel_pending,
    activateButtonLabel_rejected,
    activationFailedAlertTitle,
    activationFailedAlertMessage,
    activationConfirmDialogTitle,
    activationConfirmDialogText,
    deactivateButtonLabel_idle,
    deactivateButtonLabel_pending,
    deactivateButtonLabel_rejected,
    deactivationFailedAlertTitle,
    deactivationFailedAlertMessage,
    deactivationConfirmDialogTitle,
    deactivationConfirmDialogText,
    supplier,
    activeChipLabel,
    inactiveChipLabel,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: theme.spacing(40),
    minWidth: 280,
    padding: theme.spacing(1),
  },
  cardActions: {
    display: 'flex',
  },
  avatar: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    background: theme.palette.info.main,
  },
  button: {
    minHeight: 38,
  },
}))

type SupplierInfoCardProps = {
  supplier: ISupplierNormalized
  onChangeStatus: () => void
}

type Translations = typeof defaultTranslations

export default SupplierInfoCard
