import { RouteComponentProps } from 'react-router-dom'
import { useProject } from '../../../projects/hooks/use-project'
import { useCalendarEventMutations } from '../../../calendar-events/hooks/use-calendar-event-mutations'
import { NewCalendarEventData, useGetCalendarEventsQuery } from '../../../calendar-events/api'
import { NewTaskData, useGetTasksQuery } from '../../../tasks/api'
import { useTaskMutations } from '../../../tasks/hooks/use-task-mutations'
import { useTodoMutations } from '../../../todos/hooks/use-todo-mutations'
import { NewTodoData, useGetTodosQuery } from '../../../todos/api'
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useAuthUser } from '../../../users/hooks/use-auth-user'
import { useGetOrganisationByIdQuery } from '../../../organisations/api'
import Calendar from '../../../calendar-events/components/Calendar'
import { adaptCalendarEvent, adaptTask, adaptTodo } from '../../../calendar-events/utils'
import { keyBy, merge } from 'lodash'
import ErrorList from '../../../components/ErrorList'
import { StringMap } from '../../../types/common'
import { useI18n } from '../../../hooks'

const queryOptions = { refetchOnMountOrArgChange: true } as const

const useLoader = ({ projectId }: { projectId: string }) => {
  const translations = useTranslations()
  const queryErrors = {} as QueryErrors
  const queryParams = { project: projectId }

  const { project, isLoading: isLoadingProject, isError: isErrorProject } = useProject(projectId, queryOptions)
  const {
    data: calendarEvents = [],
    isLoading: isLoadingCalendarEvents,
    isError: isErrorCalendarEvents,
  } = useGetCalendarEventsQuery(queryParams, queryOptions)
  const {
    data: tasks = [],
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
  } = useGetTasksQuery(queryParams, queryOptions)
  const {
    data: todos = [],
    isLoading: isLoadingTodos,
    isError: isErrorTodos,
  } = useGetTodosQuery(queryParams, queryOptions)

  const organisationId = project && project?.isOrgProject ? project.maintainerId : ''
  const skipFetchingOrganisation = !organisationId
  const {
    data: organisation,
    isLoading: isLoadingOrganisation,
    isError: isErrorOrganisation,
  } = useGetOrganisationByIdQuery(organisationId, {
    ...queryOptions,
    skip: skipFetchingOrganisation,
  })

  const isLoading =
    isLoadingProject || isLoadingCalendarEvents || isLoadingTasks || isLoadingTodos || isLoadingOrganisation
  const isError = isErrorProject || isErrorCalendarEvents || isErrorTasks || isErrorTodos || isErrorOrganisation

  if (isErrorProject) queryErrors.project = translations.fetchErrorProject
  if (isErrorCalendarEvents) queryErrors.calendarEvent = translations.fetchErrorCalendarEvents
  if (isErrorTasks) queryErrors.task = translations.fetchErrorTasks
  if (isErrorTodos) queryErrors.todo = translations.fetchErrorTodos
  if (isErrorOrganisation) queryErrors.organisation = translations.fetchErrorOrganisation

  return {
    project,
    calendarEvents,
    tasks,
    todos,
    organisation,
    isLoading,
    isError,
    queryErrors,
  }
}

const ProjectCalendarRoute = ({ match }: RouteComponentProps<RouteParams>) => {
  const translations = useTranslations()
  const { projectId } = match.params
  const { project, calendarEvents, tasks, todos, organisation, isLoading, isError, queryErrors } = useLoader({
    projectId,
  })
  const { createCalendarEvent, updateInfo: updateCalendarEvent } = useCalendarEventMutations()
  const { createOrgTask, createUserTask, updatePlan: updateTask } = useTaskMutations()
  const { createTodo, updateInfo: updateTodo } = useTodoMutations()
  const { user } = useAuthUser()

  const { events, entitiesById } = React.useMemo(() => {
    const taskEvents = tasks.filter((t) => t.plannedEndDate || t.plannedStartDate).map(adaptTask)
    const todoEvents = todos.filter((todo) => todo.dueDate).map(adaptTodo)
    const adaptedCalenderEvents = calendarEvents.map(adaptCalendarEvent)
    return {
      events: [...adaptedCalenderEvents, ...taskEvents, ...todoEvents],
      entitiesById: merge(keyBy(calendarEvents, 'id'), keyBy(tasks, 'id'), keyBy(todos, 'id')),
    }
  }, [calendarEvents, todos, tasks])

  if (isLoading) return <CircularProgress />
  if (!project) return <ErrorList errors={[translations.projectNotFoundError]} />
  if (isError) return <ErrorList errors={queryErrors} />

  const onAddCalendarEvent = (eventData: NewCalendarEventData) => {
    return createCalendarEvent({
      ...eventData,
      project: project.id,
      organisation: project.isOrgProject ? project.maintainerId : null,
    })
  }

  const onAddTask = (taskData: Omit<NewTaskData, 'projectId'>) => {
    return project.isOrgProject
      ? createOrgTask(project.maintainerId, { ...taskData, projectId: project.id })
      : createUserTask(project.maintainerId, { ...taskData, projectId: project.id })
  }

  const onAddTodo = (todoData: NewTodoData) => {
    return createTodo({
      ...todoData,
      project: project.id,
      organisation: project.isOrgProject ? project.maintainerId : null,
    })
  }

  return (
    <Calendar
      events={events}
      entitiesById={entitiesById}
      canAddCalendarEvents={project.canCreateProjectCalendarEvents}
      canAddTasks={project.canCreateProjectTasks}
      canAddTodos={project.canCreateProjectTodos}
      onAddCalendarEvent={onAddCalendarEvent}
      onAddTask={onAddTask}
      onAddTodo={onAddTodo}
      onUpdateCalendarEvent={updateCalendarEvent}
      onUpdateTask={updateTask}
      onUpdateTodo={updateTodo}
      weekendDays={project.isOrgProject ? organisation?.weekendDays : user?.weekendDays}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translations')
  const translations = t || ({} as StringMap)

  const {
    projectNotFoundError = defaults.projectNotFoundError,
    fetchErrorCalendarEvents = defaults.fetchErrorCalendarEvents,
    fetchErrorProject = defaults.fetchErrorProject,
    fetchErrorTasks = defaults.fetchErrorTasks,
    fetchErrorTodos = defaults.fetchErrorTodos,
    fetchErrorOrganisation = defaults.fetchErrorOrganisation,
  } = translations

  return {
    projectNotFoundError,
    fetchErrorCalendarEvents,
    fetchErrorProject,
    fetchErrorTasks,
    fetchErrorTodos,
    fetchErrorOrganisation,
  }
}
const defaultTranslations = {
  projectNotFoundError: 'Project not found',
  fetchErrorCalendarEvents: 'Failed to fetch calendar events',
  fetchErrorProject: 'Failed to fetch project',
  fetchErrorTasks: 'Failed to fetch tasks',
  fetchErrorTodos: 'Failed to fetch todos',
  fetchErrorOrganisation: 'Failed to fetch organisation',
}
type Translations = typeof defaultTranslations
type RouteParams = { projectId: string }
export default ProjectCalendarRoute

type QueryErrors = {
  calendarEvent?: string
  task?: string
  todo?: string
  project?: string
  organisation?: string
}
