import * as MembershipAPI from '../services/membership-api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IMembershipAPI } from '../interfaces/membership-api'
import { RootState } from '../../store'
import { SetContextOption } from '../../context-options/store'
import { makeContextOptionFromMembership } from '../../context-options/utils'

const makeFetchMembershipById = (membershipAPI: IMembershipAPI) => {
  return createAsyncThunk(
    'memberships/fetchMembershipById',
    async (membershipId: string) => await membershipAPI.fetchMembershipById(membershipId)
  )
}

const makeFetchUserMemberships = (membershipAPI: IMembershipAPI) => {
  return createAsyncThunk(
    'memberships/fetchUserMemberships',
    async (userId: string | undefined = undefined, { getState }) => {
      userId = userId || ((getState() as RootState).auth.user?.id as string)
      return await membershipAPI.fetchUserMemberships(userId)
    }
  )
}

const makeFetchOrgMemberships = (membershipAPI: IMembershipAPI) => {
  return createAsyncThunk('memberships/fetchOrgMemberships', async (orgId: string) => {
    return await membershipAPI.fetchOrgMemberships(orgId)
  })
}

const makeTransferOwnership = (membershipAPI: IMembershipAPI) => {
  return createAsyncThunk('memberships/transferOwnership', async (membershipId: string) => {
    return await membershipAPI.transferOwnership(membershipId)
  })
}

export const makeAddMembership = (membershipAPI: IMembershipAPI) => {
  return createAsyncThunk(
    'memberships/addMembership',
    async ({ orgId, ...newMembership }: AddMembershipThunkArg, { dispatch }) => {
      const res = await membershipAPI.addMembership(orgId, newMembership)
      dispatch(SetContextOption(makeContextOptionFromMembership(res)))
      return res
    }
  )
}

const makeUpdateMembershipInfo = (membershipAPI: IMembershipAPI) => {
  return createAsyncThunk(
    'memberships/updateMembershipInfo',
    async ({ membershipId, ...updateData }: MembershipInfoUpdateThunkArg, { dispatch }) => {
      const membership = await membershipAPI.updateMembershipInfo(membershipId, updateData)
      const contextOption = makeContextOptionFromMembership(membership)
      dispatch(SetContextOption(contextOption))
      return membership
    }
  )
}

const makeSendInvitation = (membershipAPI: IMembershipAPI) => {
  return createAsyncThunk('memberships/sendInvitation', async ({ membershipId, language }: SendInvitationThunkArg) => {
    return await membershipAPI.sendInvitation({ membershipId, language })
  })
}

const makeCancelInvitation = (membershipAPI: IMembershipAPI) => {
  return createAsyncThunk('memberships/cancelInvitation', async (membershipId: string) => {
    return await membershipAPI.cancelInvitation(membershipId)
  })
}

const makeActivateMemberships = (membershipAPI: IMembershipAPI) => {
  return createAsyncThunk(
    'memberships/activateMemberships',
    async ({ orgId, membershipIds }: ActivateMembershipsThunkArg) => {
      return await membershipAPI.activateMemberships(orgId, membershipIds)
    }
  )
}

const makeDeactivateMemberships = (membershipAPI: IMembershipAPI) => {
  return createAsyncThunk(
    'memberships/deactivateMemberships',
    async ({ orgId, membershipIds }: DeactivateMembershipsThunkArg) => {
      return await membershipAPI.deactivateMemberships(orgId, membershipIds)
    }
  )
}

export const FetchMembershipById = makeFetchMembershipById(MembershipAPI)
export const FetchUserMemberships = makeFetchUserMemberships(MembershipAPI)
export const FetchOrgMemberships = makeFetchOrgMemberships(MembershipAPI)
export const TransferOwnership = makeTransferOwnership(MembershipAPI)
export const AddMembership = makeAddMembership(MembershipAPI)
export const UpdateMembershipInfo = makeUpdateMembershipInfo(MembershipAPI)
export const SendInvitation = makeSendInvitation(MembershipAPI)
export const CancelInvitation = makeCancelInvitation(MembershipAPI)
export const ActivateMemberships = makeActivateMemberships(MembershipAPI)
export const DeactivateMemberships = makeDeactivateMemberships(MembershipAPI)

type AddMembershipThunkArg = {
  orgId: string
} & MembershipAPI.NewMemberData

type MembershipInfoUpdateThunkArg = {
  membershipId: string
} & MembershipAPI.MembershipInfoUpdateData

type ActivateMembershipsThunkArg = {
  orgId: string
  membershipIds: string[]
}
type DeactivateMembershipsThunkArg = {
  orgId: string
  membershipIds: string[]
}
type SendInvitationThunkArg = {
  membershipId: string
  language: string
}
