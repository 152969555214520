import { api } from '../../api'
import { IMembershipResponse } from '../interfaces/membership-response'
import { makeMembershipViewModel } from './membership'

export const membershipApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrgMemberships: builder.query({
      query: (orgId: string) => `/orgs/${orgId}/memberships`,
      transformResponse: (response: IMembershipResponse[]) => response.map(makeMembershipViewModel),
    }),
  }),
})

export const { useGetOrgMembershipsQuery } = membershipApi
