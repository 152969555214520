import React from 'react'
import {
  Button,
  FormControl,
  Select,
  TableContainer,
  TableHead,
  TableCell,
  Typography,
  makeStyles,
  Theme,
  MenuItem,
  Paper,
  Table,
  TableRow,
  Checkbox,
  TableBody,
  Grid,
  Toolbar as MuiToolbar,
  Link as MuiLink,
  alpha,
} from '@material-ui/core'
import { StringMap } from 'i18next'
import { uniq, without } from 'lodash'
import { Link } from 'react-router-dom'
import { useConfirmDialog } from '../../components/ConfirmDialog'
import { useI18n } from '../../hooks'
import { paths } from '../../paths'
import { CustomerStatusEnum } from '../../types/customer'
import { useOrgCustomers } from '../../customers/hooks/use-org-customers'
import { useSnackbar } from 'notistack'
import doublet from '../../utils/doublet'
import { useUrlWithContext } from '../../hooks/use-url-with-context'

const useOrgCustomersTable = ({ orgId }: { orgId: string }) => {
  const { customers, activeCustomers, inactiveCustomers, updateCustomerStatus, canUpdateAnyCustomer } =
    useOrgCustomers(orgId)
  const [actionState, setActionState] = React.useState<ActionState>('idle')
  const [filter, setFilter] = React.useState<CustomerFilter>('active')
  const [selectedRows, setSelectedRows] = React.useState<string[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const translations = useTranslations(defaultTranslations)

  const selectedRowCount = selectedRows.length
  const isBusy = actionState === 'busy'
  const showActiveCustomers = filter === 'active'
  const showInactiveCustomers = filter === 'inactive'
  const showAllCustomers = filter === 'all'
  const formattedActivateBtnLabel = translations.activateBtnLabel.replace('{{count}}', `${selectedRowCount}`)
  const formattedDeactivateBtnLabel = translations.deactivateBtnLabel.replace('{{count}}', `${selectedRowCount}`)
  const activateOrDeactivateBtnLabel = showActiveCustomers ? formattedDeactivateBtnLabel : formattedActivateBtnLabel
  const customersToShow = showActiveCustomers ? activeCustomers : showInactiveCustomers ? inactiveCustomers : customers
  const idsOfCustomersToShow = customersToShow.map((c) => c.id)
  const enableRowSelection = canUpdateAnyCustomer && Boolean(customersToShow.length) && !showAllCustomers
  const disableActivateOrDeactivateBtn = !selectedRowCount || isBusy

  const onChangeFilter = (filter: CustomerFilter) => {
    setSelectedRows([])
    setFilter(filter)
  }
  const onConfirmDeactivateCustomers = async () => {
    setActionState('busy')
    for (let customerId of selectedRows) {
      const updateData = { status: CustomerStatusEnum.inActive }
      const [error] = await doublet(updateCustomerStatus, customerId, updateData)
      if (error) {
        const defaultErrorMessage = 'Failed to deactivate customer'
        const message = error.message || error || defaultErrorMessage
        enqueueSnackbar(message, { variant: 'error' })
      } else {
        const message = `${selectedRowCount} customers deactivated`
        enqueueSnackbar(message, { variant: 'success' })
      }
    }
    setSelectedRows([])
    setActionState('idle')
  }

  const onConfirmActivateCustomers = async () => {
    setActionState('busy')
    for (let customerId of selectedRows) {
      const updateData = { status: CustomerStatusEnum.active }
      const [error] = await doublet(updateCustomerStatus, customerId, updateData)
      if (error) {
        const defaultErrorMessage = 'Failed to activate customer'
        const message = error.message || error || defaultErrorMessage
        enqueueSnackbar(message, { variant: 'error' })
      } else {
        const message = `${selectedRowCount} customers activated`
        enqueueSnackbar(message, { variant: 'success' })
      }
    }
    setSelectedRows([])
    setActionState('idle')
  }

  const { confirm: confirmDeactivateCustomers } = useConfirmDialog({
    onConfirm: onConfirmDeactivateCustomers,
    primaryActionButtonLabel: formattedDeactivateBtnLabel,
    title: translations.deactivateDialogTitle,
    text: translations.deactivateDialogText.replace('{{count}}', `${selectedRowCount}`),
  })

  const { confirm: confirmActivateCustomers } = useConfirmDialog({
    onConfirm: onConfirmActivateCustomers,
    primaryActionButtonLabel: formattedActivateBtnLabel,
    title: translations.activateDialogTitle,
    text: translations.activateDialogText.replace('{{count}}', `${selectedRowCount}`),
  })

  const onClickActivateOrDeactivateBtn = showActiveCustomers ? confirmDeactivateCustomers : confirmActivateCustomers

  return {
    selectedRows,
    setSelectedRows,
    selectedRowCount,
    filter,
    onChangeFilter,
    isBusy,
    activateOrDeactivateBtnLabel,
    idsOfCustomersToShow,
    customersToShow,
    onClickActivateOrDeactivateBtn,
    enableRowSelection,
    disableActivateOrDeactivateBtn,
  }
}

const OrgCustomersTable = ({ orgId }: { orgId: string }) => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const { createPathWithGivenContext } = useUrlWithContext()

  const {
    customersToShow,
    idsOfCustomersToShow,
    selectedRows,
    setSelectedRows,
    onChangeFilter,
    filter,
    activateOrDeactivateBtnLabel,
    onClickActivateOrDeactivateBtn,
    enableRowSelection,
    disableActivateOrDeactivateBtn,
  } = useOrgCustomersTable({ orgId })

  return (
    <div className={classes.tableRoot}>
      {/** Toolbar  */}
      <MuiToolbar variant="dense" disableGutters>
        <Grid container spacing={1} justifyContent={'space-between'}>
          <Grid item>
            {enableRowSelection && (
              <Button size="small" onClick={onClickActivateOrDeactivateBtn} disabled={disableActivateOrDeactivateBtn}>
                {activateOrDeactivateBtnLabel}
              </Button>
            )}
          </Grid>
          <Grid item>
            <FormControl>
              <Select
                variant="outlined"
                value={filter}
                onChange={(e) => onChangeFilter(e.target.value as CustomerFilter)}
              >
                <MenuItem value={'all'}>{translations.allCustomersOption}</MenuItem>
                <MenuItem value={'active'}>{translations.activeCustomersOption}</MenuItem>
                <MenuItem value={'inactive'}>{translations.inactiveCustomersOption}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </MuiToolbar>
      {/** Table  */}
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="customers table">
          <TableHead>
            <TableRow>
              {enableRowSelection && (
                <TableCell padding="checkbox">
                  <Checkbox
                    size="small"
                    color="primary"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedRows([...idsOfCustomersToShow])
                      } else {
                        setSelectedRows([])
                      }
                    }}
                    checked={Boolean(selectedRows.length && selectedRows.length === idsOfCustomersToShow.length)}
                    indeterminate={Boolean(selectedRows.length && selectedRows.length !== idsOfCustomersToShow.length)}
                  />
                </TableCell>
              )}
              <TableCell padding="checkbox">{translations.customerNameHeader}</TableCell>
              <TableCell>{translations.customerNumberHeader}</TableCell>
              <TableCell>{translations.customerLanguageHeader}</TableCell>
              <TableCell>{translations.customerContactHeader}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customersToShow.map(($customer: any) => {
              return (
                <TableRow key={$customer.id}>
                  {enableRowSelection && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={selectedRows.includes($customer.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedRows((prev) => uniq([...prev, $customer.id]))
                          } else {
                            setSelectedRows((prev) => without(prev, $customer.id))
                          }
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell padding="checkbox">
                    {$customer.homePage ? (
                      <MuiLink
                        component={Link}
                        to={createPathWithGivenContext({
                          path: paths.home(),
                          mainContextId: orgId,
                          subContextId: $customer.id,
                        })}
                      >
                        {$customer.fullname}
                      </MuiLink>
                    ) : (
                      $customer.fullname
                    )}
                  </TableCell>
                  <TableCell>{$customer.customerNumber}</TableCell>
                  <TableCell>{$customer.language}</TableCell>
                  <TableCell>
                    {$customer.defaultContact && $customer.homePage ? (
                      <MuiLink
                        component={Link}
                        to={createPathWithGivenContext({
                          path: `${$customer.homePage}/settings?tab=contact&contact=${$customer.defaultContact.id}`,
                          mainContextId: orgId,
                          subContextId: $customer.id,
                        })}
                      >
                        {`${$customer.defaultContact.firstname} ${$customer.defaultContact.lastname}`}
                      </MuiLink>
                    ) : $customer.defaultContact ? (
                      `${$customer.defaultContact.firstname} ${$customer.defaultContact.lastname}`
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {!idsOfCustomersToShow.length && (
          <Typography className="noData" color="textSecondary" variant="subtitle1">
            {translations.noCustomersText}
          </Typography>
        )}
      </TableContainer>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    padding: theme.spacing(0, 2),
    maxWidth: 1440,
    margin: 'auto',
  },
  header: {
    padding: theme.spacing(2, 0),
  },
  tableRoot: {
    maxWidth: 1440,
    borderRadius: 7,
    padding: theme.spacing(1, 0),
    width: '100%',
    background: theme.palette.common.white,
    '& .MuiToolbar-root': {
      padding: theme.spacing(0, 2),
      '& .MuiButton-root': {
        marginRight: theme.spacing(),
        transition: theme.transitions.create('background'),
        background: alpha(theme.palette.primary.main, 0.05),
        '&:hover': {
          background: alpha(theme.palette.primary.main, 0.1),
        },
      },
      '& .MuiSelect-outlined': {
        padding: '6px 9px',
        paddingRight: 32,
        fontSize: 14,
      },
    },
    '& .MuiTableContainer-root': {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
      '& .MuiTableRow-root th': {
        textTransform: 'uppercase',
        fontSize: 12,
        color: theme.palette.text.secondary,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      '& .MuiTableCell-paddingCheckbox': {
        padding: theme.spacing(0.5),
        width: 'auto',
      },
      '& .noData': {
        textAlign: 'center',
        padding: 20,
      },
    },
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('customer')
  const translations = (t?.customersPage || {}) as StringMap

  const {
    deactivateBtnLabel = defaults.deactivateBtnLabel,
    deactivateDialogTitle = defaults.deactivateDialogTitle,
    deactivateDialogText = defaults.deactivateDialogText,
    activateBtnLabel = defaults.activateBtnLabel,
    activateDialogTitle = defaults.activateDialogTitle,
    activateDialogText = defaults.activateDialogText,
    allCustomersOption = defaults.allCustomersOption,
    activeCustomersOption = defaults.activeCustomersOption,
    inactiveCustomersOption = defaults.inactiveCustomersOption,
    customerNameHeader = defaults.customerNameHeader,
    customerNumberHeader = defaults.customerNumberHeader,
    customerLanguageHeader = defaults.customerLanguageHeader,
    customerContactHeader = defaults.customerContactHeader,
    noCustomersText = defaults.noCustomersText,
  } = translations

  return {
    deactivateBtnLabel,
    deactivateDialogTitle,
    deactivateDialogText,
    activateBtnLabel,
    activateDialogTitle,
    activateDialogText,
    allCustomersOption,
    activeCustomersOption,
    inactiveCustomersOption,
    customerNameHeader,
    customerNumberHeader,
    customerLanguageHeader,
    customerContactHeader,
    noCustomersText,
  }
}

const defaultTranslations = {
  deactivateBtnLabel: 'Deactivate selected customers',
  deactivateDialogTitle: 'Deactivate customers',
  deactivateDialogText: 'Are you sure you want to deactivate {{count}} customers?',
  activateBtnLabel: 'Activate selected customers',
  activateDialogTitle: 'Activate customers',
  activateDialogText: 'Are you sure you want to activate {{count}} customers?',
  allCustomersOption: 'All customers',
  activeCustomersOption: 'Active customers',
  inactiveCustomersOption: 'Inactive customers',
  customerNameHeader: 'Customer name',
  customerNumberHeader: 'Customer number',
  customerLanguageHeader: 'Language',
  customerContactHeader: 'Contact',
  noCustomersText: 'No customers',
}

type CustomerFilter = 'all' | 'active' | 'inactive'
type ActionState = 'idle' | 'busy'
type Translations = typeof defaultTranslations

export default OrgCustomersTable
