import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'
import TablePagination from '@material-ui/core/TablePagination'
import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import { useI18n } from '../../hooks'

const BillingBreakdownTable = ({ breakdown }: BillingBreakdownTableProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const rowsToShow = breakdown.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{translations.member}</TableCell>
              <TableCell>{translations.accessLevel}</TableCell>
              <TableCell>{translations.price}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsToShow.map((breakdown) => (
              <TableRow key={breakdown.membershipId}>
                <TableCell>{breakdown.name}</TableCell>
                <TableCell>{breakdown.plan}</TableCell>
                <TableCell>
                  {breakdown.price !== breakdown.priceWithDiscount && (
                    <Typography component="span" className={classes.discount}>
                      {breakdown.price}
                    </Typography>
                  )}
                  &nbsp;&nbsp;
                  <Typography component="span" variant="subtitle1">
                    {breakdown.priceWithDiscount}&nbsp;&nbsp;
                  </Typography>
                  <Typography component="span" variant="caption">
                    EUR/{translations.month}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={breakdown.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={translations.membersPerPage}
          labelDisplayedRows={({ from, to, count }) => {
            return translations.showingMembers
              .replace('{{from}}', from.toString())
              .replace('{{to}}', to.toString())
              .replace('{{count}}', count.toString())
          }}
        />
      </TableContainer>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    tableContainer: {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.divider}`,
      '& th': {
        textTransform: 'uppercase',
        padding: theme.spacing(1.5),
        color: theme.palette.text.secondary,
        fontSize: theme.typography.caption.fontSize,
        letterSpacing: 1,
        fontWeight: theme.typography.fontWeightBold,
      },
      '& td, & th': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    discount: {
      color: theme.palette.grey[500],
      textDecoration: 'line-through',
    },
  }
})

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('org')
  const translations = t?.orgBillingPage || {}

  return {
    membersPerPage: translate('membersPerPage'),
    showingMembers: translate('showingMembers'),
    member: translate('member'),
    accessLevel: translate('accessLevel'),
    price: translate('price'),
    month: translate('month'),
  }

  function translate(key: keyof Translations) {
    return translations[key] || defaults[key]
  }
}

const defaultTranslations = {
  membersPerPage: 'Members per page',
  showingMembers: 'Showing {{from}} - {{to}} of {{count}} members',
  member: 'Member',
  accessLevel: 'Access level',
  price: 'Price',
  month: 'month',
}

type Translations = typeof defaultTranslations

type BillingBreakdownTableProps = {
  breakdown: BreakDown[]
}

type BreakDown = {
  membershipId: string
  name: string
  plan: string
  price: number
  priceWithDiscount: number
}

export default BillingBreakdownTable
