export const policies: Policies = {
  CreateCustomers: {
    enables: ['ReadAnyCustomer', 'UpdateAnyCustomer'],
    disables: [],
  },
  ReadAnyCustomer: {
    enables: [],
    disables: ['CreateCustomers', 'UpdateAnyCustomer'],
  },
  UpdateAnyCustomer: {
    enables: ['ReadAnyCustomer'],
    disables: ['CreateCustomers'],
  },
  CreateSuppliers: {
    enables: ['ReadAnySupplier', 'UpdateAnySupplier'],
    disables: [],
  },
  ReadAnySupplier: {
    enables: [],
    disables: ['CreateSuppliers', 'UpdateAnySupplier'],
  },
  UpdateAnySupplier: {
    enables: ['ReadAnySupplier'],
    disables: ['CreateSuppliers'],
  },
  CreateWorkspaces: {
    enables: ['ReadAnyWorkspace', 'UpdateAnyWorkspace'],
    disables: [],
  },
  ReadAnyWorkspace: {
    enables: [],
    disables: ['CreateWorkspaces', 'UpdateAnyWorkspace'],
  },
  UpdateAnyWorkspace: {
    enables: ['ReadAnyWorkspace'],
    disables: ['CreateWorkspaces'],
  },
  CreateMemberships: {
    enables: [
      'ReadAnyNormalMembershipDetails',
      'ReadAnyLimitedMembershipDetails',
      'UpdateAnyMembershipDetails',
      'UpdateAnyMembershipStatus',
      'UpdateAnyMembershipPermissions',
    ],
    disables: ['UpdateAnyMembershipPermissions'],
  },
  ReadAnyNormalMembershipDetails: {
    enables: [],
    disables: [
      'CreateMemberships',
      'ReadAnyLimitedMembershipDetails',
      'UpdateAnyMembershipDetails',
      'UpdateAnyMembershipStatus',
    ],
  },
  ReadAnyLimitedMembershipDetails: {
    enables: [],
    disables: ['UpdateAnyMembershipDetails', 'CreateMemberships', 'UpdateAnyMembershipStatus'],
  },
  UpdateAnyMembershipDetails: {
    enables: ['ReadAnyNormalMembershipDetails', 'ReadAnyLimitedMembershipDetails'],
    disables: ['CreateMemberships'],
  },
  UpdateAnyMembershipPermissions: {
    enables: ['CreateMemberships'],
    disables: ['CreateMemberships'],
  },
  UpdateAnyMembershipStatus: {
    enables: ['ReadAnyNormalMembershipDetails', 'ReadAnyLimitedMembershipDetails', 'UpdateAnyMembershipDetails'],
    disables: ['CreateMemberships'],
  },
  CreateProjects: {
    enables: ['CreateTasks'],
    disables: [],
  },
  ReadAnyProject: {
    enables: ['ReadAnyTask'],
    disables: [
      'UpdateAnyProjectDetails',
      'UpdateAnyProjectPlan',
      'UpdateAnyProjectStatus',
      'UpdateAnyProjectManagers',
      'UpdateAnyProjectParticipants',
      'UpdateAnyProjectCustomers',
      'UpdateAnyProjectSuppliers',
      'UpdateAnyProjectWorkspaces',
      'DeleteAnyProject',
    ],
  },
  UpdateAnyProjectDetails: {
    enables: ['ReadAnyProject', 'UpdateAnyTaskDetails'],
    disables: [],
  },
  UpdateAnyProjectPlan: {
    enables: ['ReadAnyProject'],
    disables: [],
  },
  UpdateAnyProjectStatus: {
    enables: ['ReadAnyProject', 'UpdateAnyTaskStatus'],
    disables: [],
  },
  UpdateAnyProjectManagers: {
    enables: ['ReadAnyProject', 'UpdateAnyProjectParticipants', 'UpdateAnyTaskManagers'],
    disables: [],
  },
  UpdateAnyProjectParticipants: {
    enables: ['ReadAnyProject', 'UpdateAnyTaskParticipants'],
    disables: ['UpdateAnyProjectManagers'],
  },
  UpdateAnyProjectCustomers: {
    enables: ['ReadAnyProject'],
    disables: [],
  },
  UpdateAnyProjectSuppliers: {
    enables: ['ReadAnyProject', 'UpdateAnyTaskSuppliers'],
    disables: [],
  },
  UpdateAnyProjectWorkspaces: {
    enables: ['ReadAnyProject', 'UpdateAnyTaskWorkspaces'],
    disables: [],
  },
  DeleteAnyProject: {
    enables: ['ReadAnyProject', 'DeleteAnyTask'],
    disables: [],
  },
  CreateTasks: {
    enables: [],
    disables: ['CreateProjects'],
  },
  ReadAnyTask: {
    enables: [],
    disables: [
      'ReadAnyProject',
      'UpdateAnyTaskDetails',
      'UpdateAnyTaskStatus',
      'UpdateAnyTaskManagers',
      'UpdateAnyTaskParticipants',
      'UpdateAnyTaskSuppliers',
      'UpdateAnyTaskWorkspaces',
      'DeleteAnyTask',
    ],
  },
  UpdateAnyTaskDetails: {
    enables: ['ReadAnyTask'],
    disables: ['UpdateAnyProjectDetails'],
  },
  UpdateAnyTaskStatus: {
    enables: ['ReadAnyTask'],
    disables: ['UpdateAnyProjectStatus'],
  },
  UpdateAnyTaskManagers: {
    enables: ['ReadAnyTask', 'UpdateAnyTaskParticipants'],
    disables: ['UpdateAnyProjectManagers'],
  },
  UpdateAnyTaskParticipants: {
    enables: ['ReadAnyTask'],
    disables: ['UpdateAnyTaskManagers', 'UpdateAnyProjectParticipants'],
  },
  UpdateAnyTaskSuppliers: {
    enables: ['ReadAnyTask'],
    disables: ['UpdateAnyProjectSuppliers'],
  },
  UpdateAnyTaskWorkspaces: {
    enables: ['ReadAnyTask'],
    disables: ['UpdateAnyProjectWorkspaces'],
  },
  DeleteAnyTask: {
    enables: ['ReadAnyTask'],
    disables: ['DeleteAnyProject'],
  },
  CreateCalendarEvents: {
    enables: [],
    disables: [],
  },
  ReadAnyCalendarEvent: {
    enables: [],
    disables: ['UpdateAnyCalendarEvent', 'DeleteAnyCalendarEvent'],
  },
  UpdateAnyCalendarEvent: {
    enables: ['ReadAnyCalendarEvent'],
    disables: [],
  },
  DeleteAnyCalendarEvent: {
    enables: ['ReadAnyCalendarEvent'],
    disables: [],
  },
  CreateTodos: {
    enables: [],
    disables: [],
  },
  ReadAnyTodo: {
    enables: [],
    disables: ['UpdateAnyTodo', 'DeleteAnyTodo'],
  },
  UpdateAnyTodo: {
    enables: ['ReadAnyTodo'],
    disables: [],
  },
  DeleteAnyTodo: {
    enables: ['ReadAnyTodo'],
    disables: [],
  },
  ReadOrganisation: {
    enables: [],
    disables: ['UpdateOrganisationDetails'],
  },
  UpdateOrganisationDetails: {
    enables: ['ReadOrganisation'],
    disables: [],
  },
  UpdateAnyRole: {
    enables: [],
    disables: [],
  },
  ReadCustomer: {
    enables: [],
    disables: [],
  },
  ReadSupplier: {
    enables: [],
    disables: [],
  },
  ReadWorkspace: {
    enables: [],
    disables: [],
  },
  ReadProject: {
    enables: [],
    disables: [
      'UpdateProjectDetails',
      'UpdateProjectPlan',
      'UpdateProjectStatus',
      'UpdateProjectManagers',
      'UpdateProjectParticipants',
      'UpdateProjectCustomers',
      'UpdateProjectSuppliers',
      'UpdateProjectWorkspaces',
      'DeleteProject',
    ],
  },
  UpdateProjectDetails: {
    enables: ['ReadProject'],
    disables: [],
  },
  UpdateProjectPlan: {
    enables: ['ReadProject'],
    disables: [],
  },
  UpdateProjectStatus: {
    enables: ['ReadProject'],
    disables: [],
  },
  UpdateProjectManagers: {
    enables: ['ReadProject', 'UpdateProjectParticipants'],
    disables: [],
  },
  UpdateProjectParticipants: {
    enables: ['ReadProject'],
    disables: ['UpdateProjectManagers'],
  },
  UpdateProjectCustomers: {
    enables: ['ReadProject'],
    disables: [],
  },
  UpdateProjectSuppliers: {
    enables: ['ReadProject'],
    disables: [],
  },
  UpdateProjectWorkspaces: {
    enables: ['ReadProject'],
    disables: [],
  },
  DeleteProject: {
    enables: ['ReadProject'],
    disables: [],
  },
  CreateProjectTasks: {
    enables: [],
    disables: [],
  },
  CreateProjectCalendarEvents: {
    enables: [],
    disables: [],
  },
  CreateProjectTodos: {
    enables: [],
    disables: [],
  },
  CreateSubTasks: {
    enables: [],
    disables: [],
  },
  ReadTask: {
    enables: [],
    disables: [
      'UpdateTaskDetails',
      'UpdateTaskStatus',
      'UpdateTaskManagers',
      'UpdateTaskParticipants',
      'UpdateTaskSuppliers',
      'UpdateTaskWorkspaces',
      'DeleteTask',
    ],
  },
  UpdateTaskDetails: {
    enables: ['ReadTask'],
    disables: [],
  },
  UpdateTaskStatus: {
    enables: ['ReadTask'],
    disables: [],
  },
  UpdateTaskManagers: {
    enables: ['ReadTask', 'UpdateTaskParticipants'],
    disables: [],
  },
  UpdateTaskParticipants: {
    enables: ['ReadTask'],
    disables: ['UpdateTaskManagers'],
  },
  UpdateTaskSuppliers: {
    enables: ['ReadTask'],
    disables: [],
  },
  UpdateTaskWorkspaces: {
    enables: ['ReadTask'],
    disables: [],
  },
  DeleteTask: {
    enables: ['ReadTask'],
    disables: [],
  },
  CreateTaskCalendarEvents: {
    enables: [],
    disables: [],
  },
  CreateTaskTodos: {
    enables: [],
    disables: [],
  },
}

type Policy = {
  enables: string[]
  disables: string[]
}

export type Policies = {
  [ability: string]: Policy
}
