import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import TodosViewHeader from '../todos/components/TodosViewHeader'
import TodoList from '../treegrid/todo-list/TodoList'
import { useAppContext } from '../hooks/use-app-context'
import { useAllOptions } from '../options/hooks/use-all-options'
import { useTodoMutations } from '../todos/hooks/use-todo-mutations'
import { useAuthUserMembership } from '../memberships/hooks/use-auth-user-membership'
import { todoApi, NewTodoData, TodoQueryParams } from '../todos/api'
import { useFirstDayOfWeek } from '../hooks/use-first-day-of-week'
import { useUserTimeZone } from '../hooks/use-user-time-zone'
import { useDateFormat } from '../users/hooks/use-date-format'
import { Theme, makeStyles } from '@material-ui/core'
import { getOptionsForMainContext } from '../options/utils'
import { getTodoQueryFromContext } from '../context-options/utils'
import { AppMainContextType, AppSubContextType } from '../types/app-context'
import { useI18n, useRouter } from '../hooks'
import { StringMap } from '../types/common'
import ErrorList from '../components/ErrorList'

const queryOptions = { refetchOnMountOrArgChange: true } as const

const TodosRoute = () => {
  const translations = useTranslations()
  const {
    appContext: { mainContext, subContext },
  } = useAppContext()
  const context = subContext || mainContext
  const router = useRouter()
  // @ts-expect-error - Ts is not aware of the query object
  const todosView = (router.query.view || 'currentAndFuture') as TodosViewOptions
  const query = makeTodosQuery(context, todosView)
  const { data: todos = [], isLoading, isError, status } = todoApi.useGetTodosQuery(query, queryOptions)
  const { options } = useAllOptions()
  const { createTodo } = useTodoMutations()
  const { canCreateTodos } = useAuthUserMembership()
  const classes = useStyles()
  const firstDayOfWeek = useFirstDayOfWeek()
  const userTimeZone = useUserTimeZone()
  const { dateFormat, dateSeparator } = useDateFormat()
  const [key, setKey] = React.useState(JSON.stringify(query))

  React.useEffect(() => {
    if (status === 'fulfilled') {
      // Need to recreate the treegrid when the query is changed and the data is completed refetching (status = fulfilled)
      setKey(JSON.stringify(query))
    }
  }, [status, query])

  if (isLoading) return <CircularProgress />
  if (!context || !mainContext) return <ErrorList errors={[translations.contextNotFoundError]} />
  if (isError) return <ErrorList errors={[translations.fetchErrorTodos]} />

  const canAddTodos = mainContext.type === 'user' || canCreateTodos

  function addTodo(newTodo: NewTodoData) {
    const newTodoData = { ...newTodo }
    if (mainContext && mainContext.type === 'org') {
      newTodoData.organisation = mainContext.id
    }
    if (context) {
      if (context.type === 'customer') {
        newTodoData.customers = [context.id]
      } else if (context.type === 'member') {
        newTodoData.responsible = [context.id]
      } else if (context.type === 'workspace') {
        newTodoData.workspaces = [context.id]
      }
    }
    return createTodo(newTodoData)
  }

  return (
    <div className={classes.viewContainer}>
      <TodosViewHeader title={context.name} />
      <TodoList
        key={key}
        todos={todos}
        gridInfo={`${context.name} - Todos`}
        dateFormat={dateFormat}
        dateSeparator={dateSeparator}
        firstDayOfWeek={firstDayOfWeek}
        timeZone={userTimeZone}
        options={getOptionsForMainContext(options, mainContext)}
        canCreateTodos={canAddTodos}
        onAddTodo={addTodo}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  viewContainer: {
    marginTop: theme.spacing(0.75),
    '& > :first-child': {
      marginBottom: theme.spacing(1.125),
    },
  },
}))

const makeTodosQuery = (
  context: AppMainContextType | AppSubContextType | null,
  view: TodosViewOptions
): TodoQueryParams => {
  if (view === 'completed') {
    return {
      completed: 'true',
      ...getTodoQueryFromContext(context),
    }
  }

  if (view === 'currentAndFuture') {
    return {
      completed: 'false',
      ...getTodoQueryFromContext(context),
    }
  }

  return getTodoQueryFromContext(context)
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = t || ({} as StringMap)

  const { contextNotFoundError = defaults.contextNotFoundError, fetchErrorTodos = defaults.fetchErrorTodos } =
    translations

  return {
    contextNotFoundError,
    fetchErrorTodos,
  }
}

const defaultTranslations = {
  contextNotFoundError: 'Context not found',
  fetchErrorTodos: 'Failed to fetch to-dos',
}
type Translations = typeof defaultTranslations
type TodosViewOptions = 'all' | 'currentAndFuture' | 'completed'

export default TodosRoute
