import React from 'react'

export const IDLE = 'idle'
export const PENDING = 'pending'
export const SUCCESS = 'success'
export const ERROR = 'error'

const defaultConfig: UseAsyncConfig = {
  immediate: true,
  initialData: null,
  resetToIdleInMs: null,
}

export const useAsync = <T, E = string>(
  asyncFunction: (...args: any) => Promise<T>,
  { immediate = true, initialData = null, resetToIdleInMs = null } = defaultConfig
) => {
  const [status, setStatus] = React.useState<AsyncStatus>('idle')
  const [data, setData] = React.useState<T | null>(initialData)
  const [error, setError] = React.useState<E | null>(null)
  // The execute function wraps asyncFunction and
  // handles setting state for pending, data, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = React.useCallback(
    (...args: any) => {
      setStatus(PENDING)
      setData(null)
      setError(null)
      return asyncFunction(...args)
        .then((data: any) => {
          setData(data)
          setStatus(SUCCESS)
        })
        .catch((error: any) => {
          setError(error?.message || error)
          setStatus(ERROR)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [asyncFunction]
  )

  const reset = React.useCallback(() => {
    setStatus(IDLE)
    setData(null)
    setError(null)
  }, [])
  // Status helpers
  const isIdle = React.useCallback(() => status === IDLE, [status])
  const isPending = React.useCallback(() => status === PENDING, [status])
  const isSuccess = React.useCallback(() => status === SUCCESS, [status])
  const isError = React.useCallback(() => status === ERROR, [status])

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  React.useEffect(() => {
    if (immediate) execute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, immediate])

  React.useEffect(() => {
    let timeoutId: undefined | NodeJS.Timeout
    if (status !== IDLE && status !== PENDING && resetToIdleInMs !== null) {
      timeoutId = setTimeout(() => {
        setStatus(IDLE)
        setError(null)
        setData(null)
      }, resetToIdleInMs)
    }
    return () => timeoutId && clearTimeout(timeoutId)
  }, [status, resetToIdleInMs])

  return {
    execute,
    status,
    setStatus,
    setError,
    reset,
    data,
    error,
    isIdle,
    isPending,
    isError,
    isSuccess,
  }
}

type AsyncStatus = typeof ERROR | typeof IDLE | typeof PENDING | typeof SUCCESS
type UseAsyncConfig = {
  immediate?: boolean
  initialData?: any
  resetToIdleInMs?: number | null
}
