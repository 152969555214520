import React from 'react'
import TextField from '@material-ui/core/TextField'

import { FocusEvent } from 'react'
import { useField } from 'formik'

const FormikInput = React.forwardRef(function FormikInput(props: any, ref) {
  const all = useField(props)
  const [field, meta, dispatch] = all
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout>()
  const cleanUpError = () => dispatch.setError(undefined)

  const handleBlur = function (e: FocusEvent<any>) {
    field.onBlur(e)
    props.onBlur?.(e)
    if (timeoutId) clearTimeout(timeoutId)
    setTimeoutId(setTimeout(cleanUpError, 5000))
  }

  React.useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [timeoutId])

  return (
    <TextField
      ref={ref}
      error={meta.touched && meta.error != null}
      helperText={meta.touched && meta.error}
      {...field}
      {...props}
      onBlur={handleBlur}
      FormHelperTextProps={{ 'data-test': `${props.name}-error` }}
    />
  )
})

export default FormikInput
