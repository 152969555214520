import {
  getCustomerOptions,
  getMembershipOptions,
  getSupplierOptions,
  getWorkspaceOptions,
  getOptionId,
  getOptionName,
} from '../../options/utils'
import { TreeGridTranslations } from '../hooks/use-translations'
import { Option, TreeGridColumn } from '../types'
import { makeEnumString, makeStateEnum, makeStatusEnum } from '../utils'
import { ActivityColumnName } from './types'

export const makeColumns = ({
  options,
  defaultVisibleColumns = getDefaultVisibleColumns(),
  defaultColumnOrder = getDefaultColumnOrder(),
  translations,
}: MakeColumnProps): TreeGridColumn[] => {
  const statusEnum = makeStatusEnum(translations)
  const stateEnum = makeStateEnum(translations)

  const customerOptions = getCustomerOptions(options)
  const customerNames = customerOptions.map(getOptionName)
  const customerIds = customerOptions.map(getOptionId)
  const customersEnum = makeEnumString(customerNames)
  const customersEnumKeys = makeEnumString(customerIds)

  const membershipOptions = getMembershipOptions(options)
  const membershipNames = membershipOptions.map(getOptionName)
  const membershipIds = membershipOptions.map(getOptionId)
  const membershipEnum = makeEnumString(membershipNames)
  const membershipEnumKeys = makeEnumString(membershipIds)

  const supplierOptions = getSupplierOptions(options)
  const supplierNames = supplierOptions.map(getOptionName)
  const supplierIds = supplierOptions.map(getOptionId)
  const suppliersEnum = makeEnumString(supplierNames)
  const suppliersEnumKeys = makeEnumString(supplierIds)

  const workspaceOptions = getWorkspaceOptions(options)
  const workspaceNames = workspaceOptions.map(getOptionName)
  const workspaceIds = workspaceOptions.map(getOptionId)
  const workspacesEnum = makeEnumString(workspaceNames)
  const workspacesEnumKeys = makeEnumString(workspaceIds)

  const hasCustomerOptions = customerOptions.length > 0
  const hasMembershipOptions = membershipOptions.length > 0
  const hasSupplierOptions = supplierOptions.length > 0
  const hasWorkspaceOptions = workspaceOptions.length > 0
  const isCustomerColumnVisible = hasCustomerOptions && defaultVisibleColumns.includes('customers')
  const isResponsibleColumnVisible = hasMembershipOptions && defaultVisibleColumns.includes('responsible')
  const isParticipantsColumnVisible = hasMembershipOptions && defaultVisibleColumns.includes('participants')
  const isSupplierColumnVisible = hasSupplierOptions && defaultVisibleColumns.includes('suppliers')
  const isWorkspaceColumnVisible = hasWorkspaceOptions && defaultVisibleColumns.includes('workspaces')

  const openColumn = {
    Name: 'open',
    Type: 'Icon',
    Visible: defaultVisibleColumns.includes('open'),
    Width: 32,
    CanExport: 0,
    CanPrint: 0,
    CanSort: 0,
    CanHide: 0,
    CanEdit: 0,
    CanMove: 0,
    header: '',
    Class: 'openButton',
  }

  const activityNumberColumn: ActivityListColumn = {
    Name: 'activityNumber',
    MinWidth: 80,
    CaseSensitive: 0,
    header: 'No.',
    Visible: defaultVisibleColumns.includes('activityNumber'),
  }
  const titleColumn: ActivityListColumn = {
    Name: 'title',
    MinWidth: 160,
    Type: 'Html',
    RelWidth: 1,
    CaseSensitive: 0,
    header: translations.titleColumnHeader,
    Visible: defaultVisibleColumns.includes('title'),
  }

  const descriptionColumn: ActivityListColumn = {
    Name: 'description',
    Visible: defaultVisibleColumns.includes('description'),
    CaseSensitive: 0,
    Type: 'Lines',
    MinWidth: 200,
    AcceptEnters: 1,
    header: translations.descriptionColumnHeader,
    CanSort: 0,
  }

  const plannedStartDateColumn: ActivityListColumn = {
    Name: 'plannedStartDate',
    Type: 'Date',
    Width: 154,
    Visible: defaultVisibleColumns.includes('plannedStartDate'),
    header: translations.plannedStartDateColumnHeader,
  }

  const plannedEndDateColumn: ActivityListColumn = {
    Name: 'plannedEndDate',
    Type: 'Date',
    Width: 154,
    Visible: defaultVisibleColumns.includes('plannedEndDate'),
    header: translations.plannedEndDateColumnHeader,
  }

  const actualStartDateColumn: ActivityListColumn = {
    Name: 'actualStartDate',
    Type: 'Date',
    Width: 154,
    Visible: defaultVisibleColumns.includes('actualStartDate'),
    header: translations.actualStartDateColumnHeader,
  }

  const statusColumn: ActivityListColumn = {
    Name: 'status',
    Type: 'Enum',
    Enum: statusEnum,
    MinWidth: 120,
    Visible: defaultVisibleColumns.includes('status'),
    header: translations.statusColumnHeader,
  }

  const stateColumn: ActivityListColumn = {
    Name: 'state',
    Type: 'Enum',
    Enum: stateEnum,
    CanSort: 0,
    CanExport: 0,
    MinWidth: 64,
    MaxWidth: 64,
    Visible: defaultVisibleColumns.includes('state'),
    header: translations.stateColumnHeader,
  }

  const statusDescriptionColumn: ActivityListColumn = {
    Name: 'statusDescription',
    Visible: defaultVisibleColumns.includes('statusDescription'),
    CaseSensitive: 0,
    Type: 'Lines',
    MinWidth: 200,
    RelWidth: 1,
    AcceptEnters: 1,
    header: translations.statusDescriptionColumnHeader,
    CanSort: 0,
  }

  const customersColumn: ActivityListColumn = {
    Name: 'customers',
    Type: 'Enum',
    Range: true,
    header: translations.customersColumnHeader,
    CanHide: hasCustomerOptions ? 1 : 0,
    Visible: isCustomerColumnVisible,
    Enum: customersEnum,
    EnumKeys: customersEnumKeys,
  }

  const responsibleColumn: ActivityListColumn = {
    Name: 'responsible',
    Type: 'Enum',
    Range: true,
    MinWidth: 120,
    CanHide: hasMembershipOptions ? 1 : 0,
    Visible: isResponsibleColumnVisible ? 1 : 0,
    header: translations.responsibleColumnHeader,
    Enum: membershipEnum,
    EnumKeys: membershipEnumKeys,
  }

  const participantsColumn: ActivityListColumn = {
    Name: 'participants',
    Type: 'Enum',
    Range: true,
    MinWidth: 120,
    CanHide: hasMembershipOptions ? 1 : 0,
    Visible: isParticipantsColumnVisible ? 1 : 0,
    header: translations.participantsColumnHeader,
    Enum: membershipEnum,
    EnumKeys: membershipEnumKeys,
  }

  const suppliersColumn: ActivityListColumn = {
    Name: 'suppliers',
    Type: 'Enum',
    Range: true,
    MinWidth: 120,
    CanHide: hasSupplierOptions ? 1 : 0,
    Visible: isSupplierColumnVisible ? 1 : 0,
    header: translations.suppliersColumnHeader,
    Enum: suppliersEnum,
    EnumKeys: suppliersEnumKeys,
  }

  const workspacesColumn: ActivityListColumn = {
    Name: 'workspaces',
    Type: 'Enum',
    Range: true,
    MinWidth: 120,
    CanHide: hasWorkspaceOptions ? 1 : 0,
    Visible: isWorkspaceColumnVisible ? 1 : 0,
    header: translations.workspacesColumnHeader,
    Enum: workspacesEnum,
    EnumKeys: workspacesEnumKeys,
  }

  const columnsByName = {
    customers: customersColumn,
    activityNumber: activityNumberColumn,
    title: titleColumn,
    description: descriptionColumn,
    responsible: responsibleColumn,
    workspaces: workspacesColumn,
    suppliers: suppliersColumn,
    plannedStartDate: plannedStartDateColumn,
    plannedEndDate: plannedEndDateColumn,
    actualStartDate: actualStartDateColumn,
    status: statusColumn,
    state: stateColumn,
    statusDescription: statusDescriptionColumn,
    participants: participantsColumn,
    open: openColumn,
  }

  const columns: TreeGridColumn[] = defaultColumnOrder.map((columnName) => columnsByName[columnName])
  return columns.concat({ Name: 'Panel', Visible: 0, CanHide: 0 })
}

type MakeColumnProps = {
  options: Option[]
  defaultVisibleColumns?: ActivityColumnName[]
  defaultColumnOrder?: ActivityColumnName[]
  translations: TreeGridTranslations
}

function getDefaultColumnOrder(): ActivityColumnName[] {
  return [
    'open',
    'customers',
    'activityNumber',
    'title',
    'description',
    'responsible',
    'participants',
    'workspaces',
    'suppliers',
    'plannedStartDate',
    'plannedEndDate',
    'actualStartDate',
    'status',
    'state',
    'statusDescription',
  ]
}

function getDefaultVisibleColumns(): ActivityColumnName[] {
  return [
    'open',
    'customers',
    'activityNumber',
    'title',
    'responsible',
    'plannedStartDate',
    'plannedEndDate',
    'status',
    'state',
    'statusDescription',
  ]
}

type ActivityListColumn = TreeGridColumn & {
  Name: ActivityColumnName
}
