import React from 'react'
import InvitationsView from '../invitations/views/InvitationsView'
import { Alert, AlertTitle } from '@material-ui/lab'
import { unwrapResult } from '@reduxjs/toolkit'
import { FetchUserInvitations } from '../invitations/store/actions'
import { useAsync } from '../hooks/use-async'
import { useAppDispatch } from '../store'
import Progress from '../components/Progress'

const useLoader = () => {
  const dispatch = useAppDispatch()
  const loader = React.useCallback(async () => {
    dispatch({ type: 'load_invitations_route' })
    unwrapResult(await dispatch(FetchUserInvitations()))
  }, [dispatch])
  return useAsync<void, any>(loader)
}

const InvitationsRoute = () => {
  const { error, ...status } = useLoader()

  if (status.isError()) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error?.toString()}
      </Alert>
    )
  }

  if (status.isSuccess()) {
    return <InvitationsView />
  }

  return <Progress label="" />
}

export default InvitationsRoute
