import { createSelector } from '@reduxjs/toolkit'
import { RootState, useAppSelector } from '../store'
import { MONDAY } from '../constants'

const DEFAULT_FIRST_DAY_OF_WEEK = MONDAY

export const useFirstDayOfWeek = (orgId?: string) => {
  return useAppSelector((state) => getFirstDayOfWeek(state, orgId))
}

const getFirstDayOfWeek = createSelector(
  (state: RootState) => state.orgs.byIds,
  (state: RootState) => state.auth.user?.firstDayOfWeek,
  (state: RootState, orgId?: string) => orgId,
  (orgs, firstDayOfWeek, orgId) => {
    if (orgId && orgs[orgId]) return orgs[orgId].firstDayOfWeek
    return firstDayOfWeek === 0 || firstDayOfWeek ? firstDayOfWeek : DEFAULT_FIRST_DAY_OF_WEEK
  }
)
