import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormikInput from '../../../../components/FormikInput'
import InputLabel from '../../../../components/InputLabel'
import FormErrorAlert from '../../../../components/FormErrorAlert'
import { Plus } from 'react-feather'
import { Form, Formik } from 'formik'
import { makeStyles, Theme } from '@material-ui/core'
import { useI18n, useNetworkStatus } from '../../../../hooks'
import { paths } from '../../../../paths'
import { useHistory } from 'react-router-dom'
import { identity, pickBy } from 'lodash'
import { useAppDispatch } from '../../../../store'
import { CreateSupplier } from '../../../../suppliers/store/actions'
import { useAppContext } from '../../../../hooks/use-app-context'
import { useUrlWithContext } from '../../../../hooks/use-url-with-context'
import { makeValidateSupplierNumber } from '../../../../suppliers/utils/valitation'
import { StringMap } from '../../../../types/common'

const NewSupplierRoute = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const { appContext } = useAppContext()
  const { mainContext } = appContext

  if (mainContext?.type !== 'org') {
    return (
      <>
        <img src="/exclamation.svg" alt="Select organisation" height="64px" className={classes.exclamation} />
        <Typography variant="subtitle1" color="textSecondary" style={{ textAlign: 'center', marginTop: 24 }}>
          {translations.selectOrganisationMessage}
        </Typography>
      </>
    )
  }

  return (
    <Paper elevation={0} className={classes.container} data-test="new-supplier-card">
      <Typography variant="h3" className={classes.pageHeader}>
        {translations.pageHeader}
      </Typography>
      <SupplierForm />
    </Paper>
  )
}

const SupplierForm = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const translations = useTranslations(defaultTranslations)
  const { appContext } = useAppContext()
  const { createPathWithGivenContext } = useUrlWithContext()
  const { mainContext } = appContext
  const orgId = mainContext?.id as string
  const { setStatus, isIdle, isPending, isRejected } = useNetworkStatus({
    resetDelayInMS: 3000,
  })

  const handleSubmit = async (supplier: SupplierFormValues) => {
    setStatus('pending')
    try {
      const cleaned = pickBy(supplier, identity) as SupplierFormValues
      const actionResult = await dispatch(CreateSupplier({ orgId, ...cleaned }))
      const status = actionResult?.meta?.requestStatus
      if (status === 'rejected') throw actionResult
      setStatus(status)
      const supplierId = (actionResult.payload as any).id
      const supplierHomeURLWithContext = createPathWithGivenContext({
        path: paths.supplierSettings(),
        mainContextId: orgId,
        subContextId: supplierId,
      })
      history.push(`${supplierHomeURLWithContext}&created=now`)
    } catch (error) {
      setStatus('rejected')
    }
  }

  const formikProps = {
    initialValues: {
      name: '',
      supplierNumber: '',
    },
    onSubmit: handleSubmit,
    validate: makeValidate(orgId, translations),
  }

  let createButtonStartIcon: null | JSX.Element = null
  let createButtonEndIcon: null | JSX.Element = <Plus size={16} />
  let submitButtonLabel = translations.submitButtonLabel_idle

  if (isPending()) {
    createButtonStartIcon = <CircularProgress size={16} />
    createButtonEndIcon = null
    submitButtonLabel = translations.submitButtonLabel_pending
  }

  return (
    <Formik {...formikProps}>
      {({ errors, values }) => {
        const shouldDisableButton = Boolean(values.name === '' || errors.name || errors.supplierNumber || !isIdle())
        return (
          <Form className={classes.form}>
            <InputLabel label={translations.supplierNameLabel} isRequired={true} />
            <FormikInput
              name="name"
              variant="outlined"
              placeholder={translations.supplierNamePlaceholder}
              fullWidth
              disabled={!isIdle()}
              inputProps={{ 'data-test': 'supplier-name-input' }}
            />
            <InputLabel label={translations.supplierNumberLabel} isRequired={false} />
            <FormikInput
              name="supplierNumber"
              variant="outlined"
              placeholder={translations.supplierNumberPlaceholder}
              fullWidth
              disabled={!isIdle()}
              inputProps={{ 'data-test': 'supplier-number-input' }}
            />
            {isRejected() && (
              <FormErrorAlert title={translations.errorAlertTitle} description={translations.errorAlertDescription} />
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={createButtonStartIcon}
              endIcon={createButtonEndIcon}
              disabled={shouldDisableButton}
              data-test="create-supplier-button"
            >
              {submitButtonLabel}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(4, 'auto'),
    padding: theme.spacing(4),
    maxWidth: theme.breakpoints.values['sm'],
    border: `1px solid ${theme.palette.divider}`,
  },
  pageHeader: { marginBottom: theme.spacing(5) },
  form: {
    '& > div': {
      transition: theme.transitions.create('margin'),
      marginBottom: theme.spacing(3),
      '&:nth-last-child(2)': {
        marginBottom: theme.spacing(5),
      },
      '&:nth-last-child(1)': {
        marginTop: theme.spacing(3),
      },
    },
  },
  exclamation: {
    display: 'block',
    margin: 'auto',
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const { translations: supplierTranslations } = useI18n('supplier')
  const supplierForm = (supplierTranslations.supplierForm || {}) as StringMap
  const translations = t || ({} as StringMap)

  const somethingWentWrongError = translations.appFallbackErrorMessage || defaults.somethingWentWrongError
  const {
    pageHeader = defaults.pageHeader,
    selectOrganisationMessage = defaults.selectOrganisationMessage,
    supplierNameLabel = defaults.supplierNameLabel,
    supplierNamePlaceholder = defaults.supplierNamePlaceholder,
    supplierNumberLabel = defaults.supplierNumberLabel,
    supplierNumberPlaceholder = defaults.supplierNumberPlaceholder,
    submitButtonLabel_idle = defaults.submitButtonLabel_idle,
    submitButtonLabel_pending = defaults.submitButtonLabel_pending,
    errorAlertTitle = defaults.errorAlertTitle,
    errorAlertDescription = defaults.errorAlertDescription,
    invalidSupplierNumberError = defaults.invalidSupplierNumberError,
    duplicateSupplierNumberError = defaults.duplicateSupplierNumberError,
    numberOnlySupplierNumberError = defaults.numberOnlySupplierNumberError,
  } = supplierForm

  return {
    pageHeader,
    selectOrganisationMessage,
    supplierNameLabel,
    supplierNamePlaceholder,
    supplierNumberLabel,
    supplierNumberPlaceholder,
    submitButtonLabel_idle,
    submitButtonLabel_pending,
    errorAlertTitle,
    errorAlertDescription,
    invalidSupplierNumberError,
    duplicateSupplierNumberError,
    numberOnlySupplierNumberError,
    somethingWentWrongError,
  }
}
const defaultTranslations = {
  pageHeader: "Let's add a new supplier",
  selectOrganisationMessage: 'Select an organisation before creating a new supplier',
  supplierNameLabel: 'Supplier name',
  supplierNamePlaceholder: 'e.g. Starbrix Intl',
  supplierNumberLabel: 'Supplier number',
  supplierNumberPlaceholder: 'e.g. 12345',
  submitButtonLabel_idle: 'Create supplier',
  submitButtonLabel_pending: 'Creating supplier...',
  errorAlertTitle: 'Error',
  errorAlertDescription: 'Failed to create supplier',
  invalidSupplierNumberError: 'Supplier number is not valid',
  duplicateSupplierNumberError: 'Supplier number already exists',
  numberOnlySupplierNumberError: 'Supplier number can contain numbers only',
  somethingWentWrongError: 'Something went wrong',
}

const makeValidate = (orgId: string, translations: Translations) => {
  const validateSupplierNumber = makeValidateSupplierNumber({ orgId, translations })
  return async (values: SupplierFormValues) => {
    const errors = {} as ValidationErrors
    const { name, supplierNumber } = values

    if (!name || typeof name !== 'string') {
      errors.name = 'Name is required'
    }

    if (supplierNumber) {
      const validationResult = await validateSupplierNumber({ supplierNumber })
      if (validationResult.supplierNumber) {
        errors.supplierNumber = validationResult.supplierNumber
      }
    }

    return errors
  }
}

type ValidationErrors = { [k in keyof SupplierFormValues]: string }

type SupplierFormValues = {
  name: string
  supplierNumber: string
}

type Translations = typeof defaultTranslations

export default NewSupplierRoute
