import { createAsyncThunk } from '@reduxjs/toolkit'
import { IOptionsAPI } from '../interfaces/options-api'
import * as OptionsAPI from '../services/options-api'

const makeFetchOptions = (optionsAPI: IOptionsAPI) => {
  return createAsyncThunk('options/fetchOptions', async (orgId: string) => {
    return await optionsAPI.fetchOptions(orgId)
  })
}

const makeFetchUserOptions = (optionsAPI: IOptionsAPI) => {
  return createAsyncThunk('options/fetchUserOptions', async () => {
    return await optionsAPI.fetchUserOptions()
  })
}

export const FetchOptions = makeFetchOptions(OptionsAPI)
export const FetchUserOptions = makeFetchUserOptions(OptionsAPI)
