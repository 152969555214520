import DefaultContactCard from '../../components/DefaultContactCard'

import { Avatar } from '@material-ui/core'
import { startCase, values } from 'lodash'
import { useDispatch } from 'react-redux'
import { ChangeSupplierDefaultContact } from '../store/actions'
import type { ISupplierNormalized } from '../interfaces/supplier-normalized'

const SupplierDefaultContact = ({ supplier }: SupplierDefaultContactProps) => {
  const dispatch = useDispatch()
  if (!supplier) return null
  const contactList = values(supplier.contacts)

  /**
   *  "contactSelectItems",
   *  list items to be passed to async select component,
   *  which renders these items as a list,
   *  from which one item can be selected as default contact
   */
  const contactListItems = contactList.map((contact) => {
    const initials = contact.firstname[0] + contact.lastname[0]
    const fullname = `${contact.firstname} ${contact.lastname}`
    const isDefaultContact = supplier.defaultContact?.id === contact.id

    const onSelect = () => {
      return dispatch(
        ChangeSupplierDefaultContact({
          supplierId: contact.supplierId as string,
          contactId: contact.id,
        })
      ) as unknown as Promise<unknown>
    }

    const avatar = <Avatar>{initials.toUpperCase()}</Avatar>

    return {
      value: contact.id,
      primaryText: startCase(fullname),
      secondaryText: isDefaultContact ? 'Default contact' : '',
      onSelect,
      selected: isDefaultContact,
      avatar,
    }
  })

  return (
    <DefaultContactCard
      defaultContact={supplier.defaultContact}
      contactListItems={contactListItems}
      actionable={supplier.isActive}
    />
  )
}

type SupplierDefaultContactProps = {
  supplier: ISupplierNormalized
}

export default SupplierDefaultContact
