import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'

import { Mail } from 'react-feather'
import { useHistory } from 'react-router'
import { useUserInvitations } from '../../../invitations/hooks/use-user-invitations'
import { useUrlWithContext } from '../../../hooks/use-url-with-context'
import { COLOR_WHITE } from '../../../constants'

const InvitationsMailIcon = () => {
  const history = useHistory()
  const { createPathWithContext } = useUrlWithContext()
  const { pendingInvitations } = useUserInvitations()
  const handleClick = () => {
    const path = createPathWithContext('/invitations')
    history.push(path)
  }
  return (
    <IconButton onClick={handleClick}>
      <Badge overlap="rectangular" badgeContent={pendingInvitations.length} color="secondary">
        <Mail size={20} color={COLOR_WHITE} />
      </Badge>
    </IconButton>
  )
}

export default InvitationsMailIcon
