import { PayloadAction } from '@reduxjs/toolkit'
import { WorkspacesState } from './index'
import { IWorkspaceResponse } from '../interfaces/workspace-response'
import { normalizeWorkspace } from '../interfaces/workspace-normalized'
import { difference, omitBy } from 'lodash'

export const setWorkspaceCase = (state: WorkspacesState, action: PayloadAction<IWorkspaceResponse>) => {
  const workspace = action.payload
  state.byIds[workspace.id] = normalizeWorkspace(workspace)
  if (!state.allIds.includes(workspace.id)) state.allIds.push(workspace.id)
}

export const setWorkspacesCase = (state: WorkspacesState, action: PayloadAction<IWorkspaceResponse[]>) => {
  action.payload.forEach((workspace) => {
    state.byIds[workspace.id] = normalizeWorkspace(workspace)
    if (!state.allIds.includes(workspace.id)) state.allIds.push(workspace.id)
  })
}

export const deleteOrganisationCase = (state: WorkspacesState, action: PayloadAction<string>) => {
  const workspaceIdsToDelete: string[] = []

  state.allIds.forEach((id) => {
    const workspace = state.byIds[id]
    if (workspace.orgId === action.payload) workspaceIdsToDelete.push(id)
  })

  state.byIds = omitBy(state.byIds, { orgId: action.payload })
  state.allIds = difference(state.allIds, workspaceIdsToDelete)
}
