import { useGetBoardQuery } from '../boards/api'
import { useAppContext } from '../hooks/use-app-context'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useAuthUserMembership } from '../memberships/hooks/use-auth-user-membership'
import BoardView from '../boards/components/BoardView'
import { makePopulatedBoard } from '../boards/api/board'
import { useBoardMutations } from '../boards/hooks/use-board-mutations'
import { useOnMoveCard } from '../boards/hooks/use-on-move-card'
import { getBoardQueryFromContext } from '../context-options/utils'
import { useI18n } from '../hooks'
import { StringMap } from '../types/common'
import ErrorList from '../components/ErrorList'

const queryOptions = { refetchOnMountOrArgChange: true } as const

const KanbanRoute = () => {
  const translations = useTranslations()
  const {
    appContext: { mainContext, subContext },
  } = useAppContext()
  const context = subContext || mainContext
  const query = getBoardQueryFromContext(context)
  const { data, isLoading, isError } = useGetBoardQuery(query, queryOptions)
  const { canCreateTodos } = useAuthUserMembership()
  const { addTodoCard, updateColumnName } = useBoardMutations()
  const { onMoveCard } = useOnMoveCard()

  if (isLoading) return <CircularProgress />
  if (!context || !mainContext) return <ErrorList errors={[translations.contextNotFoundError]} />
  if (isError) return <ErrorList errors={[translations.fetchErrorBoard]} />
  if (!data) return <ErrorList errors={[translations.boardNotFoundError]} />

  const { board, tasks, todos } = data
  const populatedBoard = makePopulatedBoard({ board, tasks, todos })
  const canAddTodos = mainContext.type === 'user' || canCreateTodos

  return (
    <BoardView
      title={context.name}
      board={populatedBoard}
      canAddTasks={false}
      canAddTodos={canAddTodos}
      onAddTask={() => {}}
      onAddTodo={addTodoCard}
      onMoveCard={onMoveCard}
      onSaveColumnName={updateColumnName}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = t || ({} as StringMap)

  const {
    contextNotFoundError = defaults.contextNotFoundError,
    fetchErrorBoard = defaults.fetchErrorBoard,
    boardNotFoundError = defaults.boardNotFoundError,
  } = translations

  return {
    boardNotFoundError,
    contextNotFoundError,
    fetchErrorBoard,
  }
}

const defaultTranslations = {
  contextNotFoundError: 'Context not found',
  fetchErrorBoard: 'Failed to fetch board',
  boardNotFoundError: 'Board not found',
}
type Translations = typeof defaultTranslations

export default KanbanRoute
