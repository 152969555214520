import * as actions from './actions'
import { createSlice } from '@reduxjs/toolkit'
import { IUserNormalized } from '../interfaces/user-normalized'
import {
  resetAuthStateCase,
  setAccessTokenCase,
  setAuthErrorCase,
  setAuthStateCase,
  setRefreshTokenCase,
  setUserCase,
} from './case-reducers'

const refreshTokenInStorage = localStorage.getItem('starbrix_refresh_token')
const parsedRefreshToken = refreshTokenInStorage ? JSON.parse(refreshTokenInStorage) : null

const initialState: AuthState = {
  isAuthenticated: parsedRefreshToken != null,
  isRefreshingToken: false,
  user: null,
  refreshToken: parsedRefreshToken,
  accessToken: null,
  tokenExpiresAt: null,
  authError: null,
}

const usersSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    SetAuthState: setAuthStateCase,
    SetRefreshToken: setRefreshTokenCase,
  },
  extraReducers({ addCase }) {
    addCase(actions.FetchUser.fulfilled, setUserCase)
    addCase(actions.VerifyAccount.fulfilled, setUserCase)
    addCase(actions.RefreshAccessToken.fulfilled, setAccessTokenCase)
    addCase(actions.RefreshAccessToken.rejected, setAuthErrorCase)
    addCase(actions.UpdateUserInfo.fulfilled, setUserCase)
    addCase(actions.ResetAuthState.toString(), resetAuthStateCase)
    addCase(actions.ClearStorageAndAuthState.fulfilled, resetAuthStateCase)
  },
})

export const { SetAuthState, SetRefreshToken } = usersSlice.actions
export default usersSlice.reducer

export type AuthState = {
  isAuthenticated: boolean
  isRefreshingToken: boolean
  user: null | IUserNormalized
  refreshToken: null | string
  accessToken: null | string
  tokenExpiresAt: null | number
  authError: string | null
}
