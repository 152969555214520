import { RouteComponentProps } from 'react-router-dom'
import Board from '../../../boards/components/Board'
import { useGetBoardQuery } from '../../../boards/api'
import { makePopulatedBoard } from '../../../boards/api/board'
import { useProject } from '../../../projects/hooks/use-project'
import { useBoardMutations } from '../../../boards/hooks/use-board-mutations'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useOnMoveCard } from '../../../boards/hooks/use-on-move-card'
import { useI18n } from '../../../hooks'
import { StringMap } from '../../../types/common'
import ErrorList from '../../../components/ErrorList'

const queryOptions = { refetchOnMountOrArgChange: true } as const

const ProjectKanbanRoute = ({ match }: RouteComponentProps<RouteParams>) => {
  const translations = useTranslations()
  const { projectId } = match.params
  const { project, isLoading: isLoadingProject } = useProject(projectId, queryOptions)
  const {
    data,
    isLoading: isLoadingBoard,
    isError,
  } = useGetBoardQuery({ id: projectId, type: 'project' }, queryOptions)
  const { addTaskCard, addTodoCard, updateColumnName } = useBoardMutations()
  const { onMoveCard } = useOnMoveCard()

  if (isLoadingBoard || isLoadingProject) return <CircularProgress />
  if (isError) return <ErrorList errors={[translations.fetchErrorBoard]} />
  if (!data) return <ErrorList errors={[translations.boardNotFoundError]} />
  if (!project) return <ErrorList errors={[translations.projectNotFoundError]} />
  const { board, tasks, todos } = data
  const populatedBoard = makePopulatedBoard({ board, tasks, todos })

  return (
    <Board
      board={populatedBoard}
      canAddTasks={project.canCreateProjectTasks}
      canAddTodos={project.canCreateProjectTodos}
      onAddTask={addTaskCard}
      onAddTodo={addTodoCard}
      onMoveCard={onMoveCard}
      onSaveColumnName={updateColumnName}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = t || ({} as StringMap)

  const {
    fetchErrorBoard = defaults.fetchErrorBoard,
    boardNotFoundError = defaults.boardNotFoundError,
    projectNotFoundError = defaults.projectNotFoundError,
  } = translations

  return {
    boardNotFoundError,
    projectNotFoundError,
    fetchErrorBoard,
  }
}

const defaultTranslations = {
  fetchErrorBoard: 'Failed to fetch board',
  boardNotFoundError: 'Board not found',
  projectNotFoundError: 'Project not found',
}
type Translations = typeof defaultTranslations
type RouteParams = { projectId: string }
export default ProjectKanbanRoute
