import InlineEditableText from '../../components/InlineEditableText'
import { makeStyles, Theme } from '@material-ui/core'
import { TaskViewModel } from '../api/task'
import { useTaskMutations } from '../hooks/use-task-mutations'

const TaskCardTitle = ({ task }: { task: TaskViewModel }) => {
  const classes = useStyles()
  const { updateInfo } = useTaskMutations()

  return (
    <InlineEditableText
      value={task.title || '(Untitled)'}
      onSave={(title) => updateInfo(task.id, { title })}
      TypographyProps={{ variant: 'h5', className: classes.taskTitle }}
      isEditable={task.canUpdateDetails}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  taskTitle: {
    '& span, & textarea': {
      maxWidth: 'none',
      textWrap: 'inherit',
    },
  },
}))

export default TaskCardTitle
