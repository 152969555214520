import { useContext } from 'react'
import { ConfirmDialogContext } from '.'
import { OpenDialogParams } from './types'

export const useConfirmDialog = <T = ConfirmFn>(params: OpenDialogParams): { confirm: T } => {
  const context = useContext(ConfirmDialogContext)
  const confirm = (...args: [...any[]]) => context.openDialog(params, ...args)
  return { confirm: confirm as T }
}

type ConfirmFn = (...args: [...any[]]) => void
