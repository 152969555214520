import { Option } from '../types'
import { ProjectColumnName } from './types'
import { makeEnumString, makeStateEnum, makeStatusEnum } from '../utils'
import {
  getCustomerOptions,
  getMembershipOptions,
  getOptionId,
  getOptionName,
  getSupplierOptions,
  getWorkspaceOptions,
} from '../../options/utils'
import { TreeGridTranslations } from '../hooks/use-translations'

export function makeColumns({
  defaultVisibleColumns,
  defaultColumnOrder,
  options,
  translations,
}: MakeColumnProps): Column[] {
  const statusEnum = makeStatusEnum(translations)
  const stateEnum = makeStateEnum(translations)

  const customerOptions = getCustomerOptions(options)
  const customerNames = customerOptions.map(getOptionName)
  const customerIds = customerOptions.map(getOptionId)
  const customersEnum = makeEnumString(customerNames)
  const customersEnumKeys = makeEnumString(customerIds)

  const membershipOptions = getMembershipOptions(options)
  const membershipNames = membershipOptions.map(getOptionName)
  const membershipIds = membershipOptions.map(getOptionId)
  const membershipEnum = makeEnumString(membershipNames)
  const membershipEnumKeys = makeEnumString(membershipIds)

  const supplierOptions = getSupplierOptions(options)
  const supplierNames = supplierOptions.map(getOptionName)
  const supplierIds = supplierOptions.map(getOptionId)
  const suppliersEnum = makeEnumString(supplierNames)
  const suppliersEnumKeys = makeEnumString(supplierIds)

  const workspaceOptions = getWorkspaceOptions(options)
  const workspaceNames = workspaceOptions.map(getOptionName)
  const workspaceIds = workspaceOptions.map(getOptionId)
  const workspacesEnum = makeEnumString(workspaceNames)
  const workspacesEnumKeys = makeEnumString(workspaceIds)

  const hasCustomerOptions: boolean = customerOptions.length > 0
  const hasMembershipOptions: boolean = membershipOptions.length > 0
  const hasSupplierOptions: boolean = supplierOptions.length > 0
  const hasWorkspaceOptions: boolean = workspaceOptions.length > 0

  const openColumn = {
    Name: 'open',
    Type: 'Icon',
    Visible: defaultVisibleColumns.includes('open'),
    Width: 32,
    CanExport: 0,
    CanPrint: 0,
    CanSort: 0,
    CanHide: 0,
    header: '',
    Class: 'openButton',
  }

  const projectNumberColumn = {
    Name: 'projectNumber',
    MinWidth: 80,
    MaxWidth: 80,
    CaseSensitive: 0,
    header: 'PNo.',
    Visible: defaultVisibleColumns.includes('projectNumber'),
  }

  const titleColumn = {
    Name: 'title',
    MinWidth: 160,
    RelWidth: 1,
    CaseSensitive: 0,
    header: translations.titleColumnHeader,
    Visible: defaultVisibleColumns.includes('title'),
  }

  const descriptionColumn = {
    Name: 'description',
    Visible: defaultVisibleColumns.includes('description'),
    CaseSensitive: 0,
    Type: 'Lines',
    MinWidth: 200,
    AcceptEnters: 1,
    header: translations.descriptionColumnHeader,
    CanSort: 0,
  }

  const plannedStartDateColumn = {
    Name: 'plannedStartDate',
    Type: 'Date',
    Visible: defaultVisibleColumns.includes('plannedStartDate'),
    header: translations.plannedStartDateColumnHeader,
    Width: 154,
  }

  const plannedEndDateColumn = {
    Name: 'plannedEndDate',
    Type: 'Date',
    Width: 154,
    Visible: defaultVisibleColumns.includes('plannedEndDate'),
    header: translations.plannedEndDateColumnHeader,
  }

  const actualStartDateColumn = {
    Name: 'actualStartDate',
    Type: 'Date',
    Width: 154,
    Visible: defaultVisibleColumns.includes('actualStartDate'),
    header: translations.actualStartDateColumnHeader,
  }

  const actualEndDateColumn = {
    Name: 'actualEndDate',
    Type: 'Date',
    Visible: defaultVisibleColumns.includes('actualEndDate'),
    Width: 154,
    header: translations.actualEndDateColumnHeader,
  }

  const rejectedDateColumn = {
    Name: 'rejectedDate',
    Type: 'Date',
    Visible: defaultVisibleColumns.includes('rejectedDate'),
    Width: 154,
    header: translations.rejectedDateColumnHeader,
    CanEdit: 0,
  }

  const statusColumn = {
    Name: 'status',
    Type: 'Enum',
    Enum: statusEnum,
    MinWidth: 120,
    Visible: defaultVisibleColumns.includes('status'),
    header: translations.statusColumnHeader,
  }

  const stateColumn = {
    Name: 'state',
    Type: 'Enum',
    Enum: stateEnum,
    CanSort: 0,
    CanExport: 0,
    MinWidth: 64,
    MaxWidth: 64,
    Visible: defaultVisibleColumns.includes('state'),
    header: translations.stateColumnHeader,
  }

  const statusDescriptionColumn = {
    Name: 'statusDescription',
    Visible: defaultVisibleColumns.includes('statusDescription'),
    CaseSensitive: 0,
    Type: 'Lines',
    MinWidth: 200,
    RelWidth: 1,
    AcceptEnters: 1,
    header: translations.statusDescriptionColumnHeader,
    CanSort: 0,
  }

  const customersColumn = {
    Name: 'customers',
    Type: 'Enum',
    Value: 0,
    Range: true,
    MinWidth: 120,
    CanHide: hasCustomerOptions ? 1 : 0,
    Visible: hasCustomerOptions && defaultVisibleColumns.includes('customers'),
    header: translations.customersColumnHeader,
    Enum: customersEnum,
    EnumKeys: customersEnumKeys,
  }

  const managersColumn = {
    Name: 'managers',
    Type: 'Enum',
    Value: 0,
    Range: true,
    MinWidth: 120,
    CanHide: hasMembershipOptions ? 1 : 0,
    Visible: defaultVisibleColumns.includes('managers') && hasMembershipOptions ? 1 : 0,
    header: translations.managersColumnHeader,
    Enum: membershipEnum,
    EnumKeys: membershipEnumKeys,
  }

  const participantsColumn = {
    Name: 'participants',
    Type: 'Enum',
    Value: 0,
    Range: true,
    MinWidth: 120,
    CanHide: hasMembershipOptions ? 1 : 0,
    Visible: defaultVisibleColumns.includes('participants') && hasMembershipOptions ? 1 : 0,
    header: translations.participantsColumnHeader,
    Enum: membershipEnum,
    EnumKeys: membershipEnumKeys,
  }

  const suppliersColumn = {
    Name: 'suppliers',
    Type: 'Enum',
    Value: 0,
    Range: true,
    MinWidth: 120,
    CanHide: hasSupplierOptions ? 1 : 0,
    Visible: hasSupplierOptions && defaultVisibleColumns.includes('suppliers'),
    header: translations.suppliersColumnHeader,
    Enum: suppliersEnum,
    EnumKeys: suppliersEnumKeys,
  }

  const workspacesColumn = {
    Name: 'workspaces',
    Type: 'Enum',
    Value: 0,
    Range: true,
    MinWidth: 120,
    CanHide: hasWorkspaceOptions ? 1 : 0,
    Visible: hasWorkspaceOptions && defaultVisibleColumns.includes('workspaces'),
    header: translations.workspacesColumnHeader,
    Enum: workspacesEnum,
    EnumKeys: workspacesEnumKeys,
  }

  const columnConfigByColumnName: Record<ProjectColumnName, Column> = {
    open: openColumn,
    projectNumber: projectNumberColumn,
    title: titleColumn,
    description: descriptionColumn,
    plannedStartDate: plannedStartDateColumn,
    plannedEndDate: plannedEndDateColumn,
    actualStartDate: actualStartDateColumn,
    actualEndDate: actualEndDateColumn,
    rejectedDate: rejectedDateColumn,
    status: statusColumn,
    state: stateColumn,
    statusDescription: statusDescriptionColumn,
    managers: managersColumn,
    participants: participantsColumn,
    suppliers: suppliersColumn,
    workspaces: workspacesColumn,
    customers: customersColumn,
  }

  const columns = defaultColumnOrder
    .map((columnName) => columnConfigByColumnName[columnName])
    .concat({ Name: 'Panel', Visible: 0, CanHide: 0 })

  return columns
}

type MakeColumnProps = {
  defaultVisibleColumns: ProjectColumnName[]
  defaultColumnOrder: ProjectColumnName[]
  options: Option[]
  translations: TreeGridTranslations
}

type Column = Partial<TCol> & {
  header?: string
}
