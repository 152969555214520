import { TimeZoneType } from '../../constants/timezones'
import { IOptionNormalized } from '../../options/interfaces/options-normalized'
import {
  getCustomerOptions,
  getMembershipOptions,
  getOptionId,
  getOptionName,
  getWorkspaceOptions,
} from '../../options/utils'
import { DateTimeService } from '../../services/date-time-service'
import { makeEnumString } from '../utils'
import { TreeGridTodo } from './types'
import { isTodoDueDatePastDue } from '../../todos/utils/is-past-due'
import { TodoViewModel } from '../../todos/api/todo'

export const makeTreegridTodoRows = ({
  todos,
  dateFormat,
  timeZone,
  options,
}: {
  todos: TodoViewModel[]
  dateFormat: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
}): TreeGridTodo[] => {
  return todos.map((todo) => makeTreegridTodoRow({ todo, dateFormat, timeZone, options }))
}

export const makeTreegridTodoRow = ({
  todo,
  dateFormat,
  timeZone,
  options,
}: {
  todo: TodoViewModel
  dateFormat: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
}): TreeGridTodo => {
  const filterOptionByOrganisation = makeFilterOptionByOrganisation(todo)
  const { id, title, description, enableTimeComponent, createdAt } = todo
  const dateTimeService = new DateTimeService({ dateFormat, timeZone, enableTimeComponent })
  const dueDate = getDueDate()
  const customers = todo.customers.map((c) => c.id).join(';')
  const workspaces = todo.workspaces.map((w) => w.id).join(';')
  const responsible = todo.responsible.map((r) => r.id).join(';')

  const customerOptions = getCustomerOptions(options).filter(filterOptionByOrganisation)
  const customerNames = customerOptions.map(getOptionName)
  const customerIds = customerOptions.map(getOptionId)
  const customersEnum = makeEnumString(customerNames)
  const customersEnumKeys = makeEnumString(customerIds)

  const workspaceOptions = getWorkspaceOptions(options).filter(filterOptionByOrganisation)
  const workspaceNames = workspaceOptions.map(getOptionName)
  const workspaceIds = workspaceOptions.map(getOptionId)
  const workspacesEnum = makeEnumString(workspaceNames)
  const workspacesEnumKeys = makeEnumString(workspaceIds)

  const responsibleOptions = getMembershipOptions(options).filter(filterOptionByOrganisation)
  const responsibleNames = responsibleOptions.map(getOptionName)
  const responsibleIds = responsibleOptions.map(getOptionId)
  const responsibleEnum = makeEnumString(responsibleNames)
  const responsibleEnumKeys = makeEnumString(responsibleIds)

  return {
    id,
    createdAt: new Date(createdAt).getTime(),
    title,
    description,
    dueDate,
    dueDateFormat: dateTimeService.getFormat(),
    dueDateClass: getDueDateClass(),
    customers,
    workspaces,
    responsible,
    completed: todo.completed ? 1 : 0,
    enableTimeComponent: todo.enableTimeComponent ? 1 : 0,
    customersEnum,
    customersEnumKeys,
    workspacesEnum,
    workspacesEnumKeys,
    responsibleEnum,
    responsibleEnumKeys,
    CanEdit: todo.canUpdate ? 1 : 0,
    CanDelete: todo.canDelete ? 1 : 0,
    responsibleCanEdit: todo.canUpdate && responsibleOptions.length > 0 ? 1 : 0,
    customersCanEdit: todo.canUpdate && customerOptions.length > 0 ? 1 : 0,
    workspacesCanEdit: todo.canUpdate && workspaceOptions.length > 0 ? 1 : 0,
    Height: 48,
    MaxHeight: 48,
  }

  function getDueDate(): number | string {
    return todo.dueDate ? dateTimeService.removeTimezoneOffset(todo.dueDate, 'UTC').getTime() : ''
  }

  function getDueDateClass(): string {
    const isPastDue = isTodoDueDatePastDue({ todo, dateTimeService })
    return isPastDue ? 'redText' : ''
  }
}

function makeFilterOptionByOrganisation(todo: TodoViewModel) {
  return function (option: IOptionNormalized) {
    return option.orgId === todo.organisation?.id
  }
}
