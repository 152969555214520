import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { Theme, alpha, makeStyles } from '@material-ui/core'
import { useI18n } from '../../hooks'
import { StringMap } from '../../types/common'
import { useAppDispatch } from '../../store'
import { useSnackbar } from 'notistack'
import { useErrorHandler } from 'react-error-boundary'
import { AcceptOrRejectInvitation } from '../store/actions'
import { InvitationStatus } from '../constants'
import { RefreshAccessToken } from '../../users/store/actions'
import { FetchOrganisation } from '../../organisations/store/actions'
import { FetchContextOptions } from '../../context-options/store/actions'
import { RemoveInvitation } from '../store'
import { useConfirmDialog } from '../../components/ConfirmDialog'
import { Mail } from 'react-feather'
import { IInvitationResponse } from '../interfaces/invitation-response'
import { FetchUserMemberships } from '../../memberships/store'
import { FetchMembershipPermissions } from '../../permissions/store/actions'

const PendingInvitationCard = ({ invitation, setAcceptedInvitation = () => {} }: PendingInvitationCardProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const handleError = useErrorHandler()
  const { orgName, id: invitationId, createdAt } = invitation
  const formattedTimestamp = new Date(createdAt).toLocaleString()

  const handleClickAccept = () => {
    const promise = dispatch(
      AcceptOrRejectInvitation({
        invitationId,
        status: InvitationStatus.ACCEPTED,
      })
    )

    promise
      .then(async (action) => {
        if (action.type.match('reject')) {
          const { payload } = action
          let errorMessage = JSON.stringify(payload)
          enqueueSnackbar(errorMessage, { variant: 'error' })
          return
        }
        const resolved = await Promise.all([
          dispatch(RefreshAccessToken()),
          dispatch(FetchOrganisation(invitation.orgId)),
          dispatch(FetchContextOptions()),
          dispatch(FetchUserMemberships()),
        ])
        const userMembershipsRequest = resolved[3] as any
        if (userMembershipsRequest.meta.requestStatus === 'fulfilled') {
          const userMembership = userMembershipsRequest.payload.find(
            (membership: any) => membership.orgId === invitation.orgId
          )
          if (userMembership) {
            dispatch(FetchMembershipPermissions(userMembership.id))
          }
        }
        dispatch(RemoveInvitation({ id: invitation.id }))
        setAcceptedInvitation(invitation)
      })
      .catch(handleError)
  }

  const handleRejectConfirm = async () => {
    try {
      const request = await dispatch(
        AcceptOrRejectInvitation({
          invitationId,
          status: InvitationStatus.REJECTED,
        })
      )

      const requestStatus = request.meta.requestStatus
      if (requestStatus === 'rejected') {
        const { payload } = request
        let errorMessage = JSON.stringify(payload)
        enqueueSnackbar(errorMessage, { variant: 'error' })
        return
      }
      const successMessage = `${translations.invitationRejectionMessage_success} ${orgName}`
      enqueueSnackbar(successMessage, { variant: 'success' })
      dispatch(RemoveInvitation({ id: invitation.id }))
    } catch (error: any) {
      handleError(error)
    }
  }

  const confirmRejectionMessage = `${translations.invitationRejectionConfirmation}'${orgName}'?`
  const confirmRejectionDialog = useConfirmDialog({
    onConfirm: handleRejectConfirm,
    text: confirmRejectionMessage,
  })

  return (
    <Paper className={classes.cardRoot} component="li">
      <div>
        <Avatar className={classes.avatar}>
          <Mail />
        </Avatar>
      </div>
      <div>
        <Typography variant="caption" color="textSecondary" component="p" className={classes.timestamp}>
          {formattedTimestamp}
        </Typography>
        <Typography variant="body1" color="textPrimary" component="p" className={classes.text}>
          {translations.invitationMessage} <strong>{invitation.orgName}</strong>
        </Typography>
        <div className={classes.actions}>
          <Button
            variant="outlined"
            size="small"
            onClick={confirmRejectionDialog.confirm}
            data-test="reject-invitation-button"
          >
            {translations.rejectButtonLabel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClickAccept}
            data-test="accept-invitation-button"
          >
            {translations.acceptButtonLabel}
          </Button>
        </div>
      </div>
    </Paper>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = (t?.invitationsPage || {}) as StringMap

  return {
    invitationMessage: translate('invitationMessage'),
    invitationRejectionMessage_success: translate('invitationRejectionMessage_success'),
    invitationRejectionConfirmation: translate('invitationRejectionConfirmation'),
    rejectButtonLabel: translate('rejectButtonLabel'),
    acceptButtonLabel: translate('acceptButtonLabel'),
  }

  function translate(key: keyof Translations) {
    return translations[key] || defaults[key]
  }
}

const defaultTranslations = {
  invitationMessage: 'You have been invited to join this organisation',
  invitationRejectionMessage_success: 'Successfully rejected the invitation sent by',
  invitationRejectionConfirmation: 'Are you sure you want to reject the received invitation?',
  rejectButtonLabel: 'Reject',
  acceptButtonLabel: 'Accept',
}

const useStyles = makeStyles((theme: Theme) => ({
  pageHeader: {
    margin: theme.spacing(2, 0),
  },
  avatar: {
    marginLeft: theme.spacing(2),
    background: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
    width: theme.spacing(6),
    height: theme.spacing(6),
    border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
  },
  cardRoot: {
    boxShadow: 'none',
    borderRadius: theme.spacing(),
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(),
    border: `1px solid ${theme.palette.divider}`,
    '& > div': {
      padding: theme.spacing(2),
    },
  },
  timestamp: {
    paddingBottom: theme.spacing(0.25),
  },
  text: {
    paddingBottom: theme.spacing(2.5),
  },
  actions: {
    '& button': {
      marginRight: theme.spacing(1.5),
    },
  },
}))

type Translations = typeof defaultTranslations
type PendingInvitationCardProps = {
  invitation: IInvitationResponse
  setAcceptedInvitation?: (invitation: IInvitationResponse) => void
}

export default PendingInvitationCard
