import { TimeZoneType } from '../../constants/timezones'
import { DateTimeService } from '../../services/date-time-service'
import { getStateIconHtml, makeEnumString } from '../utils'
import { TreeGridProject, TreeGridProjectCellPermissions } from './types'
import { sortBy } from 'lodash'
import { IOptionNormalized } from '../../options/interfaces/options-normalized'
import { getCustomerOptions, getMembershipOptions, getSupplierOptions, getWorkspaceOptions } from '../../options/utils'
import { isProjectPlannedEndDatePastDue, isProjectPlannedStartDatePastDue } from '../../projects/utils/date-utils'
import { ProjectViewModel } from '../../projects/api/project'
import { TreeGridTranslations } from '../hooks/use-translations'
import { mapStatusToTranslations } from '../utils'

export const makeTreegridProjectRows = ({ projects, ...rest }: MakeRowsProps): TreeGridProject[] => {
  return projects.map((project) => makeTreeGridProjectRow({ project, ...rest }))
}

export const makeTreeGridProjectRow = (props: MakeProjectRowProps): TreeGridProject => {
  const { project, dateFormat, timeZone, options, translations } = props
  const { id, title, description, statusDescription, enableTimeComponent } = project
  const dateTimeService = new DateTimeService({ dateFormat, timeZone, enableTimeComponent })
  const dateTimeFormat = dateTimeService.getFormat()
  const projectNumber = project.customProjectNumber || project.projectNumber
  const customers = project.customers.map((c) => c.id).join(';')
  const participants = project.participants.map((p) => p.id).join(';')
  const managers = project.managers.map((m) => m.id).join(';')
  const suppliers = project.suppliers.map((s) => s.id).join(';')
  const workspaces = project.workspaces.map((w) => w.id).join(';')
  const plannedStartDate = getPlannedStartDate() // number of milliseconds since epoch, or null
  const plannedEndDate = getPlannedEndDate() // number of milliseconds since epoch, or null
  const actualStartDate = getActualStartDate() // number of milliseconds since epoch, or null
  const actualEndDate = getActualEndDate() // number of milliseconds since epoch, or null
  const rejectedDate = getRejectedDate() // number of milliseconds since epoch, or null
  const cellPermissions = getTreeGridProjectCellPermissions()
  const state = getStateIconHtml(project.state) // html for the state icon
  const plannedStartDateClass = getPlannedStartDateClass() // css class for rendering red text if project is past due
  const plannedEndDateClass = getPlannedEndDateClass() // css class for rendering red text if project is past due
  const statusDescriptionTip = getStatusDescriptionTip() // Text showing the user who made the last update and when it was made
  const status = mapStatusToTranslations(translations)[project.status]

  const membershipOptions = getMembershipOptions(options).filter(({ orgId }) => project.maintainerId === orgId)
  const participantOptions = membershipOptions.filter(({ id }) => !managers.includes(id))
  const managerNames = membershipOptions.map(({ name }) => name)
  const managerIds = membershipOptions.map(({ id }) => id)
  const managersEnum = makeEnumString(managerNames)
  const managersEnumKeys = makeEnumString(managerIds)
  const participantNames = participantOptions.map(({ name }) => name)
  const participantIds = participantOptions.map(({ id }) => id)
  const participantsEnum = makeEnumString(participantNames)
  const participantsEnumKeys = makeEnumString(participantIds)

  const supplierOptions = getSupplierOptions(options).filter(({ orgId }) => project.maintainerId === orgId)
  const supplierNames = supplierOptions.map(({ name }) => name)
  const supplierIds = supplierOptions.map(({ id }) => id)
  const suppliersEnum = makeEnumString(supplierNames)
  const suppliersEnumKeys = makeEnumString(supplierIds)

  const workspaceOptions = getWorkspaceOptions(options).filter(({ orgId }) => project.maintainerId === orgId)
  const workspaceNames = workspaceOptions.map(({ name }) => name)
  const workspaceIds = workspaceOptions.map(({ id }) => id)
  const workspacesEnum = makeEnumString(workspaceNames)
  const workspacesEnumKeys = makeEnumString(workspaceIds)

  const customerOptions = getCustomerOptions(options).filter(({ orgId }) => project.maintainerId === orgId)
  const customerNames = customerOptions.map(({ name }) => name)
  const customerIds = customerOptions.map(({ id }) => id)
  const customersEnum = makeEnumString(customerNames)
  const customersEnumKeys = makeEnumString(customerIds)

  return {
    id,
    open: '/external-link-icon.svg',
    title,
    description,
    status,
    state,
    statusDescription,
    projectNumber,
    customers,
    participants,
    managers,
    suppliers,
    workspaces,
    plannedStartDate,
    plannedEndDate,
    actualStartDate,
    actualEndDate,
    rejectedDate,
    plannedStartDateClass,
    plannedEndDateClass,
    statusDescriptionTip,
    enableTimeComponent,
    plannedStartDateFormat: dateTimeFormat,
    plannedEndDateFormat: dateTimeFormat,
    actualStartDateFormat: dateTimeFormat,
    actualEndDateFormat: dateTimeFormat,
    rejectedDateFormat: dateTimeFormat,
    managersEnum,
    managersEnumKeys,
    participantsEnum,
    participantsEnumKeys,
    suppliersEnum,
    suppliersEnumKeys,
    workspacesEnum,
    workspacesEnumKeys,
    customersEnum,
    customersEnumKeys,
    Height: 48,
    MaxHeight: 48,
    ...cellPermissions,
  }

  function getActualStartDate(): number | string {
    return project.actualStartDate ? dateTimeService.removeTimezoneOffset(project.actualStartDate, 'UTC').getTime() : ''
  }

  function getActualEndDate(): number | string {
    return project.actualEndDate ? dateTimeService.removeTimezoneOffset(project.actualEndDate, 'UTC').getTime() : ''
  }

  function getPlannedStartDate(): number | string {
    return project.plannedStartDate
      ? dateTimeService.removeTimezoneOffset(project.plannedStartDate, 'UTC').getTime()
      : ''
  }

  function getPlannedEndDate(): number | string {
    return project.plannedEndDate ? dateTimeService.removeTimezoneOffset(project.plannedEndDate, 'UTC').getTime() : ''
  }

  function getRejectedDate(): number | string {
    return project.rejectedDate ? dateTimeService.removeTimezoneOffset(project.rejectedDate, 'UTC').getTime() : ''
  }

  function getPlannedStartDateClass(): string {
    const isPastDue = isProjectPlannedStartDatePastDue({ project, dateTimeService })
    return isPastDue ? 'redText' : ''
  }

  function getPlannedEndDateClass(): string {
    const isPastDue = isProjectPlannedEndDatePastDue({ project, dateTimeService })
    return isPastDue ? 'redText' : ''
  }

  function getStatusDescriptionTip() {
    const sortedUpdates = sortBy(project.statusDescriptionUpdates, 'updatedAt')
    const latestUpdate = sortedUpdates[sortedUpdates.length - 1]
    let tip = ''
    if (latestUpdate) {
      const formatStr = `${dateFormat} HH:mm`
      const { updatedBy, updatedAt } = latestUpdate
      const offsetDate = dateTimeService.removeTimezoneOffset(updatedAt)
      // if there is a latest update
      tip += updatedBy // include the user who made the update
      tip += '&nbsp;&nbsp;&bull;&nbsp;&nbsp;' // some spacing
      tip += dateTimeService.format(offsetDate, formatStr) // and formatted date of update
    }
    return tip
  }

  function getTreeGridProjectCellPermissions(): TreeGridProjectCellPermissions {
    return {
      titleCanEdit: project.canUpdateDetails ? 1 : 0,
      descriptionCanEdit: project.canUpdateDetails ? 1 : 0,
      projectNumberCanEdit: project.canUpdateDetails ? 1 : 0,
      plannedStartDateCanEdit: project.canUpdatePlan ? 1 : 0,
      plannedEndDateCanEdit: project.canUpdatePlan ? 1 : 0,
      statusCanEdit: project.canUpdateStatus ? 1 : 0,
      stateCanEdit: project.canUpdateStatus ? 1 : 0,
      statusDescriptionCanEdit: project.canUpdateStatus ? 1 : 0,
      actualEndDateCanEdit: project.canUpdateStatus ? 1 : 0,
      actualStartDateCanEdit: project.canUpdateStatus ? 1 : 0,
      managersCanEdit: project.canUpdateManagers ? 1 : 0,
      suppliersCanEdit: project.canUpdateSuppliers ? 1 : 0,
      workspacesCanEdit: project.canUpdateWorkspaces ? 1 : 0,
      participantsCanEdit: project.canUpdateParticipants ? 1 : 0,
      customersCanEdit: project.canUpdateCustomers ? 1 : 0,
    }
  }
}

type MakeProjectRowProps = {
  project: ProjectViewModel
  dateFormat: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
  translations: TreeGridTranslations
}

type MakeRowsProps = {
  projects: ProjectViewModel[]
  dateFormat: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
  translations: TreeGridTranslations
}
