import { TodoResponse } from './todo-response'

export const makeTodoViewModel = (todoResponse: TodoResponse): TodoViewModel => {
  return {
    ...todoResponse,
    isOrgTodo: todoResponse.organisation !== null,
    isUserTodo: todoResponse.organisation === null,
    isCompleted: todoResponse.completed !== null,
  }
}

export type TodoViewModel = TodoResponse & {
  isOrgTodo: boolean
  isUserTodo: boolean
  isCompleted: boolean
}
