import { makeStyles, Theme } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

const StatusDescriptionHistory = ({ entries }: Props) => {
  const classes = useStyles()
  return (
    <List className={classes.list}>
      {entries.map((entry, index) => (
        <ListItem divider={index < entries.length - 1}>
          <ListItemText
            primaryTypographyProps={{ style: { whiteSpace: 'pre-wrap' } }}
            primary={entry.description}
            secondary={
              <Typography variant="caption" color="textSecondary" component="p" align="right">
                {entry.updatedBy}
                &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
                {entry.updatedAt}
                &nbsp;&nbsp;&nbsp;
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 0,
  },
}))

export type HistoryEntry = {
  description: string | null
  updatedBy: string
  updatedAt: string
}

type Props = { entries: HistoryEntry[] }

export default StatusDescriptionHistory
