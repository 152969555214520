export const makeTaskApiEndpoints = (taskId = ':taskId') => {
  const endpoints = {
    tasks: () => `/tasks`,
    taskItem: (id = '') => `/tasks/${id || taskId}`,
    copyTask: () => `/tasks/copy`,
    userTasks: (userId = ':userId') => `/users/${userId}/tasks`,
    orgTasks: (orgId = ':orgId') => `/orgs/${orgId}/tasks`,
    taskPlan: (taskId = '') => `${endpoints.taskItem(taskId)}/plan`,
    taskDuration: (taskId = '') => `${endpoints.taskItem(taskId)}/duration`,
    taskDaysLeft: (taskId = '') => `${endpoints.taskItem(taskId)}/days-left`,
    taskStatus: (taskId = '') => `${endpoints.taskItem(taskId)}/status`,
    taskStatusDescription: (taskId = '') => `${endpoints.taskItem(taskId)}/status-description`,
    taskPermissions: (taskId = '') => `${endpoints.taskItem(taskId)}/permissions`,
    taskManagers: (taskId = '') => `${endpoints.taskItem(taskId)}/managers`,
    taskManagerItem: (managerId = ':managerId', taskId = '') => `${endpoints.taskItem(taskId)}/managers/${managerId}`,
    taskParticipants: (taskId = '') => `${endpoints.taskItem(taskId)}/participants`,
    taskParticipantItem: (participantId = ':participantId', taskId = '') =>
      `${endpoints.taskItem(taskId)}/participants/${participantId}`,
    taskSuppliers: (taskId = '') => `${endpoints.taskItem(taskId)}/suppliers`,
    taskSupplierItem: (supplierId = ':supplierId', taskId = '') =>
      `${endpoints.taskItem(taskId)}/suppliers/${supplierId}`,
    taskWorkspaces: (taskId = '') => `${endpoints.taskItem(taskId)}/workspaces`,
    taskWorkspaceItem: (workspaceId = ':workspaceId', taskId = '') =>
      `${endpoints.taskItem(taskId)}/workspaces/${workspaceId}`,
    archiveTask: (taskId = '') => `${endpoints.taskItem(taskId)}/archive`,
    unarchiveTask: (taskId = '') => `${endpoints.taskItem(taskId)}/unarchive`,
    taskOrder: (taskId = '') => `${endpoints.taskItem(taskId)}/update-task-order`,
    taskDependencies: (taskId = '') => `${endpoints.taskItem(taskId)}/dependencies`,
  }
  return endpoints
}
