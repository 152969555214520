import { createSelector } from '@reduxjs/toolkit'
import { filter, values } from 'lodash'
import { RootState } from '../../store'

export const getSupplierById = (state: RootState, supplierId: string) => {
  return state.suppliers.byIds[supplierId]
}

export const getOrgSuppliers = createSelector(
  (state: RootState) => state.suppliers.byIds,
  (state: RootState, orgId: string) => orgId,
  (suppliers, orgId) => {
    return filter(values(suppliers), { orgId })
  }
)

export const getAllSuppliers = createSelector(
  (state: RootState) => state.suppliers.byIds,
  (suppliers) => values(suppliers)
)
