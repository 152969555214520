import { chain, groupBy } from 'lodash'
import { invariant } from '../../utils/invariant'

// This function is a hacky solution to highlight the row in the grid when a task is added.
// Don't use this function for any other purpose.
export const forceChangeBackgroundColorOfRow = ({
  row,
  color,
  leftColumns = true,
  midColumns = true,
}: ChangeBackgroundColorOfRowParams) => {
  // @ts-ignore
  const leftCols: HTMLElement[] = row?.r0?.children || []
  // @ts-ignore
  const middleCols: HTMLElement[] = row?.r1?.children || []
  const columns: HTMLElement[] = []

  if (leftColumns) columns.push(...leftCols)
  if (midColumns) columns.push(...middleCols)

  for (const td of columns) {
    td.style.backgroundColor = color
  }
}

export const toggleClassInRow = ({ grid, row, className }: ToggleClassOfRowParams) => {
  if (!grid || !row) return
  row.Class = typeof row.Class === 'string' ? row.Class : ''
  row.Class = row.Class.includes(className) ? row.Class.replace(className, '') : `${row.Class} ${className}`
  grid.RefreshRow(row)
}

export const getLatestCreatedTasks = <T extends { id: string; createdAt: string }>(tasks: T[]): T[] => {
  const groupedByCreatedAt = groupBy(tasks, 'createdAt')
  const latestCreatedAt = chain(groupedByCreatedAt)
    .keys()
    .map((createdAt) => new Date(createdAt).getTime())
    .max()
    .value()
  return groupedByCreatedAt[new Date(latestCreatedAt).toISOString()]
}

export const getHighestLevelTask = (tasks: TaskLike[]) => {
  return tasks.reduce((highestLevelTask, currentTask) => {
    if (currentTask.ancestors.length < highestLevelTask.ancestors.length) {
      return currentTask
    } else {
      return highestLevelTask
    }
  })
}

export const createTreegrid = ({ id, layout, data }: { id: string; layout: any; data: any }) => {
  invariant(id, 'id is required to create a new treegrid')
  invariant(layout, 'layout is required to create a new treegrid')
  invariant(data, 'data is required to create a new treegrid')
  const layoutScript = `${id}Layout`

  if (!window.Grids[id]) {
    // @ts-ignore
    window[layoutScript] = layout

    // eslint-disable-next-line no-console
    console.log('Creating treegrid', id)
    window.TreeGrid(
      {
        Layout: { Script: layoutScript },
        Data: { Data: { Body: [data] } },
        Debug: process.env.NODE_ENV === 'development' ? 'Problem,Error' : '',
      },
      id
    )
  }
}

export const destroyTreegrid = (id: string) => {
  invariant(id, 'id is required to destroy a treegrid')
  // eslint-disable-next-line no-console
  console.log('Destroying treegrid', id)
  window.Grids[id]?.Dispose()
  // @ts-expect-error
  window.Grids.length = 0
}

export const updateGridData = ({ id, data }: { id: string; data: any }) => {
  invariant(id, 'id is required to update grid data')
  invariant(data, 'data is required to update grid data')
  if (window.Grids[id]) {
    // @ts-ignore
    window.Grids[id].Source.Data.Data.Body = [data]
    window.Grids[id]?.ReloadBody()
  }
}

export const getSummaryTextWithRowCount = (summaryText: string, rowCount: number): string => {
  return summaryText.replace('{{rowCount}}', `${rowCount}`).replace(/\d+/g, `${rowCount}`)
}

export const syncDataFromServerToGrid = (grid: TGrid, data: any[]) => {
  if (!grid || !data.length) return
  const changes = JSON.stringify({ Changes: data })
  grid.AddDataFromServer(changes)
  grid.AcceptChanges()
}

type ChangeBackgroundColorOfRowParams = {
  row: TRow
  color: string
  leftColumns?: boolean
  midColumns?: boolean
}

type ToggleClassOfRowParams = {
  grid: TGrid
  row: TRow
  className: string
}

type TaskLike = {
  id: string
  ancestors: string[]
  createdAt: string
}
