export const buildUrl = (baseUrl: string, params?: URLSearchParams | void): string => {
  return params ? `${baseUrl}?${new URLSearchParams(params).toString()}` : baseUrl
}

export const makeUrlSearchParams = (params: Record<string, string | string[]>): URLSearchParams => {
  return new URLSearchParams(
    Object.entries(params).flatMap(([key, values]) => {
      return Array.isArray(values) ? values.map((value) => [key, value]) : [[key, values]]
    })
  )
}
