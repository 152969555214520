import React from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import SupplierNotFound from '../../../suppliers/components/SupplierNotFound'
import SupplierInfoCard from '../../../suppliers/components/SupplierInfoCard'
import SupplierDefaultContact from '../../../suppliers/components/SupplierDefaultContact'
import SupplierDetailsPanel from '../../../suppliers/components/SupplierDetailsPanel'
import { Link } from 'react-router-dom'
import { ArrowLeft, Plus } from 'react-feather'
import { paths } from '../../../paths'
import { makeStyles, Theme } from '@material-ui/core'
import { useSupplier } from '../../../suppliers/hooks/use-supplier'
import { useI18n, useRouter } from '../../../hooks'
import { useAppDispatch } from '../../../store'
import { FetchSupplierById } from '../../../suppliers/store/actions'
import { useAsync } from '../../../hooks/use-async'
import { useUrlWithContext } from '../../../hooks/use-url-with-context'
import { useAppContext } from '../../../hooks/use-app-context'

const useLoader = ({ supplierId }: { supplierId: string }) => {
  const dispatch = useAppDispatch()
  const loader = React.useCallback(async () => {
    dispatch({ type: 'load_supplier_settings_route' })
    if (supplierId) {
      await dispatch(FetchSupplierById(supplierId))
    }
  }, [dispatch, supplierId])
  return useAsync(loader)
}
const SupplierSettingsRoute = () => {
  const classes = useStyles()
  const router = useRouter()
  const { t } = useI18n('supplier')
  const { appContext } = useAppContext()
  const supplierId = appContext.subContext?.id as string
  const { created } = router.query as RouteParams
  const { error, ...status } = useLoader({ supplierId })
  const { supplier, onAddContact, onChangeStatus } = useSupplier(supplierId)
  const { createPathWithContext } = useUrlWithContext()

  if (error) return <p>{error}</p>
  if (status.isPending()) return <CircularProgress />
  if (!supplier) return <SupplierNotFound />

  return (
    <Grid container direction="column" spacing={2} className={classes.container}>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        component="header"
        data-test="page-header"
      >
        <Grid item>
          <Button
            startIcon={<ArrowLeft size={18} />}
            component={Link}
            to={createPathWithContext(paths.orgSuppliers(), { withSubContext: false })}
          >
            {t('supplierHomePage.allSuppliersButtonLabel', 'All suppliers')}
          </Button>
        </Grid>
        {created === 'now' && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              endIcon={<Plus size={18} />}
              component={Link}
              to={createPathWithContext(paths.orgNewSupplier(), { withSubContext: false })}
              data-test="add-another-supplier-button"
            >
              {t('supplierHomePage.addAnotherSupplierButtonLabel', 'Add supplier')}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={2} justifyContent="space-between">
        <Grid item>
          <SupplierInfoCard supplier={supplier} onChangeStatus={onChangeStatus} />
          <SupplierDefaultContact supplier={supplier} />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <SupplierDetailsPanel supplier={supplier} onAddContact={onAddContact} />
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 'calc(100% - 49px)',
    width: 1440,
    margin: 'auto',
  },
}))

type RouteParams = { created?: 'now' }
export default SupplierSettingsRoute
