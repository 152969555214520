import Button from '@material-ui/core/Button'
import FormikInput from '../components/FormikInput'
import ErrorFallback from '../components/ErrorFallback'
import InputLabel from '@material-ui/core/FormLabel'
import Stack from '../components/Stack'
import { Formik, Form, FormikHelpers } from 'formik'
import { useErrorHandler } from 'react-error-boundary'
import { Link } from 'react-router-dom'
import { useNetworkStatus } from '../hooks'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getAuthState } from '../users/store/selectors'
import { useI18n } from '../hooks'
import { ErrorBoundary } from 'react-error-boundary'
import { useAppDispatch } from '../store'
import { RequestVerificationCode } from '../users/store/actions'
import { Alert, AlertTitle } from '@material-ui/lab'
import { IUserNormalized } from '../users/interfaces/user-normalized'
import { paths } from '../paths'
import { useUrlWithContext } from '../hooks/use-url-with-context'
import { useLanguage } from '../i18n/use-language'

const NewVerificationCodeRoute = () => {
  const classes = useStyles()
  const state = useSelector(getAuthState)
  const user = state.user as IUserNormalized
  const translations = useTranslations(defaultTranslations)

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Stack className={classes.container}>
        <Typography component="h5" variant="h5">
          {translations.requestVerificationCodeHeader}
        </Typography>
        <RequestVerificationCodeForm userId={user.id} />
      </Stack>
    </ErrorBoundary>
  )
}

const RequestVerificationCodeForm = ({ userId }: { userId: string }) => {
  const language = useLanguage()
  const translations = useTranslations(defaultTranslations)
  const handleError = useErrorHandler()
  const { createPathWithGivenContext } = useUrlWithContext()
  const goToHomePage = () => createPathWithGivenContext({ path: paths.home(), mainContextId: userId })
  const networkStatus = useNetworkStatus({
    resetDelayInMS: 5000,
    onResetStatusAfterSuccess: goToHomePage,
  })
  const { setStatus, isRejected, isIdle, isFulfilled } = networkStatus
  const dispatch = useAppDispatch()

  const handleReceiveCode = async (
    { email }: { email: string },
    { setSubmitting, resetForm }: FormikHelpers<{ email: string }>
  ) => {
    try {
      setStatus('pending')
      const actionResult = await dispatch(RequestVerificationCode({ email, language }))
      if (actionResult.meta.requestStatus === 'fulfilled') {
        setStatus('fulfilled')
        resetForm()
      }
      if (actionResult.meta.requestStatus !== 'fulfilled') setStatus('rejected')
      setSubmitting(false)
    } catch (error: any) {
      handleError(error)
    }
  }

  const formikProps = {
    initialValues: { email: '' },
    onSubmit: handleReceiveCode,
  }

  return (
    <Formik {...formikProps}>
      {({ errors, values }) => {
        const shouldDisableInput = !isIdle()
        const shouldDisableButton = Boolean(values.email === '' || errors.email || !isIdle())

        let errorMessage
        let title
        let severity: 'error' | 'success' | undefined

        if (isRejected()) {
          title = translations.error
          errorMessage = translations.sendVerificationCodeFailedMessage
          severity = 'error'
        }

        if (isFulfilled()) {
          title = translations.success
          errorMessage = translations.sendVerificationCodeSuccessMessage
          severity = 'success'
        }

        const to = paths.accountVerification()
        const buttonText = translations.verifyAccountNow

        return (
          <Form>
            <Stack>
              <div>
                <InputLabel htmlFor="email">{`${translations.email}:`}</InputLabel>
                <FormikInput
                  id="email"
                  name="email"
                  variant="outlined"
                  placeholder={translations.emailPlaceholder}
                  disabled={shouldDisableInput}
                  autoComplete="off"
                  fullWidth
                />
              </div>
              <div>
                {errorMessage && (
                  <Alert severity={severity}>
                    <AlertTitle>{title}</AlertTitle>
                    {errorMessage}
                  </Alert>
                )}
              </div>
              <Stack direction="row" packed>
                <Button color="primary" variant="contained" type="submit" disabled={shouldDisableButton} fullWidth>
                  {translations.receiveCode}
                </Button>
                <Button color="primary" component={Link} to={to}>
                  {buttonText}
                </Button>
              </Stack>
            </Stack>
            <></>
          </Form>
        )
      }}
    </Formik>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 650,
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 4),
    borderRadius: 4,
    background: theme.palette.common.white,
  },
}))

const useTranslations = (defaults: Translations) => {
  const { translations: t } = useI18n('translation')
  const { translations: newVerificationCodeT } = t.newVerificationCodePage

  const {
    email = defaults.email,
    emailPlaceholder = defaults.emailPlaceholder,
    error = defaults.error,
    success = defaults.success,
  } = t

  const {
    requestVerificationCodeHeader = defaults.requestVerificationCodeHeader,
    receiveCode = defaults.receiveCode,
    verifyAccountNow = defaults.verifyAccountNow,
    sendVerificationCodeFailedMessage = defaults.sendVerificationCodeFailedMessage,
    sendVerificationCodeSuccessMessage = defaults.sendVerificationCodeSuccessMessage,
  } = newVerificationCodeT

  return {
    requestVerificationCodeHeader,
    email,
    emailPlaceholder,
    receiveCode,
    verifyAccountNow,
    error,
    sendVerificationCodeFailedMessage,
    success,
    sendVerificationCodeSuccessMessage,
  }
}

const defaultTranslations = {
  requestVerificationCodeHeader: 'Request new verification code',
  email: 'Email',
  emailPlaceholder: 'Enter your email',
  receiveCode: 'Receive code',
  verifyAccountNow: 'Verify account now',
  error: 'Error',
  sendVerificationCodeFailedMessage: 'Failed to send verification code',
  success: 'Success',
  sendVerificationCodeSuccessMessage: 'A new verification code has been sent to your email',
}
type Translations = typeof defaultTranslations

export default NewVerificationCodeRoute
