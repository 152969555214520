import Alert from '@material-ui/lab/Alert'
import AbilitiesTable from '../../components/AbilitiesTable'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { camelCase } from 'lodash'
import { useI18n } from '../../hooks'
import { RoleNormalized } from '../interfaces/role-normalized'

const RoleAbilitiesManager = ({
  role,
  updateRoleAbilities,
  applyRoleAbilitiesToAll,
  showApplyRoleAbbilitiesToAllButton = true,
}: RoleAbilitiesManagerProps) => {
  const translations = useTranslations(defaultTranslations)

  const onUpdateAbilities = (update: RoleAbilitiesUpdateData) => {
    return updateRoleAbilities(role.id, update)
  }

  const onApplyRoleAbilitiesToAll = () => {
    return applyRoleAbilitiesToAll(role.id)
  }

  const infoText = translations[getInfoKey(role.name)] || translations.orgMemberRoleInfo
  const additionalInfoText = translations[getAdditionalInfoKey(role.name)] || translations.orgMemberRoleAdditionalInfo
  let alertInfo = infoText
  if (showApplyRoleAbbilitiesToAllButton) {
    alertInfo = `${infoText} ${additionalInfoText}`
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Alert
        severity="info"
        action={
          showApplyRoleAbbilitiesToAllButton && (
            <Button onClick={onApplyRoleAbilitiesToAll} color="primary" variant="contained">
              {translations[getApplyToAllButtonLabelKey(role.name)] || translations.orgMemberRoleApplyToAllBtnLabel}
            </Button>
          )
        }
      >
        {alertInfo}
      </Alert>
      <Grid item>
        <AbilitiesTable groupedAbilities={role.abilitiesByResource} onUpdateAbilities={onUpdateAbilities} />
      </Grid>
    </Grid>
  )
}
export default RoleAbilitiesManager

const defaultTranslations = {
  orgMemberRoleApplyToAllBtnLabel: 'Apply to all',
  orgMemberRoleInfo: 'These changes will only be applied to new members upon their addition.',
  orgMemberRoleAdditionalInfo:
    "To apply these changes to existing members, use the 'Apply to all' button on the right.",
  projectManagerRoleApplyToAllBtnLabel: 'Apply to all',
  projectManagerRoleInfo: 'These changes will only be applied to new project managers upon their addition.',
  projectManagerRoleAdditionalInfo:
    "To apply these changes to existing project managers, use the 'Apply to all' button on the right.",
  projectParticipantRoleApplyToAllBtnLabel: 'Apply to all',
  projectParticipantRoleInfo: 'These changes will only be applied to new project participants upon their addition.',
  projectParticipantRoleAdditionalInfo:
    "To apply these changes to existing project participants, use the 'Apply to all' button on the right.",
  taskManagerRoleApplyToAllBtnLabel: 'Apply to all',
  taskManagerRoleInfo: 'These changes will only be applied to new task managers upon their addition.',
  taskManagerRoleAdditionalInfo:
    "To apply these changes to existing task managers, use the 'Apply to all' button on the right.",
  taskParticipantRoleApplyToAllBtnLabel: 'Apply to all',
  taskParticipantRoleInfo: 'These changes will only be applied to new task participants upon their addition.',
  taskParticipantRoleAdditionalInfo:
    "To apply these changes to existing task participants, use the 'Apply to all' button on the right.",
}

const useTranslations = (defaults = defaultTranslations): typeof defaultTranslations => {
  const { translations: t } = useI18n('permission')
  const translations = t || {}
  const {
    orgMemberRoleApplyToAllBtnLabel = defaults.orgMemberRoleApplyToAllBtnLabel,
    projectManagerRoleApplyToAllBtnLabel = defaults.projectManagerRoleApplyToAllBtnLabel,
    projectParticipantRoleApplyToAllBtnLabel = defaults.projectParticipantRoleApplyToAllBtnLabel,
    taskManagerRoleApplyToAllBtnLabel = defaults.taskManagerRoleApplyToAllBtnLabel,
    taskParticipantRoleApplyToAllBtnLabel = defaults.taskParticipantRoleApplyToAllBtnLabel,
    orgMemberRoleInfo = defaults.orgMemberRoleInfo,
    orgMemberRoleAdditionalInfo = defaults.orgMemberRoleAdditionalInfo,
    projectManagerRoleInfo = defaults.projectManagerRoleInfo,
    projectManagerRoleAdditionalInfo = defaults.projectManagerRoleAdditionalInfo,
    projectParticipantRoleInfo = defaults.projectParticipantRoleInfo,
    projectParticipantRoleAdditionalInfo = defaults.projectParticipantRoleAdditionalInfo,
    taskManagerRoleInfo = defaults.taskManagerRoleInfo,
    taskManagerRoleAdditionalInfo = defaults.taskManagerRoleAdditionalInfo,
    taskParticipantRoleInfo = defaults.taskParticipantRoleInfo,
    taskParticipantRoleAdditionalInfo = defaults.taskParticipantRoleAdditionalInfo,
  } = translations

  return {
    orgMemberRoleApplyToAllBtnLabel,
    projectManagerRoleApplyToAllBtnLabel,
    projectParticipantRoleApplyToAllBtnLabel,
    taskManagerRoleApplyToAllBtnLabel,
    taskParticipantRoleApplyToAllBtnLabel,
    orgMemberRoleInfo,
    orgMemberRoleAdditionalInfo,
    projectManagerRoleInfo,
    projectManagerRoleAdditionalInfo,
    projectParticipantRoleInfo,
    projectParticipantRoleAdditionalInfo,
    taskManagerRoleInfo,
    taskManagerRoleAdditionalInfo,
    taskParticipantRoleInfo,
    taskParticipantRoleAdditionalInfo,
  }
}

type RoleAbilitiesManagerProps = {
  role: RoleNormalized
  showApplyRoleAbbilitiesToAllButton?: boolean
  applyRoleAbilitiesToAll: (roleId: string) => void
  updateRoleAbilities: (roleId: string, updateData: RoleAbilitiesUpdateData) => void
}

type RoleAbilitiesUpdateData = {
  abilitiesToAdd?: string[]
  abilitiesToRemove?: string[]
}

const getApplyToAllButtonLabelKey = (roleName: string) => {
  return `${camelCase(roleName)}RoleApplyToAllBtnLabel` as keyof typeof defaultTranslations
}

const getInfoKey = (roleName: string) => {
  return `${camelCase(roleName)}RoleInfo` as keyof typeof defaultTranslations
}

const getAdditionalInfoKey = (roleName: string) => {
  return `${camelCase(roleName)}RoleAdditionalInfo` as keyof typeof defaultTranslations
}
