import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'

export const getMembershipPermissions = (state: RootState, membershipId: string) => {
  return state.permissions.byIds[membershipId]
}

export const getManyMembershipPermissions = createSelector(
  (state: RootState, membershipIds: string[]) => state.permissions.byIds,
  (_: RootState, membershipIds: string[]) => membershipIds,
  (byIds, ids) => ids.map((id) => byIds[id]).filter(Boolean)
)
