import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import InputBase from '@material-ui/core/InputBase'
import NativeSelect from '@material-ui/core/NativeSelect'
import { useTranslation } from 'react-i18next'
import { ChevronDown, Globe } from 'react-feather'
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import { languageList } from '../utils/languages'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    formControl: {
      minWidth: 182,
      margin: theme.spacing(1, 0),
      '& label': {
        color: theme.palette.text.secondary,
        fontFamily: theme.typography.fontFamily,
        width: '100%',
      },
    },
  })
})

const LanguageSelectInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.divider}`,
      fontSize: 16,
      padding: theme.spacing(1.2, 2),
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: theme.typography.body1.fontFamily,
      lineHeight: 1.8,
      height: '1.8em',
      '&:focus': {
        borderRadius: 4,
      },
      '& ~ svg': {
        color: theme.palette.common.black,
        paddingRight: theme.spacing(),
      },
    },
  })
)(InputBase)

export const LanguageSwitch = ({ label, value, onChangeLanguage }: LanguageSwitchProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const defaultLabel = t('chooseLanguage', 'Choose Language')

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>
        <Globe style={{ marginRight: 8 }} size={16} />
        {label || defaultLabel}
      </InputLabel>
      <NativeSelect
        name="languageSwitch"
        id="languageSwitch"
        onChange={(event) => onChangeLanguage(event.target.value)}
        value={value}
        input={<LanguageSelectInput />}
        IconComponent={ChevronDown}
      >
        {languageList.map((lng) => (
          <option key={lng.value} value={lng.value}>
            {lng.label}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  )
}

const AppLanguageSwitch = () => {
  const { t, i18n } = useTranslation()
  const label = t('chooseLanguage', 'Choose Language')
  return <LanguageSwitch onChangeLanguage={i18n.changeLanguage.bind(i18n)} label={label} value={i18n.language} />
}

type LanguageSwitchProps = {
  label?: string
  value: string
  onChangeLanguage: (language: string) => void
}

export default AppLanguageSwitch
