import React from 'react'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Tooltip from '@material-ui/core/Tooltip'
import { Menu as MenuIcon } from 'react-feather'
import { makeStyles, MenuItem, Theme, Typography, useMediaQuery } from '@material-ui/core'
import { Check, Maximize2, Minimize2, MoreVertical, Settings, Square } from 'react-feather'
import { filter } from 'lodash'
import { Archive, Trash, Save, Eye, EyeOff } from 'react-feather'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'
import { format } from 'date-fns'
import { COLOR_BLACK, COLOR_ERROR, COLOR_LIGHT } from '../../constants'
import { useAuthUser } from '../../users/hooks/use-auth-user'
import { useDateFormat } from '../../users/hooks/use-date-format'
import { useUrlWithContext } from '../../hooks/use-url-with-context'
import { DateTimeService } from '../../services/date-time-service'
import { useRouter } from '../../hooks/use-router'
import { getDefaultDateFormat } from '../../utils/date'
import { useProjectMutations } from '../hooks/use-project-mutations'
import { useConfirmDialog } from '../../components/ConfirmDialog'
import { paths } from '../../paths'
import InlineEditableText from '../../components/InlineEditableText'
import DatePicker from '../../components/DatePicker'
import { useI18n } from '../../hooks/use-i18n'
import { StringMap } from '../../types/common'
import { ProjectViewModel } from '../api/project'
import BoardColumnChooser from '../../boards/components/BoardColumnChooser'

const fn = () => {}
const ProjectNavBar = ({
  project,
  isBaselineBarShown = false,
  showBaselineBar = fn,
  hideBaselineBar = fn,
  isActualBarShown = false,
  showActualBar = fn,
  hideActualBar = fn,
  isFullScreen = false,
  toggleFullScreen = fn,
  isFullWidth = false,
  translations = defaultProjectNavbarTranslations,
}: ProjectNavBarProps) => {
  const {
    basePlanSavedDate,
    basePlanSavedBy,
    plannedStartDate,
    plannedEndDate,
    lockPlannedEndDate,
    lockPlannedStartDate,
  } = project
  const router = useRouter()
  const actions = useProjectMutations()
  const { createPathWithContext } = useUrlWithContext()
  const { user } = useAuthUser()
  const { dateFormat } = useDateFormat()
  const { enqueueSnackbar } = useSnackbar()

  const canUpdatePlanStart = Boolean(lockPlannedStartDate && project.canUpdatePlan)
  const canUpdatePlanEnd = Boolean(lockPlannedEndDate && project.canUpdatePlan)

  const dateTimeService = new DateTimeService({
    enableTimeComponent: project.enableTimeComponent,
    dateFormat,
    timeZone: user?.timeZone,
  })

  const timeZonedPlannedStartDate =
    plannedStartDate && dateTimeService.removeTimezoneOffset(new Date(plannedStartDate)).toISOString()
  const timeZonedPlannedEndDate =
    plannedEndDate && dateTimeService.removeTimezoneOffset(new Date(plannedEndDate)).toISOString()

  const classes = useStyles({
    isFullScreen,
    isFullWidth,
    isStartEditable: canUpdatePlanStart,
    isEndEditable: canUpdatePlanEnd,
    hasStartDate: Boolean(plannedStartDate),
    hasEndDate: Boolean(plannedEndDate),
    timeIsEnabled: project.enableTimeComponent,
    plannedStartDateIsPastDue: project.plannedStartDateIsPastDue,
    plannedEndDateIsPastDue: project.plannedEndDateIsPastDue,
  })
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [navAnchorEl, setNavAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const closeMenu = () => setMenuAnchorEl(null)
  const closeNav = () => setNavAnchorEl(null)
  const tabValue = getActiveTabIndex(router.pathname)
  const defaultDateFormat = getDefaultDateFormat()

  const handleSaveBasePlan = () => {
    closeMenu()
    actions
      .saveBasePlan(project.id)
      .then((project) => {
        if (project) return enqueueSnackbar(translations.basePlanSavedSuccessText)
        throw new Error('Error saving base plan')
      })
      .catch(() =>
        enqueueSnackbar(translations.basePlanSavedFailureText, {
          variant: 'error',
        })
      )
  }

  const handleDeleteProject = async () => {
    closeMenu()
    actions
      .deleteProject(project.id)
      .then((deleted) => {
        if (deleted) {
          const projectsPath = createPathWithContext(paths.projects())
          enqueueSnackbar(translations.deleteProjectdSuccessText)
          return router.history.push(projectsPath)
        }
        throw new Error('Error deleting project')
      })
      .catch(() =>
        enqueueSnackbar(translations.deleteProjectdFailureText, {
          variant: 'error',
        })
      )
  }

  const handleArchiveProject = async () => {
    closeMenu()
    actions
      .archiveProject(project.id)
      .then((archivedProject) => {
        if (archivedProject) return enqueueSnackbar(translations.archiveProjectdSuccessText)
        throw new Error('Error archiving project')
      })
      .catch(() => {
        enqueueSnackbar(translations.archiveProjectdFailureText, {
          variant: 'error',
        })
      })
  }

  const { confirm: confirmSaveBasePlan } = useConfirmDialog({
    onCancel: () => closeMenu(),
    onConfirm: handleSaveBasePlan,
    text: translations.saveBasePlanConfirmText,
    title: translations.saveBasePlanConfirmTitle,
    primaryActionButtonLabel: translations.saveBasePlanConfirmBtnLabel,
  })

  const { confirm: confirmDeleteProject } = useConfirmDialog({
    onCancel: () => closeMenu(),
    onConfirm: handleDeleteProject,
    text: translations.deleteProjectConfirmText,
    title: translations.deleteProjectConfirmTitle,
    primaryActionButtonLabel: translations.deleteProjectBtnLabel,
  })

  const { confirm: confirmArchiveProject } = useConfirmDialog({
    onCancel: () => closeMenu(),
    onConfirm: handleArchiveProject,
    text: translations.archiveProjectConfirmText,
    title: translations.archiveProjectConfirmTitle,
    primaryActionButtonLabel: translations.archiveProjectBtnLabel,
  })

  const goToPermissions = () => {
    closeMenu()
    router.push(settingsRoute)
  }

  const basicRoute = createPathWithContext(paths.projectBasic(project.id))
  const ganttRoute = createPathWithContext(paths.projectGantt(project.id))
  const kanbanRoute = createPathWithContext(paths.projectKanban(project.id))
  const tasksRoute = createPathWithContext(paths.projectTasks(project.id))
  const todosRoute = `${createPathWithContext(paths.projectTodos(project.id))}&view=all`
  const calendarRoute = createPathWithContext(paths.projectCalendar(project.id))
  const settingsRoute = createPathWithContext(paths.projectSettings(project.id))

  const getBaselineSavedInfo = (): null | string => {
    if (!basePlanSavedDate) return null
    const formattedDate = format(new Date(basePlanSavedDate), defaultDateFormat)
    const savedBy = basePlanSavedBy?.split(':')[1] || translations.savedByYou
    return translations.baselineSavedText.replace('{{date}}', formattedDate).replace('{{saved_by}}', savedBy)
  }

  const getEyeIcon = (isShown: boolean, size = 20): JSX.Element => {
    if (isShown) return <EyeOff size={size} color={COLOR_BLACK} />
    else return <Eye size={size} color={COLOR_BLACK} />
  }

  const getCheckIcon = (isChecked: boolean): JSX.Element => {
    if (isChecked) {
      return <Check className={classes.checkFilledIcon} size={16} />
    } else {
      return <Square size={18} color={COLOR_BLACK} />
    }
  }

  const menuItems = [
    {
      id: 'enableTimeComponentCheckbox',
      visible: project.canUpdatePlan,
      primaryText: translations.enableTimeComponentLabel,
      icon: getCheckIcon(Boolean(project.enableTimeComponent)),
      onClick: () => actions.updateTimeComponent(project.id, { enableTimeComponent: !project.enableTimeComponent }),
    },
    {
      id: 'saveBasePlanButton',
      visible: !basePlanSavedDate && project.canUpdatePlan,
      primaryText: translations.saveBasePlanBtnLabel,
      onClick: confirmSaveBasePlan,
      icon: <Save size={18} color={COLOR_BLACK} />,
    },
    {
      id: 'showBaselineBarButton',
      visible: Boolean(basePlanSavedDate),
      primaryText: isBaselineBarShown ? translations.hideBaselineBarText : translations.showBaselineBarText,
      secondaryText: getBaselineSavedInfo(),
      onClick: () => {
        closeMenu()
        isBaselineBarShown ? hideBaselineBar() : showBaselineBar()
      },
      icon: getEyeIcon(isBaselineBarShown),
    },
    {
      id: 'showActualBarButton',
      visible: true,
      primaryText: isActualBarShown ? translations.hideActualBarText : translations.showActualBarText,
      onClick: () => {
        closeMenu()
        isActualBarShown ? hideActualBar() : showActualBar()
      },
      icon: getEyeIcon(isActualBarShown),
      divider: true,
    },
    {
      id: 'managePermissionsButton',
      visible: true,
      primaryText: translations.projectSettingsButtonLabel,
      onClick: goToPermissions,
      icon: <Settings size={18} color={COLOR_BLACK} />,
    },
    {
      id: 'archiveProjectButton',
      visible: project.canDelete && !project.archivedDate,
      primaryText: translations.archiveProjectBtnLabel,
      onClick: confirmArchiveProject,
      icon: <Archive size={18} color={COLOR_BLACK} data-test="archive-project-button" />,
    },
    {
      id: 'deleteProjectButton',
      visible: project.canDelete,
      primaryText: translations.deleteProjectBtnLabel,
      secondaryText: translations.deleteProjectSecondaryText,
      onClick: confirmDeleteProject,
      icon: <Trash size={18} color={COLOR_BLACK} data-test="delete-project-button" />,
    },
  ]
  const visibleMenuItems = filter(menuItems, ({ id, visible }) => {
    if (id === 'showActualBarButton' || id === 'showBaselineBarButton' || id === 'saveBasePlanButton') {
      return visible && isGanttRoute(router.pathname)
    } else {
      return visible
    }
  })

  const isSmallScreen = useMediaQuery(() => '(max-width:1300px)')
  const isMobileScreen = useMediaQuery(() => '(max-width:600px)')

  return (
    <>
      <div className={classes.stickyFlexContainer}>
        <Grid container alignItems="center" wrap="nowrap" className={classes.navbarContainer}>
          <Grid container item alignItems="center" className={classes.infoContainer}>
            <Grid item>
              <InlineEditableText
                isEditable={Boolean(project.canUpdateDetails)}
                value={project.customProjectNumber || project.projectNumber}
                onSave={(customProjectNumber) => actions.updateProjectNumber(project.id, customProjectNumber)}
                TypographyProps={{ variant: 'subtitle1' }}
              />
            </Grid>
            <Grid item>
              <span className={classes.divider} />
            </Grid>
            <Tooltip title={`${project.customProjectNumber || project.projectNumber}: ${project.title}`}>
              <Grid item style={{ overflow: 'hidden', minWidth: '40px' }}>
                <InlineEditableText
                  isEditable={project.canUpdateDetails}
                  value={project.title}
                  onSave={(title) => actions.updateInfo(project.id, { title })}
                  TypographyProps={{ variant: 'subtitle1' }}
                />
              </Grid>
            </Tooltip>
            {!isBasicRoute(router.pathname) && (
              <>
                <Grid item>
                  <span className={classes.divider} />
                </Grid>

                <Grid item className={classes.planContainer}>
                  {!isMobileScreen && (
                    <Grid item>
                      <Typography variant="body2" className="title">
                        {translations.planTitle}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <Tooltip title={project.lockPlannedStartDate ? '' : translations.projectPlanToolTipLabel}>
                      {canUpdatePlanStart ? (
                        <DatePicker
                          readOnly={!canUpdatePlanStart}
                          isClearable={canUpdatePlanStart}
                          selected={timeZonedPlannedStartDate ? new Date(timeZonedPlannedStartDate) : null}
                          placeholderText={translations.addStartDate}
                          textFieldProps={{
                            size: 'small',
                            className: clsx(classes.datePickerInput, classes.startInput, classes.plannedStartDateInput),
                          }}
                          onChange={(newDate: Date) => {
                            if (newDate) {
                              const newDateIsoString = newDate.toISOString()
                              if (timeZonedPlannedStartDate !== newDateIsoString) {
                                actions.updatePlan(project.id, { plannedStartDate: newDateIsoString })
                              }
                            } else {
                              actions.updatePlan(project.id, { plannedStartDate: '' })
                            }
                          }}
                          showTimeSelect={project.enableTimeComponent}
                          dateFormat={dateTimeService.getFormat()}
                          calendarStartDay={user?.firstDayOfWeek || 0}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          {...(project.plannedStartDateIsPastDue
                            ? {
                                color: 'error',
                              }
                            : { color: 'textSecondary' })}
                        >
                          {timeZonedPlannedStartDate
                            ? dateTimeService.format(timeZonedPlannedStartDate)
                            : translations.noStartDate}
                        </Typography>
                      )}
                    </Tooltip>
                  </Grid>
                  <span className="dash">-</span>
                  <Grid item>
                    <Tooltip title={project.lockPlannedEndDate ? '' : translations.projectPlanToolTipLabel}>
                      {canUpdatePlanEnd ? (
                        <DatePicker
                          readOnly={!canUpdatePlanEnd}
                          isClearable={canUpdatePlanEnd}
                          selected={timeZonedPlannedEndDate ? new Date(timeZonedPlannedEndDate) : null}
                          placeholderText={translations.addEndDate}
                          textFieldProps={{
                            size: 'small',
                            className: clsx(classes.datePickerInput, classes.endInput, classes.plannedEndDateInput),
                          }}
                          onChange={(newDate: Date) => {
                            if (newDate) {
                              const newDateIsoString = newDate.toISOString()
                              if (timeZonedPlannedEndDate !== newDateIsoString) {
                                actions.updatePlan(project.id, { plannedEndDate: newDateIsoString })
                              }
                            } else {
                              actions.updatePlan(project.id, { plannedEndDate: '' })
                            }
                          }}
                          showTimeSelect={project.enableTimeComponent}
                          dateFormat={dateTimeService.getFormat()}
                          calendarStartDay={user?.firstDayOfWeek || 0}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          {...(project.plannedEndDateIsPastDue
                            ? {
                                color: 'error',
                              }
                            : { color: 'textSecondary' })}
                        >
                          {timeZonedPlannedEndDate
                            ? dateTimeService.format(timeZonedPlannedEndDate)
                            : translations.noEndDate}
                        </Typography>
                      )}
                    </Tooltip>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item>
            <Grid
              item
              container
              alignItems="flex-start"
              justifyContent="flex-end"
              className={classes.tabsContainer}
              wrap="nowrap"
            >
              {isSmallScreen ? (
                <Grid item className={classes.iconMenus}>
                  <IconButton
                    size="small"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      setNavAnchorEl(navAnchorEl ? null : e.currentTarget)
                    }}
                  >
                    <MenuIcon size={16} color={COLOR_BLACK} />
                  </IconButton>
                </Grid>
              ) : (
                <Grid item>
                  <Tabs className={classes.tabs} value={tabValue} indicatorColor="primary" textColor="primary">
                    <Tooltip title={translations.basicInfoTabLabel}>
                      <Tab
                        className={classes.tab}
                        label={translations.basicInfoTabLabel}
                        onClick={() => router.push(`${basicRoute}`)}
                      />
                    </Tooltip>
                    <Tooltip title={translations.ganttChartTabLabel}>
                      <Tab
                        className={classes.tab}
                        label={translations.ganttChartTabLabel}
                        onClick={() => router.push(`${ganttRoute}`)}
                      />
                    </Tooltip>
                    <Tooltip title={translations.tasksTabLabel}>
                      <Tab
                        className={classes.tab}
                        label={translations.tasksTabLabel}
                        onClick={() => router.push(`${tasksRoute}`)}
                      />
                    </Tooltip>
                    <Tooltip title={translations.todoListTabLabel}>
                      <Tab
                        className={classes.tab}
                        label={translations.todoListTabLabel}
                        onClick={() => router.push(`${todosRoute}`)}
                      />
                    </Tooltip>
                    <Tooltip title={translations.calendarTabLabel}>
                      <Tab
                        className={classes.tab}
                        label={translations.calendarTabLabel}
                        onClick={() => router.push(`${calendarRoute}`)}
                      />
                    </Tooltip>
                    <Tooltip title={translations.kanbanTabLabel}>
                      <Tab
                        className={classes.tab}
                        label={translations.kanbanTabLabel}
                        onClick={() => router.push(`${kanbanRoute}`)}
                      />
                    </Tooltip>
                  </Tabs>
                </Grid>
              )}

              <Grid container item wrap="nowrap" className={classes.iconMenus}>
                {isGanttRoute(router.pathname) && (
                  <Tooltip title={translations.fullScreenAriaLabel}>
                    <Grid item>
                      <IconButton size="small" aria-label={translations.fullScreenAriaLabel} onClick={toggleFullScreen}>
                        {isFullScreen ? (
                          <Minimize2 size={16} color={COLOR_BLACK} />
                        ) : (
                          <Maximize2 size={16} color={COLOR_BLACK} />
                        )}
                      </IconButton>
                    </Grid>
                  </Tooltip>
                )}
                {isKanbanRoute(router.pathname) && (
                  <Grid item>
                    <BoardColumnChooser boardId={project.id} boardType="project" buttonVariant="iconButton" />
                  </Grid>
                )}
                {!isFullScreen && Boolean(visibleMenuItems.length) && (
                  <Tooltip title={translations.menuAriaLabel}>
                    <Grid item>
                      <IconButton
                        aria-label={translations.menuAriaLabel}
                        size="small"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          setMenuAnchorEl(menuAnchorEl ? null : e.currentTarget)
                        }}
                        data-test="project-three-dotted-menu"
                      >
                        <MoreVertical size={16} color={COLOR_BLACK} />
                      </IconButton>
                    </Grid>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Menu
        id="project-three-dotted-menu"
        anchorEl={navAnchorEl}
        open={Boolean(navAnchorEl)}
        onClose={closeNav}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        variant="selectedMenu"
        disablePortal
        keepMounted
      >
        <Tooltip title={translations.basicInfoTabLabel}>
          <MenuItem
            onClick={() => router.push(basicRoute)}
            selected={isBasicRoute(router.pathname)}
            className={classes.navListItem}
          >
            {translations.basicInfoTabLabel}
          </MenuItem>
        </Tooltip>
        <Tooltip title={translations.ganttChartTabLabel}>
          <MenuItem
            onClick={() => router.push(ganttRoute)}
            selected={isGanttRoute(router.pathname)}
            className={classes.navListItem}
          >
            {translations.ganttChartTabLabel}
          </MenuItem>
        </Tooltip>
        <Tooltip title={translations.tasksTabLabel}>
          <MenuItem
            onClick={() => router.push(tasksRoute)}
            selected={isTasksRoute(router.pathname)}
            className={classes.navListItem}
          >
            {translations.tasksTabLabel}
          </MenuItem>
        </Tooltip>
        <Tooltip title={translations.todoListTabLabel}>
          <MenuItem
            onClick={() => router.push(todosRoute)}
            selected={isTodoListRoute(router.pathname)}
            className={classes.navListItem}
          >
            {translations.todoListTabLabel}
          </MenuItem>
        </Tooltip>
        <Tooltip title={translations.calendarTabLabel}>
          <MenuItem
            onClick={() => router.push(calendarRoute)}
            selected={isCalendarRoute(router.pathname)}
            className={classes.navListItem}
          >
            {translations.calendarTabLabel}
          </MenuItem>
        </Tooltip>
        <Tooltip title={translations.kanbanTabLabel}>
          <MenuItem
            onClick={() => router.push(kanbanRoute)}
            selected={isKanbanRoute(router.pathname)}
            className={classes.navListItem}
          >
            {translations.kanbanTabLabel}
          </MenuItem>
        </Tooltip>
      </Menu>

      {/* Project context menu  */}
      <Menu
        id="project-three-dotted-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        variant="menu"
        disablePortal
        keepMounted
      >
        {visibleMenuItems.map((menuItem: any) => {
          const { icon, primaryText, secondaryText = null, visible, ...listItemProps } = menuItem
          return (
            <ListItem key={menuItem.id} {...listItemProps} button className={classes.menuListItem}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={primaryText} secondary={secondaryText} />
            </ListItem>
          )
        })}
      </Menu>
    </>
  )
}

const ProjectNavBarWithI18n = (props: ProjectNavBarProps) => {
  const { translations: t } = useI18n('project')
  const translations = (t?.projectNavBar || {}) as StringMap
  const appNavBarTranslations = useI18n('translation').translations?.appNavBar || {}

  const {
    tasksLabel = defaultProjectNavbarTranslations.tasksTabLabel,
    todosLabel = defaultProjectNavbarTranslations.todoListTabLabel,
  } = appNavBarTranslations

  const {
    saveBasePlanBtnLabel = defaultProjectNavbarTranslations.saveBasePlanBtnLabel,
    basePlanSaveSuccessTitle = defaultProjectNavbarTranslations.basePlanSaveSuccessTitle,
    basePlanSavedSuccessText = defaultProjectNavbarTranslations.basePlanSavedSuccessText,
    basePlanSaveFailureTitle = defaultProjectNavbarTranslations.basePlanSaveFailureTitle,
    basePlanSavedFailureText = defaultProjectNavbarTranslations.basePlanSavedFailureText,
    saveBasePlanConfirmTitle = defaultProjectNavbarTranslations.saveBasePlanConfirmTitle,
    saveBasePlanConfirmBtnLabel = defaultProjectNavbarTranslations.saveBasePlanConfirmBtnLabel,
    saveBasePlanConfirmText = defaultProjectNavbarTranslations.saveBasePlanConfirmText,
    hideBaselineBarText = defaultProjectNavbarTranslations.hideBaselineBarText,
    showBaselineBarText = defaultProjectNavbarTranslations.showBaselineBarText,
    hideActualBarText = defaultProjectNavbarTranslations.hideActualBarText,
    showActualBarText = defaultProjectNavbarTranslations.showActualBarText,
    baselineSavedText = defaultProjectNavbarTranslations.baselineSavedText,
    savedByYou = defaultProjectNavbarTranslations.savedByYou,
    fullScreenAriaLabel = defaultProjectNavbarTranslations.fullScreenAriaLabel,
    menuAriaLabel = defaultProjectNavbarTranslations.menuAriaLabel,
    projectSettingsButtonLabel = defaultProjectNavbarTranslations.projectSettingsButtonLabel,
    archiveProjectBtnLabel = defaultProjectNavbarTranslations.archiveProjectBtnLabel,
    deleteProjectBtnLabel = defaultProjectNavbarTranslations.deleteProjectBtnLabel,
    deleteProjectSecondaryText = defaultProjectNavbarTranslations.deleteProjectSecondaryText,
    basicInfoTabLabel = defaultProjectNavbarTranslations.basicInfoTabLabel,
    ganttChartTabLabel = defaultProjectNavbarTranslations.ganttChartTabLabel,
    kanbanTabLabel = defaultProjectNavbarTranslations.kanbanTabLabel,
    noStartDate = defaultProjectNavbarTranslations.noStartDate,
    noEndDate = defaultProjectNavbarTranslations.noEndDate,
    addStartDate = defaultProjectNavbarTranslations.addStartDate,
    addEndDate = defaultProjectNavbarTranslations.addEndDate,
    planTitle = defaultProjectNavbarTranslations.planTitle,
    enableTimeComponentLabel = defaultProjectNavbarTranslations.enableTimeComponentLabel,
    deleteProjectdSuccessText = defaultProjectNavbarTranslations.deleteProjectdSuccessText,
    deleteProjectdFailureText = defaultProjectNavbarTranslations.deleteProjectdFailureText,
    deleteProjectConfirmTitle = defaultProjectNavbarTranslations.deleteProjectConfirmTitle,
    deleteProjectConfirmText = defaultProjectNavbarTranslations.deleteProjectConfirmText,
    archiveProjectdSuccessText = defaultProjectNavbarTranslations.archiveProjectdSuccessText,
    archiveProjectdFailureText = defaultProjectNavbarTranslations.archiveProjectdFailureText,
    archiveProjectConfirmTitle = defaultProjectNavbarTranslations.archiveProjectConfirmTitle,
    archiveProjectConfirmText = defaultProjectNavbarTranslations.archiveProjectConfirmText,
    boardMenuAriaLabel = defaultProjectNavbarTranslations.boardMenuAriaLabel,
    boardMenuSubHeader = defaultProjectNavbarTranslations.boardMenuSubHeader,
    calendarTabLabel = defaultProjectNavbarTranslations.calendarTabLabel,
    projectPlanToolTipLabel = defaultProjectNavbarTranslations.projectPlanToolTipLabel,
  } = translations

  return (
    <ProjectNavBar
      {...props}
      translations={{
        saveBasePlanBtnLabel,
        basePlanSaveSuccessTitle,
        basePlanSavedSuccessText,
        basePlanSaveFailureTitle,
        basePlanSavedFailureText,
        saveBasePlanConfirmTitle,
        saveBasePlanConfirmBtnLabel,
        saveBasePlanConfirmText,
        hideBaselineBarText,
        showBaselineBarText,
        hideActualBarText,
        showActualBarText,
        baselineSavedText,
        savedByYou,
        fullScreenAriaLabel,
        menuAriaLabel,
        projectSettingsButtonLabel,
        archiveProjectBtnLabel,
        deleteProjectBtnLabel,
        deleteProjectSecondaryText,
        basicInfoTabLabel,
        tasksTabLabel: tasksLabel,
        ganttChartTabLabel,
        kanbanTabLabel,
        todoListTabLabel: todosLabel,
        calendarTabLabel,
        noStartDate,
        noEndDate,
        addStartDate,
        addEndDate,
        planTitle,
        enableTimeComponentLabel,
        deleteProjectdSuccessText,
        deleteProjectdFailureText,
        deleteProjectConfirmTitle,
        deleteProjectConfirmText,
        archiveProjectdSuccessText,
        archiveProjectdFailureText,
        archiveProjectConfirmTitle,
        archiveProjectConfirmText,
        boardMenuAriaLabel,
        boardMenuSubHeader,
        projectPlanToolTipLabel,
      }}
    />
  )
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  infoContainer: { marginTop: -6, overflow: 'hidden' },
  menuListItem: { width: 314 },
  navListItem: {
    width: 240,
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  navbarContainer: {
    background: COLOR_LIGHT,
    width: ({ isFullWidth }) => (isFullWidth ? '100%' : 1440),
  },
  tabsContainer: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  stickyFlexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    zIndex: 1,
  },
  divider: {
    width: 1,
    borderRight: `1px solid ${theme.palette.grey[500]}`,
    height: 18,
  },
  tab: {
    maxWidth: 160,
    minWidth: 96,
    minHeight: 32,
    fontSize: 13,
    padding: `2px 12px`,

    '& .MuiTab-wrapper': {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  tabs: {
    '& .MuiTabs-indicator': {
      top: 36,
    },
  },
  iconMenus: {
    paddingRight: 8,
    paddingTop: 6,
    '& button': {
      background: theme.palette.divider,
      borderRadius: 7,
      padding: 4,
      marginLeft: 8,
    },
  },
  startInput: {
    width: ({ isStartEditable, hasStartDate, timeIsEnabled }) => {
      if (!isStartEditable) return 64
      if (isStartEditable && !hasStartDate) return 96
      if (!timeIsEnabled) return 120
      return 160
    },
    '& .MuiInputBase-input': {
      textAlign: ({ isStartEditable }: StyleProps) => {
        return isStartEditable ? 'left' : 'center'
      },
    },
  },
  endInput: {
    width: ({ isEndEditable, hasEndDate, timeIsEnabled }) => {
      if (!isEndEditable) return 64
      if (isEndEditable && !hasEndDate) return 96
      if (!timeIsEnabled) return 120
      return 160
    },
    '& .MuiInputBase-input': {
      textAlign: ({ isEndEditable }: StyleProps) => {
        return isEndEditable ? 'left' : 'center'
      },
    },
  },
  datePickerInput: {
    '& fieldset': {
      border: 0,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 0,
    },
    '& .MuiInputBase-input': {
      paddingRight: 0,
      fontSize: 14,
    },
    '& .MuiInputAdornment-root': {
      '& svg': {
        width: 14,
        height: 14,
      },
    },
  },
  plannedStartDateInput: {
    '& .MuiInputBase-input': {
      color: (props: StyleProps) => (props.plannedStartDateIsPastDue ? COLOR_ERROR : ''),
    },
  },
  plannedEndDateInput: {
    '& .MuiInputBase-input': {
      color: (props: StyleProps) => (props.plannedEndDateIsPastDue ? COLOR_ERROR : ''),
    },
  },
  planContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: 8,
    '& .title, .dash': {
      padding: theme.spacing(0.2, 1),
    },
  },
  checkFilledIcon: {
    background: theme.palette.primary.main,
    stroke: theme.palette.common.white,
    borderRadius: 3,
  },
}))

const isGanttRoute = (pathname: string) => pathname.includes('gantt')
const isBasicRoute = (pathname: string) => pathname.includes('basic')
const isTasksRoute = (pathname: string) => pathname.includes('tasks')
const isKanbanRoute = (pathname: string) => pathname.includes('kanban')
const isTodoListRoute = (pathname: string) => pathname.includes('todos')
const isCalendarRoute = (pathname: string) => pathname.includes('calendar')

const getActiveTabIndex = (pathname: string) => {
  if (isBasicRoute(pathname)) return 0
  if (isGanttRoute(pathname)) return 1
  if (isTasksRoute(pathname)) return 2
  if (isTodoListRoute(pathname)) return 3
  if (isCalendarRoute(pathname)) return 4
  if (isKanbanRoute(pathname)) return 5
  throw new Error('Invalid pathname')
}

const defaultProjectNavbarTranslations = {
  saveBasePlanBtnLabel: 'Save base plan',
  basePlanSaveSuccessTitle: 'Success',
  basePlanSavedSuccessText: 'Base plan saved',
  basePlanSaveFailureTitle: 'Failed',
  basePlanSavedFailureText: 'Failed to save base plan',
  saveBasePlanConfirmTitle: `This cannot be done twice`,
  saveBasePlanConfirmBtnLabel: `Save`,
  saveBasePlanConfirmText: 'Once you save base plan it can not be reverted or changed.',
  hideBaselineBarText: 'Hide baseline bar',
  showBaselineBarText: 'Show baseline bar',
  hideActualBarText: 'Hide actual bar',
  showActualBarText: 'Show actual bar',
  baselineSavedText: '{{saved_by}} saved baseline plan on {{date}}.',
  savedByYou: 'you',
  fullScreenAriaLabel: 'Open in full screen',
  menuAriaLabel: 'Open menu',
  projectSettingsButtonLabel: 'Project settings',
  archiveProjectBtnLabel: 'Archive project',
  deleteProjectBtnLabel: 'Delete project',
  deleteProjectSecondaryText: 'Permanently removes the project from database.',
  basicInfoTabLabel: 'Basic info',
  tasksTabLabel: 'Tasks',
  ganttChartTabLabel: 'Gantt',
  kanbanTabLabel: 'Kanban',
  todoListTabLabel: 'To-dos',
  calendarTabLabel: 'Calendar',
  noStartDate: 'No start',
  noEndDate: 'No end',
  addStartDate: 'Add start',
  addEndDate: 'Add end',
  planTitle: 'Schedule:',
  enableTimeComponentLabel: 'Enable scheduling in hours and minutes',
  deleteProjectdSuccessText: 'Project deleted, redirecting . . .',
  deleteProjectdFailureText: 'Failed to delete project',
  deleteProjectConfirmTitle: 'Delete this project',
  deleteProjectConfirmText:
    'Continuing this action will delete all data linked with this project including all tasks. After this it can not be restored',
  archiveProjectdSuccessText: 'Project archived',
  archiveProjectdFailureText: 'Failed to archive project',
  archiveProjectConfirmTitle: 'Archive this project',
  archiveProjectConfirmText:
    'Archiving this project also archives all the resources linked with this project. However you can unarchive project later',
  boardMenuAriaLabel: 'Open board menu',
  boardMenuSubHeader: "Board column's visibility",
  projectPlanToolTipLabel: 'Plan is inherited from tasks',
}

type ProjectNavBarProps = {
  project: ProjectViewModel & { plannedStartDateIsPastDue: boolean; plannedEndDateIsPastDue: boolean }
  isBaselineBarShown?: boolean
  showBaselineBar?: () => void
  hideBaselineBar?: () => void
  isActualBarShown?: boolean
  showActualBar?: () => void
  hideActualBar?: () => void
  isFullScreen?: boolean
  toggleFullScreen?: () => void
  isFullWidth?: boolean
  translations?: typeof defaultProjectNavbarTranslations
}

type StyleProps = {
  isFullScreen: boolean
  isFullWidth: boolean
  isStartEditable: boolean
  hasStartDate: boolean
  isEndEditable: boolean
  hasEndDate: boolean
  timeIsEnabled: boolean
  plannedStartDateIsPastDue: boolean
  plannedEndDateIsPastDue: boolean
}

export default ProjectNavBarWithI18n
