import FormikInput from '../../components/FormikInput'

import {
  Grid,
  Theme,
  Dialog,
  makeStyles,
  Typography,
  ListItem,
  ListItemText,
  Avatar,
  CircularProgress,
  ListItemAvatar,
  ListItemSecondaryAction,
  Popper,
  ClickAwayListener,
  Grow,
  Paper,
  List,
} from '@material-ui/core'
import { Formik } from 'formik'
import { useState, useRef } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { Check, AlertTriangle } from 'react-feather'
import { COLOR_LIGHT, COLOR_WHITE } from '../../constants'
import { useNetworkStatus } from '../../hooks'

const AddMembershipDialog = ({
  open,
  memberships,
  handleClose,
  handleAddMembership,
  translations = defaultTranslations,
}: AddMembershipDialogProps) => {
  const classes = useStyles()

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="Add members to workspace"
      className={classes.dialog}
    >
      <Grid direction="column" spacing={3} className={classes.root} container>
        <Grid item>
          <div className={classes.image}>
            <img src="/invite-member.svg" alt="Invite Member" />
          </div>
        </Grid>
        <Grid className={classes.primaryText} item>
          <Typography variant="h6">{translations.dialogMessage}</Typography>
        </Grid>
        <Grid item>
          <AddMembershipField
            handleAddMembership={handleAddMembership}
            memberships={memberships}
            translations={translations}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

const AddMembershipField = ({
  memberships,
  handleAddMembership,
  translations = defaultTranslations,
}: AddMembershipFieldProps) => {
  const classes = useStyles()
  const [openPopper, setOpenPopper] = useState(false)
  const anchorRef = useRef(null)

  const handleOpenPopper = () => setOpenPopper(true)
  const handleClosePopper = () => setOpenPopper(false)
  const handleClickClose = (resetForm: any) => (event: any) => {
    const isInputField = event.target.nodeName === 'INPUT'
    if (isInputField) return
    handleClosePopper()
    setTimeout(resetForm, 100)
  }

  const getMembershipsToBeSelected = (query: string) => {
    const membershipsToBeSelected = memberships.filter(($membership: IMembership) => {
      const formattedQuery = query.toLowerCase()
      return $membership.fullname.toLowerCase().includes(formattedQuery)
    })

    return membershipsToBeSelected
  }

  return (
    <Formik initialValues={{ query: '' }} onSubmit={() => {}}>
      {({ values }) => {
        const openMenu = openPopper
        const membershipsToBeSelected = getMembershipsToBeSelected(values.query)

        return (
          <div>
            <FormikInput
              ref={anchorRef}
              id="query"
              name="query"
              variant="outlined"
              placeholder={translations.inputFieldPlaceholder}
              autoComplete="off"
              className={classes.input}
              size="small"
              onFocus={handleOpenPopper}
              inputProps={{ 'data-test': 'add-membership-input' }}
            />
            <Popper
              className={classes.popper}
              container={anchorRef.current}
              open={openMenu}
              anchorEl={anchorRef.current}
              transition
              placement="top-start"
            >
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper elevation={2}>
                    <ClickAwayListener onClickAway={handleClickClose}>
                      <MembershipList
                        memberships={membershipsToBeSelected}
                        handleAddMembership={handleAddMembership}
                        translations={translations}
                      />
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        )
      }}
    </Formik>
  )
}

const MembershipList = ({
  memberships,
  handleAddMembership,
  translations = defaultTranslations,
}: MembershipListProps) => {
  const classes = useStyles()

  return (
    <>
      {!Boolean(memberships.length) && (
        <ListItem>
          <ListItemText primary={translations.membershipNotFoundMessage} />
        </ListItem>
      )}
      {Boolean(memberships.length) && (
        <List className={classes.list}>
          {memberships.map(($member: IMembership) => {
            const { membershipId } = $member
            return (
              <MembershipListItem
                key={membershipId}
                member={$member}
                handleAddMembership={(membershipId: string) => handleAddMembership(membershipId)}
                translations={translations}
              />
            )
          })}
        </List>
      )}
    </>
  )
}

const MembershipListItem = ({
  member,
  handleAddMembership,
  translations = defaultTranslations,
}: MembershipListItemProps) => {
  const classes = useStyles()
  const handleError = useErrorHandler()
  const { setStatus, isPending, isRejected, isIdle, isFulfilled } = useNetworkStatus()
  const { fullname, userInitials, membershipId, isSelected } = member
  const disabled = isSelected || !isIdle()

  let secondaryTextColor: 'primary' | 'textSecondary' | 'error' = 'primary'
  if (isSelected || isPending()) secondaryTextColor = 'textSecondary'
  if (isRejected()) secondaryTextColor = 'error'

  const getSecondaryText = () => {
    let secondaryText = isSelected
      ? translations.addMembershipMessage_fulfilled
      : translations.addMembershipMessage_ideal
    if (isPending()) secondaryText = translations.addMembershipMessage_pending
    if (isRejected()) secondaryText = translations.addMembershipMessage_rejected
    return secondaryText
  }

  const handleClick = async () => {
    try {
      setStatus('pending')
      await handleAddMembership(membershipId)
      setStatus('fulfilled')
    } catch (error: any) {
      handleError(error)
      setStatus('rejected')
    }
  }

  return (
    <ListItem
      onClick={handleClick}
      button
      disabled={disabled}
      component="div"
      data-test={`membership-item-${fullname}`}
    >
      <ListItemAvatar>
        <Avatar className={classes.avatar}>{userInitials}</Avatar>
      </ListItemAvatar>
      <Grid direction="column" container>
        <Grid item>
          <ListItemText primary={fullname} />
        </Grid>
        <Grid item>
          <ListItemText secondary={getSecondaryText()} secondaryTypographyProps={{ color: secondaryTextColor }} />
        </Grid>
      </Grid>

      <ListItemSecondaryAction>
        {isPending() && <CircularProgress size={16} />}
        {isFulfilled() && <Check size={16} data-test="success-icon" />}
        {isRejected() && <AlertTriangle size={16} color="red" />}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: theme.spacing(30),
    overflowY: 'hidden',
  },
  primaryText: {
    textAlign: 'center',
  },
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      padding: theme.spacing(6),
      backgroundColor: COLOR_LIGHT,
    },
  },
  image: {
    padding: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    width: '100%',
    backgroundColor: COLOR_WHITE,
  },
  list: {
    listStyle: 'none',
    padding: '0px',
    margin: '0px',
    width: '100%',

    '& .MuiListItem-button': {
      borderBottom: `1px solid ${theme.palette.divider}`,

      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
  },
  popper: {
    width: '100%',
    maxHeight: theme.spacing(30),
    overflow: 'auto',
    zIndex: theme.zIndex.modal,
  },
}))

const defaultTranslations = {
  dialogMessage: 'Add memberships',
  addMembershipMessage_ideal: 'Add membership',
  addMembershipMessage_pending: 'Adding membership',
  addMembershipMessage_rejected: 'Unable to add membership',
  addMembershipMessage_fulfilled: 'Membership added',
  membershipNotFoundMessage: 'No match found',
  inputFieldPlaceholder: 'Add a new membership',
}

type MembershipListItemProps = {
  member: IMembership
  handleAddMembership: (membershipId: string) => Promise<void>
  translations?: typeof defaultTranslations
}

type AddMembershipFieldProps = {
  memberships: IMembership[]
  handleAddMembership: (membershipId: string) => Promise<void>
  translations?: typeof defaultTranslations
}

type MembershipListProps = {
  memberships: IMembership[]
  handleAddMembership: (membershipId: string) => Promise<void>
  translations?: typeof defaultTranslations
}

type IMembership = {
  membershipId: string
  fullname: string
  userInitials: string
  isSelected: boolean
}

type AddMembershipDialogProps = {
  open: boolean
  memberships: IMembership[]
  handleClose: () => void
  handleAddMembership: (membershipId: string) => Promise<void>
  translations?: AddMembershipDialogTranslations
}

export type AddMembershipDialogTranslations = typeof defaultTranslations
export default AddMembershipDialog
