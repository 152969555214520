import React from 'react'
import { TreeGridTranslations } from './use-translations'

export const useAddTaskButton = ({
  canCreateRootTasks,
  translations,
}: {
  canCreateRootTasks: boolean
  translations: TreeGridTranslations
}) => {
  const [showAddTaskButton, setShowAddTaskButton] = React.useState(canCreateRootTasks)
  const [disableAddTaskButton, setDisableAddTaskButton] = React.useState(!canCreateRootTasks)

  const grid = window.Grids['__taskTreeGrid__'] as any
  const toolbarRow = grid?.Toolbar as any
  const addTaskBtnLabel = translations.addTask

  // This effect is used to disable the add task button when disableShowAddTaskButton is true
  React.useEffect(() => {
    if (!grid || !toolbarRow) return

    toolbarRow.AddTask = disableAddTaskButton
      ? `<button disabled="true">${addTaskBtnLabel}<span class="icon"> + </span></button>`
      : `<button >${addTaskBtnLabel}<span class="icon"> + </span></button>`

    grid.RefreshRow(toolbarRow)
  }, [disableAddTaskButton, grid, toolbarRow, addTaskBtnLabel])

  return {
    showAddTaskButton,
    setShowAddTaskButton,
    disableAddTaskButton,
    setDisableAddTaskButton,
  }
}
