import { CircularProgress } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import Grid from '@material-ui/core/Grid'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { useState, useEffect } from 'react'
import AppSplashScreen from './AppSplashScreen'
import UnknownError from './UnknownError'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#302D43',
      background: 'transparent',
    },
  })
)

interface AppBackdropProps {
  timeout?: number
  variant?: 'progress' | 'splash'
}

const AppBackdrop = ({ timeout, variant = 'progress' }: AppBackdropProps) => {
  const [showProgress, setShowProgress] = useState(true)

  useEffect(() => {
    if (timeout) {
      const backdropTimeout = setTimeout(() => setShowProgress(false), timeout)
      return () => clearTimeout(backdropTimeout)
    }
  }, [timeout])

  const classes = useStyles()

  return (
    <Backdrop open={true} className={classes.backdrop}>
      <Grid container justifyContent="center">
        <Grid item md={6}>
          {showProgress && variant === 'progress' && <CircularProgress className={classes.progress} />}
          {showProgress && variant === 'splash' && <AppSplashScreen />}
          {!showProgress && <UnknownError />}
        </Grid>
      </Grid>
    </Backdrop>
  )
}

export default AppBackdrop
