/* eslint-disable no-console */
import { PayloadAction } from '@reduxjs/toolkit'
import { keyBy, keys, omitBy, without } from 'lodash'
import { IInvitationResponse } from '../interfaces/invitation-response'
import { InvitationsState } from './index'

export const setUserInvitationsCase = (
  state: InvitationsState,
  action: PayloadAction<{ userId: string; invitations: IInvitationResponse[] }>
) => {
  const { invitations, userId } = action.payload
  const incomingInvitations = keyBy(invitations, 'id')
  const updatedInvitations = {
    ...omitBy(state.byIds, ($i) => $i.userId === userId),
    ...incomingInvitations,
  }
  state.byIds = updatedInvitations
  state.allIds = keys(updatedInvitations)
}

export const removeInvitationCase = (state: InvitationsState, action: PayloadAction<{ id: string }>) => {
  const { id } = action.payload
  state.byIds = omitBy(state.byIds, { id })
  state.allIds = without(state.allIds, id)
}

export const deleteOrganisationCase = (state: InvitationsState, action: PayloadAction<string>) => {
  const orgInvitationIds: string[] = []

  state.allIds.forEach((id) => {
    if (state.byIds[id].orgId === action.payload) {
      orgInvitationIds.push(id)
    }
  })

  state.byIds = omitBy(state.byIds, { orgId: action.payload })
  state.allIds = without(state.allIds, ...orgInvitationIds)
}
