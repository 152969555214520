import { MembershipPermissionResponse } from '../interfaces/membership-permission'
import { MembershipPermissions } from '../interfaces/membership-permissions'

export const flattenGroupedMembershipPermission = (
  membershipPermission: MembershipPermissionResponse
): MembershipPermissions => {
  return membershipPermission.abilitiesByResources.reduce(
    (membershipPermissions, { abilities }) => {
      Object.entries(abilities).forEach(([ability, value]) => {
        const abilityKey = `can${ability}` as PermissionKey
        membershipPermissions[abilityKey] = value
        return membershipPermissions
      })
      return membershipPermissions
    },
    { membershipId: membershipPermission.id } as MembershipPermissions
  )
}

type PermissionKey = Exclude<keyof MembershipPermissions, 'membershipId'>
