import AutoSaveInput from '../../components/AutoSaveInput'
import AutoSaveSelect from '../../components/AutoSaveSelect/AutoSaveSelect'

import { makeStyles, Paper, Theme } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { CustomerTypeEnum } from '../../types/customer'
import { useI18n } from '../../hooks'
import { languageList } from '../../utils/languages'
import { UpdateCustomerInfo } from '../store/actions'
import { IOrgCustomerNormalized } from '../interfaces/customer-normalized'
import { makeValidateCustomerNumber } from '../utils/validate-customer-number'
import { useAppContext } from '../../hooks/use-app-context'
import { StringMap } from 'i18next'

const OrganisationCustomerBasicInfoForm = ({ customer, canUpdateBasicInfo }: BasicInfoFormProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const translations = useTranslations(defaultTranslations)
  const { appContext } = useAppContext()
  const orgId = appContext.mainContext?.id as string
  const validateCustomerNumber = makeValidateCustomerNumber({ orgId, translations })

  const makeOnSave =
    (field: keyof IOrgCustomerNormalized) => async (values: { [k in keyof IOrgCustomerNormalized]: string }) => {
      const action: any = await dispatch(
        UpdateCustomerInfo({
          customerId: customer.id,
          [field]: values[field],
          type: CustomerTypeEnum.organisation,
        })
      )
      // Need to reject explicitly because even api responds with other codes than 200,
      // the request is resolved not rejected
      if (action?.meta?.requestStatus === 'rejected') {
        return Promise.reject(action.error)
      }
    }

  const disabled = !customer.isActive || !canUpdateBasicInfo

  return (
    <Paper elevation={0} className={classes.paper}>
      <AutoSaveInput
        name="name"
        label={translations.nameLabel}
        placeholder="e.g. Acme Inc"
        onSave={makeOnSave('name')}
        initialValue={customer.name}
        disabled={disabled}
        required
        inputProps={{ 'data-test': 'customer-name-input' }}
      />
      <AutoSaveInput
        name="customerNumber"
        label={translations.customerNumberLabel}
        placeholder="e.g. 123"
        initialValue={`${customer.customerNumber}` || ''}
        onSave={makeOnSave('customerNumber')}
        disabled={disabled}
        FormikConfig={{
          validate: validateCustomerNumber,
          validateOnBlur: false,
        }}
        inputProps={{ 'data-test': 'customer-number-input' }}
      />
      <AutoSaveInput
        name="businessId"
        label={translations.businessIdLabel}
        placeholder="e.g. 1234567-8"
        onSave={makeOnSave('businessId')}
        initialValue={customer.businessId || ''}
        disabled={disabled}
        inputProps={{ 'data-test': 'business-id-input' }}
      />
      <AutoSaveInput
        name="vatRegistrationNumber"
        label={translations.vatRegistrationNumberLabel}
        placeholder="e.g. FI12345678"
        initialValue={customer.vatRegistrationNumber || ''}
        onSave={makeOnSave('vatRegistrationNumber')}
        disabled={disabled}
        inputProps={{ 'data-test': 'vat-registration-number-input' }}
      />
      <AutoSaveSelect
        name="language"
        label={translations.languageLabel}
        initialValue={customer.language}
        onSave={makeOnSave('language')}
        options={languageList}
        disabled={disabled}
        data-test="language-select-input"
      />
    </Paper>
  )
}

const defaultTranslations = {
  nameLabel: 'Name',
  customerNumberLabel: 'Customer number',
  businessIdLabel: 'Business ID',
  vatRegistrationNumberLabel: 'Vat registration number',
  languageLabel: 'Language',
  invalidCustomerNumberError: 'Customer number is not valid',
  duplicateCustomerNumberError: 'Customer number already exists',
  numberOnlyCustomerNumberError: 'Customer number can contain numbers only',
  somethingWentWrongError: 'Something went wrong',
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const { translations: customerTranslations } = useI18n('customer')
  const customerForm = (customerTranslations.customerForm || {}) as StringMap
  const basicInfoForm = (customerTranslations.basicInfoForm || {}) as StringMap
  const translations = t || ({} as StringMap)

  const somethingWentWrongError = translations.appFallbackErrorMessage || defaults.somethingWentWrongError
  const {
    nameLabel = defaults.nameLabel,
    customerNumberLabel = defaults.customerNumberLabel,
    businessIdLabel = defaults.businessIdLabel,
    vatRegistrationNumberLabel = defaults.vatRegistrationNumberLabel,
    languageLabel = defaults.languageLabel,
  } = basicInfoForm
  const {
    invalidCustomerNumberError = defaults.invalidCustomerNumberError,
    duplicateCustomerNumberError = defaults.duplicateCustomerNumberError,
    numberOnlyCustomerNumberError = defaults.numberOnlyCustomerNumberError,
  } = customerForm

  return {
    nameLabel,
    customerNumberLabel,
    businessIdLabel,
    vatRegistrationNumberLabel,
    languageLabel,
    invalidCustomerNumberError,
    duplicateCustomerNumberError,
    numberOnlyCustomerNumberError,
    somethingWentWrongError,
  }
}

type BasicInfoFormProps = {
  customer: IOrgCustomerNormalized
  canUpdateBasicInfo: boolean
}

type Translations = typeof defaultTranslations

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    '& > form, & > div': {
      padding: theme.spacing(1, 2),
    },
  },
}))

export default OrganisationCustomerBasicInfoForm
