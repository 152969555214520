import CircularProgress from '@material-ui/core/CircularProgress'
import TodoList from '../../../treegrid/todo-list/TodoList'
import ErrorList from '../../../components/ErrorList'
import { RouteComponentProps } from 'react-router-dom'
import { useProject } from '../../../projects/hooks/use-project'
import { useGetTodosQuery } from '../../../todos/api'
import { useDateFormat } from '../../../users/hooks/use-date-format'
import { useUserTimeZone } from '../../../hooks/use-user-time-zone'
import { useFirstDayOfWeek } from '../../../hooks/use-first-day-of-week'
import { useOrgOptions } from '../../../options/hooks/use-org-options'
import { useTodoMutations } from '../../../todos/hooks/use-todo-mutations'
import { useI18n } from '../../../hooks'
import { StringMap } from '../../../types/common'

const queryOptions = { refetchOnMountOrArgChange: true } as const

const ProjectTodos = ({ match }: RouteComponentProps<RouteParams>) => {
  const translations = useTranslations()
  const projectId = match.params.projectId
  const { project, isLoading: isLoadingProject, isError: isErrorProject } = useProject(projectId, queryOptions)
  const {
    data: todos = [],
    isLoading: isLoadingTodos,
    isError: isErrorTodos,
  } = useGetTodosQuery({ project: projectId }, queryOptions)

  const { dateFormat, dateSeparator } = useDateFormat()
  const userTimeZone = useUserTimeZone()
  const firstDayOfWeek = useFirstDayOfWeek()
  const { options } = useOrgOptions(project?.maintainerId || '')
  const todoActions = useTodoMutations()

  if (isLoadingProject || isLoadingTodos) return <CircularProgress />
  if (isErrorProject) return <ErrorList errors={[translations.fetchErrorProject]} />
  if (isErrorTodos) return <ErrorList errors={[translations.fetchErrorTodos]} />
  if (!project) return <ErrorList errors={[translations.projectNotFoundError]} />

  return (
    <TodoList
      gridInfo={`${project?.title} - Todos`}
      dateFormat={dateFormat}
      dateSeparator={dateSeparator}
      firstDayOfWeek={firstDayOfWeek}
      timeZone={userTimeZone}
      options={options}
      todos={todos}
      canCreateTodos={project.canCreateProjectTodos}
      onAddTodo={(todoData) => todoActions.createTodo({ ...todoData, project: projectId })}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translations')
  const translations = t || ({} as StringMap)

  const {
    projectNotFoundError = defaults.projectNotFoundError,
    fetchErrorProject = defaults.fetchErrorProject,
    fetchErrorTodos = defaults.fetchErrorTodos,
  } = translations

  return {
    projectNotFoundError,
    fetchErrorProject,
    fetchErrorTodos,
  }
}
const defaultTranslations = {
  projectNotFoundError: 'Project not found',
  fetchErrorProject: 'Failed to fetch project',
  fetchErrorTodos: 'Failed to fetch to-dos',
}
type Translations = typeof defaultTranslations
type RouteParams = { projectId: string }
export default ProjectTodos
