import React from 'react'

import { useI18n } from '../hooks'
import { Alert, AlertTitle } from '@material-ui/lab'
import { IOrgNormalized } from '../organisations/interfaces/org-normalized'
import { Card, CardContent, Typography } from '@material-ui/core'
import { StringMap } from '../types/common'
import { useAppContext } from '../hooks/use-app-context'
import { useGetOrganisationByIdQuery } from '../organisations/api'

export const withOrgRouteLayout = (Component: React.ComponentType<any>) => {
  const WithLayout = () => {
    const { appContext } = useAppContext()
    const { mainContext } = appContext
    const organisationId = mainContext && mainContext.type === 'org' ? mainContext.id : ''
    const {
      data: organisation,
      error,
      isSuccess,
    } = useGetOrganisationByIdQuery(organisationId, {
      skip: !organisationId,
      refetchOnMountOrArgChange: true,
    })

    if (error) {
      const errorMessage = 'message' in error ? error.message : error
      return (
        <Alert severity="error" style={{ marginTop: 8 }}>
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )
    }

    if (isSuccess) {
      return organisation?.suspensionStatus?.isSuspended ? <Suspended organisation={organisation} /> : <Component />
    }

    return <Component />
  }

  return WithLayout
}

const Suspended = ({ organisation }: { organisation: IOrgNormalized }) => {
  const { suspendedTitle, suspendedDescription } = useTranslation()

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle2" component="p" color="error">
          {suspendedTitle}
        </Typography>
        <Typography variant="subtitle1" component="h5">
          {organisation.suspensionStatus.reason || suspendedDescription}
        </Typography>
      </CardContent>
    </Card>
  )
}

const useTranslation = (defaults: Translations = defaultTranslations) => {
  const { translations: t } = useI18n('org')
  const translations = (t?.suspendedPage || {}) as StringMap

  const { suspendedTitle = defaults.suspendedTitle, suspendedDescription = defaults.suspendedDescription } =
    translations

  return {
    suspendedTitle,
    suspendedDescription,
  }
}

const defaultTranslations = {
  suspendedTitle: 'Your account has been suspended',
  suspendedDescription: 'Please contact starbrix if you want to revoke the suspension',
}

type Translations = typeof defaultTranslations
