import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import BillingSummary from '../../../components/BillingSummary'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useAppContext } from '../../../hooks/use-app-context'
import { useI18n, useRouter } from '../../../hooks'
import BillingInformation from '../../../components/BillingInformation/BillingInformation'

export default function BillingPage() {
  const classes = useStyles()
  const router = useRouter()
  const query = router.query as { tab?: string }
  const currentTab = query?.tab === 'settings' ? 1 : 0
  const { appContext } = useAppContext()
  const { mainContext } = appContext
  const translations = useTranslations()

  if (!mainContext || mainContext.type !== 'org') return null

  return (
    <div className={classes.container}>
      <Tabs
        value={currentTab}
        onChange={(e, newValue) => {
          const currentSearch = new URLSearchParams(router.location.search)
          currentSearch.set('tab', newValue === 1 ? 'settings' : 'summary')
          router.push({ search: currentSearch.toString() })
        }}
        indicatorColor="primary"
        className={classes.tabs}
      >
        <Tab className={classes.tab} disableRipple label={translations.summary} />
        <Tab className={classes.tab} disableRipple label={translations.billingAddress} />
      </Tabs>
      <TabPanel currentTab={currentTab} index={0} element={<BillingSummary orgId={mainContext.id} />} />
      <TabPanel
        currentTab={currentTab}
        index={1}
        element={
          <div>
            <BillingInformation />
          </div>
        }
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 800,
    margin: 'auto',
    paddingTop: 8,
    paddingBottom: 32,
    borderRadius: 8,
  },
  tabs: { borderBottom: `1px solid ${theme.palette.divider}` },
  tab: { minWidth: 120 },
  tabPanel: { padding: theme.spacing(3, 0) },
}))

function TabPanel({ element, currentTab, index }: TabPanelProps) {
  const classes = useStyles()
  const hidden = currentTab !== index
  return (
    <div role="tabpanel" hidden={hidden} aria-hidden={hidden} className={classes.tabPanel}>
      {currentTab === index && element}
    </div>
  )
}

const defaultTranslations = {
  summary: 'Summary',
  billingAddress: 'Billing address',
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('org')
  const translations = t?.orgBillingPage || {}

  return {
    summary: translate('summary'),
    billingAddress: translate('billingAddress'),
  }

  function translate(key: keyof Translations) {
    return translations[key] || defaults[key]
  }
}

type Translations = typeof defaultTranslations

type TabPanelProps = {
  element: React.ReactNode
  index: any
  currentTab: any
}
