import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { makeStyles, Theme } from '@material-ui/core'
import { Check, ChevronDown, MoreVertical, Plus } from 'react-feather'
import { truncate } from 'lodash'
import { useI18n, useRouter } from '../hooks'
import { AppMainContextType } from '../types/app-context'
import { useAppContext } from '../hooks/use-app-context'
import { useUrlWithContext } from '../hooks/use-url-with-context'
import { paths } from '../paths'

const AppMainContext = () => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const router = useRouter()
  const { appContext, onChangeMainContext } = useAppContext()
  const { createPathWithContext } = useUrlWithContext()
  const { mainContext, mainContextList } = appContext
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLButtonElement | null>(null)

  const onCreateOrgBtnClick = () => {
    setMenuAnchor(null)
    router.push(createPathWithContext(paths.newOrganisation()))
  }

  const onContextItemClick = (context: AppMainContextType) => {
    closeMenu()
    onChangeMainContext(context)
  }

  if (!mainContext) return null
  const closeMenu = () => setMenuAnchor(null)
  const hasOrgContexts = mainContextList.some((ctx) => ctx.type === 'org')
  const endIcon = hasOrgContexts ? <ChevronDown height={20} width={20} /> : <MoreVertical height={20} width={20} />

  return (
    <div className={classes.wrapper}>
      <Button
        className={classes.button}
        endIcon={endIcon}
        onClick={(e) => setMenuAnchor(menuAnchor ? null : e.currentTarget)}
        data-test="main-context-menu-button"
      >
        {truncate(mainContext.name, { length: 16 })}
      </Button>
      <Menu aria-label="Context menu" open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={closeMenu}>
        {mainContextList
          .filter((item) => !item.disabled)
          .map((context) => (
            <ListItem
              key={context.id}
              className={classes.listItem}
              button
              divider
              dense
              onClick={() => onContextItemClick(context)}
              data-test={`main-context-menu-item-${context.name}`}
            >
              <ListItemIcon>{context.id === mainContext.id && <Check height="16" width="16" />}</ListItemIcon>
              <ListItemText className={context.id === mainContext.id ? 'selected' : ''}>{context.name}</ListItemText>
            </ListItem>
          ))}
        {mainContext.type === 'user' && (
          <Button
            onClick={onCreateOrgBtnClick}
            variant="outlined"
            endIcon={<Plus size={16} />}
            className={classes.createOrgBtn}
            size="small"
            data-test="create-organisation-button"
          >
            {translations.createOrgBtnLabel}
          </Button>
        )}
      </Menu>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  button: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  listItem: {
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(5),
    },
    '&:last-child': {
      borderBottom: 0,
    },
    '& .MuiListItemText-primary.selected': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  createOrgBtn: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    transition: theme.transitions.create(['color', 'background']),
    '&:hover': {
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
    },
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const { createOrgBtnLabel = defaults.createOrgBtnLabel } = t
  return { createOrgBtnLabel }
}

const defaultTranslations = {
  createOrgBtnLabel: 'Create a new organization',
}
type Translations = typeof defaultTranslations

export default AppMainContext
