import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'

import ProjectBasic from './basic'
import ProjectCalendar from './calendar'
import ProjectGantt from './gantt'
import ProjectKanban from './kanban'
import ProjectTasks from './tasks'
import ProjectTodos from './todos'
import { useProject } from '../../../projects/hooks/use-project'
import { paths } from '../../../paths'
import ProjectNavBar from '../../../projects/components/ProjectNavBar'
import React from 'react'
import { useFullscreen } from 'ahooks'
import { COLOR_LIGHT } from '../../../constants'
import { useI18n } from '../../../hooks'
import { StringMap } from '../../../types/common'
import ErrorList from '../../../components/ErrorList'

export const ProjectRouteContext = React.createContext<ProjectRouteContextValue | undefined>(undefined)

const ProjectLayout = ({ match, location }: RouteComponentProps<RouteParams>) => {
  const translations = useTranslations()
  const projectId = match.params.projectId
  const { project, plannedEndDateIsPastDue, plannedStartDateIsPastDue, isLoading, error } = useProject(projectId)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [isBaselineBarShown, setIsBaselineBarShown] = React.useState(false)
  const [isActualBarShown, setIsActualBarShown] = React.useState(true)
  const [isFullScreen, { toggleFullscreen }] = useFullscreen(containerRef)
  const showBaselineBar = () => setIsBaselineBarShown(true)
  const hideBaselineBar = () => setIsBaselineBarShown(false)
  const showActualBar = () => setIsActualBarShown(true)
  const hideActualBar = () => setIsActualBarShown(false)

  if (isLoading) return <CircularProgress />
  if (error) return <ErrorList errors={[translations.fetchErrorProject]} />
  if (!project) return <ErrorList errors={[translations.projectNotFoundError]} />
  return (
    <div ref={containerRef} style={{ background: COLOR_LIGHT }}>
      <ProjectNavBar
        project={{ ...project, plannedEndDateIsPastDue, plannedStartDateIsPastDue }}
        isActualBarShown={isActualBarShown}
        showActualBar={showActualBar}
        hideActualBar={hideActualBar}
        isBaselineBarShown={isBaselineBarShown}
        showBaselineBar={showBaselineBar}
        hideBaselineBar={hideBaselineBar}
        isFullScreen={isFullScreen}
        toggleFullScreen={toggleFullscreen}
        isFullWidth={!location.pathname.includes('basic')}
      />
      <Switch>
        <ProjectRouteContext.Provider value={{ isActualBarShown, isBaselineBarShown, isFullScreen }}>
          <Route path={paths.projectBasic()} component={ProjectBasic} />
          <Route path={paths.projectCalendar()} component={ProjectCalendar} />
          <Route path={paths.projectGantt()} component={ProjectGantt} />
          <Route path={paths.projectKanban()} component={ProjectKanban} />
          <Route path={paths.projectTasks()} component={ProjectTasks} />
          <Route path={paths.projectTodos()} component={ProjectTodos} />
        </ProjectRouteContext.Provider>
      </Switch>
    </div>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translations')
  const translations = t || ({} as StringMap)

  const { projectNotFoundError = defaults.projectNotFoundError, fetchErrorProject = defaults.fetchErrorProject } =
    translations

  return {
    projectNotFoundError,
    fetchErrorProject,
  }
}
const defaultTranslations = {
  projectNotFoundError: 'Project not found',
  fetchErrorProject: 'Failed to fetch project',
}
type Translations = typeof defaultTranslations

export default ProjectLayout

type RouteParams = { projectId: string }
type ProjectRouteContextValue = {
  isActualBarShown: boolean
  isBaselineBarShown: boolean
  isFullScreen: boolean
}
