import { useSnackbar } from 'notistack'
import * as calendarEventApi from '../api'

export const useCalendarEventMutations = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [createCalendarEventMutation] = calendarEventApi.useCreateCalendarEventMutation()
  const [updateInfoMutation] = calendarEventApi.useUpdateCalendarEventInfoMutation()
  const [updateProjectMutation] = calendarEventApi.useUpdateCalendarEventProjectMutation()
  const [updateTaskMutation] = calendarEventApi.useUpdateCalendarEventTaskMutation()
  const [updateCustomersMutation] = calendarEventApi.useUpdateCalendarEventCustomersMutation()
  const [updateParticipantsMutation] = calendarEventApi.useUpdateCalendarEventParticipantsMutation()
  const [updateSuppliersMutation] = calendarEventApi.useUpdateCalendarEventSuppliersMutation()
  const [updateWorkspacesMutation] = calendarEventApi.useUpdateCalendarEventWorkspacesMutation()
  const [deleteEventMutation] = calendarEventApi.useDeleteEventMutation()

  const handleError = (error: any) => {
    const errorMessage = 'error' in error ? error.error : 'message' in error ? error.message : 'Unknown error'
    enqueueSnackbar(errorMessage, { variant: 'error' })
  }

  const createCalendarEvent = async (eventData: calendarEventApi.NewCalendarEventData) => {
    return await createCalendarEventMutation(eventData).unwrap().catch(handleError)
  }

  const updateInfo = async (eventId: string, updateData: calendarEventApi.CalendarEventInfoUpdateData) => {
    return await updateInfoMutation({ eventId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updateProject = async (eventId: string, project: string | null) => {
    return await updateProjectMutation({ eventId, project }).unwrap().catch(handleError)
  }

  const updateTask = async (eventId: string, task: string | null) => {
    return await updateTaskMutation({ eventId, task }).unwrap().catch(handleError)
  }

  const updateCustomers = async (eventId: string, customers: string[]) => {
    return await updateCustomersMutation({ eventId, customers }).unwrap().catch(handleError)
  }

  const updateParticipants = async (eventId: string, participants: string[]) => {
    return await updateParticipantsMutation({ eventId, participants }).unwrap().catch(handleError)
  }

  const updateSuppliers = async (eventId: string, suppliers: string[]) => {
    return await updateSuppliersMutation({ eventId, suppliers }).unwrap().catch(handleError)
  }

  const updateWorkspaces = async (eventId: string, workspaces: string[]) => {
    return await updateWorkspacesMutation({ eventId, workspaces }).unwrap().catch(handleError)
  }

  const initCalendarEventUpdate = async (calendarEventId: string, updateData: InitCalendarEventUpdateData) => {
    switch (updateData.field) {
      case 'title':
      case 'startDate':
      case 'endDate':
      case 'description':
        return await updateInfo(calendarEventId, { [updateData.field]: updateData.value })
      case 'project':
        return await updateProject(calendarEventId, updateData.value)
      case 'task':
        return await updateTask(calendarEventId, updateData.value)
      case 'customers':
        return await updateCustomers(calendarEventId, updateData.value)
      case 'participants':
        return await updateParticipants(calendarEventId, updateData.value)
      case 'suppliers':
        return await updateSuppliers(calendarEventId, updateData.value)
      case 'workspaces':
        return await updateWorkspaces(calendarEventId, updateData.value)
      default:
        // @ts-expect-error
        console.error(`Field ${updateData.field} can not be updated via initCalendarEventUpdate`) // eslint-disable-line
    }
  }

  const deleteEvent = async (eventId: string) => {
    return await deleteEventMutation(eventId)
      .unwrap()
      .then(() => enqueueSnackbar('Calendar event deleted', { variant: 'success' }))
      .catch(handleError)
  }

  return {
    createCalendarEvent,
    updateInfo,
    updateProject,
    updateTask,
    updateCustomers,
    updateParticipants,
    updateSuppliers,
    updateWorkspaces,
    initCalendarEventUpdate,
    deleteEvent,
  }
}

type InitCalendarEventUpdateData =
  | {
      field: 'title' | 'startDate' | 'endDate'
      value: string
    }
  | {
      field: 'description' | 'project' | 'task'
      value: string | null
    }
  | {
      field: 'customers' | 'participants' | 'suppliers' | 'workspaces'
      value: string[]
    }
