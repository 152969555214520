/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const makeBoardApiEndpoints = (boardId = ':boardId') => {
  const endpoints = {
    boards: () => `/boards`,
    boardItem: (id = boardId) => `/boards/${id}`,
    boardColumns: (id = boardId) => `${endpoints.boardItem(id)}/columns`,
    boardColumnItem: (columnId = ':columnId', id = boardId) => `${endpoints.boardColumns(id)}/${columnId}`,
    columnName: (columnId = ':columnId', id = boardId) => `${endpoints.boardColumnItem(columnId, id)}/name`,
    boardCards: (columnId = ':columnId', id = boardId) => `${endpoints.boardColumnItem(columnId, id)}/cards`,
    boardTaskCards: (columnId = ':columnId', id = boardId) => `${endpoints.boardColumnItem(columnId, id)}/task-cards`,
    boardTodoCards: (columnId = ':columnId', id = boardId) => `${endpoints.boardColumnItem(columnId, id)}/todo-cards`,
    boardCardItem: (cardId = ':cardId', columnId = ':columnId', id = boardId) =>
      `${endpoints.boardCards(columnId, id)}/${cardId}`,
    boardTaskCardItem: (cardId = ':cardId', columnId = ':columnId', id = boardId) =>
      `${endpoints.boardTaskCards(columnId, id)}/${cardId}`,
    boardTodoCardItem: (cardId = ':cardId', columnId = ':columnId', id = boardId) =>
      `${endpoints.boardTodoCards(columnId, id)}/${cardId}`,
  }
  return endpoints
}
