import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import doublet from '../utils/doublet'
import { CheckBillingInfoResponse, fetchCheckBillingInfo } from '../organisations/services/organisation-api'
import { useAuthUserMembership } from '../memberships/hooks/use-auth-user-membership'

export const useOpenBillingSettingsDialog = (orgId: string) => {
  const [openBillingSettingsDialog, setOpenBillingSettingsDialog] = useState(false)
  const [checkBillingInfo, setCheckBillingInfo] = useState<CheckBillingInfoResponse | null>(null)
  const [checkBillingInfoError, setCheckBillingInfoError] = useState<Error | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const { canCreateMemberships } = useAuthUserMembership()

  const shouldFillBillingSettings = () => {
    if (checkBillingInfo) {
      if (checkBillingInfo.billingSettingsIsFilled) return false
      if (checkBillingInfo.canInviteUserWithoutBillingInfo) return false
    }
    return true
  }

  const checkBillingSettingsAndOpenDialog = () => {
    if (shouldFillBillingSettings()) {
      setOpenBillingSettingsDialog(true)
    }
  }

  const closeBillingSettingsDialog = async () => {
    if (shouldFillBillingSettings()) {
      const [error, data] = await doublet(fetchCheckBillingInfo, orgId)
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' })
        return
      }
      if (data) {
        setCheckBillingInfo(data)
      }
    }

    setOpenBillingSettingsDialog(false)
  }

  const recheck = async () => {
    const [error, data] = await doublet(fetchCheckBillingInfo, orgId)
    if (error) {
      setCheckBillingInfoError(error)
      enqueueSnackbar(error.message, { variant: 'error' })
      return
    }
    if (data) {
      setCheckBillingInfo(data)
    }
  }

  useEffect(() => {
    const loadData = async () => {
      const [error, data] = await doublet(fetchCheckBillingInfo, orgId)
      if (error) {
        setCheckBillingInfoError(error)
        enqueueSnackbar(error.message, { variant: 'error' })
        return
      }
      if (data) {
        setCheckBillingInfo(data)
      }
    }

    if (!checkBillingInfo && !checkBillingInfoError && canCreateMemberships) loadData()
  })

  return {
    openBillingSettingsDialog,
    errorFetchingBillingInformation: checkBillingInfoError,
    shouldFillBillingSettings: shouldFillBillingSettings(),
    checkBillingSettingsAndOpenDialog,
    closeBillingSettingsDialog,
    recheck,
  }
}
