import ResourceManager from '../../components/ResourceManager'
import { useI18n } from '../../hooks'
import { useOrgOptions } from '../../options/hooks/use-org-options'
import { ProjectViewModel } from '../api/project'
import { useProjectMutations } from '../hooks/use-project-mutations'
import { StringMap } from '../../types/common'

const ProjectCustomersWidget = ({ project }: { project: ProjectViewModel }) => {
  const translations = useTranslations(defaultTranslations)
  const { customerOptions } = useOrgOptions(project.maintainerId)
  const { addCustomers, removeCustomers } = useProjectMutations()

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={customerOptions}
      resources={project?.customers || []}
      canAddResource={project?.canUpdateCustomers || false}
      canRemoveResource={project?.canUpdateCustomers || false}
      onRemoveResource={(id) => removeCustomers(project.id, [id])}
      onAddResources={(ids) => addCustomers(project.id, ids)}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection
    />
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('project')
  const translations = (t?.customersWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: 'Customer',
  emptyOptionsMessage: 'No customers in this organisation',
  emptyResourcesMessage: 'No customers in this project',
}

type Translations = typeof defaultTranslations

export default ProjectCustomersWidget
