import InputLabel from '../../../../components/InputLabel'
import OrganisationForm from '../../../../customers/components/OrganisationForm'
import PersonForm from '../../../../customers/components/PersonForm'

import { Button, Card, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { useState } from 'react'
import { CustomerTypeEnum } from '../../../../types/customer'
import { COLOR_DARK_100, COLOR_WHITE } from '../../../../constants'
import { useI18n } from '../../../../hooks'
import { useAppContext } from '../../../../hooks/use-app-context'

const NewCustomerRoute = () => {
  const translations = useTranslations()
  const { appContext } = useAppContext()
  const mainContext = appContext.mainContext

  if (mainContext?.type !== 'org') {
    return (
      <>
        <img
          src="/exclamation.svg"
          alt="Select organisation"
          height="64px"
          style={{ display: 'block', margin: 'auto' }}
        />
        <Typography variant="subtitle1" color="textSecondary" style={{ textAlign: 'center', marginTop: 24 }}>
          {translations.selectOrganisationMessage}
        </Typography>
      </>
    )
  }

  return <CreateCustomerView />
}

const CreateCustomerView = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const defaultType = CustomerTypeEnum.organisation
  const [selectedType, setSelectedType] = useState(defaultType)
  const isOrganisation = selectedType === CustomerTypeEnum.organisation
  const isPerson = selectedType === CustomerTypeEnum.person

  const handleClickType = (type: CustomerTypeEnum) => () => {
    setSelectedType(type)
  }

  return (
    <Card className={classes.card} variant="outlined" data-test="new-customer-card">
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="h4">{translations.pageHeader}</Typography>
        </Grid>
        <Grid item>
          <InputLabel label={translations.customerTypeLabel} isRequired={true} />
          <Grid container direction="row" className={classes.buttonContainer}>
            <Grid item className={classes.item}>
              <Button
                fullWidth
                onClick={handleClickType(CustomerTypeEnum.organisation)}
                variant={isOrganisation ? 'contained' : 'text'}
                className={classes.button}
                data-test="organsiation-customer-button"
              >
                {translations.organisationLabel}
              </Button>
            </Grid>
            <Grid item className={classes.item}>
              <Button
                fullWidth
                onClick={handleClickType(CustomerTypeEnum.person)}
                variant={isPerson ? 'contained' : 'text'}
                className={classes.button}
                data-test="person-customer-button"
              >
                {translations.personLabel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {isOrganisation && <OrganisationForm />}
          {isPerson && <PersonForm />}
        </Grid>
      </Grid>
    </Card>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('customer')
  const translations = (t.customerForm || {}) as { [k: string]: string }

  const {
    pageHeader = defaults.pageHeader,
    customerTypeLabel = defaults.customerTypeLabel,
    organisationLabel = defaults.organisationLabel,
    personLabel = defaults.personLabel,
    selectOrganisationMessage = defaults.selectOrganisationMessage,
  } = translations

  return {
    pageHeader,
    customerTypeLabel,
    organisationLabel,
    personLabel,
    selectOrganisationMessage,
  }
}
const defaultTranslations = {
  pageHeader: "Let's add a new customer",
  selectOrganisationMessage: 'Select organisation before creating a customer',
  customerTypeLabel: 'Customer type',
  organisationLabel: 'Organisation',
  personLabel: 'Person',
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(8),
  },
  buttonContainer: {
    padding: theme.spacing(0.5),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
  },
  item: {
    flexGrow: 1,
  },
  card: {
    margin: theme.spacing(4, 'auto'),
    padding: theme.spacing(4),
    maxWidth: theme.breakpoints.values['sm'],
    border: `1px solid ${theme.palette.divider}`,
  },
  pageHeader: {
    marginBottom: theme.spacing(3),
  },
  button: {
    textTransform: 'capitalize',

    '&.MuiButton-text': {
      color: COLOR_DARK_100,
    },
    '&.MuiButton-contained': {
      backgroundColor: COLOR_DARK_100,
      color: COLOR_WHITE,
    },
  },
}))

type Translations = typeof defaultTranslations
export default NewCustomerRoute
