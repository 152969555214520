import { TimeZoneType } from '../../constants/timezones'
import { IOptionNormalized } from '../../options/interfaces/options-normalized'
import { DateTimeService } from '../../services/date-time-service'
import { TreeGridTask, TreeGridTaskCellPermissions } from './types'
import { sortBy } from 'lodash'
import { getStateIconHtml, makeEnumString, mapStatusToTranslations } from '../utils'
import { getCustomerOptions, getMembershipOptions, getSupplierOptions, getWorkspaceOptions } from '../../options/utils'
import { LinkType } from '../../tasks/api/task-response'
import { COLOR_GRAY } from '../../constants'
import { isTaskPlannedEndDatePastDue, isTaskPlannedStartDatePastDue } from '../../tasks/utils/date'
import { TaskViewModel } from '../../tasks/api/task'
import { TreeGridTranslations } from '../hooks/use-translations'

const spanStyles = `color: ${COLOR_GRAY} !important; cursor: pointer;font-size: 0.625rem; font-weight: 400; overflow:hidden`
export const makeTreeGridTaskRows = ({
  tasks,
  dateFormat,
  timeZone,
  options,
  translations,
}: {
  tasks: TaskViewModel[]
  dateFormat: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
  translations: TreeGridTranslations
}): TreeGridTask[] => {
  return tasks.map((task) => makeTreeGridTaskRow({ task, dateFormat, timeZone, options, translations }))
}

export const makeTreeGridTaskRow = ({
  task,
  dateFormat,
  timeZone,
  options,
  translations,
}: {
  task: TaskViewModel
  dateFormat: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
  translations: TreeGridTranslations
}): TreeGridTask => {
  const { id, title, description, statusDescription, enableTimeComponent, projectLink, taskLink, ancestorTaskLinks } =
    task
  const dateTimeService = new DateTimeService({ dateFormat, timeZone, enableTimeComponent })
  const dateTimeFormat = dateTimeService.getFormat()
  const taskNumber = task.customTaskNumber || task.taskNumber
  const status = mapStatusToTranslations(translations)[task.status]

  const customers = task.customers.map((c) => c.id).join(';')
  const participants = task.participants.map((p) => p.id).join(';')
  const managers = task.managers.map((m) => m.id).join(';')
  const suppliers = task.suppliers.map((s) => s.id).join(';')
  const workspaces = task.workspaces.map((w) => w.id).join(';')

  const titleWrap = 1
  const titleHtmlPrefix = getTitleHtmlPrefix({ projectLink, taskLink, ancestorTaskLinks }) // Title links to the task
  const plannedStartDate = getPlannedStartDate() // number of milliseconds since epoch, or null
  const plannedEndDate = getPlannedEndDate() // number of milliseconds since epoch, or null
  const actualStartDate = getActualStartDate() // number of milliseconds since epoch, or null
  const actualEndDate = getActualEndDate() // number of milliseconds since epoch, or null
  const rejectedDate = getRejectedDate() // number of milliseconds since epoch, or null
  const cellPermissions = getTreeGridTaskCellPermissions()
  const state = getStateIconHtml(task.state) // html for the state icon
  const plannedStartDateClass = getPlannedStartDateClass() // css class for rendering red text if task is past due
  const plannedEndDateClass = getPlannedEndDateClass() // css class for rendering red text if task is past due
  const statusDescriptionTip = getStatusDescriptionTip() // Text showing the user who made the last update and when it was made

  const membershipOptions = getMembershipOptions(options).filter(({ orgId }) => task.maintainerId === orgId)
  const participantOptions = membershipOptions.filter(({ id }) => !managers.includes(id))
  const managerNames = membershipOptions.map(({ name }) => name)
  const managerIds = membershipOptions.map(({ id }) => id)
  const managersEnum = makeEnumString(managerNames)
  const managersEnumKeys = makeEnumString(managerIds)
  const participantNames = participantOptions.map(({ name }) => name)
  const participantIds = participantOptions.map(({ id }) => id)
  const participantsEnum = makeEnumString(participantNames)
  const participantsEnumKeys = makeEnumString(participantIds)

  const supplierOptions = getSupplierOptions(options).filter(({ orgId }) => task.maintainerId === orgId)
  const supplierNames = supplierOptions.map(({ name }) => name)
  const supplierIds = supplierOptions.map(({ id }) => id)
  const supplierEnum = makeEnumString(supplierNames)
  const supplierEnumKeys = makeEnumString(supplierIds)

  const workspaceOptions = getWorkspaceOptions(options).filter(({ orgId }) => task.maintainerId === orgId)
  const workspaceNames = workspaceOptions.map(({ name }) => name)
  const workspaceIds = workspaceOptions.map(({ id }) => id)
  const workspaceEnum = makeEnumString(workspaceNames)
  const workspaceEnumKeys = makeEnumString(workspaceIds)

  const customerOptions = getCustomerOptions(options).filter(({ orgId }) => task.maintainerId === orgId)
  const customerNames = customerOptions.map(({ name }) => name)
  const customerIds = customerOptions.map(({ id }) => id)
  const customerEnum = makeEnumString(customerNames)
  const customerEnumKeys = makeEnumString(customerIds)

  return {
    id,
    open: '/external-link-icon.svg',
    titleWrap,
    titleHtmlPrefix,
    title,
    description,
    status,
    state,
    statusDescription,
    taskNumber,
    customers,
    participants,
    managers,
    suppliers,
    workspaces,
    plannedStartDate,
    plannedEndDate,
    actualStartDate,
    actualEndDate,
    rejectedDate,
    plannedStartDateClass,
    plannedEndDateClass,
    statusDescriptionTip,
    enableTimeComponent,
    plannedStartDateFormat: dateTimeFormat,
    plannedEndDateFormat: dateTimeFormat,
    actualStartDateFormat: dateTimeFormat,
    actualEndDateFormat: dateTimeFormat,
    rejectedDateFormat: dateTimeFormat,
    managersEnum,
    managersEnumKeys,
    participantsEnum,
    participantsEnumKeys,
    suppliersEnum: supplierEnum,
    suppliersEnumKeys: supplierEnumKeys,
    workspacesEnum: workspaceEnum,
    workspacesEnumKeys: workspaceEnumKeys,
    customersEnum: customerEnum,
    customersEnumKeys: customerEnumKeys,
    Height: 48,
    MaxHeight: 48,
    ...cellPermissions,
  }

  function getActualStartDate(): number | string {
    return task.actualStartDate ? dateTimeService.removeTimezoneOffset(task.actualStartDate, 'UTC').getTime() : ''
  }

  function getActualEndDate(): number | string {
    return task.actualEndDate ? dateTimeService.removeTimezoneOffset(task.actualEndDate, 'UTC').getTime() : ''
  }

  function getPlannedStartDate(): number | string {
    return task.plannedStartDate ? dateTimeService.removeTimezoneOffset(task.plannedStartDate, 'UTC').getTime() : ''
  }

  function getPlannedEndDate(): number | string {
    return task.plannedEndDate ? dateTimeService.removeTimezoneOffset(task.plannedEndDate, 'UTC').getTime() : ''
  }

  function getRejectedDate(): number | string {
    return task.rejectedDate ? dateTimeService.removeTimezoneOffset(task.rejectedDate, 'UTC').getTime() : ''
  }

  function getPlannedStartDateClass(): string {
    const isPastDue = isTaskPlannedStartDatePastDue({ task, dateTimeService })
    return isPastDue ? 'redText' : ''
  }

  function getPlannedEndDateClass(): string {
    const isPastDue = isTaskPlannedEndDatePastDue({ task, dateTimeService })
    return isPastDue ? 'redText' : ''
  }

  function getStatusDescriptionTip() {
    const sortedUpdates = sortBy(task.statusDescriptionUpdates, 'updatedAt')
    const latestUpdate = sortedUpdates[sortedUpdates.length - 1]
    let tip = ''
    if (latestUpdate) {
      const formatStr = `${dateFormat} HH:mm`
      const { updatedBy, updatedAt } = latestUpdate
      const offsetDate = dateTimeService.removeTimezoneOffset(updatedAt)
      // if there is a latest update
      tip += updatedBy // include the user who made the update
      tip += '&nbsp;&nbsp;&bull;&nbsp;&nbsp;' // some spacing
      tip += dateTimeService.format(offsetDate, formatStr) // and formatted date of update
    }
    return tip
  }

  function getTreeGridTaskCellPermissions(): TreeGridTaskCellPermissions {
    return {
      titleCanEdit: task.canUpdateDetails ? 1 : 0,
      descriptionCanEdit: task.canUpdateDetails ? 1 : 0,
      taskNumberCanEdit: task.canUpdateDetails ? 1 : 0,
      plannedStartDateCanEdit: task.canUpdatePlan ? 1 : 0,
      plannedEndDateCanEdit: task.canUpdatePlan ? 1 : 0,
      statusCanEdit: task.canUpdateStatus ? 1 : 0,
      stateCanEdit: task.canUpdateStatus ? 1 : 0,
      statusDescriptionCanEdit: task.canUpdateStatus ? 1 : 0,
      actualEndDateCanEdit: task.canUpdateStatus ? 1 : 0,
      actualStartDateCanEdit: task.canUpdateStatus ? 1 : 0,
      managersCanEdit: task.canUpdateManagers ? 1 : 0,
      suppliersCanEdit: task.canUpdateSuppliers ? 1 : 0,
      workspacesCanEdit: task.canUpdateWorkspaces ? 1 : 0,
      participantsCanEdit: task.canUpdateParticipants ? 1 : 0,
      customersCanEdit: 0,
    }
  }

  function getTitleHtmlPrefix(props: {
    projectLink: LinkType
    taskLink: LinkType
    ancestorTaskLinks: LinkType[]
  }): string {
    let html = ''
    const { projectLink, ancestorTaskLinks } = props

    if (projectLink) {
      if (projectLink.disabled) {
        html += `<span style='${spanStyles}' >${projectLink.label}  </span>`
      } else {
        html += `<span onclick='window.Grids.onClickTitleLink("${projectLink.href}/basic"); event.stopPropagation();' style='${spanStyles}' onmouseover="this.style.textDecoration='underline';" onmouseout="this.style.textDecoration='none';">${projectLink.label}  /  </span>`
      }
    }

    if (ancestorTaskLinks.length > 0) {
      ancestorTaskLinks.forEach((link) => {
        if (link.disabled) {
          html += `<span style='${spanStyles}'>${link.label} / </span>`
        } else {
          html += `<span onclick='window.Grids.onClickTitleLink("${link.href}/basic"); event.stopPropagation();' style='${spanStyles}'  onmouseover="this.style.textDecoration='underline';" onmouseout="this.style.textDecoration='none';">${link.label}  /  </span>`
        }
      })
    }

    return `<div>${html}</div>`
  }
}
