import { isAfter } from 'date-fns'
import { DateTimeService } from '../../services/date-time-service'
import { ProjectViewModel } from '../api/project'

export const isProjectPlannedStartDatePastDue = (props: {
  project: ProjectViewModel
  dateTimeService: DateTimeService
}) => {
  const { project, dateTimeService } = props
  if (!project || !project.plannedStartDate) return false
  const compareToDate = project.enableTimeComponent
    ? project.plannedStartDate
    : dateTimeService.endOfDay(new Date(project.plannedStartDate))
  const timezonedCompareToDate = dateTimeService.removeTimezoneOffset(compareToDate)
  const isPastDue = project.isNotStarted ? isAfter(new Date(), new Date(timezonedCompareToDate)) : false
  return isPastDue
}

export const isProjectPlannedEndDatePastDue = (props: {
  project: ProjectViewModel
  dateTimeService: DateTimeService
}) => {
  const { project, dateTimeService } = props
  if (!project || !project.plannedEndDate) return false
  const compareToDate = project.enableTimeComponent
    ? project.plannedEndDate
    : dateTimeService.endOfDay(new Date(project.plannedEndDate))
  const timezonedCompareToDate = dateTimeService.removeTimezoneOffset(compareToDate)
  const isPastDue = !project.isCompleted ? isAfter(new Date(), new Date(timezonedCompareToDate)) : false
  return isPastDue
}
