import { PayloadAction } from '@reduxjs/toolkit'
import { IUserResponse } from '../interfaces/user-response'
import { AuthState } from './index'
import { normalizeUser } from '../interfaces/user-normalized'

export const setAuthStateCase = (_: AuthState, action: PayloadAction<AuthState>) => action.payload

export const setRefreshTokenCase = (
  state: AuthState,
  action: PayloadAction<{
    refreshToken: string | null
    isAuthenticated: boolean
  }>
) => {
  state.isAuthenticated = action.payload.isAuthenticated
  state.refreshToken = action.payload.refreshToken
}

export const setUserCase = (state: AuthState, action: PayloadAction<IUserResponse>) => {
  state.user = normalizeUser(action.payload)
}

export const setAccessTokenCase = (
  state: AuthState,
  action: PayloadAction<{ accessToken: string; tokenExpiresAt: number }>
) => {
  state.accessToken = action.payload.accessToken
  state.tokenExpiresAt = action.payload.tokenExpiresAt
}

export const setIsRefreshingTokenCase = (state: AuthState, action: PayloadAction<boolean>) => {
  state.isRefreshingToken = action.payload
}

export const setTokenRefreshCompletedCase = (state: AuthState) => {
  state.isRefreshingToken = false
}
export const setAuthErrorCase = (state: AuthState, action: PayloadAction<any>) => {
  state.authError = 'Unauthorized'
}

export const resetAuthStateCase = (state: AuthState) => {
  state.isAuthenticated = false
  state.refreshToken = null
  state.user = null
  state.accessToken = null
  state.tokenExpiresAt = null
  state.isRefreshingToken = false
  state.authError = null
}
