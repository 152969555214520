import React from 'react'

import {
  Avatar,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  IconButton,
  alpha,
} from '@material-ui/core'
import { StringMap } from 'i18next'
import { Trash } from 'react-feather'
import { useConfirmDialog } from '../../components/ConfirmDialog'
import { useI18n } from '../../hooks'
import { IMembershipNormalized } from '../../memberships/interfaces/membership-normalized'

const MembershipsTable = ({
  memberships,
  emptyTableMessage,
  handleClickDelete,
  canRemoveRow,
}: MembershipsTableProps) => {
  const classes = useStyles()
  const translations = useTranslations()

  return (
    <div className={classes.tableRoot}>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="customers table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">{translations.avatarHeaderText}</TableCell>
              <TableCell padding="checkbox">{translations.nameHeaderText}</TableCell>
              <TableCell padding="checkbox">{translations.emailHeaderText}</TableCell>
              {canRemoveRow && (
                <TableCell padding="checkbox" align="right">
                  {''}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {memberships.map((membership: IMembershipNormalized) => (
              <MembershipRow
                key={membership.id}
                membership={membership}
                handleClickDelete={() => handleClickDelete(membership)}
                canRemoveRow={canRemoveRow}
              />
            ))}
          </TableBody>
        </Table>
        {!memberships.length && (
          <Typography className="noData" color="textSecondary" variant="subtitle1">
            {emptyTableMessage}
          </Typography>
        )}
      </TableContainer>
    </div>
  )
}
const MembershipRow = ({ membership, handleClickDelete, canRemoveRow }: MembershipRowProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const [actionState, setActionState] = React.useState<ActionState>('idle')
  const { fullname, userEmail, userInitials } = membership

  const { confirm: confirmDeleteMembership } = useConfirmDialog({
    onConfirm: async () => {
      setActionState('busy')
      await handleClickDelete()
      setActionState('idle')
    },
    primaryActionButtonLabel: translations.removeMemberButtonLabel,
    secondaryActionButtonLabel: translations.cancelButtonLabel,
    title: translations.removeMemberDialogTitle,
    text: translations.removeMemberDialogText,
  })

  return (
    <TableRow data-test={`membership-row-${fullname}`}>
      <TableCell padding="checkbox">
        <Avatar className={classes.listItemAvatar}>{userInitials}</Avatar>
      </TableCell>
      <TableCell padding="checkbox">{fullname}</TableCell>
      <TableCell padding="checkbox">{userEmail}</TableCell>
      {canRemoveRow && (
        <TableCell padding="checkbox" align="right">
          <IconButton
            onClick={confirmDeleteMembership}
            disabled={actionState === 'busy'}
            data-test={`trash-icon-${fullname}`}
          >
            <Trash />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('workspace')
  const translations = (t?.membersPage || {}) as StringMap

  const {
    nameHeaderText = defaults.nameHeaderText,
    emailHeaderText = defaults.emailHeaderText,
    avatarHeaderText = defaults.avatarHeaderText,
    noMembershipsText = defaults.noMembershipsText,
    removeMemberDialogTitle = defaults.removeMemberDialogTitle,
    removeMemberDialogText = defaults.removeMemberDialogText,
    cancelButtonLabel = defaults.cancelButtonLabel,
    removeMemberButtonLabel = defaults.removeMemberButtonLabel,
  } = translations

  return {
    nameHeaderText,
    emailHeaderText,
    avatarHeaderText,
    noMembershipsText,
    cancelButtonLabel,
    removeMemberButtonLabel,
    removeMemberDialogTitle,
    removeMemberDialogText,
  }
}

const defaultTranslations = {
  nameHeaderText: 'Name',
  emailHeaderText: 'Email',
  avatarHeaderText: 'Avatar',
  noMembershipsText: 'There are no members to select',
  cancelButtonLabel: 'Cancel',
  removeMemberButtonLabel: 'Remove',
  removeMemberDialogTitle: 'Remove member',
  removeMemberDialogText: 'Are you sure you want to remove the member from this workspace?',
}

const useStyles = makeStyles((theme: Theme) => ({
  listItemAvatar: {
    background: theme.palette.error.main,
    fontWeight: theme.typography.subtitle1.fontWeight,
  },
  header: {
    padding: theme.spacing(2, 0),
  },
  tableRoot: {
    maxWidth: 1440,
    borderRadius: 7,
    padding: theme.spacing(1, 0),
    width: '100%',
    background: theme.palette.common.white,
    '& .MuiToolbar-root': {
      padding: theme.spacing(0, 2),
      '& .MuiButton-root': {
        marginRight: theme.spacing(),
        transition: theme.transitions.create('background'),
        background: alpha(theme.palette.primary.main, 0.05),
        '&:hover': {
          background: alpha(theme.palette.primary.main, 0.1),
        },
      },
      '& .MuiSelect-outlined': {
        padding: '6px 9px',
        paddingRight: 32,
        fontSize: 14,
      },
    },
    '& .MuiTableContainer-root': {
      borderRadius: 0,
      '& .MuiTableRow-root th': {
        textTransform: 'uppercase',
        fontSize: 12,
        color: theme.palette.text.secondary,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      '& .MuiTableCell-paddingCheckbox': {
        padding: theme.spacing(0.5),
        width: 'auto',
      },
      '& .noData': {
        textAlign: 'center',
        padding: 20,
      },
    },
  },
}))

type ActionState = 'idle' | 'busy'
type Translations = typeof defaultTranslations
type MembershipRowProps = {
  membership: IMembershipNormalized
  handleClickDelete: () => Promise<void>
  canRemoveRow: boolean
}
type MembershipsTableProps = {
  memberships: IMembershipNormalized[]
  emptyTableMessage: string
  handleClickDelete: (membership: IMembershipNormalized) => Promise<void>
  canRemoveRow: boolean
}
export default MembershipsTable
