import Button from '@material-ui/core/Button'
import InputLabel from '../InputLabel'
import FormikInput from '../FormikInput'
import FormErrorAlert from '../FormErrorAlert'
import Paper from '@material-ui/core/Paper'

import { Form, Formik, FormikConfig } from 'formik'
import { CircularProgress, makeStyles, Theme } from '@material-ui/core'
import { useI18n } from '../../hooks'
import { useAsync } from '../../hooks/use-async'

const INITIAL_VALUES = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  jobTitle: '',
}

const ContactForm = ({ onAddContact }: ContactFormProps) => {
  const classes = useStyles()
  const canAddContact = Boolean(onAddContact)
  const translations = useTranslations(defaultTranslations)
  const { isIdle, isPending, isError, execute: handleAddContact } = useAsync(onAddContact, { immediate: false })

  const formikConfig: FormikConfig<ContactFormValues> = {
    initialValues: INITIAL_VALUES,
    onSubmit: handleAddContact,
  }

  let submitButtonEndIcon: React.ReactNode | undefined
  let submitButtonLabel = translations.submitButtonLabel_idle

  if (isPending()) {
    submitButtonEndIcon = <CircularProgress size={16} />
    submitButtonLabel = translations.submitButtonLabel_pending
  }

  return (
    <Paper elevation={0}>
      <Formik {...formikConfig}>
        {({ isValid, values }) => {
          const disableButton = !values.firstname || !values.lastname || !isIdle() || !isValid || !canAddContact

          return (
            <Form className={classes.form}>
              <InputLabel label={translations.firstnameLabel} isRequired={true} />
              <FormikInput
                name="firstname"
                variant="outlined"
                placeholder="e.g. Steve"
                fullWidth
                disabled={!canAddContact}
                inputProps={{ 'data-test': 'contact-firstname-input' }}
              />
              <InputLabel label={translations.lastnameLabel} isRequired={true} />
              <FormikInput
                name="lastname"
                variant="outlined"
                placeholder="e.g. Smith"
                fullWidth
                disabled={!canAddContact}
                inputProps={{ 'data-test': 'contact-lastname-input' }}
              />
              <InputLabel label={translations.emailLabel} />
              <FormikInput
                name="email"
                variant="outlined"
                placeholder="e.g. steve@mail.com"
                fullWidth
                disabled={!canAddContact}
                inputProps={{ 'data-test': 'contact-email-input' }}
              />
              <InputLabel label={translations.phoneLabel} />
              <FormikInput
                name="phone"
                variant="outlined"
                placeholder="e.g. 0123456789"
                fullWidth
                disabled={!canAddContact}
                inputProps={{ 'data-test': 'contact-phone-input' }}
              />
              <InputLabel label={translations.jobTitleLabel} />
              <FormikInput
                name="jobTitle"
                variant="outlined"
                placeholder="e.g. Branch Manager"
                fullWidth
                disabled={!canAddContact}
                inputProps={{ 'data-test': 'contact-job-title-input' }}
              />
              {isError() && (
                <FormErrorAlert title={translations.errorAlertTitle} description={translations.errorAlertDescription} />
              )}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                endIcon={submitButtonEndIcon}
                className={classes.button}
                disabled={disableButton}
                data-test="contact-submit-button"
              >
                {submitButtonLabel}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Paper>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('supplier')
  const translations = (t.contactForm || {}) as { [k: string]: string }

  const {
    firstnameLabel = defaults.firstnameLabel,
    lastnameLabel = defaults.lastnameLabel,
    emailLabel = defaults.emailLabel,
    phoneLabel = defaults.phoneLabel,
    jobTitleLabel = defaults.jobTitleLabel,
    errorAlertTitle = defaults.errorAlertTitle,
    errorAlertDescription = defaults.errorAlertDescription,
    submitButtonLabel_idle = defaults.submitButtonLabel_idle,
    submitButtonLabel_pending = defaults.submitButtonLabel_pending,
  } = translations

  return {
    firstnameLabel,
    lastnameLabel,
    emailLabel,
    phoneLabel,
    jobTitleLabel,
    errorAlertTitle,
    errorAlertDescription,
    submitButtonLabel_idle,
    submitButtonLabel_pending,
  }
}

const defaultTranslations = {
  firstnameLabel: 'Firstname',
  lastnameLabel: 'Lastname',
  emailLabel: 'Email',
  phoneLabel: 'Phone',
  jobTitleLabel: 'Job title',
  errorAlertTitle: 'Error',
  errorAlertDescription: 'Failed to add contact',
  submitButtonLabel_idle: 'Create contact',
  submitButtonLabel_pending: 'Creating contact',
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    minWidth: 224,
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  form: {
    padding: theme.spacing(1, 2),
    '& > div': {
      transition: theme.transitions.create('margin'),
      marginBottom: theme.spacing(3),
      '&:nth-last-child(2)': {
        marginBottom: theme.spacing(5),
      },
      '&:nth-last-child(1)': {
        marginTop: theme.spacing(3),
      },
    },
  },
}))

interface ContactFormProps {
  onAddContact: (contact: ContactFormValues) => Promise<void>
}

type ContactFormValues = {
  firstname: string
  lastname: string
  email: string
  phone: string
  jobTitle: string
}

type Translations = typeof defaultTranslations

export default ContactForm
