enum MembershipStatusEnum {
  ACTIVE = 'ACTIVE',
  IN_ACTIVE = 'IN_ACTIVE',
}

enum MembershipRoleEnum {
  orgLimitedMember = 'orgLimitedMember',
  orgMember = 'orgMember',
  orgOwner = 'orgOwner',
}

export { MembershipRoleEnum, MembershipStatusEnum }
