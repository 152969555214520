import OrgWorkspacesTable from '../../../../organisations/components/OrgWorkspacesTable'
import React from 'react'
import Progress from '../../../../components/Progress'

import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { Plus } from 'react-feather'
import { Link } from 'react-router-dom'
import { useAppContext } from '../../../../hooks/use-app-context'
import { useAuthUserMembership } from '../../../../memberships/hooks/use-auth-user-membership'
import { paths } from '../../../../paths'
import { useI18n } from '../../../../hooks'
import { StringMap } from 'i18next'
import { useAppDispatch } from '../../../../store'
import { FetchWorkspaces } from '../../../../workspaces/store/actions'
import { useAsync } from '../../../../hooks/use-async'
import { unwrapResult } from '@reduxjs/toolkit'
import { useUrlWithContext } from '../../../../hooks/use-url-with-context'
import { FetchContextOptions } from '../../../../context-options/store/actions'

const useLoader = ({ orgId }: RouteParams) => {
  const dispatch = useAppDispatch()
  const loader = React.useCallback(async () => {
    dispatch({ type: `load_org_suppliers_route` })
    const dispatchResults = await Promise.all([dispatch(FetchWorkspaces(orgId)), dispatch(FetchContextOptions())])
    dispatchResults.forEach(unwrapResult)
  }, [dispatch, orgId])
  return useAsync<void, any>(loader)
}

const OrgWorkspacesRoute = () => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const { appContext } = useAppContext()
  const { createPathWithContext } = useUrlWithContext()
  const { mainContext } = appContext
  const orgId = mainContext?.id as string
  const { canCreateWorkspaces } = useAuthUserMembership()
  const { error, isSuccess, isPending } = useLoader({ orgId })
  if (!mainContext || mainContext.type === 'user') return null
  const createWorkspaceButtonHref = createPathWithContext(paths.orgNewWorkspace(), {
    withSubContext: false,
  })

  return (
    <div className={classes.pageContainer}>
      <Grid container className={classes.header} justifyContent="space-between">
        <Grid item>
          <Typography variant="h6" component="header" data-test="page-header">
            <Box component="span" color="text.secondary">
              {`${mainContext.name} - `}
            </Box>
            {translations.workspaces}
          </Typography>
        </Grid>
        <Grid item>
          {canCreateWorkspaces && (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={createWorkspaceButtonHref}
              endIcon={<Plus size={20} />}
            >
              {translations.addBtnLabel}
            </Button>
          )}
        </Grid>
      </Grid>
      {isPending() && <Progress label="" />}
      {isSuccess() && <OrgWorkspacesTable orgId={mainContext.id} />}
      {error && <p>{error}</p>}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    padding: theme.spacing(0, 2),
    maxWidth: 1440,
    margin: 'auto',
  },
  header: {
    margin: theme.spacing(0.75, 0, 2, 0),
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('workspace')
  const translations = (t?.workspacesPage || {}) as StringMap

  const { workspaces = defaults.workspaces, addBtnLabel = defaults.addBtnLabel } = translations

  return {
    workspaces,
    addBtnLabel,
  }
}

const defaultTranslations = {
  workspaces: 'Workspaces',
  addBtnLabel: 'Add workspace',
}

type Translations = typeof defaultTranslations
type RouteParams = { orgId: string }

export default OrgWorkspacesRoute
