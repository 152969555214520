import { MoveCardData } from '../api'
import { BoardPopulated, getBoardDataFromPopulatedBoard } from '../api/board'
import { Board } from '../entities/board'
import { useBoardMutations } from './use-board-mutations'
import { useConfirmDialog } from '../../components/ConfirmDialog'
import { useI18n } from '../../hooks/use-i18n'
import { useLazyGetTasksQuery } from '../../tasks/api'
import { StringMap } from '../../types/common'
import { isCompleted, isNotStarted, isRejected } from '../../utils/status'
import { TaskViewModel } from '../../tasks/api/task'

export const useOnMoveCard = () => {
  const translations = useTranslations()
  const { moveTaskCard, moveTodoCard } = useBoardMutations()
  const [getTasks] = useLazyGetTasksQuery()

  const { confirm: confirmMoveToCompleted } = useConfirmDialog({
    onConfirm: async (moveCardData: MoveCardData) => {
      moveTaskCard({ ...moveCardData, updateSubtasks: true })
    },
    onCancel: async (moveCardData: MoveCardData) => {
      moveTaskCard(moveCardData)
    },
    primaryActionButtonLabel: translations.primaryActionButtonLabel,
    secondaryActionButtonLabel: translations.secondaryActionButtonLabel,
    title: translations.titleLabel,
    text: translations.moveToCompleteTextLabel,
  })

  const { confirm: confirmMoveToNotStarted } = useConfirmDialog({
    onConfirm: async (moveCardData: MoveCardData) => {
      moveTaskCard({ ...moveCardData, updateSubtasks: true })
    },
    onCancel: async (moveCardData: MoveCardData) => {
      moveTaskCard(moveCardData)
    },
    primaryActionButtonLabel: translations.primaryActionButtonLabel,
    secondaryActionButtonLabel: translations.secondaryActionButtonLabel,
    title: translations.titleLabel,
    text: translations.moveToNotStartedTextLabel,
  })

  const onMoveCard = async ({ board, ...moveCardData }: MoveCardData & { board: BoardPopulated }) => {
    const { cardId, columnId } = moveCardData
    const card = board.columns.byId[columnId].cards.byId[cardId]
    if (card.type === 'todo') return moveTodoCard(moveCardData)
    const boardInstance = new Board(getBoardDataFromPopulatedBoard(board))
    const destColumn = boardInstance.getColumnById(moveCardData.destColumnId)
    const isMovingToCompleted = destColumn?.position === 8
    const isMovingToNotStarted = destColumn?.position && destColumn.position < 3
    const shouldConfirm = isMovingToCompleted || isMovingToNotStarted
    if (!shouldConfirm) return moveTaskCard(moveCardData)

    await getTasks({ task: cardId })
      .unwrap()
      .then((tasks) => {
        if (isMovingToCompleted) {
          return checkHasNotCompletedDescendants(tasks)
            ? confirmMoveToCompleted(moveCardData)
            : moveTaskCard(moveCardData)
        }
        if (isMovingToNotStarted) {
          return checkHasNotNotStartedDescendants(tasks)
            ? confirmMoveToNotStarted(moveCardData)
            : moveTaskCard(moveCardData)
        }
      })
  }

  return {
    onMoveCard,
  }
}

const defaultTranslations = {
  primaryActionButtonLabel: 'Yes move this task and subtasks',
  secondaryActionButtonLabel: 'No move this task only',
  titleLabel: 'Do you want to move subtasks of this task?',
  moveToCompleteTextLabel:
    "'This task has subtasks whose current status is not Completed. Do you want to change the status of all subtasks to Completed ?',",
  moveToNotStartedTextLabel:
    "'This task has subtasks whose current status is not Not started. Do you want to change the status of all subtasks to Not started ?',",
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = (t?.boardConfirmDialog || {}) as StringMap

  const {
    primaryActionButtonLabel = defaults.primaryActionButtonLabel,
    secondaryActionButtonLabel = defaults.secondaryActionButtonLabel,
    titleLabel = defaults.titleLabel,
    moveToCompleteTextLabel = defaults.moveToCompleteTextLabel,
    moveToNotStartedTextLabel = defaults.moveToNotStartedTextLabel,
  } = translations

  return {
    primaryActionButtonLabel,
    secondaryActionButtonLabel,
    titleLabel,
    moveToCompleteTextLabel,
    moveToNotStartedTextLabel,
  }
}

function checkHasNotCompletedDescendants(tasks: TaskViewModel[]) {
  return tasks.some((task) => !task.archived && !isCompleted(task.status) && !isRejected(task.status))
}

function checkHasNotNotStartedDescendants(tasks: TaskViewModel[]) {
  return tasks.some((task) => !task.archived && !isNotStarted(task.status) && !isRejected(task.status))
}

type Translations = typeof defaultTranslations
