import React from 'react'
import UserSettingsView from '../../../users/views/UserSettingsView'
import Progress from '../../../components/Progress'

import { useAppDispatch } from '../../../store'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAsync } from '../../../hooks/use-async'
import { FetchUser } from '../../../users/store/actions'

const useLoader = () => {
  const dispatch = useAppDispatch()
  const loader = React.useCallback(async () => {
    dispatch({ type: 'load_user_settings_route' })
    unwrapResult(await dispatch(FetchUser()))
  }, [dispatch])
  return useAsync<void, any>(loader)
}

const UserSettingsRoute = () => {
  const { error, status } = useLoader()
  const errorMessage = error?.message ? error.message : error

  if (status === 'error') return <p>{errorMessage} </p>
  if (status === 'success') return <UserSettingsView />

  return <Progress label="" />
}

export default UserSettingsRoute
