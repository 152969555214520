import { find } from 'lodash'
import { COLOR_BLACK, COLOR_ERROR, COLOR_SECONDARY, COLOR_WARNING } from '../../constants'
import { TASK_STATE, TASK_STATE_ICON_NAME } from '../../tasks/constants'
import { GanttDependency, TaskStateType } from '../../tasks/types'
import { Status } from '../../types/common'
import { GanttZoom, TreegridGanttUnit } from '../types'
import { AppContextState } from '../../types/app-context'
import { IContextOptionNormalized } from '../../context-options/interfaces/context-options-normalized'
import { ProjectViewModel } from '../../projects/api/project'
import { TaskViewModel } from '../../tasks/api/task'
import { TodoViewModel } from '../../todos/api/todo'
import { CalendarEventViewModel } from '../../calendar-events/api/calendar-event'

export const treegridDateFormat = 'M/d/yyyy'
export const treegridDateTimeFormat = 'M/d/yyyy HH:mm:ss'

// Takes a gantt dependency and returns a string representation of it
// Example: { taskId: '64ed843', lagTime: 2, lagUnit: 'd', type: 'ss' } => '64ed843ss+2d'
export const serializeDepedency = (
  { taskId, lagTime, lagUnit, type, float }: GanttDependency,
  more: { inactive?: boolean } = {}
) => {
  let serialized = `${taskId}${type}`
  const lag = `${lagTime}${lagUnit}`
  if (lagTime >= 0) serialized += `+${lag}`
  if (lagTime < 0) serialized += lag
  if (float) serialized += `!${float}`
  if (more.inactive) serialized += '#8'
  return serialized
}

/** Joins an array of string to create a string that can be used as treegrid enum */
export const makeEnumString = (arr: string[]) => {
  // http://www.treegrid.com/Doc/TypeEnum.htm#CEnum
  /**
   * Treegrid treats the first character of the string as a separator
   * So if the first character is a pipe (|), it will be treated as a separator
   * So we need to add a pipe (|) at the beginning of the string and after each value
   * And we need to escape the pipe (|) in the values
   */

  // First the values that contain a pipe (|) because it will be treated as a separator
  const escapedArr = arr.filter((value) => {
    const valueHasPipe = value.includes('|')
    if (valueHasPipe) {
      // eslint-disable-next-line no-console
      console.log(`Excluding ${value} because it contains a pipe (|):`)
    }
    return !valueHasPipe
  })
  // Join the values with a pipe (|)
  const joined = escapedArr.join('|') // 'value1|value2|value3'
  // Add a pipe (|) at the beginning of the joined string
  const enumString = arr.length ? '|'.concat(joined) : '' // '|value1|value2|value3'
  return enumString
}

// Returns the treegrid enum string for state options
export const makeStateEnum = (translations: { notReported: string }) => {
  return makeEnumString([
    getHtmlForNotReportedState(translations), // This is the default value, so it must be the first value
    getStateIconHtml(TASK_STATE.GREEN),
    getStateIconHtml(TASK_STATE.YELLOW),
    getStateIconHtml(TASK_STATE.RED),
  ])
}

// Returns the treegrid enum string for status options
export const makeStatusEnum = (translations?: {
  notStarted: string
  inProgress: string
  completed: string
  rejected: string
}) => {
  return makeEnumString([
    translations?.notStarted || Status.NOT_STARTED,
    translations?.inProgress || Status.IN_PROGRESS,
    translations?.completed || Status.COMPLETED,
    translations?.rejected || Status.REJECTED,
  ])
}

// Returns html for a state icon based on the state
// e.g. getStateIconHtml(TASK_STATE.GREEN) => '<span class="material-symbols-rounded" style="font-size: 22px;  color: #000000; padding: 1px; border: 1.5px solid; border-radius: 2px; background-color: #00bfa577; border-color: #00bfa5;">&#xE876;</span>'
export const getStateIconHtml = (state: TaskStateType): string => {
  const iconName = TASK_STATE_ICON_NAME[state]
  const baseStyle = `font-size: 22px;  color: ${COLOR_BLACK}; padding: 1px; border: 1.5px solid; border-radius: 2px;`
  const styleByState = {
    [TASK_STATE.GREEN]: `${baseStyle} background-color: ${COLOR_SECONDARY}77; border-color: ${COLOR_SECONDARY}`,
    [TASK_STATE.RED]: `${baseStyle} background-color: ${COLOR_ERROR}77; border-color: ${COLOR_ERROR};`,
    [TASK_STATE.YELLOW]: `${baseStyle} background-color: ${COLOR_WARNING}77; border-color: ${COLOR_WARNING};`,
  }
  if (state === TASK_STATE.NOT_REPORTED) return ''
  return `<span class="material-symbols-rounded" style="${styleByState[state]}">${iconName}</span>`
}

const getHtmlForNotReportedState = (translations: { notReported: string }) => {
  return `
    <span style="display: inline-block; padding-top: 6px; padding-bottom: 6px;">
    ${translations.notReported}
    </span>
  `
}

export const getGanttUnitFromZoom = (zoomList: GanttZoom[], zoomName: string): TreegridGanttUnit => {
  const foundZoomLevel = find(zoomList, { Name: zoomName })
  return foundZoomLevel?.lagUnit || 'd'
}

export const getStateFromStateIcon = (iconHTML: string): TaskStateType => {
  const greenIconHTML = getStateIconHtml(TASK_STATE.GREEN)
  const yellowIconHTML = getStateIconHtml(TASK_STATE.YELLOW)
  const redIconHTML = getStateIconHtml(TASK_STATE.RED)
  if (iconHTML === greenIconHTML) return TASK_STATE.GREEN
  if (iconHTML === yellowIconHTML) return TASK_STATE.YELLOW
  if (iconHTML === redIconHTML) return TASK_STATE.RED
  return TASK_STATE.NOT_REPORTED
}

export const getLagUnitFromText = (text: string): TreegridGanttUnit => {
  text = text.toLowerCase()
  if (text === 'months' || text === 'mo' || text === 'month') {
    return 'M'
  } else if (text === 'weeks' || text === 'week' || text === 'w') {
    return 'w'
  } else if (text === 'hours' || text === 'h' || text === 'hour') {
    return 'h'
  } else if (text === 'minutes' || text === 'min' || text === 'm') {
    return 'm'
  }
  return 'd'
}

export const getShouldRemoveProjectRowBasedOnContext = (
  project: ProjectViewModel,
  appContext: AppContextState,
  userMembershipOptions: IContextOptionNormalized[]
) => {
  const { subContext } = appContext
  if (subContext) {
    const projectResources = []
    projectResources.push(...project.participants)
    projectResources.push(...project.managers)
    projectResources.push(...project.suppliers)
    projectResources.push(...project.workspaces)
    const projectResourcesIds = projectResources.map((r) => r.id)
    return !projectResourcesIds.includes(subContext.id)
  } else if (appContext.mainContext?.type === 'user') {
    const projectResources = []
    projectResources.push(...project.managers)
    projectResources.push(...project.participants)
    const projectResourcesIds = projectResources.map((r) => r.id)
    const userMembershipIds = userMembershipOptions.map((o) => o.id)
    return project.isOrgProject && !projectResourcesIds.some((id) => userMembershipIds.includes(id))
  }

  return false
}

export const getShouldRemoveTaskRowBasedOnContext = (
  task: TaskViewModel,
  appContext: AppContextState,
  userMembershipOptions: IContextOptionNormalized[]
) => {
  const { subContext } = appContext
  if (subContext && subContext.type !== 'customer') {
    const taskResources = []
    taskResources.push(...task.participants)
    taskResources.push(...task.managers)
    taskResources.push(...task.suppliers)
    const taskResourcesIds = taskResources.map((r) => r.id)
    return !taskResourcesIds.includes(subContext.id)
  } else if (appContext.mainContext?.type === 'user') {
    const taskResources = []
    taskResources.push(...task.managers)
    taskResources.push(...task.participants)
    const taskResourcesIds = taskResources.map((r) => r.id)
    const userMembershipIds = userMembershipOptions.map((o) => o.id)
    return task.isOrgTask && !taskResourcesIds.some((id) => userMembershipIds.includes(id))
  }
  return false
}

export const getShouldRemoveTodoRowBasedOnContext = (
  todo: TodoViewModel,
  appContext: AppContextState,
  userMembershipOptions: IContextOptionNormalized[]
) => {
  const { subContext } = appContext
  if (subContext) {
    const todoResources = []
    todoResources.push(...todo.responsible)
    todoResources.push(...todo.workspaces)
    todoResources.push(...todo.customers)
    const todoResourcesIds = todoResources.map((r) => r.id)
    return !todoResourcesIds.includes(subContext.id)
  } else if (appContext.mainContext?.type === 'user') {
    const todoResources = []
    todoResources.push(...todo.responsible)
    const todoResourcesIds = todoResources.map((r) => r.id)
    const userMembershipIds = userMembershipOptions.map((o) => o.id)
    return todo.isOrgTodo && !todoResourcesIds.some((id) => userMembershipIds.includes(id))
  }
  return false
}

export const getShouldRemoveEventRowBasedOnContext = (
  event: CalendarEventViewModel,
  appContext: AppContextState,
  userMembershipOptions: IContextOptionNormalized[]
) => {
  const { subContext } = appContext
  if (subContext) {
    const eventResources = []
    eventResources.push(...event.participants)
    eventResources.push(...event.workspaces)
    eventResources.push(...event.suppliers)
    eventResources.push(...event.customers)
    const eventResourcesIds = eventResources.map((r) => r.id)
    return !eventResourcesIds.includes(subContext.id)
  } else if (appContext.mainContext?.type === 'user') {
    const eventResources = []
    eventResources.push(...event.participants)
    const eventResourcesIds = eventResources.map((r) => r.id)
    const userMembershipIds = userMembershipOptions.map((o) => o.id)
    return event.isOrgEvent && !eventResourcesIds.some((id) => userMembershipIds.includes(id))
  }
  return false
}

export const mapStatusToTranslations = (translations: {
  notStarted: string
  inProgress: string
  completed: string
  rejected: string
}) => ({
  [Status.NOT_STARTED]: translations.notStarted,
  [Status.IN_PROGRESS]: translations.inProgress,
  [Status.COMPLETED]: translations.completed,
  [Status.REJECTED]: translations.rejected,
})

export const mapTranslationsToStatus = (translations: {
  notStarted: string
  inProgress: string
  completed: string
  rejected: string
}) => ({
  [translations.notStarted]: Status.NOT_STARTED,
  [translations.inProgress]: Status.IN_PROGRESS,
  [translations.completed]: Status.COMPLETED,
  [translations.rejected]: Status.REJECTED,
})
