import { MembershipRoleEnum, MembershipStatusEnum } from '../../types/memberships'
import { isBeforeDays } from '../../utils'
import { IMembershipResponse, InvitationStatus, MembershipItemPermissions } from './membership-response'

export type IMembershipNormalized = MembershipItemPermissions & {
  id: string
  createdAt: string
  updatedAt: string
  status: MembershipStatusEnum
  role: MembershipRoleEnum
  activationDate: string
  deactivationDate: string

  firstname: string
  lastname: string
  userId: string
  userEmail: string
  isConnectedToUser: boolean
  invitationStatus: InvitationStatus

  orgId: string
  orgName: string
  isDeactivated: boolean
  isOwner: boolean
  isStandardMember: boolean
  isLimitedMember: boolean
  fullname: string
  userInitials: string
  hasPendingInvitation: boolean
  hasAcceptedInvitation: boolean
  hasRejectedInvitation: boolean
  isNotInvited: boolean
  isDeactivatedBefore30Days: boolean
  reactivationDate: string | null
}

export const normalizeMembership = (membership: IMembershipResponse): IMembershipNormalized => {
  const { firstname, lastname } = membership
  const userInitials = `${firstname[0]}${lastname ? lastname[0] : firstname[1]}`.toUpperCase()
  const isDeactivatedBefore30Days = Boolean(
    membership.deactivationDate && isBeforeDays(new Date(membership.deactivationDate), 30)
  )

  return {
    ...membership,
    isDeactivated: membership.status === MembershipStatusEnum.IN_ACTIVE,
    isOwner: membership.role === MembershipRoleEnum.orgOwner,
    isStandardMember: membership.role === MembershipRoleEnum.orgMember,
    isLimitedMember: membership.role === MembershipRoleEnum.orgLimitedMember,
    fullname: `${firstname} ${lastname}`.trim(),
    userInitials,
    hasAcceptedInvitation: membership.invitationStatus === 'accepted',
    hasPendingInvitation: membership.invitationStatus === 'pending',
    hasRejectedInvitation: membership.invitationStatus === 'rejected',
    isNotInvited: membership.invitationStatus === 'not_invited',
    isDeactivatedBefore30Days,
    reactivationDate: getReactivationDate(membership),
  }
}

function getReactivationDate(membership: IMembershipResponse): string | null {
  if (!membership.deactivationDate) return null
  const deactivationDate = new Date(membership.deactivationDate)
  const reactivationDate = new Date(deactivationDate)
  reactivationDate.setDate(reactivationDate.getDate() + 30)
  return reactivationDate.toISOString()
}
