import { TimeZoneType } from '../../../constants/timezones'
import { Option } from '../../types'
import { TaskActivityRow } from '../types'
import { DateTimeService } from '../../../services/date-time-service'
import {
  getCustomerOptions,
  getMembershipOptions,
  getOptionId,
  getOptionName,
  getSupplierOptions,
  getWorkspaceOptions,
} from '../../../options/utils'
import { getStateIconHtml, makeEnumString, mapStatusToTranslations } from '../../utils'
import { COLOR_GRAY } from '../../../constants'
import { sortBy } from 'lodash'
import { isTaskPlannedEndDatePastDue, isTaskPlannedStartDatePastDue } from '../../../tasks/utils/date'
import { TaskViewModel } from '../../../tasks/api/task'
import { TreeGridTranslations } from '../../hooks/use-translations'

const spanStyles = `color: ${COLOR_GRAY} !important; cursor: pointer;font-size: 0.625rem; font-weight: 400; overflow:hidden`

export const makeTaskActivityRow = ({
  task,
  dateFormat,
  timeZone,
  options,
  translations,
}: MakeTaskActivityRowProps): TaskActivityRow => {
  const filterOptionByOrganisation = makeFilterOptionByOrganisation(task)
  const { id, title, description, statusDescription, enableTimeComponent } = task
  const dateTimeService = new DateTimeService({ dateFormat, timeZone, enableTimeComponent })
  const dateTimeFormat = dateTimeService.getFormat()
  const status = mapStatusToTranslations(translations)[task.status]

  const customers = task.customers.map((c) => c.id).join(';')
  const customerOptions = getCustomerOptions(options).filter(filterOptionByOrganisation)
  const customerNames = customerOptions.map(getOptionName)
  const customerIds = customerOptions.map(getOptionId)
  const customersEnum = makeEnumString(customerNames)
  const customersEnumKeys = makeEnumString(customerIds)

  const responsible = task.managers.map((r) => r.id).join(';')
  const participants = task.participants.map((r) => r.id).join(';')
  const membershipOptions = getMembershipOptions(options).filter(filterOptionByOrganisation)
  const participantOptions = membershipOptions.filter(({ id }) => !responsible.includes(id))
  const responsibleNames = membershipOptions.map(getOptionName)
  const responsibleIds = membershipOptions.map(getOptionId)
  const responsibleEnum = makeEnumString(responsibleNames)
  const responsibleEnumKeys = makeEnumString(responsibleIds)
  const participantNames = participantOptions.map(getOptionName)
  const participantIds = participantOptions.map(getOptionId)
  const participantsEnum = makeEnumString(participantNames)
  const participantsEnumKeys = makeEnumString(participantIds)

  const suppliers = task.suppliers.map((s) => s.id).join(';')
  const supplierOptions = getSupplierOptions(options).filter(filterOptionByOrganisation)
  const supplierNames = supplierOptions.map(getOptionName)
  const supplierIds = supplierOptions.map(getOptionId)
  const suppliersEnum = makeEnumString(supplierNames)
  const suppliersEnumKeys = makeEnumString(supplierIds)

  const workspaces = task.workspaces.map((w) => w.id).join(';')
  const workspaceOptions = getWorkspaceOptions(options).filter(filterOptionByOrganisation)
  const workspaceNames = workspaceOptions.map(getOptionName)
  const workspaceIds = workspaceOptions.map(getOptionId)
  const workspacesEnum = makeEnumString(workspaceNames)
  const workspacesEnumKeys = makeEnumString(workspaceIds)

  return {
    id,
    type: 'task',
    open: '/external-link-icon.svg',
    activityNumber: task.customTaskNumber || task.taskNumber,
    title,
    titleHtmlPrefix: getTitleHtmlPrefix(),
    description,
    status,
    state: getStateIconHtml(task.state),
    statusDescription,
    statusDescriptionTip: getStatusDescriptionTip(),
    enableTimeComponent,
    plannedStartDate: getPlannedStartDate(),
    plannedStartDateClass: getPlannedStartDateClass(),
    plannedStartDateFormat: dateTimeFormat,
    plannedEndDate: getPlannedEndDate(),
    plannedEndDateClass: getPlannedEndDateClass(),
    plannedEndDateFormat: dateTimeFormat,
    actualStartDate: getActualStartDate(),
    actualStartDateFormat: dateTimeFormat,
    responsible,
    responsibleEnum,
    responsibleEnumKeys,
    participants,
    participantsEnum,
    participantsEnumKeys,
    customers,
    customersEnum,
    customersEnumKeys,
    suppliers,
    suppliersEnum,
    suppliersEnumKeys,
    workspaces,
    workspacesEnum,
    workspacesEnumKeys,
    activityNumberCanEdit: task.canUpdateDetails ? 1 : 0,
    titleCanEdit: task.canUpdateDetails ? 1 : 0,
    descriptionCanEdit: task.canUpdateDetails ? 1 : 0,
    plannedStartDateCanEdit: task.canUpdatePlan ? 1 : 0,
    plannedEndDateCanEdit: task.canUpdatePlan ? 1 : 0,
    statusCanEdit: task.canUpdateStatus ? 1 : 0,
    stateCanEdit: task.canUpdateStatus ? 1 : 0,
    statusDescriptionCanEdit: task.canUpdateStatus ? 1 : 0,
    actualStartDateCanEdit: task.canUpdateStatus ? 1 : 0,
    customersCanEdit: 0,
    responsibleCanEdit: task.canUpdateManagers && task.isOrgTask ? 1 : 0,
    participantsCanEdit: task.canUpdateParticipants && task.isOrgTask ? 1 : 0,
    suppliersCanEdit: task.canUpdateSuppliers && task.isOrgTask ? 1 : 0,
    workspacesCanEdit: task.canUpdateWorkspaces && task.isOrgTask ? 1 : 0,
    Height: 48,
    MaxHeight: 48,
  }

  function getPlannedStartDate(): number | string {
    return task.plannedStartDate ? dateTimeService.removeTimezoneOffset(task.plannedStartDate, 'UTC').getTime() : ''
  }

  function getPlannedEndDate(): number | string {
    return task.plannedEndDate ? dateTimeService.removeTimezoneOffset(task.plannedEndDate, 'UTC').getTime() : ''
  }

  function getActualStartDate(): number | string {
    return task.actualStartDate ? dateTimeService.removeTimezoneOffset(task.actualStartDate, 'UTC').getTime() : ''
  }

  function getPlannedStartDateClass(): string {
    const isPastDue = isTaskPlannedStartDatePastDue({ task, dateTimeService })
    return isPastDue ? 'redText' : ''
  }

  function getPlannedEndDateClass(): string {
    const isPastDue = isTaskPlannedEndDatePastDue({ task, dateTimeService })
    return isPastDue ? 'redText' : ''
  }

  function getStatusDescriptionTip() {
    const sortedUpdates = sortBy(task.statusDescriptionUpdates, 'updatedAt')
    const latestUpdate = sortedUpdates[sortedUpdates.length - 1]
    let tip = ''
    if (latestUpdate) {
      const formatStr = `${dateFormat} HH:mm`
      const { updatedBy, updatedAt } = latestUpdate
      const offsetDate = dateTimeService.removeTimezoneOffset(updatedAt)
      // if there is a latest update
      tip += updatedBy // include the user who made the update
      tip += '&nbsp;&nbsp;&bull;&nbsp;&nbsp;' // some spacing
      tip += dateTimeService.format(offsetDate, formatStr) // and formatted date of update
    }
    return tip
  }

  function getTitleHtmlPrefix(): string {
    let html = ''
    const { projectLink, ancestorTaskLinks } = task

    if (projectLink) {
      if (projectLink.disabled) {
        html += `<span style='${spanStyles}' >${projectLink.label}  </span>`
      } else {
        html += `<span onclick='window.Grids.onClickTitleLink("${projectLink.href}/basic"); event.stopPropagation();' style='${spanStyles}' onmouseover="this.style.textDecoration='underline';" onmouseout="this.style.textDecoration='none';">${projectLink.label}  /  </span>`
      }
    }

    if (ancestorTaskLinks.length > 0) {
      ancestorTaskLinks.forEach((link) => {
        if (link.disabled) {
          html += `<span style='${spanStyles}'>${link.label} / </span>`
        } else {
          html += `<span onclick='window.Grids.onClickTitleLink("${link.href}/basic"); event.stopPropagation();' style='${spanStyles}'  onmouseover="this.style.textDecoration='underline';" onmouseout="this.style.textDecoration='none';">${link.label}  /  </span>`
        }
      })
    }

    return `<div>${html}</div>`
  }
}

function makeFilterOptionByOrganisation(task: TaskViewModel) {
  return function (option: Option) {
    return option.orgId === task.maintainerId
  }
}

type MakeTaskActivityRowProps = {
  task: TaskViewModel
  dateFormat: string
  timeZone: TimeZoneType
  options: Option[]
  translations: TreeGridTranslations
}
