import doublet from '../../utils/doublet'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import InputLabel from '@material-ui/core/FormLabel'
import Stack from '../../components/Stack'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import AssociationsManager from '../components/AssociationsManager'
import AbilitiesTable from '../../components/AbilitiesTable'
import MemberMenu from '../components/MemberMenu'
import { useMembership } from '../hooks/use-membership'
import { Divider, Theme, alpha, makeStyles } from '@material-ui/core'
import { useMembershipActions } from '../hooks/use-membership-actions'
import { Mail, Send } from 'react-feather'
import { MembershipRoleEnum } from '../../types/memberships'
import { useMembershipPermission } from '../../permissions/hooks/use-memberhip-permission'
import { useOrgOptions } from '../../options/hooks/use-org-options'
import { IMembershipNormalized } from '../interfaces/membership-normalized'
import { IMembershipPermissionNormalized } from '../../permissions/interfaces/permission-normalized'
import { InvitationStatus } from '../interfaces/membership-response'
import { IOptionNormalized } from '../../options/interfaces/options-normalized'
import { COLOR_BLACK, COLOR_PRIMARY } from '../../constants'
import { useI18n } from '../../hooks'
import { StringMap } from '../../types/common'
import { isNormalMember } from '../utils'
import { useSnackbar } from 'notistack'
import { useConfirmDialog } from '../../components/ConfirmDialog'
import { useOpenBillingSettingsDialog } from '../../hooks/use-open-billing-settings-dialog'
import BillingInformationDialog from '../../components/BillingInformation/BillingInformationDialog'
import { useLanguage } from '../../i18n/use-language'
import { LanguageSwitch } from '../../components/LanguageSwitch'
import { AlertTitle } from '@material-ui/lab'

const useMembershipSettingsView = ({ membershipId }: Props) => {
  const { membership } = useMembership(membershipId)
  const { permission, updateAssociations, updateAbilitiesInResources, updateAbilities, copyPermissions } =
    useMembershipPermission(membershipId)
  const { updateInfo, sendInvitation, cancelInvitation } = useMembershipActions()
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const appLanguage = useLanguage()
  const [invitationEmailLanguage, setInvitationEmailLanguage] = React.useState(appLanguage)

  const {
    userEmail,
    orgId,
    canUpdatePermissions,
    canUpdateInvitationStatus,
    isLimitedMember,
    isStandardMember,
    hasAcceptedInvitation,
    hasPendingInvitation,
    isNotInvited,
    hasRejectedInvitation,
  } = membership

  const isLimitedOrStandardMember = isLimitedMember || isStandardMember
  const isNotInvitedOrRejected = isNotInvited || hasRejectedInvitation
  const hasInvitation = hasAcceptedInvitation || hasPendingInvitation
  const shouldShowEmailField = !userEmail || isNotInvited
  const shouldShowInviteButton = isNotInvitedOrRejected && userEmail && canUpdateInvitationStatus
  const shouldShowPermissionAndAssociationManager = canUpdatePermissions && isLimitedOrStandardMember && hasInvitation

  const updateAbilitiesFn = async (updates: AbilitiesUpdateData) => {
    const [error] = await doublet(updateAbilities, { orgId, ...updates })
    if (error) enqueueSnackbar(error.message, { variant: 'error' })
  }

  const onOpenDialog = () => setIsDialogOpen(true)
  const onCloseDialog = () => setIsDialogOpen(false)
  const saveFirstname = async (e: React.FocusEvent<HTMLInputElement>) => {
    const firstname = e.target.value
    const isSameFirstname = firstname === membership.firstname
    if (isSameFirstname || !firstname) return
    const [error] = await doublet(updateInfo, membershipId, { firstname })
    if (error) enqueueSnackbar(error.message, { variant: 'error' })
  }
  const saveLastname = async (e: React.FocusEvent<HTMLInputElement>) => {
    const lastname = e.target.value
    const isSameLastname = lastname === membership.lastname
    if (isSameLastname) return
    const [error] = await doublet(updateInfo, membershipId, { lastname })
    if (error) enqueueSnackbar(error.message, { variant: 'error' })
  }
  const saveUserEmail = async (e: React.FocusEvent<HTMLInputElement>) => {
    const userEmail = e.target.value
    const isSameEmail = userEmail === membership.userEmail
    if (isSameEmail) return
    const [error] = await doublet(updateInfo, membershipId, { userEmail })
    if (error) enqueueSnackbar(error.message, { variant: 'error' })
  }
  const onClickSendInvitation = async () => {
    onCloseDialog()
    await doublet(sendInvitation, { membershipId, language: invitationEmailLanguage })
  }

  return {
    isDialogOpen,
    membership,
    permission,
    shouldShowEmailField,
    shouldShowInviteButton,
    shouldShowPermissionAndAssociationManager,
    updateAssociations,
    updateAbilitiesInResources,
    updateAbilities: updateAbilitiesFn,
    copyPermissions,
    sendInvitation,
    cancelInvitation,
    saveFirstname,
    saveUserEmail,
    saveLastname,
    onClickSendInvitation,
    onCloseDialog,
    onOpenDialog,
    invitationEmailLanguage,
    setInvitationEmailLanguage,
  }
}

const MembershipSettingsView = ({ membershipId }: Props) => {
  const classes = useStyles()
  const translations = useTranslations()
  const {
    membership,
    permission,
    shouldShowEmailField,
    shouldShowInviteButton,
    shouldShowPermissionAndAssociationManager,
    saveFirstname,
    saveLastname,
    saveUserEmail,
    updateAssociations,
    updateAbilitiesInResources,
    updateAbilities,
    copyPermissions,
    cancelInvitation,
    isDialogOpen,
    onClickSendInvitation,
    onCloseDialog,
    onOpenDialog,
    invitationEmailLanguage,
    setInvitationEmailLanguage,
  } = useMembershipSettingsView({ membershipId })
  const {
    checkBillingSettingsAndOpenDialog,
    openBillingSettingsDialog,
    closeBillingSettingsDialog,
    shouldFillBillingSettings,
    recheck,
  } = useOpenBillingSettingsDialog(membership.orgId)
  const { customerOptions, membershipOptions, supplierOptions } = useOrgOptions(membership.orgId)
  const isFillBillingInfoAlertVisible = shouldShowInviteButton && shouldFillBillingSettings

  const invitationSentAlert = (
    <Alert
      icon={<Mail size={20} color={COLOR_BLACK} />}
      severity="info"
      style={{ marginTop: -12, marginBottom: 12 }}
      action={
        membership.canUpdateInvitationStatus && (
          <Button
            size="small"
            color="inherit"
            style={{ background: alpha(COLOR_PRIMARY, 0.075) }}
            onClick={async () => {
              await cancelInvitation(membershipId)
              await recheck()
            }}
          >
            {translations.cancelInvitationLabel}
          </Button>
        )
      }
    >
      {translations.memberInvitedMessage
        .replace('{{orgName}}', membership.orgName)
        .replace('{{fullname}}', membership.fullname)}
    </Alert>
  )

  const invitationRejectedAlert = (
    <Alert severity="warning" style={{ marginTop: -12, marginBottom: 12 }} variant="filled">
      {translations.memberRejectedMessage
        .replace('{{orgName}}', membership.orgName)
        .replace('{{fullname}}', membership.fullname)}
    </Alert>
  )

  return (
    <Paper className={classes.container} variant="outlined">
      <Stack>
        <header className={classes.pageHeader} data-test="page-header">
          <Typography variant="h5">{translations.pageHeader.replace('{{name}}', membership.fullname)}</Typography>
          {membership.userEmail && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {membership.userEmail}
            </Typography>
          )}
        </header>
        {membership.hasPendingInvitation && invitationSentAlert}
        {membership.hasRejectedInvitation && invitationRejectedAlert}
        <div>
          <InputLabel htmlFor="firstname">{translations.firstnameLabel}</InputLabel>
          <TextField
            id="firstname"
            name="firstname"
            defaultValue={membership.firstname}
            onBlur={saveFirstname}
            disabled={!membership.canUpdateDetails}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <InputLabel htmlFor="lastname">{translations.lastnameLabel}</InputLabel>
          <TextField
            id="lastname"
            name="lastname"
            defaultValue={membership.lastname}
            onBlur={saveLastname}
            disabled={!membership.canUpdateDetails}
            variant="outlined"
            fullWidth
          />
        </div>
        {shouldShowEmailField && (
          <div>
            <InputLabel htmlFor="userEmail">{translations.emailLabel}</InputLabel>
            <TextField
              id="userEmail"
              name="userEmail"
              defaultValue={membership.userEmail}
              onBlur={saveUserEmail}
              disabled={!membership.canUpdateDetails}
              variant="outlined"
              fullWidth
            />
          </div>
        )}
        <InvitationEmailLanguageSwitch
          language={invitationEmailLanguage}
          onChangeLanguage={setInvitationEmailLanguage}
          shouldShowLanguageSwitch={Boolean(shouldShowInviteButton)}
          invitationLanguageDescription={translations.invitationLanguageDescription}
        />
        {isFillBillingInfoAlertVisible && (
          <>
            <Alert severity="warning" data-test="fill-billing-settings-message">
              <span>{translations.fillBillingInfoText}</span>
              <span
                onClick={checkBillingSettingsAndOpenDialog}
                style={{
                  cursor: 'pointer',
                  color: 'blue',
                  marginLeft: 8,
                  textDecoration: 'underline',
                }}
                data-test="fill-billing-settings-link"
              >
                {translations.clickMeLabel}
              </span>
            </Alert>
            <BillingInformationDialog open={openBillingSettingsDialog} closeDialog={closeBillingSettingsDialog} />
          </>
        )}
        {shouldShowInviteButton && (
          <>
            <Button
              endIcon={<Send size={16} />}
              color="primary"
              variant="contained"
              onClick={onOpenDialog}
              style={{ marginTop: 24, marginBottom: 8 }}
              disabled={shouldFillBillingSettings}
            >
              {membership.hasRejectedInvitation
                ? translations.inviteAgainButtonLabel
                : translations.inviteButtonLabel.replace('{{name}}', membership.fullname)}
            </Button>
            <Typography variant="caption" component="p">
              {translations.invitationInfo.replace('{{email}}', membership.userEmail)}
            </Typography>
            <Dialog open={isDialogOpen} onClose={onCloseDialog}>
              <DialogTitle>
                {translations.invitationDialogTitle
                  .replace('{{name}}', membership.fullname)
                  .replace('{{orgName}}', membership.orgName)}
              </DialogTitle>
              <DialogContent>
                <DialogContentText style={{ marginBottom: 32 }}>
                  {translations.invitationInfo.replace('{{email}}', membership.userEmail)}
                </DialogContentText>
                <AssociationAndPermissionManager
                  membership={membership}
                  permission={permission}
                  copyPermissions={copyPermissions}
                  setAbilitiesInResources={updateAbilitiesInResources}
                  setAssociations={updateAssociations}
                  updateAbilities={updateAbilities}
                  customerOptions={customerOptions}
                  supplierOptions={supplierOptions}
                  membershipOptions={membershipOptions}
                  translations={translations}
                />
              </DialogContent>
              <DialogActions style={{ padding: 20 }}>
                <Button
                  onClick={onClickSendInvitation}
                  color="primary"
                  variant="contained"
                  endIcon={<Send size={16} />}
                >
                  {translations.sendInvitationLabel}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
        {shouldShowPermissionAndAssociationManager && (
          <AssociationAndPermissionManager
            membership={membership}
            permission={permission}
            copyPermissions={copyPermissions}
            setAbilitiesInResources={updateAbilitiesInResources}
            setAssociations={updateAssociations}
            updateAbilities={updateAbilities}
            customerOptions={customerOptions}
            supplierOptions={supplierOptions}
            membershipOptions={membershipOptions}
            translations={translations}
          />
        )}
      </Stack>
    </Paper>
  )
}

const AssociationAndPermissionManager = ({
  membership,
  permission,
  copyPermissions,
  setAbilitiesInResources,
  setAssociations,
  updateAbilities,
  customerOptions,
  supplierOptions,
  membershipOptions,
  translations = defaultTranslations,
}: AssociationAndPermissionManagerProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const roleLabel = isNormalMember(membership) ? translations.standardMemberLabel : translations.limitedMemberLabel
  const { enqueueSnackbar } = useSnackbar()
  const [copyFromMemberId, setCopyFromMemberId] = React.useState<string | undefined>(undefined)
  const [confirmCopyFromSelectedMember, setConfirmCopyFromSelectedMember] = React.useState(false)

  const basePermissionInfo = translations.currentBasePermissionInfo
    .replace('{{name}}', membership.fullname)
    .replace('{{role}}', roleLabel)

  const onCopyFromStandardMember = async () => {
    const [error] = await doublet(copyPermissions, MembershipRoleEnum.orgMember)
    if (error) {
      const message = error.message || 'Failed to copy permissions from standard member'
      return enqueueSnackbar(message, { variant: 'error' })
    }
    return enqueueSnackbar(translations.resetPermissionToStandardMemberSuccess, { variant: 'success' })
  }

  const onCopyFromLimitedMember = async () => {
    const [error] = await doublet(copyPermissions, MembershipRoleEnum.orgLimitedMember)
    if (error) {
      const message = error.message || 'Failed to copy permissions from limited member'
      return enqueueSnackbar(message, { variant: 'error' })
    }
    return enqueueSnackbar(translations.resetPermissionToLimitedMemberSuccess, { variant: 'success' })
  }

  const onCopyFromMember = React.useCallback(
    async (copyFrom: string) => {
      setAnchorEl(null)
      const [error] = await doublet(copyPermissions, copyFrom)
      if (error) {
        const message = error.message || 'Failed to copy permissions from member'
        return enqueueSnackbar(message, { variant: 'error' })
      }
      const member = getMemberById(membershipOptions, copyFrom)
      const message = translations.copyPermissionFromMemberSuccess.replace('{{name}}', member?.name || '')
      return enqueueSnackbar(message, { variant: 'success' })
    },
    [copyPermissions, enqueueSnackbar, membershipOptions, setAnchorEl, translations]
  )

  const { confirm: confirmCopyFromStandardMember } = useConfirmDialog({
    title: translations.resetToStandardMemberLabel,
    text: translations.resetPermissionToStandardConfirmationDialogText,
    onConfirm: () => onCopyFromStandardMember(),
  })

  const { confirm: confirmCopyFromLimitedMember } = useConfirmDialog({
    title: translations.resetToLimitedMemberLabel,
    text: translations.resetPermissionToLimitedConfirmationDialogText,
    onConfirm: () => onCopyFromLimitedMember(),
  })

  const { confirm: confirmCopyFromMember } = useConfirmDialog({
    title: translations.copyFromMemberLabel,
    text: translations.copyPermissionFromMemberConfirmationDialogText,
    onConfirm: () => setConfirmCopyFromSelectedMember(true),
    onCancel: () => setConfirmCopyFromSelectedMember(false),
  })

  React.useEffect(() => {
    if (confirmCopyFromSelectedMember) {
      onCopyFromMember(copyFromMemberId || '')
      setConfirmCopyFromSelectedMember(false)
    }
  }, [confirmCopyFromSelectedMember, copyFromMemberId, onCopyFromMember])

  return (
    <>
      <div style={{ marginTop: 16 }}>
        <Typography variant="h6" style={{ marginTop: 16, marginBottom: 8 }}>
          {translations.managePermissionsLabel}
          <Chip
            color="secondary"
            label={getMembershipTypeChipLabel(membership, translations)}
            variant="outlined"
            size="small"
            style={{ marginLeft: 16 }}
          />
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: 16 }}>
          {basePermissionInfo}
        </Typography>
        <div style={{ marginLeft: -12, marginRight: -12, marginTop: 20 }}>
          {permission && (
            <AbilitiesTable groupedAbilities={permission.abilitiesByResources} onUpdateAbilities={updateAbilities} />
          )}
        </div>
        {membership.isLimitedMember && permission && (
          <>
            <div style={{ marginBottom: 20, marginTop: 20 }}>
              <AssociationsManager
                abilities={permission.abilities}
                abilitiesInResources={permission.abilitiesInResources}
                associations={permission.associations}
                setAbilitiesInResources={setAbilitiesInResources}
                setAssociations={setAssociations}
                updateAbilities={updateAbilities}
                customerOptions={customerOptions}
                supplierOptions={supplierOptions}
                canUpdatePermissions={membership.canUpdatePermissions}
              />
            </div>
            <Divider />
          </>
        )}

        <Typography variant="h6" gutterBottom style={{ margin: '16px 0' }}>
          {translations.resetButtonLabel}
        </Typography>

        {membership.canUpdatePermissions && (
          <Stack>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                {translations.resetToStandardMemberLabel}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {translations.normalMembershipHelperText}
              </Typography>
              <Button
                size="small"
                style={{ marginLeft: -4, marginTop: 8 }}
                color="primary"
                onClick={confirmCopyFromStandardMember}
              >
                {translations.resetToStandardMemberLabel}
              </Button>
            </div>

            <Divider />

            <div>
              <Typography variant="subtitle1" gutterBottom>
                {translations.resetToLimitedMemberLabel}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {translations.limitedMembershipHelperText}
              </Typography>
              <Button
                size="small"
                style={{ marginLeft: -4, marginTop: 8 }}
                color="primary"
                onClick={confirmCopyFromLimitedMember}
              >
                {translations.resetToLimitedMemberLabel}
              </Button>
            </div>

            <Divider />

            <div>
              <Typography variant="subtitle1" gutterBottom>
                {translations.copyFromMemberLabel}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {translations.copiedMemberHelperText.replace('{{copy_to_name}}', membership.fullname)}
              </Typography>
              <Button
                size="small"
                style={{ marginLeft: -4, marginTop: 8 }}
                color="primary"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                {translations.copyFromMemberLabel}
              </Button>
              <MemberMenu
                anchor={anchorEl}
                menuTitle={translations.chooseMemberToCopyLabel}
                searchInputPlaceholder={translations.searchMembersPlaceholder}
                options={getMembersWithAccess(membershipOptions)}
                onClickAway={() => setAnchorEl(null)}
                onClickMenuItem={({ id }) => {
                  setCopyFromMemberId(id)
                  confirmCopyFromMember()
                }}
                PopperProps={{ placement: 'bottom-start' }}
              />
            </div>
          </Stack>
        )}
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(0.75, 'auto'),
    maxWidth: 750,
    backgroundColor: 'white',
    padding: theme.spacing(4),
  },

  pageHeader: { marginBottom: theme.spacing(2), '& > h5': { marginBottom: theme.spacing(1) } },
  invitationLanguageSwitch: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    '& .MuiAlert-root': {
      border: '1px solid #f0f0f0',
      width: '100%',
    },
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('membership')
  const newMemberPageTranslations = (t.newMemberPage || {}) as StringMap
  const settingsPageTranslations = (t.membershipSettingsView || {}) as StringMap

  const {
    standardMemberLabel = defaults.standardMemberLabel,
    limitedMemberLabel = defaults.limitedMemberLabel,
    copyFromMemberLabel = defaults.copyFromMemberLabel,
    chooseMemberToCopyLabel = defaults.chooseMemberToCopyLabel,
    searchMembersPlaceholder = defaults.searchMembersPlaceholder,
    invitationInfo = defaults.invitationInfo,
    invitationLanguageDescription = defaults.invitationLanguageDescription,
  } = t

  const {
    fillBillingInfoText = defaults.fillBillingInfoText,
    clickMeLabel = defaults.clickMeLabel,
    sendInvitationLabel = defaults.sendInvitationLabel,
    normalMembershipHelperText = defaults.normalMembershipHelperText,
    limitedMembershipHelperText = defaults.limitedMembershipHelperText,
  } = newMemberPageTranslations

  const {
    firstnameLabel = defaults.firstnameLabel,
    lastnameLabel = defaults.lastnameLabel,
    emailLabel = defaults.emailLabel,
    pageHeader = defaults.pageHeader,
    managePermissionsOrAssociationsLabel = defaults.managePermissionsOrAssociationsLabel,
    manageAssociationsLabel = defaults.manageAssociationsLabel,
    managePermissionsLabel = defaults.managePermissionsLabel,
    noAccessMemberLabel = defaults.noAccessMemberLabel,
    orgOwnerLabel = defaults.orgOwnerLabel,
    cancelInvitationLabel = defaults.cancelInvitationLabel,
    memberInvitedMessage = defaults.memberInvitedMessage,
    inviteButtonLabel = defaults.inviteButtonLabel,
    invitationDialogTitle = defaults.invitationDialogTitle,
    resetPermissionsLabel = defaults.resetPermissionsLabel,
    resetToStandardMemberLabel = defaults.resetToStandardMemberLabel,
    resetToLimitedMemberLabel = defaults.resetToLimitedMemberLabel,
    copyPermissionFromMemberConfirmationDialogText = defaults.copyPermissionFromMemberConfirmationDialogText,
    copyPermissionFromMemberSuccess = defaults.copyPermissionFromMemberSuccess,
    resetPermissionToLimitedConfirmationDialogText = defaults.resetPermissionToLimitedConfirmationDialogText,
    resetPermissionToLimitedMemberSuccess = defaults.resetPermissionToLimitedMemberSuccess,
    resetPermissionToStandardConfirmationDialogText = defaults.resetPermissionToStandardConfirmationDialogText,
    resetPermissionToStandardMemberSuccess = defaults.resetPermissionToStandardMemberSuccess,
    resetButtonLabel = defaults.resetButtonLabel,
    currentBasePermissionInfo = defaults.currentBasePermissionInfo,
    copiedMemberHelperText = defaults.copiedMemberHelperText,
    memberRejectedMessage = defaults.memberRejectedMessage,
    inviteAgainButtonLabel = defaults.inviteAgainButtonLabel,
  } = settingsPageTranslations

  return {
    standardMemberLabel,
    limitedMemberLabel,
    copyFromMemberLabel,
    chooseMemberToCopyLabel,
    searchMembersPlaceholder,
    fillBillingInfoText,
    clickMeLabel,
    sendInvitationLabel,
    firstnameLabel,
    lastnameLabel,
    emailLabel,
    pageHeader,
    managePermissionsOrAssociationsLabel,
    manageAssociationsLabel,
    managePermissionsLabel,
    noAccessMemberLabel,
    orgOwnerLabel,
    cancelInvitationLabel,
    memberInvitedMessage,
    inviteButtonLabel,
    invitationInfo,
    invitationDialogTitle,
    resetPermissionsLabel,
    resetToStandardMemberLabel,
    resetToLimitedMemberLabel,
    copyPermissionFromMemberConfirmationDialogText,
    copyPermissionFromMemberSuccess,
    resetPermissionToLimitedConfirmationDialogText,
    resetPermissionToLimitedMemberSuccess,
    resetPermissionToStandardConfirmationDialogText,
    resetPermissionToStandardMemberSuccess,
    normalMembershipHelperText,
    limitedMembershipHelperText,
    copiedMemberHelperText,
    resetButtonLabel,
    currentBasePermissionInfo,
    memberRejectedMessage,
    inviteAgainButtonLabel,
    invitationLanguageDescription,
  }
}

const defaultTranslations = {
  pageHeader: 'Settings of {{name}}',
  firstnameLabel: 'Firstname',
  lastnameLabel: 'Lastname',
  emailLabel: 'Email',
  fillBillingInfoText: 'Please fill in the billing information before sending the invitation!!!',
  clickMeLabel: 'Click me',
  sendInvitationLabel: 'Send invitation',
  managePermissionsOrAssociationsLabel: 'Manage permissions or associations',
  manageAssociationsLabel: 'Manage associations',
  managePermissionsLabel: 'Manage permissions',
  standardMemberLabel: 'Standard member',
  limitedMemberLabel: 'Limited member',
  noAccessMemberLabel: 'No access',
  orgOwnerLabel: 'Organisation owner',
  cancelInvitationLabel: 'Cancel invitation',
  memberInvitedMessage: 'An invitation to join {{orgName}} has been sent to {{fullname}}',
  memberRejectedMessage: '{{fullname}} has rejected the invitation to join {{orgName}}',
  inviteButtonLabel: 'Invite {{name}}',
  inviteAgainButtonLabel: 'Invite again',
  invitationInfo: 'We will send an email at {{email}} with a link to join the organisation.',
  invitationDialogTitle: 'Invite {{name}} to join {{orgName}}?',
  resetPermissionsLabel: 'Reset permissions to defaults or copy permissions',
  resetToStandardMemberLabel: 'Reset to standard member',
  resetToLimitedMemberLabel: 'Reset to limited member',
  copyFromMemberLabel: 'Copy permissions',
  copyPermissionFromMemberConfirmationDialogText: 'Are you sure you want to copy permissions from the selected member?',
  copyPermissionFromMemberSuccess: 'Successfully copied permissions of {{name}}',
  resetPermissionToLimitedConfirmationDialogText: 'Are you sure you want to reset permissions to limited member?',
  resetPermissionToLimitedMemberSuccess: 'Successfully reset permissions to limited member',
  resetPermissionToStandardConfirmationDialogText: 'Are you sure you want to reset permissions to standard member?',
  resetPermissionToStandardMemberSuccess: 'Successfully reset permissions to standard member',
  chooseMemberToCopyLabel: 'Choose member to copy from',
  searchMembersPlaceholder: 'Search members',
  normalMembershipHelperText:
    "A normal member has access to all projects, tasks, to-dos, resources, and other information within the organization. However, specific actions can be restricted by adjusting the member's permissions.",
  limitedMembershipHelperText:
    'A limited member can access their own projects, tasks, to-dos, and other relevant information within the organization. Additionally, they will be able to view resources specified in the input field of this form. Permissions for a limited member operate in the same way as those for a regular member.',
  resetButtonLabel: 'Reset permissions',
  currentBasePermissionInfo: `Currently {{name}} has base permissions of {{role}}. If any permission has been modified, it is reflected in the table below.`,
  copiedMemberHelperText: '{{copy_to_name}} will have exact same permissions as the permissions copied member',
  invitationLanguageDescription: 'The invitation email will be sent in the selected language',
}

const getMembershipTypeChipLabel = (
  membership: { role: string; invitationStatus: InvitationStatus },
  translations: Translations
) => {
  let roleLabel = translations.standardMemberLabel
  if (membership.role === 'orgOwner') roleLabel = translations.orgOwnerLabel
  if (membership.role === 'orgLimitedMember') roleLabel = translations.limitedMemberLabel
  if (membership.invitationStatus === 'accepted') return roleLabel
  return translations.noAccessMemberLabel + ' ' + roleLabel.toLowerCase()
}

const getMembersWithAccess = (options: IOptionNormalized[]) => {
  const filteredOptions = options.filter((option) => option.type !== 'noAccessMember')
  return filteredOptions.sort((a, b) => a.name.localeCompare(b.name))
}

const getMemberById = (options: IOptionNormalized[], id?: string) => {
  return options.find((member) => member.id === id)
}

const InvitationEmailLanguageSwitch = ({
  shouldShowLanguageSwitch,
  language,
  onChangeLanguage,
  invitationLanguageDescription,
}: InvitationEmailLanguageSwitchProps) => {
  const classes = useStyles()
  if (!shouldShowLanguageSwitch) return null
  return (
    <div className={classes.invitationLanguageSwitch}>
      <LanguageSwitch onChangeLanguage={onChangeLanguage} value={language} />
      <Alert severity="info" data-test="invitation-language-info" variant="outlined">
        <AlertTitle>Note</AlertTitle>
        {invitationLanguageDescription}
      </Alert>
    </div>
  )
}
export default MembershipSettingsView

type Props = { membershipId: string }
type AbilitiesUpdateData = { abilitiesToAdd?: string[]; abilitiesToRemove?: string[] }
type AbilitiesInResources = Array<{ resourceId: string; abilities: string[] }>
type Translations = typeof defaultTranslations
type AssociationAndPermissionManagerProps = {
  membership: IMembershipNormalized
  permission: IMembershipPermissionNormalized
  setAbilitiesInResources: (data: AbilitiesInResources) => any
  setAssociations: (data: string[]) => any
  updateAbilities: (data: AbilitiesUpdateData) => any
  copyPermissions: (copyFrom: string) => any
  customerOptions: IOptionNormalized[]
  supplierOptions: IOptionNormalized[]
  membershipOptions: IOptionNormalized[]
  translations?: Translations
}
type InvitationEmailLanguageSwitchProps = {
  language: string
  onChangeLanguage: (language: string) => void
  shouldShowLanguageSwitch: boolean
  invitationLanguageDescription: string
}
