import { MaintainerTypeEnum, Status } from '../../types/common'
import { TaskResponse } from '../api/task-response'

export const makeTaskViewModel = (task: TaskResponse): TaskViewModel => {
  const isOrgTask = task.maintainerType === MaintainerTypeEnum.ORG
  const isUserTask = task.maintainerType === MaintainerTypeEnum.USER

  return {
    ...task,
    isOrgTask,
    isUserTask,
    isCompleted: task.status === Status.COMPLETED,
    isNotStarted: task.status === Status.NOT_STARTED,
    isInProgress: task.status === Status.IN_PROGRESS,
    isRejected: task.status === Status.REJECTED,
  }
}

export type TaskViewModel = TaskResponse & {
  isOrgTask: boolean
  isUserTask: boolean
  isCompleted: boolean
  isNotStarted: boolean
  isInProgress: boolean
  isRejected: boolean
}
