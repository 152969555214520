import OrganisationCustomerBasicInfoForm from './OrganisationCustomerBasicInfoForm'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ContactDetails from '../../components/ContactDetails'
import ContactForm from '../../components/ContactForm'
import ContactNotFound from '../../components/ContactNotFound'
import ContactsTable from '../../components/ContactsTable'

import { makeStyles, Theme } from '@material-ui/core'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useI18n, useRouter } from '../../hooks'
import { values } from 'lodash'
import { paths } from '../../paths'
import type { IOrgCustomerNormalized } from '../interfaces/customer-normalized'
import { useAppDispatch } from '../../store'
import { ChangeCustomerDefaultContact, DeleteCustomerContact, UpdateCustomerContact } from '../store/actions'
import { IContact } from '../../types/contact'
import { unwrapResult } from '@reduxjs/toolkit'
import { useUrlWithContext } from '../../hooks/use-url-with-context'

const CustomerDetails = ({ customer, onAddContact, canManageCustomer }: CustomerDetailsProps) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const translations = useTranslations()
  const router = useRouter()
  const { createPathWithContext } = useUrlWithContext()
  const { tab, contact = '' } = router.query as {
    tab: string
    contact?: string
  }

  const [value, setValue] = useState(() => {
    if (tab === 'contacts') return 1
    if (tab === 'add-contact') return 2
    if (tab === 'contact') return 3
    return 0
  })

  const selectedContact = customer.contacts[contact] || null
  const basicInfoTabLabel = translations.basicInfoTabLabel
  const allContactsTabLabel = translations.allContactsTabLabel
  const addContactTabLabel = translations.addContactTabLabel

  const changeTab = (index: 0 | 1 | 2) => () => {
    setValue(index)
    const pathWithoutContext = paths.customerSettings()

    if (index === 1) {
      const path = createPathWithContext(pathWithoutContext + '?tab=contacts')
      router.push(path)
    } else if (index === 2) {
      const path = createPathWithContext(pathWithoutContext + '?tab=add-contact')
      router.push(path)
    } else {
      const path = createPathWithContext(pathWithoutContext)
      router.push(path)
    }
  }

  /** Effect to sync the query with the tab */
  useEffect(() => {
    if (!tab && value !== 0) {
      setValue(0)
    }
    if (value !== 1 && tab === 'contacts') {
      setValue(1)
    }
    if (value !== 2 && tab === 'add-contact') {
      setValue(2)
    }
    if (value !== 3 && tab === 'contact') {
      setValue(3)
    }
  }, [value, tab, router])

  async function addContactAndRedirect(contact: any) {
    try {
      await onAddContact(contact)
      const route = createPathWithContext(`${paths.customerSettings()}?tab=contacts`, {
        withSubContext: true,
      })
      router.push(route)
    } catch (error) {}
  }

  async function deleteContactAndRedirect() {
    try {
      await deleteContact()
      const route = createPathWithContext(`${paths.customerSettings()}?tab=contacts`, {
        withSubContext: true,
      })
      router.push(route)
    } catch (error) {}
  }

  async function makeDefaultContact() {
    await dispatch(
      ChangeCustomerDefaultContact({
        customerId: selectedContact.customerId as string,
        contactId: selectedContact.id,
      })
    )
  }

  async function deleteContact() {
    await dispatch(
      DeleteCustomerContact({
        customerId: selectedContact.customerId as string,
        contactId: selectedContact.id,
      })
    )
  }

  async function onEditContact(props: { [k in keyof IContact]?: string }) {
    unwrapResult(
      await dispatch(
        UpdateCustomerContact({
          customerId: selectedContact.customerId as string,
          contactId: selectedContact.id,
          ...props,
        })
      )
    )
  }

  return (
    <Paper elevation={0} variant="outlined" className={classes.paper}>
      <div className={classes.tabsContainer}>
        <Tabs value={value} indicatorColor="primary" textColor="primary">
          <Tab label={basicInfoTabLabel} onClick={changeTab(0)} data-test="basic-info-tab" />
          <Tab label={allContactsTabLabel} onClick={changeTab(1)} data-test="all-contacts-tab" />
          <Tab
            label={addContactTabLabel}
            style={{
              display: customer.isActive && canManageCustomer ? 'block' : 'none',
            }}
            onClick={changeTab(2)}
            data-test="add-contact-tab"
          />
          <Tab label="contact" style={{ display: 'none' }} />
        </Tabs>
      </div>
      <TabPanel index={0} value={value}>
        <OrganisationCustomerBasicInfoForm canUpdateBasicInfo={canManageCustomer} customer={customer} />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <ContactsTable contacts={values(customer.contacts)} canAddContact={canManageCustomer} />
      </TabPanel>
      <TabPanel index={2} value={value}>
        <ContactForm onAddContact={addContactAndRedirect} />
      </TabPanel>
      <TabPanel index={3} value={value}>
        {selectedContact && (
          <ContactDetails
            contact={selectedContact}
            editable={customer.isActive && canManageCustomer}
            onEditContact={onEditContact}
            onMakeDefaultContact={makeDefaultContact}
            onDeleteContact={deleteContactAndRedirect}
          />
        )}
        {!selectedContact && <ContactNotFound />}
      </TabPanel>
    </Paper>
  )
}

const TabPanel = ({ value, index, children }: PropsWithChildren<TabPanelProps>) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tab-panel-${index}`} aria-labelledby={`full-width-tab-${index}`}>
      {value === index && (
        <Slide direction="left" in={value === index} mountOnEnter unmountOnExit>
          <Box pt={2} pb={2}>
            {children}
          </Box>
        </Slide>
      )}
    </div>
  )
}

const useTranslations = (defaults = defaultTranslations) => {
  const { translations: t } = useI18n('customer')
  const translations = (t.customerHomePage || {}) as { [k: string]: string }

  const {
    basicInfoTabLabel = defaultTranslations.basicInfoTabLabel,
    allContactsTabLabel = defaultTranslations.allContactsTabLabel,
    addContactTabLabel = defaultTranslations.addContactTabLabel,
  } = translations

  return {
    basicInfoTabLabel,
    allContactsTabLabel,
    addContactTabLabel,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    minWidth: '100%',
    maxWidth: '100%',
  },
  tabsContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

const defaultTranslations: CustomerDetailsTranslations = {
  basicInfoTabLabel: 'Basic info',
  allContactsTabLabel: 'All contacts',
  addContactTabLabel: 'Add contact',
}

interface CustomerDetailsProps {
  onAddContact: (contact: any) => Promise<void>
  customer: IOrgCustomerNormalized
  canManageCustomer: boolean
}

interface TabPanelProps {
  value: number
  index: number
}

interface CustomerDetailsTranslations {
  basicInfoTabLabel: string
  allContactsTabLabel: string
  addContactTabLabel: string
}

export default CustomerDetails
