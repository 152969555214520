import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'
import { useI18n } from '../hooks'

export type MultiSearchSelectOption = {
  id: string
  name: string
}

type MultiSearchSelectProps = {
  options: MultiSearchSelectOption[]
  selectedOptions: MultiSearchSelectOption[]
  onChange: (selectedOptions: MultiSearchSelectOption[]) => void
  onBlur?: () => void
  disabled?: boolean
}

const MultiSearchSelect = (props: MultiSearchSelectProps) => {
  const translations = useTranslations(defaultTranslations)
  const { options, selectedOptions, onChange, ...more } = props

  const handleChange = (event: React.ChangeEvent<{}>, value: MultiSearchSelectOption[], reason: string) => {
    if (reason === 'select-option' || reason === 'remove-option') {
      onChange(value)
    }
  }

  const getOptionLabel = (option: MultiSearchSelectOption) => option.name

  const getOptionSelected = (option: MultiSearchSelectOption, value: MultiSearchSelectOption) => option.id === value.id

  return (
    <Autocomplete
      multiple
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
      noOptionsText={translations.noOptions}
      {...more}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const { noOptions = defaults.noOptions } = t
  return {
    noOptions,
  }
}
const defaultTranslations = {
  noOptions: 'No options',
}
type Translations = typeof defaultTranslations

export default MultiSearchSelect
