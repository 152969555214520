import { makeStyles } from '@material-ui/core'
import { useCalendarEventMutations } from '../hooks/use-calendar-event-mutations'
import InlineEditableText from '../../components/InlineEditableText'
import { CalendarEventViewModel } from '../api/calendar-event'

const CalendarEventCardTitle = ({ calendarEvent }: { calendarEvent: CalendarEventViewModel }) => {
  const classes = useStyles()
  const calendarEventActions = useCalendarEventMutations()
  return (
    <InlineEditableText
      value={calendarEvent.title}
      onSave={(title) => calendarEventActions.updateInfo(calendarEvent.id, { title })}
      TypographyProps={{ variant: 'h5', className: classes.todoTitle }}
      isEditable={calendarEvent.canUpdate}
    />
  )
}

const useStyles = makeStyles(() => ({
  todoTitle: {
    '& span, & textarea': {
      maxWidth: 'none',
    },
  },
}))

export default CalendarEventCardTitle
