import { addDays, addMonths, addYears, subDays, subMonths, subYears } from 'date-fns'
import { TreeGridTranslations } from '../hooks/use-translations'
import { GanttZoom } from '../types'

export const makeZooms = ({ translations, enableTimeComponent }: MakeZoomProps) => {
  const {
    zoomMenuHoursAndQuarters,
    zoomMenuDaysAndHours,
    zoomMenuWeeksAndHalfDays,
    zoomMenuWeeksAndDays,
    zoomMenuMonthsAndWeeks,
    zoomMenuMonthsWeeksAndDays,
    zoomMenuQuartersAndMonths,
    zoomMenuHalvesAndMonths,
    zoomMenuYearsAndQuarters,
  } = translations

  const zoomNames = {
    hoursAndQuarters: zoomMenuHoursAndQuarters,
    daysAndHours: zoomMenuDaysAndHours,
    weeksAndHalfDays: zoomMenuWeeksAndHalfDays,
    weeksAndDays: zoomMenuWeeksAndDays,
    monthsWeeksAndDays: zoomMenuMonthsWeeksAndDays,
    quartersAndMonths: zoomMenuQuartersAndMonths,
    yearsAndQuarters: zoomMenuYearsAndQuarters,
    monthsAndWeeks: zoomMenuMonthsAndWeeks,
    halvesAndMonths: zoomMenuHalvesAndMonths,
  }

  const thisMoment = new Date()
  const twoDaysBack = subDays(thisMoment, 2).getTime()
  const sevenDaysForward = addDays(thisMoment, 7).getTime()
  const tenDaysBack = subDays(thisMoment, 10).getTime()
  const threeMonthsBack = subMonths(thisMoment, 6).getTime()
  const oneMonthBack = subMonths(thisMoment, 1).getTime()
  const fiveYearsBack = subYears(thisMoment, 5).getTime()
  const twentyDaysForward = addDays(thisMoment, 20).getTime()
  const sixMonthsForward = addMonths(thisMoment, 6).getTime()
  const oneYearForward = addYears(thisMoment, 1).getTime()
  const tenYearsForward = addYears(thisMoment, 10).getTime()

  const zoomList: GanttZoom[] = [
    {
      Name: zoomNames.yearsAndQuarters,
      GanttUnits: 'M3',
      GanttDragUnits: enableTimeComponent ? 'h12' : 'd',
      lagUnit: 'd',
      GanttHeader1: 'y#yyyy',
      GanttHeader2: 'M3#MMMMM',
      GanttWidth: 64,
      GanttChartMinStart: fiveYearsBack,
      GanttChartMaxStart: fiveYearsBack,
      GanttChartMaxEnd: tenYearsForward,
      GanttChartMinEnd: tenYearsForward,
    },
    {
      Name: zoomNames.halvesAndMonths,
      GanttUnits: 'M',
      GanttDragUnits: enableTimeComponent ? 'h12' : 'd',
      lagUnit: 'd',
      GanttHeader1: 'M6#MMMMMM"." yyyy',
      GanttHeader2: 'M#MM',
      GanttChartMinStart: fiveYearsBack,
      GanttChartMaxStart: fiveYearsBack,
      GanttChartMaxEnd: tenYearsForward,
      GanttChartMinEnd: tenYearsForward,
    },
    {
      Name: zoomNames.quartersAndMonths,
      GanttUnits: 'M',
      GanttDragUnits: enableTimeComponent ? 'h12' : 'd',
      lagUnit: 'd',
      GanttHeader1: 'M3#MMMMM yyyy',
      GanttHeader2: 'M#MMM',
      GanttWidth: 64,
      GanttChartMinStart: fiveYearsBack,
      GanttChartMaxStart: fiveYearsBack,
      GanttChartMaxEnd: tenYearsForward,
      GanttChartMinEnd: tenYearsForward,
    },
    {
      Name: zoomNames.monthsWeeksAndDays,
      GanttUnits: 'd',
      GanttDragUnits: enableTimeComponent ? 'h12' : 'd',
      lagUnit: 'd',
      GanttLastUnit: enableTimeComponent ? '' : 'd',
      GanttHeader1: 'M#MMMM yyyy',
      GanttHeader2: `w1#"${translations.shortWeekLabel} "dddddddd`,
      GanttHeader3: 'd#"<span class=ganttDayHeader>"DDDDDD<br>DDDDDDD"</span>"',
      GanttHeaderHeight2: 12,
      GanttHeaderHeight3: 32,
      GanttWidth: 10,
      GanttChartMinStart: threeMonthsBack,
      GanttChartMaxStart: threeMonthsBack,
      GanttChartMaxEnd: oneYearForward,
      GanttChartMinEnd: oneYearForward,
    },
    {
      Name: zoomNames.weeksAndDays,
      GanttUnits: 'd',
      GanttDragUnits: enableTimeComponent ? 'h' : 'd',
      lagUnit: 'd',
      GanttLastUnit: enableTimeComponent ? '' : 'd',
      GanttHeader1: `w1#"${translations.shortWeekLabel} "dddddddd, MMM yyyy`,
      GanttHeader2: 'd#"<span class=ganttDayHeader>"dd"</span>"',
      GanttHeader3: 'd#"<span class=ganttDayHeader>"ddddd"</span>"',
      GanttWidth: 16,
      GanttChartMinStart: threeMonthsBack,
      GanttChartMaxStart: threeMonthsBack,
      GanttChartMaxEnd: oneYearForward,
      GanttChartMinEnd: oneYearForward,
    },
    {
      Name: zoomNames.weeksAndHalfDays,
      GanttUnits: 'h3',
      GanttDragUnits: 'm15',
      lagUnit: 'h',
      GanttHeader1: 'd#ddd, dd MMM yy',
      GanttHeader2: 'h3#HH',
      GanttWidth: 24,
      GanttChartMinStart: oneMonthBack,
      GanttChartMaxStart: oneMonthBack,
      GanttChartMaxEnd: sixMonthsForward,
      GanttChartMinEnd: sixMonthsForward,
    },
    {
      Name: zoomNames.daysAndHours,
      GanttUnits: 'm15',
      lagUnit: 'h',
      GanttHeader1: 'h12#ddd, dd MMM yy',
      GanttHeader2: 'h#HH',
      GanttWidth: 24,
      GanttChartMinStart: tenDaysBack,
      GanttChartMaxStart: tenDaysBack,
      GanttChartMaxEnd: twentyDaysForward,
      GanttChartMinEnd: twentyDaysForward,
    },
    {
      Name: zoomNames.hoursAndQuarters,
      GanttUnits: 'm5',
      lagUnit: 'm',
      GanttHeader1: 'h3#ddd, dd MMM yy',
      GanttHeader2: 'h# hh tt',
      GanttHeader3: 'm15#mm',
      GanttWidth: 24,
      GanttChartMinStart: twoDaysBack,
      GanttChartMaxStart: twoDaysBack,
      GanttChartMaxEnd: sevenDaysForward,
      GanttChartMinEnd: sevenDaysForward,
    },
  ]
  const filteredZoomList = zoomList.filter((zoom) => {
    if (!enableTimeComponent) {
      return (
        zoom.Name !== zoomNames.daysAndHours &&
        zoom.Name !== zoomNames.hoursAndQuarters &&
        zoom.Name !== zoomNames.weeksAndHalfDays
      )
    } else {
      return true
    }
  })

  return {
    defaultZoom: zoomNames.weeksAndDays,
    zoomNames,
    zoomList: filteredZoomList,
  }
}

type MakeZoomProps = {
  translations: TreeGridTranslations
  enableTimeComponent: boolean
}
