import { RouteComponentProps } from 'react-router-dom'
import { useTask } from '../../../tasks/hooks/use-task'
import { NewCalendarEventData, useGetCalendarEventsQuery } from '../../../calendar-events/api'
import { NewTaskData, useGetTasksQuery } from '../../../tasks/api'
import { NewTodoData, useGetTodosQuery } from '../../../todos/api'
import { useGetOrganisationByIdQuery } from '../../../organisations/api'
import { useCalendarEventMutations } from '../../../calendar-events/hooks/use-calendar-event-mutations'
import { useTaskMutations } from '../../../tasks/hooks/use-task-mutations'
import { useTodoMutations } from '../../../todos/hooks/use-todo-mutations'
import { useAuthUser } from '../../../users/hooks/use-auth-user'
import CircularProgress from '@material-ui/core/CircularProgress'
import Calendar from '../../../calendar-events/components/Calendar'
import { adaptTask, adaptCalendarEvent, adaptTodo } from '../../../calendar-events/utils'
import React from 'react'
import { keyBy, merge } from 'lodash'
import ErrorList from '../../../components/ErrorList'
import { useI18n } from '../../../hooks'
import { StringMap } from '../../../types/common'

const queryOptions = { refetchOnMountOrArgChange: true } as const

const useLoader = ({ taskId }: { taskId: string }) => {
  const translations = useTranslations()
  const queryErrors = {} as QueryErrors
  const queryParams = { task: taskId }
  const { task, isLoading: isLoadingTask, isError: isErrorTask } = useTask(taskId, queryOptions)
  const {
    data: calendarEvents = [],
    isLoading: isLoadingCalendarEvents,
    isError: isErrorCalendarEvents,
  } = useGetCalendarEventsQuery(queryParams, queryOptions)
  const {
    data: tasks = [],
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
  } = useGetTasksQuery(queryParams, queryOptions)
  const {
    data: todos = [],
    isLoading: isLoadingTodos,
    isError: isErrorTodos,
  } = useGetTodosQuery(queryParams, queryOptions)

  const organisationId = task && task?.isOrgTask ? task.maintainerId : ''
  const skipFetchingOrganisation = !organisationId
  const {
    data: organisation,
    isLoading: isLoadingOrg,
    isError: isErrorOrg,
  } = useGetOrganisationByIdQuery(organisationId, { ...queryOptions, skip: skipFetchingOrganisation })

  const isLoading = isLoadingTask || isLoadingCalendarEvents || isLoadingTasks || isLoadingTodos || isLoadingOrg
  const isError = isErrorTask || isErrorCalendarEvents || isErrorTasks || isErrorTodos || isErrorOrg

  if (isErrorTask) queryErrors.task = translations.fetchErrorTask
  if (isErrorCalendarEvents) queryErrors.calendarEvent = translations.fetchErrorCalendarEvents
  if (isErrorTasks) queryErrors.tasks = translations.fetchErrorTasks
  if (isErrorTodos) queryErrors.todo = translations.fetchErrorTodos
  if (isErrorOrg) queryErrors.organisation = translations.fetchErrorOrganisation

  return {
    task,
    calendarEvents,
    tasks,
    todos,
    organisation,
    isLoading,
    isError,
    queryErrors,
  }
}

const TaskCalendarRoute = ({ match }: RouteComponentProps<RouteParams>) => {
  const translations = useTranslations()
  const { taskId } = match.params
  const { task, calendarEvents, tasks, todos, organisation, isLoading, isError, queryErrors } = useLoader({ taskId })
  const { createCalendarEvent, updateInfo: updateCalendarEvent } = useCalendarEventMutations()
  const { createOrgTask, createUserTask, updatePlan: updateTask } = useTaskMutations()
  const { createTodo, updateInfo: updateTodo } = useTodoMutations()
  const { user } = useAuthUser()

  const { events, entitiesById } = React.useMemo(() => {
    const taskEvents = tasks.filter((t) => (t.plannedEndDate || t.plannedStartDate) && t.id !== taskId).map(adaptTask)
    const todoEvents = todos.filter((todo) => todo.dueDate).map(adaptTodo)
    const adaptedCalenderEvents = calendarEvents.map(adaptCalendarEvent)
    return {
      events: [...adaptedCalenderEvents, ...taskEvents, ...todoEvents],
      entitiesById: merge(keyBy(calendarEvents, 'id'), keyBy(tasks, 'id'), keyBy(todos, 'id')),
    }
  }, [calendarEvents, todos, tasks, taskId])

  if (isLoading) return <CircularProgress />
  if (!task) return <ErrorList errors={[translations.taskNotFoundError]} />
  if (isError) return <ErrorList errors={queryErrors} />

  const onAddCalendarEvent = (eventData: NewCalendarEventData) => {
    return createCalendarEvent({
      ...eventData,
      task: task.id,
      project: task.projectId,
      organisation: task.isOrgTask ? task.maintainerId : null,
    })
  }

  const onAddTask = (taskData: Omit<NewTaskData, 'projectId'>) => {
    const newTaskData: NewTaskData = { ...taskData, projectId: task.projectId, parentTaskId: task.id }
    return task.isOrgTask
      ? createOrgTask(task.maintainerId, newTaskData)
      : createUserTask(task.maintainerId, newTaskData)
  }

  const onAddTodo = (todoData: NewTodoData) => {
    return createTodo({
      ...todoData,
      task: task.id,
      project: task.projectId,
      organisation: task.isOrgTask ? task.maintainerId : null,
    })
  }

  return (
    <Calendar
      events={events}
      entitiesById={entitiesById}
      canAddCalendarEvents={task.canCreateTaskCalendarEvents}
      canAddTasks={task.canCreate}
      canAddTodos={task.canCreateTaskTodos}
      onAddCalendarEvent={onAddCalendarEvent}
      onAddTask={onAddTask}
      onAddTodo={onAddTodo}
      onUpdateCalendarEvent={updateCalendarEvent}
      onUpdateTask={updateTask}
      onUpdateTodo={updateTodo}
      weekendDays={task.isOrgTask ? organisation?.weekendDays : user?.weekendDays}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translations')
  const translations = t || ({} as StringMap)

  const {
    contextNotFoundError = defaults.contextNotFoundError,
    taskNotFoundError = defaults.taskNotFoundError,
    fetchErrorCalendarEvents = defaults.fetchErrorCalendarEvents,
    fetchErrorTask = defaults.fetchErrorTask,
    fetchErrorTasks = defaults.fetchErrorTasks,
    fetchErrorTodos = defaults.fetchErrorTodos,
    fetchErrorOrganisation = defaults.fetchErrorOrganisation,
  } = translations

  return {
    contextNotFoundError,
    taskNotFoundError,
    fetchErrorCalendarEvents,
    fetchErrorTask,
    fetchErrorTasks,
    fetchErrorTodos,
    fetchErrorOrganisation,
  }
}
const defaultTranslations = {
  contextNotFoundError: 'Context not found',
  taskNotFoundError: 'Task not found',
  fetchErrorCalendarEvents: 'Failed to fetch calendar events',
  fetchErrorTask: 'Failed to fetch task',
  fetchErrorTasks: 'Failed to fetch tasks',
  fetchErrorTodos: 'Failed to fetch todos',
  fetchErrorOrganisation: 'Failed to fetch organisation',
}
type Translations = typeof defaultTranslations
type RouteParams = { taskId: string }
export default TaskCalendarRoute

type QueryErrors = {
  calendarEvent?: string
  task?: string
  todo?: string
  tasks?: string
  organisation?: string
}
