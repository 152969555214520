import { CardContent, Typography, Card } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const DeactivatedRoute = () => {
  const { t } = useTranslation()

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle2" component="p" color="error">
          {t('accountActivationHelperHeader', 'You have deactivated your account')}
        </Typography>
        <Typography variant="subtitle1" component="h5">
          {t(
            'accountActivationHelperText',
            'Please contact Starbrix if you want to start using StarBrix again or need any help with your account'
          )}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default DeactivatedRoute
