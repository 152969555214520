import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { useI18n } from '../hooks'
import { StringMap } from '../types/common'
import { Chip, FormControl, ListItemText, makeStyles, MenuItem, OutlinedInput, Select, Theme } from '@material-ui/core'
import { ChevronDown } from 'react-feather'
import { keyBy } from 'lodash'
import { dateFormatOptions, DateFormatOptionType, dateFormatSeparatorOptions } from '../constants/date-format'
import { DateFormatSeparatorOptionType } from '../organisations/constants'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const DateFormatSelectWidget = ({
  dateFormat = dateFormatOptions[0],
  dateFormatSeparator = dateFormatSeparatorOptions[0],
  onDateFormatChange = () => {},
  disabled = false,
}: DateFormatSelectWidgetProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onDateFormatChange({
      dateFormat: event.target.value as DateFormatOptionType,
    })
  }
  const selectorOptions: {
    value: DateFormatOptionType
    label: string
  }[] = dateFormatOptions.map((dateFormat: DateFormatOptionType) => {
    const { dayLabel, monthLabel, yearLabel } = translations
    if (dateFormat === 'DD.MM.YYYY')
      return {
        value: dateFormat,
        label: `${dayLabel}${dateFormatSeparator}${monthLabel}${dateFormatSeparator}${yearLabel}`,
      }
    if (dateFormat === 'MM.DD.YYYY')
      return {
        value: dateFormat,
        label: `${monthLabel}${dateFormatSeparator}${dayLabel}${dateFormatSeparator}${yearLabel}`,
      }
    return {
      value: dateFormat,
      label: `${yearLabel}${dateFormatSeparator}${monthLabel}${dateFormatSeparator}${dayLabel}`,
    }
  })

  return (
    <Paper elevation={0}>
      <Box p={4}>
        <Grid container direction="column">
          <Typography variant="subtitle1">{translations.dateFormatTitle}</Typography>
          <Grid item>
            <FormControl className={classes.formControl} disabled={disabled}>
              <Select
                labelId="tz-select"
                id="tz-select"
                value={dateFormat}
                onChange={handleChange}
                input={<DateFormateInput />}
                MenuProps={MenuProps}
                IconComponent={ChevronDown}
                renderValue={(selected: any) => {
                  const selectorOptionsMap = keyBy(selectorOptions, 'value')
                  return (
                    <div className={classes.chips}>
                      <Chip label={selectorOptionsMap[selected].label} className={classes.chip} />
                    </div>
                  )
                }}
              >
                {selectorOptions.map(({ value, label }, index) => (
                  <MenuItem key={index} value={value}>
                    <ListItemText primary={label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

const DateFormateInput = (props: any) => {
  const classes = useStyles()
  return <OutlinedInput {...props} className={classes.dateFormatInput} />
}
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 250,
    },
  },
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {},
  dateFormatInput: {
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    borderRadius: 2,
    background: `${theme.palette.primary.main}21`,
  },
}))

const useTranslations = (defaults = defaultTranslations) => {
  const { translations: t } = useI18n('translation')
  const translations = (t.dateFormatSelectWidget || {}) as StringMap
  const {
    dateFormatTitle = defaults.dateFormatTitle,
    dayLabel = defaults.dayLabel,
    monthLabel = defaults.monthLabel,
    yearLabel = defaults.yearLabel,
  } = translations

  return { dateFormatTitle, dayLabel, monthLabel, yearLabel }
}

const defaultTranslations = {
  dateFormatTitle: 'Date format',
  dayLabel: 'Day',
  monthLabel: 'Month',
  yearLabel: 'Year',
}

type DateFormatSelectWidgetProps = {
  onDateFormatChange?: ({ dateFormat }: { dateFormat: DateFormatOptionType }) => void
  dateFormat?: DateFormatOptionType
  dateFormatSeparator?: DateFormatSeparatorOptionType
  disabled?: boolean
}

export default DateFormatSelectWidget
