import { createSelector } from '@reduxjs/toolkit'
import { filter, values } from 'lodash'
import { RootState } from '../../store'

export const getInvitationById = (state: RootState, invitationId: string) => {
  return state.invitations.byIds[invitationId]
}

export const getUserInvitations = createSelector(
  (state: RootState) => state.auth.user,
  (state: RootState) => state.invitations.byIds,
  (user, invitations) => filter(values(invitations), { userEmail: user?.email })
)

export const getOrgInvitations = createSelector(
  (state: RootState) => state.invitations.byIds,
  (state: RootState, orgId: string) => orgId,
  (invitations, orgId) => filter(values(invitations), { orgId })
)
