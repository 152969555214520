import OrgSettingsView from '../../../organisations/views/OrgSettingsView'
import { useAppDispatch } from '../../../store'
import React from 'react'
import { FetchOptions } from '../../../options/store/actions'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAsync } from '../../../hooks/use-async'
import { FetchOrgMemberships } from '../../../memberships/store'
import Progress from '../../../components/Progress'
import { Alert, AlertTitle } from '@material-ui/lab'
import { FetchOrganisation } from '../../../organisations/store/actions'
import { useAppContext } from '../../../hooks/use-app-context'
import { useI18n } from '../../../hooks'
import { StringMap } from '../../../types/common'

const useLoader = (orgId: string) => {
  const dispatch = useAppDispatch()
  const loader = React.useCallback(async () => {
    dispatch({ type: `load_org_home_route` })
    const results = await Promise.all([
      dispatch(FetchOrganisation(orgId)),
      dispatch(FetchOptions(orgId)),
      dispatch(FetchOrgMemberships(orgId)),
    ])
    results.forEach(unwrapResult)
  }, [dispatch, orgId])
  return useAsync<void, any>(loader)
}

const OrgSettingsRoute = () => {
  const translations = useTranslations()
  const { appContext } = useAppContext()
  const orgId = appContext.mainContext?.id as string
  const { error, isSuccess } = useLoader(orgId)

  if (error) {
    return (
      <Alert severity="error" style={{ marginTop: 8 }}>
        <AlertTitle>{translations.error}</AlertTitle>
        {error.message ? error.message : error}
      </Alert>
    )
  }

  if (isSuccess()) {
    return <OrgSettingsView orgId={orgId} />
  }

  return <Progress label="" />
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translations')
  const translations = t || ({} as StringMap)
  const { error = defaults.error } = translations
  return {
    error,
  }
}
const defaultTranslations = {
  error: 'Error',
}
type Translations = typeof defaultTranslations

export default OrgSettingsRoute
