import Box from '@material-ui/core/Box'
import MuiCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import { useI18n } from '../../hooks'
import { StringMap } from '../../types/common'
import { Check, Square } from 'react-feather'
import { makeStyles, Theme } from '@material-ui/core'
import { COLOR_BLACK } from '../../constants'

const CalendarViewHeader = ({
  titlePrefix,
  showEvents,
  showTasks,
  showTodos,
  toggleCalendarEvents,
  toggleTasks,
  toggleTodos,
}: CalendarViewHeaderProps) => {
  const translations = useTranslations(defaultTranslations)

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h6">
          {titlePrefix && (
            <Box component="span" color="text.secondary">
              {`${titlePrefix} - `}
            </Box>
          )}
          <Box component="span"> {translations.pageTitle} </Box>
        </Typography>
      </Grid>
      <Grid item>
        <FormGroup row>
          <FormControlLabel
            label={translations.calendarEventsLabel}
            control={<Checkbox checked={showEvents} onChange={toggleCalendarEvents} />}
          />
          <FormControlLabel
            label={translations.tasksLabel}
            control={<Checkbox checked={showTasks} onChange={toggleTasks} />}
          />
          <FormControlLabel
            label={translations.todosLabel}
            control={<Checkbox checked={showTodos} onChange={toggleTodos} />}
          />
        </FormGroup>
      </Grid>
    </Grid>
  )
}

const Checkbox = (props: CheckboxProps) => {
  const classes = useStyles()
  return (
    <MuiCheckbox
      checkedIcon={<Check className={classes.checkIconChecked} size={18} />}
      icon={<Square size={18} color={COLOR_BLACK} />}
      {...props}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  checkIconChecked: {
    background: theme.palette.primary.main,
    stroke: theme.palette.common.white,
    borderRadius: 3,
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = (t?.calendarViewHeader || {}) as StringMap

  const {
    pageTitle = defaults.pageTitle,
    tasksLabel = defaults.tasksLabel,
    calendarEventsLabel = defaults.calendarEventsLabel,
    todosLabel = defaults.todosLabel,
  } = translations

  return {
    pageTitle,
    tasksLabel,
    calendarEventsLabel,
    todosLabel,
  }
}

const defaultTranslations = {
  pageTitle: 'Calendar',
  tasksLabel: 'Tasks',
  calendarEventsLabel: 'Calendar events',
  todosLabel: 'Todos',
}

type Translations = typeof defaultTranslations

type CalendarViewHeaderProps = {
  titlePrefix?: string
  showEvents: boolean
  showTasks: boolean
  showTodos: boolean
  toggleCalendarEvents: () => void
  toggleTasks: () => void
  toggleTodos: () => void
}

export default CalendarViewHeader
