import React from 'react'
import Grid, { GridProps, GridSize } from '@material-ui/core/Grid'

// Use this component if you need to stack components vertically or horizontally with a spacing between them.
// The gap between the components is defined by the spacing prop.
const Stack = ({ children, packed, GridItemProps = {}, ...gridProps }: StackProps) => {
  const spacing = gridProps.spacing === undefined ? 2 : gridProps.spacing
  const direction = gridProps.direction || 'column'
  const isVertical = direction === 'column'
  const alignItems = gridProps.alignItems || (isVertical ? 'stretch' : 'center')
  // If the Stack is vertical, we set the Grid items to be full width.
  const autoXs = isVertical ? 12 : 'auto'
  // If the Stack is horizontal, we set the Grid items to occupy equal spacing horizontally.
  // If the Stack is horizontal and packed, we set the Grid items to be as wide as their content.
  const flexGrow = packed ? 0 : 1
  // If any of the child component is null, we ignore it
  const filteredChildren = React.Children.toArray(children).filter(Boolean)

  return (
    <Grid container {...gridProps} spacing={spacing} direction={direction} alignItems={alignItems}>
      {filteredChildren.map((child, index) => (
        <Grid item xs={autoXs as GridSize} key={index} style={{ flexGrow }} {...GridItemProps}>
          {child}
        </Grid>
      ))}
    </Grid>
  )
}

type StackProps = GridProps & {
  children: React.ReactNode
  GridItemProps?: GridProps
  packed?: boolean
}

export default Stack
