import { getMinDate, getMaxDate } from '../../utils'
import { RootNode } from '../types'
import { TaskViewModel } from '../../tasks/api/task'

export const getRowById = (rows: any[], id: string) => {
  return rows.find((row) => row.id === id)
}
export const getMinStartDate = (
  root: Pick<RootNode, 'plannedStartDate'>,
  tasks: Pick<TaskViewModel, 'plannedStartDate' | 'actualStartDate'>[]
) => {
  const startDatesAccumulator: Date[] = root.plannedStartDate ? [new Date(root.plannedStartDate)] : []
  const allStartDates: Date[] = tasks.reduce((acc, task) => {
    if (task.plannedStartDate) {
      acc.push(new Date(task.plannedStartDate))
    }
    if (task.actualStartDate) {
      acc.push(new Date(task.actualStartDate))
    }
    return startDatesAccumulator
  }, startDatesAccumulator)
  return getMinDate(allStartDates)
}

export const getMaxEndDate = (
  root: Pick<RootNode, 'plannedEndDate'>,
  tasks: Pick<TaskViewModel, 'plannedEndDate' | 'actualEndDate' | 'isInProgress'>[]
) => {
  const endDatesAccumulator: Date[] = root.plannedEndDate ? [new Date(root.plannedEndDate)] : []
  const allEndDates: Date[] = tasks.reduce((acc, task) => {
    if (task.plannedEndDate) {
      acc.push(new Date(task.plannedEndDate))
    }
    if (task.actualEndDate) {
      acc.push(new Date(task.actualEndDate))
    }
    if (task.isInProgress) {
      acc.push(new Date())
    }
    return endDatesAccumulator
  }, endDatesAccumulator)
  return getMaxDate(allEndDates)
}
