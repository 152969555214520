import { CalendarEventResponse } from './calendar-event-response'

export const makeCalendarEventViewModel = (calendarEvent: CalendarEventResponse) => {
  return {
    ...calendarEvent,
    isOrgEvent: calendarEvent.organisation !== null,
    isUserEvent: calendarEvent.organisation === null,
  }
}

export type CalendarEventViewModel = CalendarEventResponse & {
  isUserEvent: boolean
  isOrgEvent: boolean
}
