import { createSelector } from '@reduxjs/toolkit'
import { RootState, useAppSelector } from '../store'
import { SATURDAY, SUNDAY } from '../constants'

const DEFAULT_WEEKEND_DAYS = [SATURDAY, SUNDAY]

export const useWeekendDays = (orgId?: string) => {
  return useAppSelector((state) => getWeekendDays(state, orgId))
}

const getWeekendDays = createSelector(
  (state: RootState) => state.orgs.byIds,
  (state: RootState) => state.auth.user?.weekendDays,
  (state: RootState, orgId?: string) => orgId,
  (orgs, weekendDays, orgId) => {
    if (orgId && orgs[orgId]) return orgs[orgId].weekendDays
    return weekendDays || DEFAULT_WEEKEND_DAYS
  }
)
