import { unwrapResult } from '@reduxjs/toolkit'
import { useAuthUserMembership } from '../../memberships/hooks/use-auth-user-membership'
import { useAppDispatch, useAppSelector } from '../../store'
import { SupplierStatusEnum } from '../../types/supplier'
import { AddSupplierContact, UpdateSupplierStatus } from '../store/actions'
import { getSupplierById } from '../store/selectors'
import { useCalendarEventMutations } from '../../calendar-events/hooks/use-calendar-event-mutations'
import { NewCalendarEventData } from '../../calendar-events/api'

export const useSupplier = (supplierId: string) => {
  const dispatch = useAppDispatch()
  const supplier = useAppSelector((state) => getSupplierById(state, supplierId))
  const { canCreateCalendarEvents } = useAuthUserMembership()
  const calendarEventActions = useCalendarEventMutations()

  const onAddCalendarEvent = (event: NewCalendarEventData) => {
    return calendarEventActions.createCalendarEvent({
      title: event.title,
      startDate: event.startDate,
      endDate: event.endDate,
      description: event.description || null,
      organisation: supplier.orgId,
      suppliers: [supplier.id],
    })
  }

  const onChangeStatus = () => {
    const status = supplier.isActive ? SupplierStatusEnum.inactive : SupplierStatusEnum.active
    return dispatch(
      UpdateSupplierStatus({
        supplierId: supplier.id,
        status: status,
      })
    )
  }

  const onAddContact = async (contact: {
    firstname: string
    lastname: string
    phone?: string
    email?: string
    jobTitle?: string
  }) => {
    unwrapResult(await dispatch(AddSupplierContact({ supplierId, ...contact })))
  }

  return {
    supplier,
    canAddCalendarEvents: canCreateCalendarEvents,
    canManageSupplier: supplier?.canUpdate,
    onAddCalendarEvent,
    onChangeStatus,
    onAddContact,
  }
}
