import * as locales from 'date-fns/locale'
import { makeColumns } from './columns'
import { format } from 'date-fns'
import { TreeGridTranslations } from '../hooks/use-translations'
import { GanttZoom, Option, RootNode } from '../types'

export const makeLayout = (props: MakeLayoutProps) => {
  const {
    id,
    translations,
    showGantt,
    showGanttToolbarActions,
    showFilters,
    locale,
    root,
    firstDayOfWeek,
    dateSeparator,
  } = props
  const columns = makeColumns(props)
  const { leftCols, rightCols, midCols } = columns

  return {
    Cfg: makeCfg({ id }),
    LeftCols: leftCols,
    Cols: midCols,
    RightCols: rightCols,
    Header: makeHeader({ columns, translations }),
    Actions: makeActions(),
    Panel: makePanel({ translations }),
    Toolbar: makeToolbar({
      showAddTaskButton: props.showAddTaskButton,
      showGanttToolbarActions,
      showFilters,
      locale,
      translations,
    }),
    Head: makeHead({ root, showFilters, translations }),
    Def: makeDef({ showGantt, translations }),
    Lang: makeLang({ firstDayOfWeek, dateSeparator, translations }),
  }
}

const makeActions = () => {
  return {
    OnRightClickCell: 'Grid.showContextMenu(Row, Col, Grid)',
    OnIns: 'window.Grids.OnAddTask(Grid, Row, Col)',
  }
}

const makeCfg = ({ id = 'TaskTreeGrid' } = {}) => {
  /** @link link for documentation: https://www.treegrid.com/Doc/SearchAttr.html */
  return {
    id,
    SuppressCfg: process.env.NODE_ENV === 'development' ? 1 : 0,
    CfgId: id,
    AutoVersion: 1,
    Style: 'white', // Base treegrid style
    GanttStyle: 'Material', // Base Gantt style
    MainCol: 'title',
    Selecting: 0, // disables selecting of rows
    Dragging: 1, // enables dragging of rows
    ScrollLeft: 0, // sets initial horizontal scroll of middle (variable) columns, in pixels.
    LeftScrollLeft: 0, // sets initial horizontal scroll of left columns, in pixels.
    RightScrollLeft: 0, // sets initial horizontal scroll of right columns, in pixels.
    LeftCanResize: 4,
    RightCanResize: 4,
    SectionShrinkOrder: ['Right,Mid,Left'], // shrinks right first if width is not enough to render all sections
    PrintVisible: 1,
    PrintExpanded: 1,
    PrintPageOrientation: 1,
    PrintPaddingHeight: 20,
    PDFFitPage: 1,
    Code: process.env.REACT_APP_TREEGRID_CODE,
    UpCounter: 1,
  }
}

const makeDef = ({ showGantt, translations }: { showGantt: boolean; translations: TreeGridTranslations }) => {
  return [
    // Default settings for Leaf rows
    {
      Name: 'Leaf',
      GanttMenu: [
        {
          Name: translations.deleteMainBar,
        },
      ],
    },
    // Default settings for Branch rows
    {
      Name: 'Branch',
      GanttMenu: [],
    },
    //Default settings for all rows
    {
      Name: 'R',
      MaxHeight: showGantt ? 16 : 48,
    },
  ]
}

const makeHead = ({ root, showFilters, translations }: MakeHeadProps) => {
  const headerRow = {
    id: 'headerRow',
    Name: translations.pageHeader,
    Space: -1,
    Visible: 0,
    PanelVisible: 0,
    CanExport: 0,
    CanPrint: 2,
    Cells: `Logo,GridInfo,PrintDate`,
    LogoRelWidth: 1,
    LogoAligh: 'Left',
    LogoVAligh: 'middle',
    LogoType: 'Html',
    Logo: `<img style="height: 40px;" src="/favicon.png"  alt="company logo"/>`,
    PrintDate: new Date(),
    PrintDateType: 'Date',
    PrintDateFormat: root.dateFormat,
    PrintDateCanEdit: 0,
    GridInfo: `<span class="largeText">${root.summary}<span>`,
    GridInfoType: 'Html',
    GridInfoRelWidth: 2,
  }
  const filtersRow = {
    CanExport: 0,
    CanPrint: 0,
    id: 'filterRow',
    Kind: 'Filter',
    statusFilterOff: translations.statusFilterOffLabel,
    stateFilterOff: translations.stateFilterOffLabel,
    managersFilterOff: translations.managersFilterOffLabel,
    participantsFilterOff: translations.participantsFilterOffLabel,
    suppliersFilterOff: translations.suppliersFilterOffLabel,
    workspacesFilterOff: translations.workspacesFilterOffLabel,
    Visible: showFilters ? 1 : 0,
  }

  return [headerRow, filtersRow]
}

const makeHeader = ({ columns, translations }: MakeHeaderProps) => {
  const leftColsWithHeaderAndName = columns.leftCols.filter((col) => 'header' in col && 'Name' in col)
  const midColsWithHeaderAndName = columns.midCols.filter((col) => 'header' in col && 'Name' in col)
  const rightColsWithHeaderAndName = columns.rightCols.filter((col) => 'header' in col && 'Name' in col)
  const colsWithHeaderAndName = [
    ...leftColsWithHeaderAndName,
    ...midColsWithHeaderAndName,
    ...rightColsWithHeaderAndName,
  ] as ColumnWithNameAndHeader[]
  const Header: Record<string, string | number> = {
    Name: translations.rowHeaders,
    plannedStartDateClass: 'text-right',
    plannedEndDateClass: 'text-right',
    actualStartDateClass: 'text-right',
    actualEndDateClass: 'text-right',
    durationClass: 'text-center',
    daysLeftClass: 'text-center',
  }
  colsWithHeaderAndName.forEach((col) => {
    Header[col.Name] = col.header
  })
  return Header
}

const makeLang = ({ firstDayOfWeek, dateSeparator, translations }: MakeLangProps) => {
  return {
    Format: {
      FirstWeekDay: firstDayOfWeek,
      GMT: 1,
      DateSeparator: dateSeparator,
      StrictDates: 7, // To fix the behavior where the main bar could be dragged into the weekend
      LongDayNames: translations.longDayNames,
      ShortDayNames: translations.shortDayNames,
      Day2CharNames: translations.day2CharNames,
      Day1CharNames: translations.day1CharNames,
      LongMonthNames: translations.longMonthNames,
      LongMonthNames2: translations.longMonthNames,
      ShortMonthNames: translations.shortMonthNames,
    },
    Gantt: {
      DelGanttDep: translations.deleteDependency,
      GanttDepLagChange: translations.changeDependencyLag,
    },
    MenuColumns: {
      Caption: translations.chooseItemsLabel,
      ColsCaption: translations.chooseColumnsLabel,
      SpaceCaption: translations.chooseToolbarsLabel,
      RowsCaption: translations.chooseRowsLabel,
    },
    MenuButtons: {
      Ok: translations.okButtonLabel,
      Cancel: translations.cancelButtonLabel,
      HideAll: translations.hideAllButtonLabel,
    },
    MenuPrint: {
      Caption: translations.printOptionsLabel,
      PrintOptions: translations.printOptionsLabel,
      PrintRows: translations.printMaxRowsPerPageLabel,
      PrintPageRoot: translations.printStartRowLabel,
      PrintPageSize: translations.printPageSizeLabel,
      PrintPageOptions: translations.printBrowserPrintInfoLabel,
      PrintPageOrientation: translations.printPageOrientationLabel,
      PrintPageOrientations: `${translations.portraitLabel},${translations.landscapeLabel}`,
      PrintMarginWidth: translations.printMarginWidthLabel,
      PrintMarginHeight: translations.printMarginHeightLabel,
      PDFFitPage: translations.printFitToPageLabel,
      PDFFitPages: `${translations.noneLabel},${translations.widthLabel},${translations.heightLabel},${translations.singlePageLabel}`,
      PrintDPI: translations.printDPIRatioLabel,
      PrintWidth: translations.printPageWidthLabel,
      PrintHeight: translations.printPageHeightLabel,
      PrintResults: translations.printSizeInfo,
    },
    MenuExport: {
      Caption: translations.exportOptionsLabel,
      ExportOptions: translations.exportOptionsLabel,
      ExportName: translations.exportFileNameLabel,
      ExportFormat: translations.exportFileFormatLabel,
      ExportExpanded: translations.exportAllRowsExpandedLabel,
      ExportOutline: translations.exportTreeInExcelLabel,
      ExportIndent: translations.exportIndentTreeInMainColumnLabel,
      ExportFixedRows: translations.exportFreezeHeadRowsLabel,
      ExportFixedCols: translations.exportFreezeLeftColumnsLabel,
      ExportEmpty: translations.exportEmptyCellsLabel,
    },
  }
}

const makePanel = ({ translations }: { translations: TreeGridTranslations }) => {
  return {
    Move: 1,
    PanelDeleteTip: translations.archiveTask,
  }
}

const makeToolbar = ({
  showAddTaskButton,
  showGanttToolbarActions,
  showFilters,
  locale,
  translations,
}: MakeToolbarProps) => {
  const formattedToday = format(new Date(), 'dd MMM', {
    // @ts-ignore
    locale: locales[locale],
  })
  /**
   * @link https://www.treegrid.com/Doc/GlobalSettings.htm#DefaultToolbar
   */
  return {
    AddChild: 0,
    Cfg: 0,
    Contrasts: 0,
    Debug: 0,
    DefSort: 0,
    GanttStyles: 0,
    Help: 0,
    Indent: 0,
    Lock: 0,
    Outdent: 0,
    Reload: 0,
    Sizes: 0,
    Space: 0,
    Styles: 0,
    WinScroll: 0,
    Correct: 0,
    Add: 0,
    Link: 0,

    Cells05Custom: showAddTaskButton ? 'AddTask' : '',
    Cells60Filter: 'Filter',
    FilterType: 'Button',
    FilterButtonClass: showFilters ? 'Filter_Button_Active' : 'Filter_Button',
    FilterOnClick: 'window.Grids.OnFilterToggle()',

    AddTaskType: 'Button',
    AddTaskButton: 'Button',
    AddTask: `<button>${translations.toolbarAddTaskBtnLabel}<span class="icon"> + </span></button>`,
    AddTaskClass: 'toolbarCustomButton toolbarCustomButton--primary',
    AddTaskOnClick: 'window.Grids.OnAddTask(Grid, Row, Col)',

    // To turn off the GanttZoom icons on Cells 50
    Cells50GanttZoom: '',
    // A custom empty cell to push the GanttZoom to the right
    Cells80Right: 'Spacer',
    SpacerHtml: '<span></span>',
    SpacerRelWidth: '1',

    Cells99GanttZoom: showGanttToolbarActions ? 'ZoomIn,ZoomOut,ZoomFit,Zoom,Prev,Today,Next' : '',
    ZoomType: 'SelectGanttZoom',
    ZoomHtmlPrefix: `${translations.showLabel} <b>`,
    ZoomHtmlPostfix: '</b>',
    ZoomWidth: '184',

    NextType: 'Button',
    NextButton: 'Button',
    Next: '<button class="icon"> > </button>',
    NextClass: 'toolbarCustomButton toolbarCustomButton--small',
    NextOnClick: 'window.Grids.OnClickNext()',

    PrevType: 'Button',
    PrevButton: 'Button',
    Prev: '<button class="icon"> < </button>',
    PrevClass: 'toolbarCustomButton toolbarCustomButton--small',
    PrevOnClick: 'window.Grids.OnClickPrev()',

    TodayType: 'Button',
    TodayButton: 'Button',
    // @ts-ignore
    Today: `<button >${formattedToday}</button>`,
    TodayClass: 'toolbarCustomButton toolbarCustomButton--small',
    TodayOnClick: 'window.Grids.OnClickToday()',

    PrintTip: translations.toolbarPrintTip,
    ExportTip: translations.toolbarExportTip,
    ExpandAllTip: translations.toolbarExpandAllTip,
    CollapseAllTip: translations.toolbarCollapseAllTip,
    ColumnsTip: translations.toolbarColumnsTip,
    ZoomInTip: translations.toolbarZoomInTip,
    ZoomOutTip: translations.toolbarZoomOutTip,
    ZoomFitTip: translations.toolbarZoomFitTip,
    PrevTip: translations.toolbarPrevTip,
    NextTip: translations.toolbarNextTip,
    ScalesLabel: translations.scaleLabel,
  }
}

type MakeToolbarProps = {
  showAddTaskButton: boolean
  showGanttToolbarActions: boolean
  showFilters: boolean
  locale: string
  translations: TreeGridTranslations
}

type MakeLangProps = {
  firstDayOfWeek: DayOfWeek
  dateSeparator: string
  translations: TreeGridTranslations
}

type MakeHeadProps = {
  root: RootNode
  showFilters: boolean
  translations: TreeGridTranslations
}

type MakeHeaderProps = {
  columns: MadeColumns
  translations: TreeGridTranslations
}

type MakeLayoutProps = {
  id: string
  root: RootNode
  showActualBar: boolean
  showBaselineBar: boolean
  showGantt: boolean
  showGanttToolbarActions: boolean
  showAddTaskButton: boolean
  showFilters: boolean
  translations: TreeGridTranslations
  visibleColumns?: string[]
  options: Option[]
  weekendDays: DayOfWeek[]
  firstDayOfWeek: DayOfWeek
  dateSeparator: string
  locale: string
  defaultZoom: string
  zoomList: GanttZoom[]
}

type Column = Partial<TCol> & {
  header?: string
}

type MadeColumns = {
  leftCols: Column[]
  midCols: Column[]
  rightCols: Column[]
}

type ColumnWithNameAndHeader = {
  header: string
  Name: string
}
