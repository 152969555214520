import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import NoContacts from './NoContacts'

import { IContact } from '../../types/contact'
import { useI18n, useRouter } from '../../hooks'
import { makeStyles, Theme } from '@material-ui/core'
import { useUrlWithContext } from '../../hooks/use-url-with-context'

const ContactsTable = ({ contacts, canAddContact }: ContactsTableProps) => {
  const { pathname, history } = useRouter()
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const { createPathWithContext } = useUrlWithContext()

  if (!contacts.length) return <NoContacts showAddButton={canAddContact} />

  return (
    <TableContainer>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell align="left">{translations.tableHeaderFirstname}</TableCell>
            <TableCell align="left">{translations.tableHeaderLastname}</TableCell>
            <TableCell align="left">{translations.tableHeaderEmail}</TableCell>
            <TableCell align="left">{translations.tableHeaderPhoneNumber}</TableCell>
            <TableCell align="left">{translations.tableHeaderJobTitle}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact) => {
            const contactURI = createPathWithContext(`${pathname}?tab=contact&contact=${contact.id}`)
            const redirectToContact = () => history.push(contactURI)
            return (
              <TableRow key={contact.id}>
                <TableCell
                  className={classes.tableCell}
                  onClick={redirectToContact}
                  data-test={`contacts-table-${contact.firstname} ${contact.lastname}`}
                >
                  <span>{contact.firstname || '-'}</span>
                </TableCell>
                <TableCell>{contact.lastname || '-'}</TableCell>
                <TableCell>{contact.email || '-'}</TableCell>
                <TableCell>{contact.phone || '-'}</TableCell>
                <TableCell>{contact.jobTitle || '-'}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = (t || {}) as { [k: string]: string }
  const { firstname, lastname, email, phoneNumber, jobTitle } = translations

  const tableHeaderFirstname = firstname || defaultTranslations.tableHeaderFirstname
  const tableHeaderLastname = lastname || defaultTranslations.tableHeaderLastname
  const tableHeaderEmail = email || defaultTranslations.tableHeaderEmail
  const tableHeaderPhoneNumber = phoneNumber || defaultTranslations.tableHeaderPhoneNumber
  const tableHeaderJobTitle = jobTitle || defaultTranslations.tableHeaderJobTitle

  return {
    tableHeaderFirstname,
    tableHeaderLastname,
    tableHeaderEmail,
    tableHeaderPhoneNumber,
    tableHeaderJobTitle,
  }
}

const defaultTranslations = {
  tableHeaderFirstname: 'Firstname',
  tableHeaderLastname: 'Lastname',
  tableHeaderEmail: 'Email',
  tableHeaderPhoneNumber: 'Phone number',
  tableHeaderJobTitle: 'Job title',
}

const useStyles = makeStyles((theme: Theme) => ({
  tableHead: {
    background: theme.palette.background.default,
    textTransform: 'uppercase',
    '& th': {
      fontSize: 12,
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
  },
  tableCell: {
    color: theme.palette.primary.main,
    transition: theme.transitions.create('opacity'),
    '&:first-child': {
      '&:hover': {
        cursor: 'pointer',
        opacity: 1,
      },
      '& > span:hover': {
        paddingBottom: theme.spacing(0.5),
        borderBottom: `1px dotted ${theme.palette.primary.main}`,
      },
    },
  },
}))

type ContactsTableProps = {
  contacts: IContact[]
  canAddContact: boolean
}

type Translations = typeof defaultTranslations

export default ContactsTable
