import ResourceManager from '../../components/ResourceManager'
import { StringMap } from 'i18next'
import { useI18n } from '../../hooks'
import { useOrgOptions } from '../../options/hooks/use-org-options'
import { useProjectMutations } from '../hooks/use-project-mutations'
import { ProjectViewModel } from '../api/project'

const ProjectSuppliersWidget = ({ project }: { project: ProjectViewModel }) => {
  const translations = useTranslations(defaultTranslations)
  const { supplierOptions } = useOrgOptions(project.maintainerId)
  const { addSuppliers, removeSuppliers } = useProjectMutations()

  if (!project) return null

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={supplierOptions}
      resources={project.suppliers}
      canAddResource={project.canUpdateSuppliers}
      canRemoveResource={project.canUpdateSuppliers}
      onAddResources={(ids) => addSuppliers(project.id, ids)}
      onRemoveResource={(id) => removeSuppliers(project.id, [id])}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection
    />
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('project')
  const translations = (t?.suppliersWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: 'Suppliers',
  emptyOptionsMessage: 'No suppliers in this organisation',
  emptyResourcesMessage: 'No suppliers in this project',
}

type Translations = typeof defaultTranslations

export default ProjectSuppliersWidget
