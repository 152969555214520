import { PayloadAction } from '@reduxjs/toolkit'
import { omitBy, without } from 'lodash'
import { CustomersState } from './index'
import {
  IContactResponse,
  ICustomerResponse,
} from '../interfaces/customer-response'
import { normalizeCustomer } from '../interfaces/customer-normalized'

export const setCustomerCase = (
  state: CustomersState,
  action: PayloadAction<ICustomerResponse>
) => {
  state.byIds[action.payload.id] = normalizeCustomer(action.payload)
  if (!state.allIds.includes(action.payload.id)) {
    state.allIds.push(action.payload.id)
  }
}

export const setCustomersCase = (
  state: CustomersState,
  action: PayloadAction<ICustomerResponse[]>
) => {
  action.payload.forEach((customer) => {
    state.byIds[customer.id] = normalizeCustomer(customer)
    if (!state.allIds.includes(customer.id)) state.allIds.push(customer.id)
  })
}

export const setContactCase = (
  state: CustomersState,
  action: PayloadAction<IContactResponse>
) => {
  if (action.payload.customerId) {
    const customer = state.byIds[action.payload.customerId]
    if (customer && 'contacts' in customer) {
      customer.contacts[action.payload.id] = action.payload
      state.byIds[customer.id] = customer
    }
  }
}

export const deleteContactCase = (
  state: CustomersState,
  action: PayloadAction<{
    customerId: string
    contactId: string
  }>
) => {
  const { customerId, contactId } = action.payload
  const customer = state.byIds[customerId]
  if (customer && 'contacts' in customer) {
    customer.contacts = omitBy(customer.contacts, { id: contactId })
    const isDefaultContact = customer.defaultContact?.id === contactId
    if (isDefaultContact) customer.defaultContact = null
    state.byIds[customerId] = customer
  }
}

export const deleteOrganisationCase = (
  state: CustomersState,
  action: PayloadAction<string>
) => {
  const orgCustomerIds: string[] = []
  for (const customerId in state.allIds) {
    if (state.byIds[customerId].orgId === action.payload) {
      orgCustomerIds.push(customerId)
    }
  }
  state.byIds = omitBy(state.byIds, { orgId: action.payload })
  state.allIds = without(state.allIds, ...orgCustomerIds)
}
