import { format } from 'date-fns'
import { find, includes, isArray, replace } from 'lodash'
import { ISO_DATE_REGEX } from '../constants'
import { countries } from '../constants/countries'
import { DateFormat } from '../types/common'

export const normalizeDate = (date: string, dateFormat: string = DateFormat.DD_MM_YYYY): string => {
  if (!date) return date
  return format(new Date(date), reactDatepickerDateFormat(dateFormat))
}

export const reactDatepickerDateFormat = (dateFormat: string) => {
  return replace(dateFormat, 'mm', 'MM')
}

export const getDefaultDateFormat = () => {
  const fallback = 'M/d/yyyy'
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const country = find(countries, ({ timezones }) => {
    return includes(timezones, userTimezone)
  })

  return country?.preferredDateFormat || fallback
}

/**
 * NOTE: This function only works correctly if weekend days are adjacent to each other,
 * check unit tests to find out how this function works
 * @param days Array of weekend days
 * @returns index of start and end days of weekend
 * @example if days = [0, 6] then returns { start: 6, end: 0 }
 */
export const getWeekendBoundaries = (days: DayOfWeek[] = []): { start: DayOfWeek | null; end: DayOfWeek | null } => {
  const ascSorted = [...days].sort()
  const lastIndex = ascSorted.length - 1
  let { 0: start = null, [lastIndex]: end = null } = ascSorted

  if (end === 6 && lastIndex >= 1) {
    var index = 1
    while (index <= lastIndex) {
      var diff = ascSorted[index] - ascSorted[index - 1]
      if (diff > 1) {
        start = ascSorted.slice(index).shift() as DayOfWeek
        end = ascSorted.slice(0, index).pop() as DayOfWeek
      }
      index++
    }
  }

  return {
    start,
    end,
  }
}
// var dates = []
// dates.push(new Date('2011/06/25'))
// dates.push(new Date('2011/06/26'))
// dates.push(new Date('2011/06/27'))
// dates.push(new Date('2011/06/28'))
// var maxDate = new Date(Math.max.apply(null, dates))
// var minDate = new Date(Math.min.apply(null, dates))

export const getMaxDate = (dates: Array<Date | string>): Date | null => {
  if (!isArray(dates) || !dates.length) return null
  const milis = dates
    .map((d) => new Date(d))
    .filter(isValidDate)
    .map((d) => d.getTime())
  return milis.length ? new Date(Math.max(...milis)) : null
}

export const getMinDate = (dates: Array<Date | string>): Date | null => {
  if (!isArray(dates)) return null
  const milis = dates
    .map((d) => new Date(d))
    .filter(isValidDate)
    .map((d) => d.getTime())
  return milis.length ? new Date(Math.min(...milis)) : null
}

/**
 *
 * @param value a Date object or a string
 * @returns true/false for whether the given value is valid date or valid ISO date string
 */
export const isValidDate = (value: Date | string): boolean => {
  if (value instanceof Date) return !isNaN(value as any)
  if (typeof value === 'string') return ISO_DATE_REGEX.test(value)
  return false
}

export const isBeforeDays = (date: Date, days: number): boolean => {
  const now = new Date()
  now.setDate(now.getDate() - days)
  return date < now
}
