import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'

import { makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const MembersTable = ({
  memberRows,
  selectedMemberships,
  onRowSelect,
  onManagePermissionsClick,
}: MembersTableProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const managePermissionsButtonLabel = t('managePermissionsButtonLabel')

  return (
    <div className={classes.membersTable}>
      {memberRows.map((row) => {
        return (
          <div key={row.id} className="memberRow">
            <div className="left">
              <Checkbox
                checked={selectedMemberships.includes(row.id)}
                color="primary"
                className="checkbox"
                onChange={() => onRowSelect(row.id)}
                disabled={row.disabled}
              />
              <Avatar className="avatar">{row.initials}</Avatar>
              <div>
                <Typography variant="subtitle1">{row.fullname}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {row.email}
                </Typography>
              </div>
            </div>
            <div className="right">
              <Button variant="outlined" disabled={row.disabled} onClick={() => onManagePermissionsClick(row.id)}>
                {managePermissionsButtonLabel}
              </Button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

type MembersTableProps = {
  memberRows: MemberRow[]
  selectedMemberships: string[]
  onRowSelect: (membershipId: string) => void
  onManagePermissionsClick: (membershipId: string) => void
}

type MemberRow = {
  id: string
  fullname: string
  email: string
  initials: string
  disabled: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  membersTable: {
    border: `1px solid ${theme.palette.divider}`,
    borderTop: 'none',
    borderBottomLeftRadius: theme.spacing(),
    borderBottomRightRadius: theme.spacing(),
    '& .checkbox': {
      marginRight: 20,
    },
    '& .memberRow': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.palette.common.white,
      padding: theme.spacing(1.5),
      borderTop: `1px solid ${theme.palette.divider}`,
      '&:last-child': {
        borderBottomRightRadius: theme.spacing(),
        borderBottomLeftRadius: theme.spacing(),
      },
      '& .avatar': {
        backgroundColor: theme.palette.error.main,
        marginRight: 20,
      },
      '& .left': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  },
}))

export default MembersTable
