import { CalendarEventViewModel } from '../api/calendar-event'
import { useCalendarEventMutations } from '../hooks/use-calendar-event-mutations'
import ResourceManager from '../../components/ResourceManager'
import { useI18n } from '../../hooks/use-i18n'
import { useOrgOptions } from '../../options/hooks/use-org-options'
import { StringMap } from '../../types/common'

const EventCustomersWidget = ({ calendarEvent }: { calendarEvent: CalendarEventViewModel }) => {
  const translations = useTranslations(defaultTranslations)
  const { customerOptions } = useOrgOptions(calendarEvent.organisation?.id || '')
  const { updateCustomers } = useCalendarEventMutations()

  const onAddCustomers = (customerIds: string[]) => {
    const customers = calendarEvent.customers.map(($c) => $c.id).concat(customerIds)
    updateCustomers(calendarEvent.id, customers)
  }

  const onRemoveCustomer = (customer: string) => {
    const customers = calendarEvent.customers.filter(($c) => $c.id !== customer).map(($c) => $c.id)
    updateCustomers(calendarEvent.id, customers)
  }

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={customerOptions}
      resources={calendarEvent.customers}
      canAddResource={calendarEvent.canUpdate}
      canRemoveResource={calendarEvent.canUpdate}
      onAddResources={onAddCustomers}
      onRemoveResource={onRemoveCustomer}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection
    />
  )
}

const useTranslations = (defaults = defaultTranslations) => {
  const { translations: t } = useI18n('calendarEvent')
  const translations = (t?.customersWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: 'Customers',
  emptyOptionsMessage: 'No customers in this organisation',
  emptyResourcesMessage: 'This event is not connected to any customer',
}

export default EventCustomersWidget
