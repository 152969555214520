import { createSelector } from '@reduxjs/toolkit'
import { RootState, useAppSelector } from '../store'
import { DateTimeService } from '../services/date-time-service'
import { TimeZoneType } from '../constants/timezones'

const DEFAULT_TIME_ZONE = DateTimeService.getOsTimeZone()

export const useUserTimeZone = (): TimeZoneType => {
  return useAppSelector(getTimeZone)
}

const getTimeZone = createSelector(
  (state: RootState) => state.auth.user?.timeZone,
  (timezone) => timezone || DEFAULT_TIME_ZONE
)
