import React from 'react'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'

import { BarChart2, Eye, EyeOff } from 'react-feather'
import { COLOR_BLACK } from '../../constants'
import { useI18n } from '../../hooks'
import { StringMap } from '../../types/common'
import { Tooltip } from '@material-ui/core'
import { useBoardMutations } from '../hooks/use-board-mutations'
import { useGetBoardQuery } from '../api'
import { BoardType } from '../api/board'
import { getTranslatedDefaultColumnNames } from '../utils'

const BoardColumnChooser = ({ boardId, boardType, buttonVariant = 'button' }: BoardColumnChooserProps) => {
  const translations = useTranslations(defaultTranslations)
  const { data } = useGetBoardQuery({ id: boardId, type: boardType })
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null)
  const closeMenu = () => setAnchor(null)
  const { updateColumn } = useBoardMutations()

  if (!data) return null

  const { board } = data
  const columnMenuItems = board.columns.allIds.map((columnId) => {
    const column = board.columns.byId[columnId]
    return {
      id: columnId,
      isOn: column.isVisible,
      primaryText: column.name || getTranslatedDefaultColumnNames({ columnName: column.defaultName, translations }),
      onClick: () => updateColumn({ boardId: board.id, columnId, isVisible: !column.isVisible }),
    }
  })

  return (
    <>
      {buttonVariant === 'button' && (
        <Button
          onClick={(e) => setAnchor(anchor ? null : e.currentTarget)}
          startIcon={<BarChart2 style={{ transform: 'rotate(180deg)' }} size={16} />}
        >
          {translations.buttonLabel}
        </Button>
      )}
      {buttonVariant === 'iconButton' && (
        <Tooltip title={translations.buttonTip} placement="left">
          <IconButton onClick={(e) => setAnchor(anchor ? null : e.currentTarget)}>
            <BarChart2 color={COLOR_BLACK} style={{ transform: 'rotate(180deg)' }} size={16} />
          </IconButton>
        </Tooltip>
      )}
      <Menu id="column-menu-tray" anchorEl={anchor} open={Boolean(anchor)} onClose={closeMenu}>
        <ListSubheader>{translations.headerText}</ListSubheader>
        <Divider />
        {columnMenuItems?.map((menuItem) => (
          <ListItem dense key={menuItem.id}>
            <ListItemText primary={menuItem.primaryText} />
            <ListItemSecondaryAction>
              <IconButton onClick={menuItem.onClick}>
                {menuItem.isOn && <Eye color={COLOR_BLACK} size={20} />}
                {!menuItem.isOn && <EyeOff color={COLOR_BLACK} size={20} />}
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </Menu>
    </>
  )
}

const defaultTranslations = {
  headerText: "Board column's visibility",
  buttonLabel: 'Choose columns',
  buttonTip: "Open board's column chooser menu",
  inputQueueLabel: 'Input queue',
  backlogLabel: 'Backlog',
  notStartedLabel: 'Not started',
  planningLabel: 'Planning',
  implementationLabel: 'Implementation',
  reviewLabel: 'Review',
  testingLabel: 'Testing',
  deploymentLabel: 'Deployment',
  completedLabel: 'Completed',
}

const useTranslations = (defaults: Translations): Translations => {
  const { translations: t } = useI18n('translation')
  const { translations: projectTranslations } = useI18n('project')
  const translations = (t?.boardColumnChooser || {}) as StringMap
  const columnTranslations = (t?.boardColumns || {}) as StringMap

  const {
    headerText = defaults.headerText,
    buttonLabel = defaults.buttonLabel,
    buttonTip = defaults.buttonTip,
  } = translations

  const {
    inputQueueLabel = defaults.inputQueueLabel,
    backlogLabel = defaults.backlogLabel,
    planningLabel = defaults.planningLabel,
    implementationLabel = defaults.implementationLabel,
    reviewLabel = defaults.reviewLabel,
    testingLabel = defaults.testingLabel,
    deploymentLabel = defaults.deploymentLabel,
  } = columnTranslations

  const notStartedLabel = projectTranslations.notStarted || defaults.notStartedLabel
  const completedLabel = projectTranslations.completed || defaults.completedLabel

  return {
    headerText,
    buttonLabel,
    buttonTip,
    inputQueueLabel,
    backlogLabel,
    notStartedLabel,
    planningLabel,
    implementationLabel,
    reviewLabel,
    testingLabel,
    deploymentLabel,
    completedLabel,
  }
}

type Translations = typeof defaultTranslations

type BoardColumnChooserProps = {
  boardId: string
  boardType: BoardType
  buttonVariant?: 'iconButton' | 'button'
}

export default BoardColumnChooser
