import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ProjectsViewSelect from './ProjectsViewSelect'
import { useI18n } from '../../hooks'
import { StringMap } from '../../types/common'

const ProjectsViewHeader = ({ titlePrefix, showArchivedTitle = false }: ProjectsViewHeaderProps) => {
  const translations = useTranslations()

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h6">
          {titlePrefix && (
            <Box component="span" color="text.secondary">
              {`${titlePrefix} - `}
            </Box>
          )}
          {!showArchivedTitle && <Box component="span"> {translations.pageTitle} </Box>}
          {showArchivedTitle && (
            <Box component="span" style={{ color: 'red' }}>
              {translations.archivedPageTitle}
            </Box>
          )}
        </Typography>
      </Grid>
      <Grid item>
        <ProjectsViewSelect />
      </Grid>
    </Grid>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = (t?.projectsViewHeader || {}) as StringMap

  const {
    pageTitle = defaults.pageTitle,
    showFiltersButtonLabel = defaults.showFiltersButtonLabel,
    addProjectButtonLabel = defaults.addProjectButtonLabel,
    archivedPageTitle = defaults.archivedPageTitle,
  } = translations

  return {
    pageTitle,
    showFiltersButtonLabel,
    addProjectButtonLabel,
    archivedPageTitle,
  }
}

const defaultTranslations = {
  pageTitle: 'Projects',
  showFiltersButtonLabel: 'Filters',
  addProjectButtonLabel: 'Add project',
  archivedPageTitle: 'Archived projects',
}

type Translations = typeof defaultTranslations

type ProjectsViewHeaderProps = {
  titlePrefix?: string
  showArchivedTitle?: boolean
}

export default ProjectsViewHeader
