import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Unarchive = (props: SvgIconProps) => {
  return (
    <SvgIcon color="disabled" {...props}>
      <g clip-path="url(#clip0_2715_8450)">
        <path
          d="M19.3333 21.3334H4.66665V14.6667H3.33331V21.3334C3.33331 21.687 3.47379 22.0261 3.72384 22.2762C3.97389 22.5262 4.31302 22.6667 4.66665 22.6667H19.3333C19.6869 22.6667 20.0261 22.5262 20.2761 22.2762C20.5262 22.0261 20.6666 21.687 20.6666 21.3334V14.6667H19.3333V21.3334Z"
          fill="inherit"
        />
        <path
          d="M9.33331 16C9.33331 16.1768 9.40355 16.3464 9.52858 16.4714C9.6536 16.5964 9.82317 16.6666 9.99998 16.6666H14C14.1768 16.6666 14.3464 16.5964 14.4714 16.4714C14.5964 16.3464 14.6666 16.1768 14.6666 16C14.6666 15.8232 14.5964 15.6536 14.4714 15.5286C14.3464 15.4036 14.1768 15.3333 14 15.3333H9.99998C9.82317 15.3333 9.6536 15.4036 9.52858 15.5286C9.40355 15.6536 9.33331 15.8232 9.33331 16Z"
          fill="inherit"
        />
        <path
          d="M10 12H4.00002V9.33333H10V8H3.66669C3.40147 8 3.14712 8.10536 2.95958 8.29289C2.77204 8.48043 2.66669 8.73478 2.66669 9V13.3333H10.52C10.1882 12.9682 10.003 12.4934 10 12Z"
          fill="inherit"
        />
        <path
          d="M20.3333 8H14V9.33333H20V12H14C13.997 12.4934 13.8118 12.9682 13.48 13.3333H21.3333V9C21.3333 8.73478 21.228 8.48043 21.0404 8.29289C20.8529 8.10536 20.5985 8 20.3333 8Z"
          fill="inherit"
        />
        <path
          d="M8.66664 6.36665L11.3333 3.73331V12C11.3333 12.1768 11.4035 12.3464 11.5286 12.4714C11.6536 12.5964 11.8232 12.6666 12 12.6666C12.1768 12.6666 12.3464 12.5964 12.4714 12.4714C12.5964 12.3464 12.6666 12.1768 12.6666 12V3.73331L15.3333 6.36665C15.3955 6.42837 15.4692 6.47724 15.5502 6.51048C15.6313 6.54371 15.7181 6.56066 15.8057 6.56035C15.8933 6.56004 15.9799 6.54248 16.0607 6.50867C16.1416 6.47487 16.2149 6.42547 16.2766 6.36331C16.3384 6.30115 16.3872 6.22745 16.4205 6.1464C16.4537 6.06535 16.4707 5.97855 16.4703 5.89096C16.47 5.80336 16.4525 5.71668 16.4187 5.63587C16.3849 5.55506 16.3355 5.4817 16.2733 5.41998L12 1.18665L7.73997 5.41998C7.63673 5.54703 7.58385 5.70764 7.59141 5.87117C7.59897 6.03471 7.66644 6.18975 7.78096 6.30674C7.89548 6.42373 8.04904 6.4945 8.21238 6.50554C8.37572 6.51659 8.53741 6.46715 8.66664 6.36665V6.36665Z"
          fill="inherit"
        />
      </g>
      <defs>
        <clipPath id="clip0_2715_8450">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default Unarchive
