import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import MembershipSettingsView from '../../../memberships/views/MembershipSettingsView'

import { unwrapResult } from '@reduxjs/toolkit'
import { useAsync } from '../../../hooks/use-async'
import { useAppDispatch } from '../../../store'
import { FetchMembershipById } from '../../../memberships/store/actions'
import { FetchUserOptions } from '../../../options/store/actions'
import { FetchMembershipPermissions } from '../../../permissions/store/actions'
import { useMembership } from '../../../memberships/hooks/use-membership'
import { useAuthUserMembership } from '../../../memberships/hooks/use-auth-user-membership'
import { useAppContext } from '../../../hooks/use-app-context'

const useLoader = (params: RouteParams) => {
  const dispatch = useAppDispatch()
  const { canUpdateAnyMembershipPermissions } = useAuthUserMembership()

  const loader = React.useCallback(async () => {
    dispatch({ type: 'load_membership_settings_route' })
    const promises = [dispatch(FetchMembershipById(params.membershipId)), dispatch(FetchUserOptions())] as any[]
    if (canUpdateAnyMembershipPermissions) {
      promises.push(dispatch(FetchMembershipPermissions(params.membershipId)))
    }
    const dispatchResults = await Promise.all(promises)
    dispatchResults.forEach(unwrapResult)
  }, [dispatch, params.membershipId, canUpdateAnyMembershipPermissions])

  return useAsync(loader)
}

const MembershipSettingsRoute = () => {
  const { appContext } = useAppContext()
  const membershipId = appContext.subContext?.id as string
  const { error, ...status } = useLoader({ membershipId })
  const { membership } = useMembership(membershipId)

  if (status.isError()) {
    return <p>{error}</p>
  }

  if (status.isSuccess() && membership) {
    return <MembershipSettingsView membershipId={membershipId} />
  }

  return <CircularProgress />
}

type RouteParams = { membershipId: string }

export default MembershipSettingsRoute
