import Paper from '@material-ui/core/Paper'
import AppLanguageSwitch from '../components/LanguageSwitch'
import Stack from './Stack'
import { Redirect, useLocation } from 'react-router-dom'
import { useAppSelector } from '../store'
import { getAuthState } from '../users/store/selectors'
import { paths } from '../paths'
import { useUrlWithContext } from '../hooks/use-url-with-context'

export function withPublicRouteLayout(Component: React.ComponentType) {
  const WithLayout = () => {
    const location = useLocation()
    const { isAuthenticated, user } = useAppSelector(getAuthState)
    const { createPathWithGivenContext } = useUrlWithContext()

    if (isAuthenticated) {
      const from = (location.state as { from?: Location })?.from || null
      const redirectTo = from
        ? from.pathname + from.search
        : createPathWithGivenContext({ path: paths.home(), mainContextId: user?.id })
      return <Redirect to={redirectTo} />
    }

    return (
      <Paper variant="outlined" style={{ maxWidth: 650, padding: 40, margin: '80px auto' }}>
        <Stack>
          <AppLanguageSwitch />
          <Component />
        </Stack>
      </Paper>
    )
  }
  return WithLayout
}
