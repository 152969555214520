import store, { AppStore } from './store'
import { Storage } from './utils/local-storage'
import { REFRESH_TOKEN } from './constants'
import { SetRefreshToken } from './users/store'

const refreshTokenStore = new Storage(REFRESH_TOKEN)

const syncRefreshTokenInStateWithLocalStorage = ({
  dispatch,
  getState,
}: AppStore): void => {
  const refreshTokenInState = getState().auth.refreshToken
  const refreshTokenInStorage = refreshTokenStore.getValue()
  if (refreshTokenInState !== refreshTokenInStorage) {
    dispatch(
      SetRefreshToken({
        isAuthenticated: refreshTokenInStorage != null,
        refreshToken: refreshTokenInStorage,
      })
    )
    if (!refreshTokenInStorage) window.location.reload()
  }
}

const handleTokenStorage = (e: StorageEvent) =>
  syncRefreshTokenInStateWithLocalStorage(store)

export function registerGlobalEvents() {
  /** for syncing refresh token across tabs */
  window.addEventListener('storage', handleTokenStorage)
}

export function unregisterGlobalEvents() {
  /** for syncing refresh token across tabs */
  window.removeEventListener('storage', handleTokenStorage)
}
