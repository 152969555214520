import OrganisationCustomerDefaultContact from './OrganisationCustomerDefaultContact'
import OrganisationCustomerDetailsPanel from './OrganisationCustomerDetailsPanel'
import OrganisationCustomerInfoCard from './OrganisationCustomerInfoCard'

import { Button, Grid, makeStyles, Theme } from '@material-ui/core'
import { ArrowLeft, Plus } from 'react-feather'
import { Link } from 'react-router-dom'
import { useI18n, useRouter } from '../../hooks'
import { paths } from '../../paths'
import { useCustomer } from '../hooks/use-customer'
import { IOrgCustomerNormalized } from '../interfaces/customer-normalized'
import { StringMap } from '../../types/common'
import { useUrlWithContext } from '../../hooks/use-url-with-context'

const OrgCustomerHome = ({ customer }: OrgCustomerHomeProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const router = useRouter()
  const created = (router.query as { created: string }).created

  const { onChangeStatus, onAddContact } = useCustomer(customer.id)
  const { createPathWithContext } = useUrlWithContext()

  const onClickBackToCustomers = () => {
    const path = createPathWithContext(paths.orgCustomers(), { withSubContext: false })
    router.push(path)
  }

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        component="header"
        data-test="page-header"
      >
        <Grid item>
          <Button variant="text" startIcon={<ArrowLeft size={16} />} onClick={onClickBackToCustomers}>
            {translations.allCustomersButtonLabel}
          </Button>
        </Grid>
        {created === 'now' && (
          <Grid item>
            <Button
              size="small"
              to={createPathWithContext(paths.orgNewCustomer(), { withSubContext: false })}
              color="primary"
              component={Link}
              variant="contained"
              endIcon={<Plus height={16} />}
              data-test="add-another-customer-button"
            >
              {translations.addAnotherCustomerButtonLabel}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          <OrganisationCustomerInfoCard customer={customer as IOrgCustomerNormalized} onChangeStatus={onChangeStatus} />
          <OrganisationCustomerDefaultContact customer={customer as IOrgCustomerNormalized} />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <OrganisationCustomerDetailsPanel
            customer={customer}
            onAddContact={onAddContact}
            canManageCustomer={customer.canUpdate}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('customer')
  const translations = (t?.customerHomePage || {}) as StringMap

  const {
    allCustomersButtonLabel = defaults.allCustomersButtonLabel,
    addAnotherCustomerButtonLabel = defaults.addAnotherCustomerButtonLabel,
  } = translations

  return {
    allCustomersButtonLabel,
    addAnotherCustomerButtonLabel,
  }
}

const defaultTranslations = {
  allCustomersButtonLabel: 'All customers',
  addAnotherCustomerButtonLabel: 'Add another customer',
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 'calc(100% - 49px)',
    width: 1440,
    margin: 'auto',
  },
}))

type Translations = typeof defaultTranslations
type OrgCustomerHomeProps = { customer: IOrgCustomerNormalized }
export default OrgCustomerHome
