/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const makeTodoApiEndpoints = (todoId = ':todoId') => {
  const endpoints = {
    todos: () => `/todos`,
    orgTodos: (orgId = ':orgId') => `/orgs/${orgId}/todos`,
    todoItem: (id = '') => `/todos/${id || todoId}`,
    orgRelation: (id = '') => `${endpoints.todoItem(id)}/org`,
    projectRelation: (id = '') => `${endpoints.todoItem(id)}/project`,
    taskRelation: (id = '') => `${endpoints.todoItem(id)}/task`,
    responsibleRelation: (id = '') => `${endpoints.todoItem(id)}/responsibles`,
    workspacesRelation: (id = '') => `${endpoints.todoItem(id)}/workspaces`,
    customersRelation: (id = '') => `${endpoints.todoItem(id)}/customers`,
    todoStatus: (id = '') => `${endpoints.todoItem(id)}/status`,
  }
  return endpoints
}
