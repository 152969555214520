import { RouteComponentProps } from 'react-router-dom'
import { useProject } from '../../../projects/hooks/use-project'
import { useGetProjectPermissionsQuery } from '../../../projects/api'
import { CircularProgress } from '@material-ui/core'
import { useGetOrgMembershipsQuery } from '../../../memberships/api'
import ProjectSettings from '../../../projects/components/ProjectSettings'
import { paths } from '../../../paths'
import { useI18n } from '../../../hooks'
import { StringMap } from '../../../types/common'
import ErrorList from '../../../components/ErrorList'

const queryOptions = { refetchOnMountOrArgChange: true } as const

const ProjectSettingsRoute = ({ match, history }: RouteComponentProps<RouteParams>) => {
  const translations = useTranslations()
  const { projectId } = match.params
  const { project, isLoading: isLoadingProject, isError: isErrorProject } = useProject(projectId, queryOptions)
  const {
    data: projectPermissions = [],
    isLoading: isLoadingPermissions,
    isError: isErrorPermissions,
  } = useGetProjectPermissionsQuery(projectId, { ...queryOptions, skip: !project || project.isUserProject })
  const {
    data: memberships = [],
    isLoading: isLoadingOrgMemberships,
    isError: isErrorOrgMemberships,
  } = useGetOrgMembershipsQuery(project?.maintainerId || '', {
    ...queryOptions,
    skip: !project || project.isUserProject,
  })

  if (isLoadingProject || isLoadingPermissions || isLoadingOrgMemberships) return <CircularProgress />
  if (isErrorProject) return <ErrorList errors={[translations.fetchErrorProject]} />
  if (!project) return <ErrorList errors={[translations.projectNotFoundError]} />
  if (isErrorOrgMemberships) return <ErrorList errors={[translations.fetchErrorMemberships]} />
  if (isErrorPermissions) return <ErrorList errors={[translations.fetchErrorPermissions]} />
  const projectBasic = `${paths.projectBasic(projectId)}${history.location.search}`

  return (
    <ProjectSettings
      project={project}
      projectPermissions={projectPermissions}
      memberships={memberships}
      navigateToProject={() => history.push(projectBasic)}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translations')
  const translations = t || ({} as StringMap)

  const {
    projectNotFoundError = defaults.projectNotFoundError,
    fetchErrorProject = defaults.fetchErrorProject,
    fetchErrorMemberships = defaults.fetchErrorMemberships,
    fetchErrorPermissions = defaults.fetchErrorPermissions,
  } = translations

  return {
    projectNotFoundError,
    fetchErrorProject,
    fetchErrorMemberships,
    fetchErrorPermissions,
  }
}
const defaultTranslations = {
  projectNotFoundError: 'Project not found',
  fetchErrorProject: 'Failed to fetch project',
  fetchErrorPermissions: 'Failed to fetch permissions',
  fetchErrorMemberships: 'Failed to fetch memberships',
}
type Translations = typeof defaultTranslations
type RouteParams = { projectId: string }
export default ProjectSettingsRoute
