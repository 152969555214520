import * as actions from './actions'
import * as orgActions from '../../organisations/store/actions'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteContactCase,
  setContactCase,
  setSupplierCase,
  setSuppliersCase,
  deleteOrganisationCase,
} from './case-reducers'
import { ISupplierNormalized } from '../interfaces/supplier-normalized'

const initialState: SuppliersState = {
  byIds: {},
  allIds: [],
}

const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    SetSupplier: setSupplierCase,
    SetSuppliers: setSuppliersCase,
  },
  extraReducers({ addCase }) {
    addCase(actions.FetchSupplierById.fulfilled, setSupplierCase)
    addCase(actions.FetchSuppliers.fulfilled, setSuppliersCase)
    addCase(actions.CreateSupplier.fulfilled, setSupplierCase)
    addCase(actions.UpdateSupplierInfo.fulfilled, setSupplierCase)
    addCase(actions.UpdateSupplierStatus.fulfilled, setSupplierCase)
    addCase(actions.ChangeSupplierDefaultContact.fulfilled, setSupplierCase)
    addCase(actions.AddSupplierContact.fulfilled, setContactCase)
    addCase(actions.UpdateSupplierContact.fulfilled, setContactCase)
    addCase(actions.DeleteSupplierContact.fulfilled, deleteContactCase)
    addCase(orgActions.DeleteOrganisation.fulfilled, deleteOrganisationCase)
  },
})

export const { SetSupplier, SetSuppliers } = suppliersSlice.actions
export default suppliersSlice.reducer

export type SuppliersState = {
  byIds: { [id: string]: ISupplierNormalized }
  allIds: string[]
}
