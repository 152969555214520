import React from 'react'
import { TimeZoneType } from '../../constants/timezones'
import { IOptionNormalized } from '../../options/interfaces/options-normalized'
import { makeLayout } from './layout'
import {
  createTreegrid,
  destroyTreegrid,
  getSummaryTextWithRowCount,
  syncDataFromServerToGrid,
} from '../utils/tree-grid'
import { useTranslations } from '../hooks/use-translations'
import {
  ActivityColumnName,
  ActivityRow,
  CalendarEventActivityRow,
  ProjectActivityRow,
  TaskActivityRow,
  TodoActivityRow,
} from './types'
import {
  getShouldRemoveEventRowBasedOnContext,
  getShouldRemoveProjectRowBasedOnContext,
  getShouldRemoveTaskRowBasedOnContext,
  getShouldRemoveTodoRowBasedOnContext,
  getStateFromStateIcon,
  mapTranslationsToStatus,
} from '../utils'
import { DateTimeService } from '../../services/date-time-service'
import { useTreeGridFilters } from '../hooks/use-treegrid-filters'
import { useUrlWithContext } from '../../hooks/use-url-with-context'
import { useRouter } from '../../hooks'
import { makeProjectActivityRow } from './rows/project-activity-row'
import { makeTodoActivityRow } from './rows/todo-activity-row'
import { makeTaskActivityRow } from './rows/task-activity-row'
import { makeCalendarEventActivityRow } from './rows/calendar-event-activity-row'
import { useAppContext } from '../../hooks/use-app-context'
import { useContextOptions } from '../../context-options/hooks/use-context-options'
import { useAuthUserMembership } from '../../memberships/hooks/use-auth-user-membership'
import { paths } from '../../paths'
import { CalendarEventViewModel } from '../../calendar-events/api/calendar-event'
import { ProjectViewModel } from '../../projects/api/project'
import { TodoViewModel } from '../../todos/api/todo'
import { TaskViewModel } from '../../tasks/api/task'
import { useCalendarEventMutations } from '../../calendar-events/hooks/use-calendar-event-mutations'
import { useProjectMutations } from '../../projects/hooks/use-project-mutations'
import { useTodoMutations } from '../../todos/hooks/use-todo-mutations'
import { useTaskMutations } from '../../tasks/hooks/use-task-mutations'
import { useUpdateTaskStatus } from '../../tasks/hooks/use-update-task-status'
import { useUpdateProjectStatus } from '../../projects/hooks/use-update-project-status'

const id = '__treegrid_activities_list__'

const useActivitiesList = (props: ActivitiesListProps) => {
  const { calendarEvents, projects, tasks, todos, options } = props
  const { dateFormat, dateSeparator, firstDayOfWeek, timeZone, gridInfo } = props
  const { initCalendarEventUpdate } = useCalendarEventMutations()
  const { initProjectUpdate } = useProjectMutations()
  const { initTaskUpdate } = useTaskMutations()
  const { initTodoUpdate } = useTodoMutations()
  const { updateTaskStatus, updatedTasks } = useUpdateTaskStatus()
  const { updateProjectStatus, updatedProject, updatedTasks: updatedProjectTasks } = useUpdateProjectStatus()
  const { showFilters, toggleFilters } = useTreeGridFilters({ gridId: id })
  const [navigateTo, setNavigateTo] = React.useState<string | null>(null)
  const { createPathWithContext } = useUrlWithContext()
  const { appContext } = useAppContext()
  const { userMembershipContextOptions } = useContextOptions()
  const { history } = useRouter()
  const translations = useTranslations()
  const authUserMembership = useAuthUserMembership()
  const canCreateProjects = Boolean(appContext.mainContext?.type === 'user' || authUserMembership?.canCreateProjects)

  translations.toolbarActivitiesListSummaryText = getSummaryTextWithRowCount(
    translations.toolbarActivitiesListSummaryText,
    props.todos.length + props.projects.length
  )
  const projectGroupHeader = `<h4 class="group-header">${translations.activitiesListProjectGroupHeader}</h4>`
  const taskTodoAndCalendarEventGroupHeader = `<h4 class="group-header">${translations.activitiesListTaskTodoAndCalendarEventGroupHeader}</h4>`
  const layout = makeLayout({
    id,
    isFilterRowVisible: showFilters,
    canCreateProjects,
    dateFormat,
    dateSeparator,
    firstDayOfWeek,
    gridInfo,
    translations,
    options,
  })

  const memoizedRows = React.useMemo(() => {
    const calendarEventRows = calendarEvents.map((calendarEvent) => {
      return makeCalendarEventActivityRow({ calendarEvent, dateFormat, timeZone, options })
    })
    const projectRows = projects.map((project) =>
      makeProjectActivityRow({ project, dateFormat, timeZone, options, translations })
    )
    const todoRows = todos.map((todo) => makeTodoActivityRow({ todo, dateFormat, timeZone, options }))
    const taskRows = tasks.map((task) => makeTaskActivityRow({ task, dateFormat, timeZone, options, translations }))
    const shouldGroupRows =
      projectRows.length > 0 && (todoRows.length > 0 || taskRows.length > 0 || calendarEventRows.length > 0)

    if (shouldGroupRows) {
      const projectRootRow = {
        Spanned: 1,
        openSpan: layout.Cols.length,
        open: projectGroupHeader,
        openType: 'Html',
        Items: projectRows,
      }

      const taskTodoAndCalendarEventRootRow = {
        Spanned: 1,
        openSpan: layout.Cols.length,
        open: taskTodoAndCalendarEventGroupHeader,
        openType: 'Html',
        Items: [...taskRows, ...todoRows, ...calendarEventRows],
      }
      return [projectRootRow, taskTodoAndCalendarEventRootRow]
    } else {
      return [...projectRows, ...taskRows, ...todoRows, ...calendarEventRows]
    }
  }, [
    calendarEvents,
    dateFormat,
    options,
    projects,
    tasks,
    timeZone,
    todos,
    layout.Cols.length,
    projectGroupHeader,
    taskTodoAndCalendarEventGroupHeader,
    translations,
  ])

  window.Grids.OnValueChanged = function (
    grid: TGrid,
    row: TRow & ActivityRow,
    column: ActivityColumnName,
    newValue,
    oldValue
  ) {
    // NOTE: Returning early from this function will cause unexpected behavior in the treegrid
    // @ts-ignore
    const isEditAllowed = row[column + 'CanEdit'] === 1
    const hasValueChanged = newValue !== oldValue
    const shouldDispatchUpdateRequest = isEditAllowed && hasValueChanged
    if (shouldDispatchUpdateRequest) {
      const value = adaptValue({ row, column, timeZone: props.timeZone, value: newValue, oldValue })
      if (isCalendarEventRow(row)) {
        const field = activityColumnToCalendarEventFieldMap[column]
        initCalendarEventUpdate(row.id, { field, value }).then((calendarEvent) => {
          if (!calendarEvent || !grid) return
          const calendarEventRow = makeCalendarEventActivityRow({ calendarEvent, dateFormat, timeZone, options })
          const shouldRemoveRow = getShouldRemoveEventRowBasedOnContext(
            calendarEvent,
            appContext,
            userMembershipContextOptions
          )
          if (shouldRemoveRow) {
            grid.AnimateRow(row, 'Delete', undefined, () => grid.RemoveRow(row))
          } else {
            syncDataFromServerToGrid(grid, [calendarEventRow])
          }
        })
      }
      if (isTodoRow(row)) {
        const field = activityColumnToTodoFieldMap[column]
        initTodoUpdate(row.id, { field, value }).then((responseData) => {
          if (!responseData || !grid) return
          const todo = 'todo' in responseData ? responseData.todo : responseData
          const shouldRemoveRow =
            getShouldRemoveTodoRowBasedOnContext(todo, appContext, userMembershipContextOptions) || todo.completed
          if (shouldRemoveRow) {
            grid.AnimateRow(row, 'Delete', undefined, () => grid.RemoveRow(row))
          } else {
            const todoRow = makeTodoActivityRow({ todo, dateFormat, timeZone, options })
            syncDataFromServerToGrid(grid, [todoRow])
          }
        })
      }
      if (isProjectRow(row)) {
        const field = activityColumnToProjectFieldMap[column]

        if (field === 'status' || field === 'actualStartDate') {
          let newValue = value
          if (field === 'status') newValue = mapTranslationsToStatus(translations)[value]
          updateProjectStatus(row.id, { [field]: newValue })
        } else {
          initProjectUpdate(row.id, { field, value }).then((responseData) => {
            if (!responseData || !grid) return

            // First take care of updating or removing the project row
            const project = 'project' in responseData ? responseData.project : responseData
            const shouldRemoveProjectRowByContext = getShouldRemoveProjectRowBasedOnContext(
              project,
              appContext,
              userMembershipContextOptions
            )
            const shouldRemoveProjectRowByStatus = !(project.isNotStarted || project.isInProgress)
            const shouldRemoveProjectRow = shouldRemoveProjectRowByContext || shouldRemoveProjectRowByStatus

            if (shouldRemoveProjectRow) {
              grid.AnimateRow(row, 'Delete', undefined, () => grid.RemoveRow(row))
            } else {
              const projectRow = makeProjectActivityRow({ project, dateFormat, timeZone, options, translations })
              syncDataFromServerToGrid(grid, [projectRow])
            }

            // Then take care of updating or removing the task rows
            const tasks = 'project' in responseData ? responseData.tasks : []
            const taskRowsToUpdateInGrid: TaskActivityRow[] = []
            const tasksToRemoveFromGrid: TaskViewModel[] = []

            tasks.forEach((task) => {
              const shouldRemoveRow = getShouldRemoveTaskRowBasedOnContext(
                task,
                appContext,
                userMembershipContextOptions
              )
              if (shouldRemoveRow) tasksToRemoveFromGrid.push(task)
              else
                taskRowsToUpdateInGrid.push(makeTaskActivityRow({ task, dateFormat, timeZone, options, translations }))
            })
            syncDataFromServerToGrid(grid, taskRowsToUpdateInGrid)
            tasksToRemoveFromGrid.forEach((task) => {
              const row = grid.GetRowById(task.id)
              if (row) grid.AnimateRow(row, 'Delete', undefined, () => grid.RemoveRow(row))
            })
          })
        }
      }
      if (isTaskRow(row)) {
        const field = activityColumnToTaskFieldMap[column]
        if (field === 'customers') return
        if (field === 'status' || field === 'actualStartDate') {
          let newValue = value
          if (field === 'status') newValue = mapTranslationsToStatus(translations)[value]
          updateTaskStatus(row.id, { [field]: newValue })
          return newValue
        } else {
          initTaskUpdate(row.id, { field, value }).then((responseData) => {
            if (!responseData || !grid) return
            const tasks = 'tasks' in responseData ? responseData.tasks : [responseData]
            const taskRowsToUpdateInGrid: TaskActivityRow[] = []
            const tasksToRemoveFromGrid: TaskViewModel[] = []
            tasks.forEach((task) => {
              const shouldRemoveRow = getShouldRemoveTaskRowBasedOnContext(
                task,
                appContext,
                userMembershipContextOptions
              )
              if (shouldRemoveRow) tasksToRemoveFromGrid.push(task)
              else
                taskRowsToUpdateInGrid.push(makeTaskActivityRow({ task, dateFormat, timeZone, options, translations }))
            })
            syncDataFromServerToGrid(grid, taskRowsToUpdateInGrid)
            tasksToRemoveFromGrid.forEach((task) => {
              const row = grid.GetRowById(task.id)
              if (row) grid.AnimateRow(row, 'Delete', undefined, () => grid.RemoveRow(row))
            })
          })
        }
      }
    }
    return newValue
  }

  // @ts-ignore
  window.Grids['OnFilterToggle'] = function () {
    toggleFilters()
  }

  window.Grids.OnClick = function (grid: TGrid, row: TRow, col: string) {
    if (col === 'AddProject') setNavigateTo(paths.newProject())
    if (col === 'open') {
      if (isProjectRow(row as unknown as ActivityRow)) setNavigateTo(paths.projectBasic(row.id))
      if (isTaskRow(row as unknown as ActivityRow)) setNavigateTo(paths.taskBasic(row.id))
    }
  }

  window.Grids.OnGetSortValue = function (grid, row, column, value) {
    if (column === 'plannedStartDate' || column === 'plannedEndDate') {
      const shouldSortToBottom = !value
      if (shouldSortToBottom) return Number.MAX_SAFE_INTEGER
    }
    return value
  }

  // @ts-ignore
  window.Grids.onClickTitleLink = function (link) {
    const linkWithContext = createPathWithContext(link)
    history.push(linkWithContext)
  }

  React.useEffect(() => {
    if (navigateTo) history.push(createPathWithContext(navigateTo))
  }, [navigateTo, history, createPathWithContext])

  React.useEffect(() => {
    const grid = window.Grids[id]
    if (grid) {
      const taskRowsToUpdate: TaskActivityRow[] = []
      updatedTasks.forEach((task) => {
        if (task.isNotStarted || task.isInProgress) {
          const row = grid.GetRowById(task.id)
          if (row) taskRowsToUpdate.push(makeTaskActivityRow({ task, dateFormat, timeZone, options, translations }))
        } else {
          const row = grid.GetRowById(task.id)
          if (row) grid.AnimateRow(row, 'Delete', undefined, () => grid.RemoveRow(row))
        }
      })
      syncDataFromServerToGrid(grid, taskRowsToUpdate)
    }
  }, [updatedTasks, dateFormat, timeZone, options, translations])

  React.useEffect(() => {
    const grid = window.Grids[id]
    if (grid && updatedProject) {
      const shouldUpdateRow = updatedProject.isNotStarted || updatedProject.isInProgress
      if (shouldUpdateRow) {
        const projectRow = makeProjectActivityRow({
          project: updatedProject,
          dateFormat,
          timeZone,
          options,
          translations,
        })
        syncDataFromServerToGrid(grid, [projectRow])
      } else {
        const row = grid.GetRowById(updatedProject.id)
        if (row) grid.AnimateRow(row, 'Delete', undefined, () => grid.RemoveRow(row))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProject])

  React.useEffect(() => {
    const grid = window.Grids[id]
    if (grid) {
      const taskRowsToUpdate: TaskActivityRow[] = []
      updatedProjectTasks.forEach((task) => {
        if (task.isNotStarted || task.isInProgress) {
          const row = grid.GetRowById(task.id)
          if (row) taskRowsToUpdate.push(makeTaskActivityRow({ task, dateFormat, timeZone, options, translations }))
        } else {
          const row = grid.GetRowById(task.id)
          if (row) grid.AnimateRow(row, 'Delete', undefined, () => grid.RemoveRow(row))
        }
      })
      syncDataFromServerToGrid(grid, taskRowsToUpdate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProjectTasks])

  return { layout, data: memoizedRows }
}

const ActivitiesList = (props: ActivitiesListProps) => {
  const { layout, data } = useActivitiesList(props)

  React.useEffect(() => {
    createTreegrid({ id, layout, data })
    return () => destroyTreegrid(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div id={id} style={{ height: 'calc(100vh - 128px)' }} data-test="activities-list-container"></div>
}

export default ActivitiesList

function adaptValue({
  row,
  column,
  value,
  timeZone,
  oldValue,
}: {
  row: ActivityRow
  column: string
  timeZone: TimeZoneType
  value: any
  oldValue: any
}) {
  if (isDateColumn(column)) return adaptDateValue({ value, timeZone })
  if (column === 'state') return getStateFromStateIcon(value)
  if (isSelectionColumn(column)) {
    if (isProjectRow(row) || isTaskRow(row)) {
      const oldArray: string[] = oldValue.split(';').filter(Boolean)
      const newArray: string[] = value.split(';').filter(Boolean)
      const add = newArray.filter((x) => !oldArray.includes(x))
      const remove = oldArray.filter((x) => !newArray.includes(x))
      return { add, remove }
    } else {
      return adaptStringToArray(value)
    }
  }
  return value
}

function isDateColumn(columnName: string) {
  return columnName === 'actualStartDate' || columnName === 'plannedEndDate' || columnName === 'plannedStartDate'
}

function isSelectionColumn(columnName: string) {
  return (
    columnName === 'responsible' ||
    columnName === 'participants' ||
    columnName === 'suppliers' ||
    columnName === 'workspaces' ||
    columnName === 'customers'
  )
}

function adaptDateValue({ value, timeZone }: { value: any; timeZone: TimeZoneType }): string | null {
  if (value) {
    const dateTimeService = new DateTimeService({ timeZone })
    return dateTimeService.addTimezoneOffset(new Date(value), 'UTC').toISOString()
  }
  return null
}

function adaptStringToArray(value: any): string[] {
  return value.split(';').filter(Boolean)
}

function isTodoRow(row: ActivityRow): row is TodoActivityRow {
  return row.type === 'todo'
}

function isProjectRow(row: ActivityRow): row is ProjectActivityRow {
  return row.type === 'project'
}

function isTaskRow(row: ActivityRow): row is TodoActivityRow {
  return row.type === 'task'
}

function isCalendarEventRow(row: ActivityRow): row is CalendarEventActivityRow {
  return row.type === 'calendarEvent'
}

const activityColumnToTodoFieldMap: Record<string, TodoField> = {
  customers: 'customers',
  title: 'title',
  description: 'description',
  responsible: 'responsible',
  workspaces: 'workspaces',
  plannedEndDate: 'dueDate',
  status: 'completed',
}

const activityColumnToProjectFieldMap: Record<string, ProjectField> = {
  customers: 'customers',
  activityNumber: 'projectNumber',
  title: 'title',
  description: 'description',
  responsible: 'managers',
  participants: 'participants',
  workspaces: 'workspaces',
  suppliers: 'suppliers',
  plannedStartDate: 'plannedStartDate',
  plannedEndDate: 'plannedEndDate',
  actualStartDate: 'actualStartDate',
  status: 'status',
  state: 'state',
  statusDescription: 'statusDescription',
}

const activityColumnToTaskFieldMap: Record<string, TaskField> = {
  customers: 'customers',
  activityNumber: 'taskNumber',
  title: 'title',
  description: 'description',
  responsible: 'managers',
  participants: 'participants',
  workspaces: 'workspaces',
  suppliers: 'suppliers',
  plannedStartDate: 'plannedStartDate',
  plannedEndDate: 'plannedEndDate',
  actualStartDate: 'actualStartDate',
  status: 'status',
  state: 'state',
  statusDescription: 'statusDescription',
}

const activityColumnToCalendarEventFieldMap: Record<string, CalendarEventField> = {
  customers: 'customers',
  title: 'title',
  description: 'description',
  participants: 'participants',
  workspaces: 'workspaces',
  suppliers: 'suppliers',
  plannedStartDate: 'startDate',
  plannedEndDate: 'endDate',
}

type CalendarEventField =
  | 'customers'
  | 'title'
  | 'description'
  | 'participants'
  | 'workspaces'
  | 'suppliers'
  | 'startDate'
  | 'endDate'

type ProjectField =
  | 'customers'
  | 'projectNumber'
  | 'title'
  | 'description'
  | 'managers'
  | 'participants'
  | 'workspaces'
  | 'suppliers'
  | 'plannedStartDate'
  | 'plannedEndDate'
  | 'actualStartDate'
  | 'status'
  | 'state'
  | 'statusDescription'

type TodoField = 'customers' | 'title' | 'description' | 'responsible' | 'workspaces' | 'dueDate' | 'completed'

type TaskField =
  | 'customers'
  | 'taskNumber'
  | 'title'
  | 'description'
  | 'managers'
  | 'participants'
  | 'workspaces'
  | 'suppliers'
  | 'plannedStartDate'
  | 'plannedEndDate'
  | 'actualStartDate'
  | 'status'
  | 'state'
  | 'statusDescription'

type ActivitiesListProps = {
  calendarEvents: CalendarEventViewModel[]
  projects: ProjectViewModel[]
  todos: TodoViewModel[]
  tasks: TaskViewModel[]
  dateFormat: string
  dateSeparator: string
  firstDayOfWeek: DayOfWeek
  gridInfo: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
}
