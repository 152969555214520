import { keyBy } from 'lodash'
import { SupplierStatusEnum } from '../../types/supplier'
import { ISupplierResponse, SupplierItemPermissions } from './supplier-response'

export const normalizeSupplier = (supplier: ISupplierResponse): ISupplierNormalized => {
  const isActive = supplier.status === SupplierStatusEnum.active
  const initials = supplier.name[0].toUpperCase()
  return { ...supplier, initials, contacts: keyBy(supplier.contacts, 'id'), isActive }
}

export type ISupplierNormalized = SupplierItemPermissions & {
  id: string
  createdAt: string
  updatedAt: string
  supplierNumber: number
  status: SupplierStatusEnum
  language: string
  orgId: string
  name: string
  initials: string
  businessId: string | null
  vatRegistrationNumber: string | null
  defaultContact: IContactNormalized | null
  contacts: { [id: string]: IContactNormalized }
  isActive: boolean
  homePage: string | null
}

export type IContactNormalized = {
  id: string
  createdAt: string
  updatedAt: string
  firstname: string
  lastname: string
  phone: string | null
  email: string | null
  jobTitle: string | null
  orgId: string
  customerId: string | null
  supplierId: string | null
}
