import api from '../../services/api-service'
import { AxiosRequestConfig } from 'axios'
import { makeContextOptionsApiRoutes } from '../utils/make-api-routes'
import { IContextOptionResponse } from '../interfaces/context-options-response'

const contextOptionsApiRoutes = makeContextOptionsApiRoutes()

export const fetchContextOptions = (
  orgId: string,
  config: AxiosRequestConfig = {}
) => {
  return api.get<IContextOptionResponse[]>(
    contextOptionsApiRoutes.contextOptions(orgId),
    config
  )
}
