import * as membershipApi from '../services/membership-api'
import * as permissionsApi from '../../permissions/services/permissions-api'
import * as actions from '../store/actions'
import * as permissionActions from '../../permissions/store/actions'
import { useAppDispatch } from '../../store'
import { unwrapResult } from '@reduxjs/toolkit'

export const useMembershipActions = () => {
  const dispatch = useAppDispatch()

  const addMembership = async (orgId: string, newMembership: membershipApi.NewMemberData) => {
    const thunkArg = { orgId, ...newMembership }
    const result = await dispatch(actions.AddMembership(thunkArg))
    return unwrapResult(result)
  }

  const updateInfo = async (membershipId: string, updateData: membershipApi.MembershipInfoUpdateData) => {
    const thunkArg = { membershipId, ...updateData }
    const result = await dispatch(actions.UpdateMembershipInfo(thunkArg))
    return unwrapResult(result)
  }

  const updateAssociations = async (membershipId: string, updateData: permissionsApi.MemberAssociationsUpdateData) => {
    const thunkArg = { membershipId, ...updateData }
    const result = await dispatch(permissionActions.UpdateMemberAssociations(thunkArg))
    return unwrapResult(result)
  }

  const updateAbilitiesInResources = async (
    membershipId: string,
    updateData: permissionsApi.MemberAbilitiesInResourcesUpdateData
  ) => {
    const thunkArg = { membershipId, ...updateData }
    const result = await dispatch(permissionActions.UpdateMemberAbilitiesInResources(thunkArg))
    return unwrapResult(result)
  }

  const sendInvitation = async (data: membershipApi.SendInvitationActionData) => {
    const result = await dispatch(actions.SendInvitation(data))
    return unwrapResult(result)
  }

  const cancelInvitation = async (membershipId: string) => {
    const result = await dispatch(actions.CancelInvitation(membershipId))
    return unwrapResult(result)
  }

  const copyPermissions = async (membershipId: string, copyPermissionsFrom: string) => {
    const result = await dispatch(permissionActions.CopyMembershipPermissions({ membershipId, copyPermissionsFrom }))
    return unwrapResult(result)
  }

  const updateAbilties = async (abiltiesUpdateData: permissionsApi.MembershipAbilitiesUpdateData) => {
    const result = await dispatch(permissionActions.UpdateMembershipAbilities(abiltiesUpdateData))
    return unwrapResult(result)
  }

  return {
    addMembership,
    updateInfo,
    updateAbilitiesInResources,
    updateAssociations,
    sendInvitation,
    cancelInvitation,
    copyPermissions,
    updateAbilties,
  }
}
