import { api } from '../../api'
import { makeOrganisationApiEndpoints } from './endpoints'
import { OrganisationViewModel } from './organisation'

const apiEndpoints = makeOrganisationApiEndpoints()

export const organisationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrganisationById: builder.query<OrganisationViewModel, string>({
      query: (id) => apiEndpoints.organisationItem(id),
    }),
  }),
})

export const { useGetOrganisationByIdQuery } = organisationApi
