import { Redirect } from 'react-router-dom'
import { useAppContext } from '../hooks/use-app-context'
import { useAuthUser } from '../users/hooks/use-auth-user'
import { useUrlWithContext } from '../hooks/use-url-with-context'
import { paths } from '../paths'

const RedirectToHome = () => {
  const { appContext } = useAppContext()
  const { mainContext } = appContext
  const { user } = useAuthUser()
  const { createPathWithContext } = useUrlWithContext()

  if (!user) return <p>Loading user...</p>
  if (user.isDeactivated) return <Redirect to={paths.deactivated()} />
  if (user.isWaitingVerification) return <Redirect to={paths.accountVerification()} />
  if (!mainContext) return <p>Main context is not detected, can't redirect</p>
  const to = createPathWithContext(paths.home(), { withSubContext: true })
  return <Redirect to={to} />
}

export default RedirectToHome
