import React from 'react'
import * as taskApi from '../api'
import { useTaskMutations } from './use-task-mutations'
import { TaskViewModel } from '../api/task'
import { useConfirmDialog } from '../../components/ConfirmDialog'
import { isCompleted, isNotStarted } from '../../utils/status'
import { useI18n } from '../../hooks/use-i18n'
import { StringMap } from '../../types/common'

export const useUpdateTaskStatus = () => {
  const translations = useTranslations()
  const taskActions = useTaskMutations()
  const [getTasks] = taskApi.useLazyGetTasksQuery()

  // This state is used inside treegrid to update treegrid rows when this list changes
  const [updatedTasks, setUpdatedTasks] = React.useState<TaskViewModel[]>([])

  const onConfirm = async (taskId: string, updateData: taskApi.TaskStatusUpdateData) => {
    const updateResult = await taskActions.updateStatus(taskId, { ...updateData, updateSubtasks: true })
    if (!updateResult) return
    setUpdatedTasks(updateResult.tasks)
  }
  const onCancel = async (taskId: string, updateData: taskApi.TaskStatusUpdateData) => {
    const updateResult = await taskActions.updateStatus(taskId, { ...updateData, updateSubtasks: false })
    if (!updateResult) return
    setUpdatedTasks(updateResult.tasks)
  }

  const notStartedText = createTranslatedDialogText(translations.dialogText, translations.notStarted)
  const completedText = createTranslatedDialogText(translations.dialogText, translations.completed)

  const { confirm: confirmChangeAllToCompleted } = useConfirmDialog({
    onConfirm,
    onCancel,
    primaryActionButtonLabel: translations.changeThisTaskAndSubtasksButtonLabel,
    secondaryActionButtonLabel: translations.changeThisTaskOnlyButtonLabel,
    text: completedText,
    title: translations.dialogTitle,
  })

  const { confirm: confirmChangeAllToNotStarted } = useConfirmDialog({
    onConfirm,
    onCancel,
    primaryActionButtonLabel: translations.changeThisTaskAndSubtasksButtonLabel,
    secondaryActionButtonLabel: translations.changeThisTaskOnlyButtonLabel,
    text: notStartedText,
    title: translations.dialogTitle,
  })

  const updateTaskStatus = async (taskId: string, updateData: taskApi.TaskStatusUpdateData) => {
    const { status, actualEndDate, actualStartDate } = updateData
    const hasChangedToCompleted = (status && isCompleted(status)) || Boolean(actualEndDate)
    const hasChangedToNotStarted = (status && isNotStarted(status)) || actualStartDate === ''
    const shouldFetchTasks = hasChangedToCompleted || hasChangedToNotStarted
    const tasks = shouldFetchTasks ? await getTasks({ task: taskId }).unwrap() : []

    if (hasChangedToCompleted && shouldConfirmChangeAllToCompleted(taskId, tasks)) {
      return confirmChangeAllToCompleted(taskId, updateData)
    } else if (hasChangedToNotStarted && shouldConfirmChangeAllToNotStarted(taskId, tasks)) {
      return confirmChangeAllToNotStarted(taskId, updateData)
    } else {
      const updateResult = await taskActions.updateStatus(taskId, updateData)
      if (!updateResult) return
      setUpdatedTasks(updateResult.tasks)
    }
  }

  return {
    updateTaskStatus,
    updatedTasks,
  }
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('task')
  const translations = (t?.taskStatusMenu || {}) as StringMap

  const {
    dialogText = defaults.dialogText,
    dialogTitle = defaults.dialogTitle,
    changeThisTaskAndSubtasksButtonLabel = defaults.changeThisTaskAndSubtasksButtonLabel,
    changeThisTaskOnlyButtonLabel = defaults.changeThisTaskOnlyButtonLabel,
    notStarted = defaults.notStarted,
    inProgress = defaults.inProgress,
    completed = defaults.completed,
    rejected = defaults.rejected,
  } = translations

  return {
    dialogText,
    dialogTitle,
    changeThisTaskAndSubtasksButtonLabel,
    changeThisTaskOnlyButtonLabel,
    notStarted,
    inProgress,
    completed,
    rejected,
  }
}

const defaultTranslations = {
  dialogText:
    'This task has subtasks whose current status is not {{status}}. Do you want to change the status of all subtasks to {{status}} ?',
  dialogTitle: 'Change status of its subtasks too?',
  changeThisTaskAndSubtasksButtonLabel: 'Yes, change this task and subtasks',
  changeThisTaskOnlyButtonLabel: 'No, change this task only',
  notStarted: 'Not started',
  inProgress: 'In progress',
  completed: 'Completed',
  rejected: 'Rejected',
}

const createTranslatedDialogText = (text: string, status: string) => {
  return text.replaceAll('{{status}}', status)
}

export const shouldConfirmChangeAllToCompleted = <Task extends { isCompleted: boolean; ancestors: string[] }>(
  taskId: string,
  tasks: Task[]
): boolean => {
  const descendants = tasks.filter((t) => t.ancestors.includes(taskId))
  const hasDescendants = descendants.length > 0
  const areAllDescendantsCompleted = descendants.every((t) => t.isCompleted)
  return hasDescendants && !areAllDescendantsCompleted
}

export const shouldConfirmChangeAllToNotStarted = <Task extends { ancestors: string[]; isNotStarted: boolean }>(
  taskId: string,
  tasks: Task[]
): boolean => {
  const descendants = tasks.filter((t) => t.ancestors.includes(taskId))
  const hasDescendants = descendants.length > 0
  const areAllDescendantsNotStarted = descendants.every((t) => t.isNotStarted)
  return hasDescendants && !areAllDescendantsNotStarted
}

type Translations = typeof defaultTranslations
