import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { makeStyles, Theme } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useI18n } from '../../../../hooks'
import { StringMap } from '../../../../types/common'
import { Plus } from 'react-feather'
import { paths } from '../../../../paths'
import { useAppContext } from '../../../../hooks/use-app-context'
import OrgSuppliersTable from '../../../../organisations/components/OrgSuppliersTable'
import { useAppDispatch } from '../../../../store'
import { useAsync } from '../../../../hooks/use-async'
import { unwrapResult } from '@reduxjs/toolkit'
import { FetchSuppliers } from '../../../../suppliers/store/actions'
import Progress from '../../../../components/Progress'
import { useAuthUserMembership } from '../../../../memberships/hooks/use-auth-user-membership'
import { useUrlWithContext } from '../../../../hooks/use-url-with-context'
import { FetchContextOptions } from '../../../../context-options/store/actions'

const useLoader = ({ orgId }: RouteParams) => {
  const dispatch = useAppDispatch()
  const loader = React.useCallback(async () => {
    dispatch({ type: `load_org_suppliers_route` })
    const dispatchResults = await Promise.all([dispatch(FetchSuppliers(orgId)), dispatch(FetchContextOptions())])
    dispatchResults.forEach(unwrapResult)
  }, [dispatch, orgId])
  return useAsync<void, any>(loader)
}

const SuppliersRoute = () => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const { appContext } = useAppContext()
  const { createPathWithContext } = useUrlWithContext()
  const { mainContext } = appContext
  const orgId = mainContext?.id as string
  const { error, isSuccess, isPending } = useLoader({ orgId })
  const { canCreateSuppliers } = useAuthUserMembership()
  if (!mainContext || mainContext.type === 'user') return null
  const createSupplierButtonHref = createPathWithContext(paths.orgNewSupplier(), {
    withSubContext: false,
  })

  return (
    <div className={classes.pageContainer}>
      <Grid container className={classes.header} justifyContent="space-between">
        <Grid item>
          <Typography variant="h6" component="header" data-test="page-header">
            <Box component="span" color="text.secondary">
              {`${mainContext.name} - `}
            </Box>
            {translations.suppliers}
          </Typography>
        </Grid>
        <Grid item>
          {canCreateSuppliers && (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={createSupplierButtonHref}
              endIcon={<Plus size={20} />}
            >
              {translations.addBtnLabel}
            </Button>
          )}
        </Grid>
      </Grid>
      {isPending() && <Progress label="" />}
      {isSuccess() && <OrgSuppliersTable orgId={mainContext.id} />}
      {error && <p>{error}</p>}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    padding: theme.spacing(0, 2),
    maxWidth: 1440,
    margin: 'auto',
  },
  header: {
    margin: theme.spacing(0.75, 0, 2, 0),
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('supplier')
  const translations = (t?.suppliersPage || {}) as StringMap

  const { suppliers = defaults.suppliers, addBtnLabel = defaults.addBtnLabel } = translations

  return {
    suppliers,
    addBtnLabel,
  }
}

const defaultTranslations = {
  suppliers: 'Suppliers',
  addBtnLabel: 'Add supplier',
}

type Translations = typeof defaultTranslations
type RouteParams = { orgId: string }

export default SuppliersRoute
