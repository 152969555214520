import OrgCustomerHome from '../../../customers/components/OrgCustomerHome'
import PersonCustomerHome from '../../../customers/components/PersonCustomerHome'
import ErrorFallback from '../../../components/ErrorFallback'
import NotFound from '../../../components/NotFound'

import { ErrorBoundary } from 'react-error-boundary'
import { useCustomer } from '../../../customers/hooks/use-customer'
import { IOrgCustomerNormalized, IPersonCustomerNormalized } from '../../../customers/interfaces/customer-normalized'
import { CustomerTypeEnum } from '../../../types/customer'
import { useAppDispatch } from '../../../store'
import React from 'react'
import { FetchCustomerById } from '../../../customers/store'
import { useAsync } from '../../../hooks/use-async'
import { useAppContext } from '../../../hooks/use-app-context'

const useLoader = ({ customerId }: RouteParams) => {
  const dispatch = useAppDispatch()
  const loader = React.useCallback(async () => {
    dispatch({ type: 'load_customer_settings_route' })
    if (customerId) {
      await dispatch(FetchCustomerById(customerId))
    }
  }, [dispatch, customerId])
  return useAsync(loader)
}

const CustomerSettingsRoute = () => {
  const { appContext } = useAppContext()
  const customerId = appContext.subContext?.id as string
  const { error, ...status } = useLoader({ customerId })
  const { customer } = useCustomer(customerId)

  if (error) {
    return <p>Error occured: {error}</p>
  }

  if (status.isPending()) {
    return <div>Loading...</div>
  }

  if (!customer) return <NotFound />

  const { type } = customer
  const isPersonCustomer = type === CustomerTypeEnum.person
  const isOrgCustomer = type === CustomerTypeEnum.organisation

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {isOrgCustomer && <OrgCustomerHome customer={customer as IOrgCustomerNormalized} />}
      {isPersonCustomer && <PersonCustomerHome customer={customer as IPersonCustomerNormalized} />}
    </ErrorBoundary>
  )
}

type RouteParams = {
  customerId: string
}

export default CustomerSettingsRoute
