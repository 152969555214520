import React from 'react'

import { Breadcrumbs, withStyles, emphasize, Chip, MenuItem, Menu } from '@material-ui/core'
import { ChevronDown, ChevronLeft } from 'react-feather'
import { useI18n, useRouter } from '../../hooks'
import { useUrlWithContext } from '../../hooks/use-url-with-context'
import { TaskViewModel } from '../api/task'
import { StringMap } from '../../types/common'

const TaskNavBreadCrumbs = ({ task }: { task: TaskViewModel }) => {
  const router = useRouter()
  const translations = useTranslations()
  const { createPathWithContext } = useUrlWithContext()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleOpen = ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(currentTarget)
  const handleClose = () => setAnchorEl(null)
  const projectRoute = createPathWithContext(`${task.projectLink.href}/basic`)

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          onClick={() => !task.projectLink.disabled && router.push(projectRoute)}
          label={task.projectLink.label}
          icon={<ChevronLeft size={16} />}
          deleteIcon={<ChevronDown />}
          onDelete={handleOpen}
        />
      </Breadcrumbs>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {task.ancestorTaskLinks.length ? (
          task.ancestorTaskLinks.map(($taskLink) => (
            <MenuItem
              key={$taskLink.href}
              onClick={() => {
                handleClose()
                const taskRoute = createPathWithContext(`${$taskLink.href}/basic`)
                if (!$taskLink.disabled) router.push(taskRoute)
              }}
            >
              {$taskLink.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem
            onClick={() => {
              handleClose()
            }}
          >
            {translations.emptyAncestorTasks}
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.body2.fontWeight,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip)

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('task')
  const taskNavBar = t?.taskNavBar || ({} as StringMap)
  const { emptyAncestorTasks = defaults.emptyAncestorTasks } = taskNavBar
  return { emptyAncestorTasks }
}
const defaultTranslations = {
  emptyAncestorTasks: 'No ancestor tasks',
}
type Translations = typeof defaultTranslations

export default TaskNavBreadCrumbs
