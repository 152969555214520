import api from '../../services/api-service'

import { AxiosRequestConfig } from 'axios'
import { makeMembershipApiRoutes } from '../utils/make-api-routes'
import { IMembershipResponse } from '../interfaces/membership-response'
import { MembershipRoleEnum } from '../../types/memberships'

const membershipApiRoutes = makeMembershipApiRoutes()

export const fetchMembershipById = (membershipId: string, config: AxiosRequestConfig = {}) => {
  return api.get<IMembershipResponse>(membershipApiRoutes.membershipItem(membershipId), config)
}

export const fetchUserMemberships = (userId: string, config: AxiosRequestConfig = {}) => {
  return api.get<IMembershipResponse[]>(membershipApiRoutes.userMemberships(userId), config)
}

export const fetchOrgMemberships = (orgId: string, config: AxiosRequestConfig = {}) => {
  return api.get<IMembershipResponse[]>(membershipApiRoutes.orgMemberships(orgId), config)
}

export const transferOwnership = (membershipId: string, config: AxiosRequestConfig = {}) => {
  return api.put<IMembershipResponse[]>(membershipApiRoutes.transferOwnership(membershipId), {}, config)
}

export const addMembership = (orgId: string, payload: NewMemberData, config: AxiosRequestConfig = {}) => {
  return api.post<IMembershipResponse>(membershipApiRoutes.orgMemberships(orgId), payload, config)
}

export const updateMembershipInfo = (
  membershipId: string,
  updateData: MembershipInfoUpdateData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<IMembershipResponse>(membershipApiRoutes.membershipItem(membershipId), updateData, config)
}

export const sendInvitation = (
  { membershipId, language }: SendInvitationActionData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<IMembershipResponse>(
    membershipApiRoutes.membershipInvitationStatus(membershipId),
    { invitationStatus: 'sent', language },
    config
  )
}

export const cancelInvitation = (membershipId: string, config: AxiosRequestConfig = {}) => {
  return api.put<IMembershipResponse>(
    membershipApiRoutes.membershipInvitationStatus(membershipId),
    { invitationStatus: 'canceled' },
    config
  )
}

export const activateMemberships = (orgId: string, membershipIds: string[], config: AxiosRequestConfig = {}) => {
  return api.put<IMembershipResponse[]>(membershipApiRoutes.orgMembershipsActivate(orgId), { membershipIds }, config)
}

export const deactivateMemberships = (orgId: string, membershipIds: string[], config: AxiosRequestConfig = {}) => {
  return api.put<IMembershipResponse[]>(membershipApiRoutes.orgMembershipsDeactivate(orgId), { membershipIds }, config)
}

export type UpdateMembershipsPermissionsPayload = {
  orgId: string
  memberships: 'all' | '*' | string[]
  permissions: any
}

export type NewNoAccessMemberData = {
  firstname: string
  lastname?: string
  userEmail?: string
  sendInvitation: false
}

export type NewStandardMemberData = {
  firstname: string
  lastname?: string
  userEmail: string
  sendInvitation: true
  abilitiesToAdd: string[]
  abilitiesToRemove: string[]
  copyPermissionsFrom: MembershipRoleEnum.orgMember
  language: string
}

export type NewLimitedMemberData = {
  firstname: string
  lastname?: string
  userEmail: string
  sendInvitation: true
  abilitiesToAdd: string[]
  abilitiesToRemove: string[]
  associations: string[]
  abilitiesInResources: ResourceAbility[]
  copyPermissionsFrom: MembershipRoleEnum.orgLimitedMember
  language: string
}

export type NewCopiedMemberData = {
  firstname: string
  lastname?: string
  userEmail: string
  sendInvitation: true
  copyPermissionsFrom: string
  language: string
}

export type NewMemberData = NewNoAccessMemberData | NewStandardMemberData | NewLimitedMemberData | NewCopiedMemberData

type ResourceAbility = { resourceId: string; abilities: string[] }

export type MembershipInfoUpdateData = {
  firstname?: string
  lastname?: string
  userEmail?: string
}

export type SendInvitationActionData = {
  membershipId: string
  language: string
}
