export const paths = {
  login: () => '/login',
  signup: () => '/signup',
  support: () => '/support',
  forgotPassword: () => '/forgot-password',
  accountVerification: () => '/account-verification',
  newVerificationCode: () => '/new-verification-code',
  deactivated: () => '/deactivated',
  resetPassword: (token = ':token') => `/reset-password/${token}`,

  invitations: () => '/invitations',
  home: () => '/home',
  projects: () => '/projects',
  tasks: () => '/tasks',
  todos: () => '/todos',
  calendar: () => '/calendar',
  kanban: () => '/kanban',

  userWelcome: () => `/users/welcome`,
  userSettings: () => `/users/settings`,
  newOrganisation: () => `/orgs/new`,

  orgWelcome: () => `/orgs/welcome`,
  orgNewMember: () => `/orgs/members/new`,
  orgMembers: () => `/orgs/members`,
  orgNewCustomer: () => `/orgs/customers/new`,
  orgNewSupplier: () => `/orgs/suppliers/new`,
  orgNewWorkspace: () => `/orgs/workspaces/new`,
  orgCustomers: () => `/orgs/customers`,
  orgSuppliers: () => `/orgs/suppliers`,
  orgWorkspaces: () => `/orgs/workspaces`,
  orgSettings: () => `/orgs/settings`,
  orgBilling: () => `/orgs/billing`,

  membershipSettings: () => `/memberships/settings`,
  customerSettings: () => `/customers/settings`,
  supplierSettings: () => `/suppliers/settings`,
  workspaceWelcome: () => `/workspaces/welcome`,
  workspaceMembers: () => `/workspaces/members`,
  workspaceSettings: () => `/workspaces/settings`,

  newProject: () => `/projects/new`,
  projectBasic: (id = ':projectId') => `/projects/${id}/basic`,
  projectTasks: (id = ':projectId') => `/projects/${id}/tasks`,
  projectTodos: (id = ':projectId') => `/projects/${id}/todos`,
  projectCalendar: (id = ':projectId') => `/projects/${id}/calendar`,
  projectKanban: (id = ':projectId') => `/projects/${id}/kanban`,
  projectGantt: (id = ':projectId') => `/projects/${id}/gantt`,
  projectSettings: (id = ':projectId') => `/projects/${id}/settings`,

  taskBasic: (id = ':taskId') => `/tasks/${id}/basic`,
  taskSubtasks: (id = ':taskId') => `/tasks/${id}/subtasks`,
  taskTodos: (id = ':taskId') => `/tasks/${id}/todos`,
  taskCalendar: (id = ':taskId') => `/tasks/${id}/calendar`,
  taskKanban: (id = ':taskId') => `/tasks/${id}/kanban`,
  taskPermissions: (id = ':taskId') => `/tasks/${id}/permissions`,
}
