import { AppMainContextType } from '../../types/app-context'
import { IOptionNormalized } from '../interfaces/options-normalized'

export const getMembershipOptions = (options: IOptionNormalized[]): IOptionNormalized[] => {
  return options.filter(
    (option) =>
      option.type === 'orgLimitedMember' ||
      option.type === 'orgMember' ||
      option.type === 'orgOwner' ||
      option.type === 'noAccessMember'
  )
}

export const getSupplierOptions = (options: IOptionNormalized[]): IOptionNormalized[] => {
  return options.filter((option) => option.type === 'supplier')
}

export const getWorkspaceOptions = (options: IOptionNormalized[]): IOptionNormalized[] => {
  return options.filter((option) => option.type === 'workspace')
}

export const getCustomerOptions = (options: IOptionNormalized[]): IOptionNormalized[] => {
  return options.filter((option) => option.type === 'customer')
}

export const getOptionsForMainContext = (
  options: IOptionNormalized[],
  mainContext: AppMainContextType
): IOptionNormalized[] => {
  if (mainContext.type === 'user') return options
  else return options.filter((option) => option.orgId === mainContext.id)
}

export const getOptionName = (option: IOptionNormalized) => option.name
export const getOptionId = (option: IOptionNormalized) => option.id
