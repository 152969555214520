import ButtonLink from '../components/ButtonLink'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormikInput from '../components/FormikInput'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/FormLabel'
import ErrorFallback from '../components/ErrorFallback'
import Stack from '../components/Stack'
import { Form, Formik, FormikHelpers } from 'formik'
import { useErrorHandler } from 'react-error-boundary'
import { useI18n, useNetworkStatus } from '../hooks'
import { ErrorBoundary } from 'react-error-boundary'
import { makeStyles, Theme } from '@material-ui/core'
import { useAppDispatch } from '../store'
import { RequestPasswordResetToken } from '../users/store/actions'
import { Alert, AlertTitle } from '@material-ui/lab'
import { paths } from '../paths'
import { useLanguage } from '../i18n/use-language'

const ForgotPasswordRoute = () => {
  const classes = useStyles()
  const translations = useTranslations()

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Stack spacing={3}>
        <div>
          <Typography component="h4" variant="h4" className={classes.header}>
            {translations.forgotPasswordPageHeader}
          </Typography>
          <Typography component="p" variant="body1" color="textSecondary">
            {translations.forgotPasswordPageSubHeader}
          </Typography>
        </div>
        <ForgotPasswordForm />
      </Stack>
    </ErrorBoundary>
  )
}

const ForgotPasswordForm = () => {
  const language = useLanguage()
  const translations = useTranslations()
  const dispatch = useAppDispatch()
  const handleError = useErrorHandler()
  const networkStatus = useNetworkStatus({ resetDelayInMS: 5000 })
  const { setStatus, isRejected, isIdle, isPending, isFulfilled } = networkStatus

  const handleSubmit = async (
    { email }: { email: string },
    { setSubmitting, resetForm }: FormikHelpers<{ email: string }>
  ) => {
    try {
      setStatus('pending')
      const actionResult = await dispatch(RequestPasswordResetToken({ email, language }))
      const success = actionResult.meta.requestStatus === 'fulfilled'
      if (success) setStatus('fulfilled')
      if (!success) setStatus('rejected')
      setSubmitting(false)
      resetForm()
    } catch (error: any) {
      handleError(error)
    }
  }

  const formikProps = {
    initialValues: { email: '' },
    onSubmit: handleSubmit,
  }

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors }) => {
        const shouldDisableInput = isPending()
        const shouldDisableButton = Boolean(values.email === '' || errors.email || !isIdle())

        let alertMessage
        let title
        let severity: 'success' | 'error' = 'success'

        if (isRejected()) {
          alertMessage = translations.sendLinkFailedMessage
          title = translations.error
          severity = 'error'
        }

        if (isFulfilled()) {
          alertMessage = translations.sendResetPasswordLinkSuccessMessage
          title = translations.success
          severity = 'success'
        }

        return (
          <Form>
            <Stack>
              <div>
                <InputLabel htmlFor="email">{`${translations.email}:`}</InputLabel>
                <FormikInput
                  id="email"
                  name="email"
                  data-test="forgot-password-email-input"
                  placeholder="e.g. steve@gmail.com"
                  variant="outlined"
                  disabled={shouldDisableInput}
                  fullWidth
                />
              </div>
              <div>
                {alertMessage && (
                  <Alert severity={severity}>
                    <AlertTitle data-test="forgot-password-result">{title}</AlertTitle>
                    {alertMessage}
                  </Alert>
                )}
              </div>
              <Stack direction="row" packed>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={shouldDisableButton}
                  startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
                  data-test="forgot-password-submit-button"
                >
                  {isSubmitting ? translations.gettingLink : translations.getLink}
                </Button>
                <ButtonLink data-test="login-link" to={paths.login()}>
                  {translations.backToLogin}
                </ButtonLink>
              </Stack>
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  header: { margin: theme.spacing(2, 0) },
}))

const useTranslations = (defaults: Translations = defaultTranslations) => {
  const { translations: t } = useI18n('translation')

  const {
    forgotPasswordPageHeader = defaults.forgotPasswordPageHeader,
    forgotPasswordPageSubHeader = defaults.forgotPasswordPageSubHeader,
    sendLinkFailedMessage = defaults.sendLinkFailedMessage,
    sendResetPasswordLinkSuccessMessage = defaults.sendResetPasswordLinkSuccessMessage,
    success = defaults.success,
    error = defaults.error,
    gettingLink = defaults.gettingLink,
    getLink = defaults.getLink,
    backToLogin = defaults.backToLogin,
    email = defaults.email,
  } = t

  return {
    forgotPasswordPageHeader,
    forgotPasswordPageSubHeader,
    sendLinkFailedMessage,
    sendResetPasswordLinkSuccessMessage,
    success,
    error,
    gettingLink,
    getLink,
    backToLogin,
    email,
  }
}

const defaultTranslations = {
  forgotPasswordPageHeader: 'Did you forget your password?',
  forgotPasswordPageSubHeader: `Don't worry, we will send you an email with a link to reset your password`,
  sendLinkFailedMessage: 'Failed to send a link!',
  sendResetPasswordLinkSuccessMessage: 'A link to reset your password has been sent to your email.',
  success: 'Success',
  error: 'Error',
  gettingLink: 'Sending a link to your email',
  getLink: 'Get a link',
  backToLogin: 'Back to login',
  email: 'Email',
}
type Translations = typeof defaultTranslations

export default ForgotPasswordRoute
