import { makeStyles, Theme } from '@material-ui/core'
import { isString, toNumber } from 'lodash'
import { Check, IconProps } from 'react-feather'

const Checkmark = ({ color = 'primary', variant = 'default', ...IconProps }: CheckmarkProps = {}) => {
  const size = IconProps.size || 24
  const parsedSize = isString(size) ? toNumber(size) : size
  const classes = useStyles({ color, variant, size: parsedSize })

  return (
    <span className={classes.checkmark}>
      <Check {...IconProps} size={parsedSize - 6} />
    </span>
  )
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  checkmark: {
    height: ({ size }) => size,
    width: ({ size }) => size,
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: ({ color, variant }) => {
      if (variant === 'contained') {
        return theme.palette[color].main
      }
    },
    color: ({ color, variant }) => {
      if (variant === 'contained') {
        return theme.palette[color].contrastText
      }
      return theme.palette[color].main
    },
    outline: ({ color, variant }) => {
      if (variant === 'outlined') {
        return `1px solid ${theme.palette[color].main}`
      }
    },
  },
}))

type CheckmarkProps = IconProps & {
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  variant?: 'contained' | 'outlined' | 'default'
}

type StyleProps = {
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  variant: 'contained' | 'outlined' | 'default'
  size: number
}

export default Checkmark
