import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Unarchive from '../../assets/icons/Unarchive'
import { makeStyles, Theme } from '@material-ui/core'
import { useI18n } from '../../hooks'
import { StringMap } from '../../types/common'
import { useProjectMutations } from '../hooks/use-project-mutations'
import { ProjectViewModel } from '../api/project'

const ProjectArchivedMessageBar = ({
  project,
  isFullWidth,
  translations = defaultTranslations,
}: ProjectArchivedMessageBarProps) => {
  const classes = useStyles({ isFullWidth })
  const { unarchiveProject } = useProjectMutations()
  if (!project.archived) return null

  return (
    <>
      <div className={classes.archivedBarContainer}>
        <div className={classes.archivedBar}>
          <Typography variant="subtitle1">{translations.projectIsArchivedMessage}</Typography>
          <Button endIcon={<Unarchive />} onClick={() => unarchiveProject(project.id)}>
            {translations.unarchiveButtonLabel}
          </Button>
        </div>
      </div>
      <div className={classes.archivedBarReplacementDiv} />
    </>
  )
}

const ProjectArchivedMessageBarWithI18n = (props: ProjectArchivedMessageBarProps) => {
  const { translations: t } = useI18n('project')
  const translations = (t?.projectArchivedMessageBar || {}) as StringMap
  const {
    projectUnarchiveSuccessMessage = defaultTranslations.projectUnarchiveSuccessMessage,
    projectUnarchiveFailureMessage = defaultTranslations.projectUnarchiveFailureMessage,
    projectIsArchivedMessage = defaultTranslations.projectIsArchivedMessage,
    unarchiveButtonLabel = defaultTranslations.unarchiveButtonLabel,
  } = translations
  return (
    <ProjectArchivedMessageBar
      {...props}
      translations={{
        projectUnarchiveSuccessMessage,
        projectUnarchiveFailureMessage,
        projectIsArchivedMessage,
        unarchiveButtonLabel,
      }}
    />
  )
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  archivedBarContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
  },
  archivedBar: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: 32,
    overflow: 'hidden',
    width: ({ isFullWidth }) => (isFullWidth ? '100%' : 1440 - 16),
    padding: theme.spacing(0, 1),
    '& button': {
      color: 'white',
      '& svg': {
        fill: 'white',
      },
    },
  },
  archivedBarReplacementDiv: {
    height: 32,
  },
}))

const defaultTranslations = {
  projectUnarchiveSuccessMessage: 'Project unarchived',
  projectUnarchiveFailureMessage: 'Failed to archive project',
  projectIsArchivedMessage: 'This project is archived',
  unarchiveButtonLabel: 'Unarchive project',
}

type ProjectArchivedMessageBarProps = {
  project: ProjectViewModel
  isFullWidth?: boolean
  translations?: typeof defaultTranslations
}
type StyleProps = {
  isFullWidth?: boolean
}

export default ProjectArchivedMessageBarWithI18n
