import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  buttonLink: {
    marginRight: theme.spacing(2),
    '& .MuiButton-label': {
      color: theme.palette.primary.main,
      borderBottom: `1.5px solid transparent`,
      transition: theme.transitions.create('border'),
      '&:hover, &:focus': {
        borderBottom: `1.5px dotted ${theme.palette.primary.main}`,
      },
    },
  },
}))

const ButtonLink = (props: any) => {
  const classes = useStyles()
  return <Button className={classes.buttonLink} {...props} component={Link} />
}

export default ButtonLink
