import Alert from '@material-ui/lab/Alert'
import Typography from '@material-ui/core/Typography'
import Stack from '../Stack'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { Theme, alpha, makeStyles } from '@material-ui/core'
import { useI18n } from '../../hooks'
import clsx from 'clsx'
import { AlertTitle } from '@material-ui/lab'
import { kebabCase } from 'lodash'

const BillingSummaryCard = ({ subtotal, total, summary, discounts }: BillingSummaryCardProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const lastDiscountIndex = discounts.length - 1

  return (
    <Paper variant="outlined">
      {discounts.map((discountCode, index) => {
        return (
          <Alert
            icon={<span className="iconEmoji">🎁</span>}
            severity="info"
            className={clsx(classes.alert, index === lastDiscountIndex ? 'lastItem' : '')}
            data-test="discount-alert"
          >
            <AlertTitle data-test="discount-alert-title">
              {translations[`${discountCode}Title` as keyof Translations]}
            </AlertTitle>
            {translations[`${discountCode}Message` as keyof Translations]}
          </Alert>
        )
      })}
      <div className={classes.cardRoot}>
        <Stack spacing={1} item style={{ marginTop: -20 }}>
          <Typography variant="subtitle1" color="textSecondary">
            {translations.estimatedTotal}
          </Typography>
          <Typography variant="h3">
            {total !== subtotal && (
              <Typography variant="h5" component="span" className={classes.discount}>
                {subtotal}
              </Typography>
            )}
            &nbsp;<span data-test="estimated-total">{total}</span>&nbsp;
            <Typography variant="body1" component="span">
              EUR
            </Typography>
          </Typography>
        </Stack>
        <hr className="divider" />
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell> {translations.accessLevel} </TableCell>
                <TableCell>{translations.members}</TableCell>
                <TableCell>{translations.pricePerMember}</TableCell>
                <TableCell>{translations.total}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {summary
                .filter((p) => p.quantity)
                .map((plan) => (
                  <TableRow key={plan.name} data-test={`cost-breakdown-${kebabCase(plan.name)}`}>
                    <TableCell>{plan.name}</TableCell>
                    <TableCell>{plan.quantity}</TableCell>
                    <TableCell>
                      <Typography>{plan.price}</Typography>
                    </TableCell>
                    <TableCell>
                      {plan.total !== plan.subtotal && (
                        <Typography component="span" className={classes.discount} variant="body2" color="textSecondary">
                          {plan.subtotal}
                        </Typography>
                      )}
                      &nbsp; &nbsp;
                      {plan.total}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  cardRoot: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    '& > div:first-child': {
      width: '38%',
    },
    '& .divider': {
      height: 100,
      width: 1,
      background: theme.palette.divider,
      color: theme.palette.divider,
      border: 'none',
      margin: theme.spacing(0, 4, 0, 1),
    },
  },
  alert: {
    borderRadius: 0,
    borderBottom: `1px solid ${alpha(theme.palette.info.main, 0.1)}`,
    background: alpha(theme.palette.info.main, 0.15),
    '& .iconEmoji': {
      padding: 8,
      background: 'white',
      borderRadius: '50%',
      height: 48,
      width: 48,
      textAlign: 'center',
    },
    '&:first-child': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    '&.lastItem': {
      borderBottom: 'none',
    },
  },
  tableContainer: {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.divider}`,
    '& th': {
      textTransform: 'uppercase',
      padding: theme.spacing(1.5),
      color: theme.palette.text.secondary,
      fontSize: theme.typography.caption.fontSize,
      letterSpacing: 1,
      fontWeight: theme.typography.fontWeightBold,
    },
    '& td, & th': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& tr:last-child td': {
      borderBottom: 'none',
    },
  },
  discount: {
    color: theme.palette.grey[500],
    textDecoration: 'line-through',
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('org')
  const translations = t?.orgBillingPage || {}

  return {
    estimatedTotal: translate('estimatedTotal'),
    pricePerMember: translate('pricePerMember'),
    total: translate('total'),
    accessLevel: translate('accessLevel'),
    members: translate('members'),
    VolumeLessThan3Message: translate('VolumeLessThan3Message'),
    VolumeMoreThan50Message: translate('VolumeMoreThan50Message'),
    VolumeMoreThan100Message: translate('VolumeMoreThan100Message'),
    VolumeMoreThan150Message: translate('VolumeMoreThan150Message'),
    VolumeMoreThan200Message: translate('VolumeMoreThan200Message'),
    VolumeLessThan3Title: translate('VolumeLessThan3Title'),
    VolumeMoreThan50Title: translate('VolumeMoreThan50Title'),
    VolumeMoreThan100Title: translate('VolumeMoreThan100Title'),
    VolumeMoreThan150Title: translate('VolumeMoreThan150Title'),
    VolumeMoreThan200Title: translate('VolumeMoreThan200Title'),
  }

  function translate(key: keyof Translations) {
    return translations[key] || defaults[key]
  }
}

const defaultTranslations = {
  VolumeLessThan3Message:
    'Having upto two members is free. We will start billing you once you have more than two members.',
  VolumeMoreThan50Message:
    'Your organization has more than 50 members and a 10% discount will be applied to your bill.',
  VolumeMoreThan100Message:
    'Your organization has more than 100 members and a 20% discount will be applied to your bill.',
  VolumeMoreThan150Message:
    'Your organization has more than 150 members and a 30% discount will be applied to your bill.',
  VolumeMoreThan200Message:
    'Your organization has more than 200 members and a 40% discount will be applied to your bill.',
  VolumeLessThan3Title: 'ou are eligible for the free tier',
  VolumeMoreThan50Title: 'You got 10% discount',
  VolumeMoreThan100Title: 'You got 20% discount',
  VolumeMoreThan150Title: 'You got 30% discount',
  VolumeMoreThan200Title: 'You got 40% discount',
  estimatedTotal: 'Estimated total',
  pricePerMember: 'Price per member',
  total: 'Total',
  accessLevel: 'Access level',
  members: 'Members',
}

type Translations = typeof defaultTranslations

type BillingSummaryCardProps = {
  subtotal: number
  total: number
  summary: { name: string; quantity: number; price: number; total: number; subtotal: number }[]
  discounts: string[]
}

export default BillingSummaryCard
