import * as AuthAPI from '../auth-api'
import * as UserAPI from '../user-api'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { IAuthAPI } from '../interfaces/auth-api'
import type { IUserAPI } from '../interfaces/user-api'
import type { RootState } from '../../store'
import { SetAuthState } from './index'
import { normalizeUser } from '../interfaces/user-normalized'
import { ResetContextOptions } from '../../context-options/store'

const REFRESH_TOKEN_KEY = 'starbrix_refresh_token'

const makeSignupUser = (userAPI: IUserAPI) => {
  return createAsyncThunk('auth/user/signup', async (newUserData: UserAPI.NewUserData, { dispatch }) => {
    const { user, auth } = await userAPI.signup(newUserData)
    const serializedToken = JSON.stringify(auth.refresh_token)
    localStorage.setItem(REFRESH_TOKEN_KEY, serializedToken)
    dispatch(
      SetAuthState({
        user: normalizeUser(user),
        isAuthenticated: true,
        isRefreshingToken: false,
        accessToken: auth.access_token,
        refreshToken: auth.refresh_token,
        // expires is how long token is valid in seconds, tokenExpiresAt is the timestamp when the token will expire
        tokenExpiresAt: Date.now() + auth.expires * 1000,
        authError: null,
      })
    )
  })
}

const makeLoginUser = (authAPI: IAuthAPI) => {
  return createAsyncThunk('auth/login', async (loginData: AuthAPI.LoginData, { dispatch }) => {
    const { user, auth } = await authAPI.login(loginData)
    const serializedToken = JSON.stringify(auth.refresh_token)
    localStorage.setItem(REFRESH_TOKEN_KEY, serializedToken)
    dispatch(
      SetAuthState({
        user: normalizeUser(user),
        isAuthenticated: true,
        isRefreshingToken: false,
        accessToken: auth.access_token,
        refreshToken: auth.refresh_token,
        // expires is how long token is valid in seconds, tokenExpiresAt is the timestamp when the token will expire
        tokenExpiresAt: Date.now() + auth.expires * 1000,
        authError: null,
      })
    )
  })
}

const makeLogoutUser = () => {
  return createAsyncThunk('auth/logout', async (_, { dispatch }) => {
    // remove the token
    localStorage.removeItem(REFRESH_TOKEN_KEY)
    // remove app context data
    sessionStorage.removeItem('mainContext')
    sessionStorage.removeItem('subContext')
    dispatch(ResetAuthState())
  })
}

const makeFetchUser = (userAPI: IUserAPI) => {
  return createAsyncThunk('auth/user/fetchUser', async () => {
    return await userAPI.fetchAuthUser()
  })
}

const makeRefreshAccessToken = (authAPI: IAuthAPI) => {
  return createAsyncThunk('auth/refreshAccessToken', async (_, { getState }) => {
    const { refreshToken } = (getState() as RootState).auth as {
      refreshToken: string
    }
    const responseData = await authAPI.fetchAccessToken(refreshToken)
    return {
      accessToken: responseData.access_token,
      // expires is how long token is valid in seconds, tokenExpiresAt is the timestamp when the token will expire
      tokenExpiresAt: Date.now() + responseData.expires * 1000,
    }
  })
}

const makeVerifyAccount = (userAPI: IUserAPI) => {
  return createAsyncThunk('auth/user/verifyAccount', async (verificationData: UserAPI.VerifyAccountData) => {
    return await userAPI.verifyAccount(verificationData)
  })
}

const makeRequestVerificationCode = (userAPI: IUserAPI) => {
  return createAsyncThunk(
    'auth/user/requestVerificationCode',
    async ({ email, language }: { email: string; language: string }) => {
      return await userAPI.requestVerificationCode({ email, language })
    }
  )
}

const makeRequestPasswordResetToken = (userAPI: IUserAPI) => {
  return createAsyncThunk(
    'auth/user/requestPasswordResetToken',
    async ({ email, language }: { email: string; language: string }) => {
      return await userAPI.requestPasswordResetToken({ email, language })
    }
  )
}

const makeResetPassword = (userAPI: IUserAPI) => {
  return createAsyncThunk('auth/user/resetPassword', async (resetPasswordData: UserAPI.ResetPasswordData) => {
    return await userAPI.resetPassword(resetPasswordData)
  })
}

const makeUpdateUserInfo = (userAPI: IUserAPI) => {
  return createAsyncThunk('users/updateUserInfo', async (payload: UserAPI.UpdateUserInfoPayload, { getState }) => {
    const userId = (getState() as RootState).auth.user?.id as string
    return await userAPI.updateUserInfo(userId, payload)
  })
}

const makeDeleteUserAccount = (userAPI: IUserAPI) => {
  return createAsyncThunk(
    'users/deleteUserAccount',
    async (payload: UserAPI.DeleteUserAccountPayload, { getState }) => {
      const userId = (getState() as RootState).auth.user?.id as string
      const response = await userAPI.deleteUserAccount(userId, payload)
      return response
    }
  )
}

const makeClearStorageAndAuthState = () => {
  return createAsyncThunk('auth/clearStorageAndAuthState', async (_, { dispatch }) => {
    localStorage.removeItem(REFRESH_TOKEN_KEY)
    sessionStorage.removeItem('mainContext')
    sessionStorage.removeItem('subContext')
    dispatch(ResetContextOptions())
  })
}

export const SignupUser = makeSignupUser(UserAPI)
export const LoginUser = makeLoginUser(AuthAPI)
export const LogoutUser = makeLogoutUser()
export const FetchUser = makeFetchUser(UserAPI)
export const RefreshAccessToken = makeRefreshAccessToken(AuthAPI)
export const VerifyAccount = makeVerifyAccount(UserAPI)
export const RequestVerificationCode = makeRequestVerificationCode(UserAPI)
export const RequestPasswordResetToken = makeRequestPasswordResetToken(UserAPI)
export const ResetPassword = makeResetPassword(UserAPI)
export const UpdateUserInfo = makeUpdateUserInfo(UserAPI)
export const ResetAuthState = createAction('auth/resetState')
export const ClearStorageAndAuthState = makeClearStorageAndAuthState()
export const DeleteUserAccount = makeDeleteUserAccount(UserAPI)
