import AppLanguageSwitch from './LanguageSwitch'
import Logout from './Logout'
import { COLOR_DIVIDER, COLOR_WHITE } from '../constants'
import React from 'react'
import Stack from './Stack'
import { Redirect } from 'react-router-dom'
import { useAppSelector } from '../store'
import { getAuthState } from '../users/store/selectors'
import { paths } from '../paths'
import { useUrlWithContext } from '../hooks/use-url-with-context'

export const withInactiveRouteLayout = (Component: React.ComponentType<any>) => {
  const WithLayout = () => {
    const { user } = useAppSelector(getAuthState)
    const { createPathWithGivenContext } = useUrlWithContext()

    if (!user) return null
    if (user.isActive) {
      const to = createPathWithGivenContext({ path: paths.home(), mainContextId: user.id })
      return <Redirect to={to} />
    }

    return (
      <Stack style={{ maxWidth: 650, margin: '80px auto' }} spacing={1}>
        <Stack direction="row" packed alignItems="center">
          <AppLanguageSwitch />
          <Logout style={logoutStyle} />
        </Stack>
        <Component />
      </Stack>
    )
  }
  return WithLayout
}

const logoutStyle = {
  marginTop: 24,
  borderRadius: 4,
  border: `1px solid ${COLOR_DIVIDER}`,
  background: COLOR_WHITE,
}
