import { NewCalendarEventData } from '../../calendar-events/api'
import { useCalendarEventMutations } from '../../calendar-events/hooks/use-calendar-event-mutations'
import { useAuthUserMembership } from '../../memberships/hooks/use-auth-user-membership'
import { useAppDispatch, useAppSelector } from '../../store'
import { NewTodoData } from '../../todos/api'
import { useTodoMutations } from '../../todos/hooks/use-todo-mutations'
import {
  AddWorkspaceManager,
  AddWorkspaceMember,
  RemoveWorkspaceManager,
  RemoveWorkspaceMember,
} from '../store/actions'
import { getWorkspaceById } from '../store/selectors'

export const useWorkspace = (workspaceId: string) => {
  const dispatch = useAppDispatch()
  const workspace = useAppSelector((s) => getWorkspaceById(s, workspaceId))
  const { canCreateCalendarEvents, canCreateTodos } = useAuthUserMembership()
  const calendarEventActions = useCalendarEventMutations()
  const todoActions = useTodoMutations()

  const onAddMember = async (membershipId: string) => {
    dispatch(AddWorkspaceMember({ workspaceId, memberId: membershipId }))
  }

  const onAddManager = async (membershipId: string) => {
    dispatch(AddWorkspaceManager({ workspaceId, managerId: membershipId }))
  }

  const onRemoveMember = async (membershipId: string) => {
    dispatch(RemoveWorkspaceMember({ workspaceId, memberId: membershipId }))
  }

  const onRemoveManager = async (membershipId: string) => {
    dispatch(RemoveWorkspaceManager({ workspaceId, managerId: membershipId }))
  }

  const onAddCalendarEvent = (event: NewCalendarEventData) => {
    return calendarEventActions.createCalendarEvent({
      ...event,
      description: event.description || null,
      organisation: workspace.orgId,
      workspaces: [workspace.id],
    })
  }

  const onAddTodo = (todoData: NewTodoData) => {
    return todoActions.createTodo({
      ...todoData,
      description: todoData.description || null,
      dueDate: todoData.dueDate || null,
      organisation: workspace.orgId,
      workspaces: [workspace.id],
    })
  }

  return {
    workspace,
    canAddCalendarEvents: canCreateCalendarEvents,
    canAddTodos: canCreateTodos,
    onAddCalendarEvent,
    onAddTodo,
    onAddMember,
    onAddManager,
    onRemoveMember,
    onRemoveManager,
  }
}
