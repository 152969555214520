import ResourceManager from '../../components/ResourceManager'
import { useCalendarEventMutations } from '../hooks/use-calendar-event-mutations'
import { useI18n } from '../../hooks/use-i18n'
import { useOrgOptions } from '../../options/hooks/use-org-options'
import { StringMap } from '../../types/common'
import { CalendarEventViewModel } from '../api/calendar-event'

const EventSuppliersWidget = ({ calendarEvent }: { calendarEvent: CalendarEventViewModel }) => {
  const { updateSuppliers } = useCalendarEventMutations()
  const translations = useTranslations(defaultTranslations)
  const { supplierOptions } = useOrgOptions(calendarEvent.organisation?.id || '')

  const onAddSuppliers = (supplierIds: string[]) => {
    const suppliers = calendarEvent.suppliers.map(($s) => $s.id).concat(supplierIds)
    updateSuppliers(calendarEvent.id, suppliers)
  }

  const onRemoveSupplier = (supplier: string) => {
    const suppliers = calendarEvent.suppliers.filter(($s) => $s.id !== supplier).map(($s) => $s.id)
    updateSuppliers(calendarEvent.id, suppliers)
  }
  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={supplierOptions}
      resources={calendarEvent.suppliers}
      canAddResource={calendarEvent.canUpdate}
      canRemoveResource={calendarEvent.canUpdate}
      onAddResources={onAddSuppliers}
      onRemoveResource={onRemoveSupplier}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection
    />
  )
}

const useTranslations = (defaults = defaultTranslations): EventParticipantsWidgetTranslations => {
  const { translations: t } = useI18n('calendarEvent')
  const translations = (t?.suppliersWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: 'Suppliers',
  emptyOptionsMessage: 'No suppliers in this organisation',
  emptyResourcesMessage: 'This event is not connected to any supplier',
}

type EventParticipantsWidgetTranslations = typeof defaultTranslations
export default EventSuppliersWidget
