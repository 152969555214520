import * as customerAPI from '../customer-api'
import { useAuthUserMembership } from '../../memberships/hooks/use-auth-user-membership'
import { useAppDispatch, useAppSelector } from '../../store'
import { getOrgCustomers } from '../store/selectors'
import { UpdateCustomerInfo, UpdateCustomerStatus } from '../store'
import { unwrapResult } from '@reduxjs/toolkit'

export const useOrgCustomers = (orgId: string) => {
  const dispatch = useAppDispatch()
  const customers = useAppSelector((s) => getOrgCustomers(s, orgId))
  const { canUpdateAnyCustomer, canReadAnyCustomer } = useAuthUserMembership()
  const activeCustomers = customers.filter((customer) => customer.isActive)
  const inactiveCustomers = customers.filter((customer) => !customer.isActive)
  const personCustomers = customers.filter((c) => c.isPersonCustomer)
  const orgCustomers = customers.filter((c) => c.isOrgCustomer)

  const updateCustomerStatus = async (
    customerId: string,
    updateData: customerAPI.CustomerStatusUpdateData
  ) => {
    const thunkArg = { customerId, ...updateData }
    const result = await dispatch(UpdateCustomerStatus(thunkArg))
    unwrapResult(result)
  }

  const updateCustomerInfo = async (
    customerId: string,
    updateData: customerAPI.CustomerInfoUpdateData
  ) => {
    const thunkArg = { customerId, ...updateData }
    const result = await dispatch(UpdateCustomerInfo(thunkArg))
    unwrapResult(result)
  }

  return {
    customers,
    activeCustomers,
    inactiveCustomers,
    personCustomers,
    orgCustomers,
    canReadCustomers: canReadAnyCustomer,
    canUpdateAnyCustomer,
    canReadAnyCustomer,
    updateCustomerStatus,
    updateCustomerInfo,
  }
}
