import OrgMembershipsTable from '../components/OrgMembershipsTable'
import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { StringMap } from 'i18next'
import { useAppContext } from '../../hooks/use-app-context'
import { useAuthUserMembership } from '../../memberships/hooks/use-auth-user-membership'
import { useI18n } from '../../hooks'
import { Plus } from 'react-feather'
import { Link } from 'react-router-dom'
import { paths } from '../../paths'
import { useUrlWithContext } from '../../hooks/use-url-with-context'
import React from 'react'

const OrgMembershipsView = ({ orgId }: { orgId: string }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { appContext } = useAppContext()
  const { mainContext } = appContext
  const { canCreateMemberships } = useAuthUserMembership()
  const { createPathWithContext } = useUrlWithContext()
  if (!mainContext || mainContext.type === 'user') return null

  return (
    <div className={classes.pageContainer}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h6" component="header" className={classes.header} data-test="page-header">
            <Box component="span" color="text.secondary">
              {`${mainContext.name} - `}
            </Box>
            {translations.membershipsHeaderText}
          </Typography>
        </Grid>
        <Grid item>
          {canCreateMemberships && (
            <Button
              variant="contained"
              color="primary"
              endIcon={<Plus size={16} />}
              size="small"
              component={Link}
              to={createPathWithContext(paths.orgNewMember(), { withSubContext: false })}
              data-test="add-member-button"
            >
              {translations.addMemberButtonLabel}
            </Button>
          )}
        </Grid>
      </Grid>
      <OrgMembershipsTable orgId={orgId} />
    </div>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('org')
  const translations = (t?.orgMembershipsPage || {}) as StringMap

  const {
    membershipsHeaderText = defaults.membershipsHeaderText,
    invitationsHeaderText = defaults.invitationsHeaderText,
    addMemberButtonLabel = defaults.addMemberButtonLabel,
  } = translations

  return {
    membershipsHeaderText,
    invitationsHeaderText,
    addMemberButtonLabel,
  }
}

const defaultTranslations = {
  membershipsHeaderText: 'All memberships',
  invitationsHeaderText: 'All Invitations',
  addMemberButtonLabel: 'Add member',
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    padding: theme.spacing(0, 2),
    maxWidth: 1440,
    margin: 'auto',
  },
  header: {
    margin: theme.spacing(0.75, 0, 2, 0),
  },
}))

type Translations = typeof defaultTranslations
export default OrgMembershipsView
