export const countries = [
  {
    countryCode: 'AL',
    countryName: 'Albania',
    languageCode: 'sq',
    languageName: 'Albanian',
    preferredDateFormat: 'yyyy-MM-dd',
    timezones: ['Europe/Tirane'],
  },
  {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Dubai'],
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: [
      'America/Argentina/Buenos_Aires',
      'America/Argentina/Cordoba',
      'America/Argentina/Salta',
      'America/Argentina/Jujuy',
      'America/Argentina/Tucuman',
      'America/Argentina/Catamarca',
      'America/Argentina/La_Rioja',
      'America/Argentina/San_Juan',
      'America/Argentina/Mendoza',
      'America/Argentina/San_Luis',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Ushuaia',
    ],
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    languageCode: 'en',
    languageName: 'English',
    preferredDateFormat: 'd/MM/yyyy',
    timezones: [
      'Australia/Lord_Howe',
      'Antarctica/Macquarie',
      'Australia/Hobart',
      'Australia/Currie',
      'Australia/Melbourne',
      'Australia/Sydney',
      'Australia/Broken_Hill',
      'Australia/Brisbane',
      'Australia/Lindeman',
      'Australia/Adelaide',
      'Australia/Darwin',
      'Australia/Perth',
      'Australia/Eucla',
    ],
  },
  {
    countryCode: 'AT',
    countryName: 'Austria',
    languageCode: 'de',
    languageName: 'German',
    preferredDateFormat: 'dd.MM.yyyy',
    timezones: ['Europe/Vienna'],
  },
  {
    countryCode: 'BE',
    countryName: 'Belgium',
    languageCode: 'fr',
    languageName: 'French',
    preferredDateFormat: 'd/MM/yyyy',
    timezones: ['Europe/Brussels'],
  },
  {
    countryCode: 'BE',
    countryName: 'Belgium',
    languageCode: 'nl',
    languageName: 'Dutch',
    preferredDateFormat: 'd/MM/yyyy',
    timezones: ['Europe/Brussels'],
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    languageCode: 'bg',
    languageName: 'Bulgarian',
    preferredDateFormat: 'yyyy-M-d',
    timezones: ['Europe/Sofia'],
  },
  {
    countryCode: 'BH',
    countryName: 'Bahrain',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Bahrain'],
  },
  {
    countryCode: 'BA',
    countryName: 'Bosnia and Herzegovina',
    languageCode: 'sr',
    languageName: 'Serbian',
    preferredDateFormat: 'yyyy-MM-dd',
    timezones: ['Europe/Sarajevo'],
  },
  {
    countryCode: 'BY',
    countryName: 'Belarus',
    languageCode: 'be',
    languageName: 'Belarusian',
    preferredDateFormat: 'd.M.yyyy',
    timezones: ['Europe/Minsk'],
  },
  {
    countryCode: 'BO',
    countryName: 'Bolivia',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'dd-MM-yyyy',
    timezones: ['America/La_Paz'],
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    languageCode: 'pt',
    languageName: 'Portuguese',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: [
      'America/Noronha',
      'America/Belem',
      'America/Fortaleza',
      'America/Recife',
      'America/Araguaina',
      'America/Maceio',
      'America/Bahia',
      'America/Sao_Paulo',
      'America/Campo_Grande',
      'America/Cuiaba',
      'America/Santarem',
      'America/Porto_Velho',
      'America/Boa_Vista',
      'America/Manaus',
      'America/Eirunepe',
      'America/Rio_Branco',
    ],
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    languageCode: 'fr',
    languageName: 'French',
    preferredDateFormat: 'yyyy-MM-dd',
    timezones: [
      'America/St_Johns',
      'America/Halifax',
      'America/Glace_Bay',
      'America/Moncton',
      'America/Goose_Bay',
      'America/Blanc-Sablon',
      'America/Toronto',
      'America/Nipigon',
      'America/Thunder_Bay',
      'America/Iqaluit',
      'America/Pangnirtung',
      'America/Atikokan',
      'America/Winnipeg',
      'America/Rainy_River',
      'America/Resolute',
      'America/Rankin_Inlet',
      'America/Regina',
      'America/Swift_Current',
      'America/Edmonton',
      'America/Cambridge_Bay',
      'America/Yellowknife',
      'America/Inuvik',
      'America/Creston',
      'America/Dawson_Creek',
      'America/Fort_Nelson',
      'America/Vancouver',
      'America/Whitehorse',
      'America/Dawson',
    ],
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    languageCode: 'en',
    languageName: 'English',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: [
      'America/St_Johns',
      'America/Halifax',
      'America/Glace_Bay',
      'America/Moncton',
      'America/Goose_Bay',
      'America/Blanc-Sablon',
      'America/Toronto',
      'America/Nipigon',
      'America/Thunder_Bay',
      'America/Iqaluit',
      'America/Pangnirtung',
      'America/Atikokan',
      'America/Winnipeg',
      'America/Rainy_River',
      'America/Resolute',
      'America/Rankin_Inlet',
      'America/Regina',
      'America/Swift_Current',
      'America/Edmonton',
      'America/Cambridge_Bay',
      'America/Yellowknife',
      'America/Inuvik',
      'America/Creston',
      'America/Dawson_Creek',
      'America/Fort_Nelson',
      'America/Vancouver',
      'America/Whitehorse',
      'America/Dawson',
    ],
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    languageCode: 'de',
    languageName: 'German',
    preferredDateFormat: 'dd.MM.yyyy',
    timezones: ['Europe/Zurich'],
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    languageCode: 'fr',
    languageName: 'French',
    preferredDateFormat: 'dd.MM.yyyy',
    timezones: ['Europe/Zurich'],
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    languageCode: 'it',
    languageName: 'Italian',
    preferredDateFormat: 'dd.MM.yyyy',
    timezones: ['Europe/Zurich'],
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'dd-MM-yyyy',
    timezones: ['America/Santiago', 'Pacific/Easter'],
  },
  {
    countryCode: 'CN',
    countryName: 'China',
    languageCode: 'zh',
    languageName: 'Chinese',
    preferredDateFormat: 'yyyy-M-d',
    timezones: ['Asia/Shanghai', 'Asia/Urumqi'],
  },
  {
    countryCode: 'CO',
    countryName: 'Colombia',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'd/MM/yyyy',
    timezones: ['America/Bogota'],
  },
  {
    countryCode: 'CR',
    countryName: 'Costa Rica',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['America/Costa_Rica'],
  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
    languageCode: 'el',
    languageName: 'Greek',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Nicosia'],
  },
  {
    countryCode: 'CZ',
    countryName: 'Czech Republic',
    languageCode: 'cs',
    languageName: 'Czech',
    preferredDateFormat: 'd.M.yyyy',
    timezones: ['Europe/Prague'],
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    languageCode: 'de',
    languageName: 'German',
    preferredDateFormat: 'dd.MM.yyyy',
    timezones: ['Europe/Berlin', 'Europe/Busingen'],
  },
  {
    countryCode: 'DK',
    countryName: 'Denmark',
    languageCode: 'da',
    languageName: 'Danish',
    preferredDateFormat: 'dd-MM-yyyy',
    timezones: ['Europe/Copenhagen'],
  },
  {
    countryCode: 'DO',
    countryName: 'Dominican Republic',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'MM/dd/yyyy',
    timezones: ['America/Santo_Domingo'],
  },
  {
    countryCode: 'DZ',
    countryName: 'Algeria',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Africa/Algiers'],
  },
  {
    countryCode: 'EC',
    countryName: 'Ecuador',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['America/Guayaquil', 'Pacific/Galapagos'],
  },
  {
    countryCode: 'EG',
    countryName: 'Egypt',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Africa/Cairo'],
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'd/MM/yyyy',
    timezones: ['Europe/Madrid', 'Africa/Ceuta', 'Atlantic/Canary'],
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    languageCode: 'ca',
    languageName: 'Catalan',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Europe/Madrid', 'Africa/Ceuta', 'Atlantic/Canary'],
  },
  {
    countryCode: 'EE',
    countryName: 'Estonia',
    languageCode: 'et',
    languageName: 'Estonian',
    preferredDateFormat: 'd.MM.yyyy',
    timezones: ['Europe/Tallinn'],
  },
  {
    countryCode: 'FI',
    countryName: 'Finland',
    languageCode: 'fi',
    languageName: 'Finnish',
    preferredDateFormat: 'd.M.yyyy',
    timezones: ['Europe/Helsinki'],
  },
  {
    countryCode: 'FR',
    countryName: 'France',
    languageCode: 'fr',
    languageName: 'French',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Europe/Paris'],
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom',
    languageCode: 'en',
    languageName: 'English',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Europe/London'],
  },
  {
    countryCode: 'GR',
    countryName: 'Greece',
    languageCode: 'el',
    languageName: 'Greek',
    preferredDateFormat: 'd/M/yyyy',
    timezones: ['Europe/Athens'],
  },
  {
    countryCode: 'GT',
    countryName: 'Guatemala',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'd/MM/yyyy',
    timezones: ['America/Guatemala'],
  },
  {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    languageCode: 'zh',
    languageName: 'Chinese',
    preferredDateFormat: 'yyyy年M月d日',
    timezones: ['Asia/Hong_Kong'],
  },
  {
    countryCode: 'HN',
    countryName: 'Honduras',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'MM-dd-yyyy',
    timezones: ['America/Tegucigalpa'],
  },
  {
    countryCode: 'HR',
    countryName: 'Croatia',
    languageCode: 'hr',
    languageName: 'Croatian',
    preferredDateFormat: 'dd.MM.yyyy.',
    timezones: ['Europe/Zagreb'],
  },
  {
    countryCode: 'HU',
    countryName: 'Hungary',
    languageCode: 'hu',
    languageName: 'Hungarian',
    preferredDateFormat: 'yyyy.MM.dd.',
    timezones: ['Europe/Budapest'],
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    languageCode: 'in',
    languageName: 'Indonesian',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: [
      'Asia/Jakarta',
      'Asia/Pontianak',
      'Asia/Makassar',
      'Asia/Jayapura',
    ],
  },
  {
    countryCode: 'IN',
    countryName: 'India',
    languageCode: 'hi',
    languageName: 'Hindi',
    preferredDateFormat: '३/६/१२',
    timezones: ['Asia/Kolkata'],
  },
  {
    countryCode: 'IN',
    countryName: 'India',
    languageCode: 'en',
    languageName: 'English',
    preferredDateFormat: 'd/M/yyyy',
    timezones: ['Asia/Kolkata'],
  },
  {
    countryCode: 'IE',
    countryName: 'Ireland',
    languageCode: 'ga',
    languageName: 'Irish',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Europe/Dublin'],
  },
  {
    countryCode: 'IE',
    countryName: 'Ireland',
    languageCode: 'en',
    languageName: 'English',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Europe/Dublin'],
  },
  {
    countryCode: 'IQ',
    countryName: 'Iraq',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Baghdad'],
  },
  {
    countryCode: 'IS',
    countryName: 'Iceland',
    languageCode: 'is',
    languageName: 'Icelandic',
    preferredDateFormat: 'd.M.yyyy',
    timezones: ['Atlantic/Reykjavik'],
  },
  {
    countryCode: 'IL',
    countryName: 'Israel',
    languageCode: 'iw',
    languageName: 'Hebrew',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Jerusalem'],
  },
  {
    countryCode: 'IT',
    countryName: 'Italy',
    languageCode: 'it',
    languageName: 'Italian',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Europe/Rome'],
  },
  {
    countryCode: 'JO',
    countryName: 'Jordan',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Amman'],
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    languageCode: 'ja',
    languageName: 'Japanese',
    preferredDateFormat: 'yyyy/MM/dd',
    timezones: ['Asia/Tokyo'],
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    languageCode: 'ja',
    languageName: 'Japanese',
    preferredDateFormat: 'H24.MM.dd',
    timezones: ['Asia/Tokyo'],
  },
  {
    countryCode: 'KR',
    countryName: 'South Korea',
    languageCode: 'ko',
    languageName: 'Korean',
    preferredDateFormat: 'yyyy. M. d',
    timezones: ['Asia/Seoul'],
  },
  {
    countryCode: 'KW',
    countryName: 'Kuwait',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Kuwait'],
  },
  {
    countryCode: 'LB',
    countryName: 'Lebanon',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Beirut'],
  },
  {
    countryCode: 'LY',
    countryName: 'Libya',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Africa/Tripoli'],
  },
  {
    countryCode: 'LT',
    countryName: 'Lithuania',
    languageCode: 'lt',
    languageName: 'Lithuanian',
    preferredDateFormat: 'yyyy.M.d',
    timezones: ['Europe/Vilnius'],
  },
  {
    countryCode: 'LU',
    countryName: 'Luxembourg',
    languageCode: 'fr',
    languageName: 'French',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Europe/Luxembourg'],
  },
  {
    countryCode: 'LU',
    countryName: 'Luxembourg',
    languageCode: 'de',
    languageName: 'German',
    preferredDateFormat: 'dd.MM.yyyy',
    timezones: ['Europe/Luxembourg'],
  },
  {
    countryCode: 'LV',
    countryName: 'Latvia',
    languageCode: 'lv',
    languageName: 'Latvian',
    preferredDateFormat: 'yyyy.d.M',
    timezones: ['Europe/Riga'],
  },
  {
    countryCode: 'MA',
    countryName: 'Morocco',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Africa/Casablanca'],
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'd/MM/yyyy',
    timezones: [
      'America/Mexico_City',
      'America/Cancun',
      'America/Merida',
      'America/Monterrey',
      'America/Matamoros',
      'America/Mazatlan',
      'America/Chihuahua',
      'America/Ojinaga',
      'America/Hermosillo',
      'America/Tijuana',
      'America/Bahia_Banderas',
    ],
  },
  {
    countryCode: 'MK',
    countryName: 'Macedonia',
    languageCode: 'mk',
    languageName: 'Macedonian',
    preferredDateFormat: 'd.M.yyyy',
    timezones: ['Europe/Skopje'],
  },
  {
    countryCode: 'MT',
    countryName: 'Malta',
    languageCode: 'en',
    languageName: 'English',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Europe/Malta'],
  },
  {
    countryCode: 'MT',
    countryName: 'Malta',
    languageCode: 'mt',
    languageName: 'Maltese',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Europe/Malta'],
  },
  {
    countryCode: 'ME',
    countryName: 'Montenegro',
    languageCode: 'sr',
    languageName: 'Serbian',
    preferredDateFormat: 'd.M.yyyy.',
    timezones: ['Europe/Podgorica'],
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    languageCode: 'ms',
    languageName: 'Malay',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Kuala_Lumpur', 'Asia/Kuching'],
  },
  {
    countryCode: 'NI',
    countryName: 'Nicaragua',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'MM-dd-yyyy',
    timezones: ['America/Managua'],
  },
  {
    countryCode: 'NL',
    countryName: 'Netherlands',
    languageCode: 'nl',
    languageName: 'Dutch',
    preferredDateFormat: 'd-M-yyyy',
    timezones: ['Europe/Amsterdam'],
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
    languageCode: 'no',
    languageName: 'Norwegian',
    preferredDateFormat: 'dd.MM.yyyy',
    timezones: ['Europe/Oslo'],
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
    languageCode: 'no',
    languageName: 'Norwegian',
    preferredDateFormat: 'dd.MM.yyyy',
    timezones: ['Europe/Oslo'],
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    languageCode: 'en',
    languageName: 'English',
    preferredDateFormat: 'd/MM/yyyy',
    timezones: ['Pacific/Auckland', 'Pacific/Chatham'],
  },
  {
    countryCode: 'OM',
    countryName: 'Oman',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Muscat'],
  },
  {
    countryCode: 'PA',
    countryName: 'Panama',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'MM/dd/yyyy',
    timezones: ['America/Panama'],
  },
  {
    countryCode: 'PE',
    countryName: 'Peru',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['America/Lima'],
  },
  {
    countryCode: 'PH',
    countryName: 'Philippines',
    languageCode: 'en',
    languageName: 'English',
    preferredDateFormat: 'M/d/yyyy',
    timezones: ['Asia/Manila'],
  },
  {
    countryCode: 'PL',
    countryName: 'Poland',
    languageCode: 'pl',
    languageName: 'Polish',
    preferredDateFormat: 'dd.MM.yyyy',
    timezones: ['Europe/Warsaw'],
  },
  {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'MM-dd-yyyy',
    timezones: ['America/Puerto_Rico'],
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    languageCode: 'pt',
    languageName: 'Portuguese',
    preferredDateFormat: 'dd-MM-yyyy',
    timezones: ['Europe/Lisbon', 'Atlantic/Madeira', 'Atlantic/Azores'],
  },
  {
    countryCode: 'PY',
    countryName: 'Paraguay',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['America/Asuncion'],
  },
  {
    countryCode: 'QA',
    countryName: 'Qatar',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Qatar'],
  },
  {
    countryCode: 'RO',
    countryName: 'Romania',
    languageCode: 'ro',
    languageName: 'Romanian',
    preferredDateFormat: 'dd.MM.yyyy',
    timezones: ['Europe/Bucharest'],
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    languageCode: 'ru',
    languageName: 'Russian',
    preferredDateFormat: 'dd.MM.yyyy',
    timezones: [
      'Europe/Kaliningrad',
      'Europe/Moscow',
      'Europe/Simferopol',
      'Europe/Volgograd',
      'Europe/Kirov',
      'Europe/Astrakhan',
      'Europe/Samara',
      'Europe/Ulyanovsk',
      'Asia/Yekaterinburg',
      'Asia/Omsk',
      'Asia/Novosibirsk',
      'Asia/Barnaul',
      'Asia/Tomsk',
      'Asia/Novokuznetsk',
      'Asia/Krasnoyarsk',
      'Asia/Irkutsk',
      'Asia/Chita',
      'Asia/Yakutsk',
      'Asia/Khandyga',
      'Asia/Vladivostok',
      'Asia/Ust-Nera',
      'Asia/Magadan',
      'Asia/Sakhalin',
      'Asia/Srednekolymsk',
      'Asia/Kamchatka',
      'Asia/Anadyr',
    ],
  },
  {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Riyadh'],
  },
  {
    countryCode: 'CS',
    countryName: 'Serbia and Montenegro',
    languageCode: 'sr',
    languageName: 'Serbian',
    preferredDateFormat: 'd.M.yyyy.',
    timezones: 'N/A',
  },
  {
    countryCode: 'SD',
    countryName: 'Sudan',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Africa/Khartoum'],
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    languageCode: 'zh',
    languageName: 'Chinese',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Singapore'],
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    languageCode: 'en',
    languageName: 'English',
    preferredDateFormat: 'M/d/yyyy',
    timezones: ['Asia/Singapore'],
  },
  {
    countryCode: 'SV',
    countryName: 'El Salvador',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'MM-dd-yyyy',
    timezones: ['America/El_Salvador'],
  },
  {
    countryCode: 'RS',
    countryName: 'Serbia',
    languageCode: 'sr',
    languageName: 'Serbian',
    preferredDateFormat: 'd.M.yyyy.',
    timezones: ['Europe/Belgrade'],
  },
  {
    countryCode: 'SK',
    countryName: 'Slovakia',
    languageCode: 'sk',
    languageName: 'Slovak',
    preferredDateFormat: 'd.M.yyyy',
    timezones: ['Europe/Bratislava'],
  },
  {
    countryCode: 'SI',
    countryName: 'Slovenia',
    languageCode: 'sl',
    languageName: 'Slovenian',
    preferredDateFormat: 'd.M.yyyy',
    timezones: ['Europe/Ljubljana'],
  },
  {
    countryCode: 'SE',
    countryName: 'Sweden',
    languageCode: 'sv',
    languageName: 'Swedish',
    preferredDateFormat: 'yyyy-MM-dd',
    timezones: ['Europe/Stockholm'],
  },
  {
    countryCode: 'SY',
    countryName: 'Syria',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Damascus'],
  },
  {
    countryCode: 'TH',
    countryName: 'Thailand',
    languageCode: 'th',
    languageName: 'Thai',
    preferredDateFormat: 'd/M/2555',
    timezones: ['Asia/Bangkok'],
  },
  {
    countryCode: 'TH',
    countryName: 'Thailand',
    languageCode: 'th',
    languageName: 'Thai',
    preferredDateFormat: '๓/๖/๒๕๕๕',
    timezones: ['Asia/Bangkok'],
  },
  {
    countryCode: 'TN',
    countryName: 'Tunisia',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Africa/Tunis'],
  },
  {
    countryCode: 'TR',
    countryName: 'Turkey',
    languageCode: 'tr',
    languageName: 'Turkish',
    preferredDateFormat: 'dd.MM.yyyy',
    timezones: ['Europe/Istanbul'],
  },
  {
    countryCode: 'TW',
    countryName: 'Taiwan',
    languageCode: 'zh',
    languageName: 'Chinese',
    preferredDateFormat: 'yyyy/M/d',
    timezones: ['Asia/Taipei'],
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    languageCode: 'uk',
    languageName: 'Ukrainian',
    preferredDateFormat: 'dd.MM.yyyy',
    timezones: ['Europe/Kiev', 'Europe/Uzhgorod', 'Europe/Zaporozhye'],
  },
  {
    countryCode: 'UY',
    countryName: 'Uruguay',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['America/Montevideo'],
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    languageCode: 'en',
    languageName: 'English',
    preferredDateFormat: 'M/d/yyyy',
    timezones: [
      'America/New_York',
      'America/Detroit',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/Indiana/Indianapolis',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Vevay',
      'America/Chicago',
      'America/Indiana/Tell_City',
      'America/Indiana/Knox',
      'America/Menominee',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/North_Dakota/Beulah',
      'America/Denver',
      'America/Boise',
      'America/Phoenix',
      'America/Los_Angeles',
      'America/Anchorage',
      'America/Juneau',
      'America/Sitka',
      'America/Metlakatla',
      'America/Yakutat',
      'America/Nome',
      'America/Adak',
      'Pacific/Honolulu',
    ],
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'M/d/yyyy',
    timezones: [
      'America/New_York',
      'America/Detroit',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/Indiana/Indianapolis',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Vevay',
      'America/Chicago',
      'America/Indiana/Tell_City',
      'America/Indiana/Knox',
      'America/Menominee',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/North_Dakota/Beulah',
      'America/Denver',
      'America/Boise',
      'America/Phoenix',
      'America/Los_Angeles',
      'America/Anchorage',
      'America/Juneau',
      'America/Sitka',
      'America/Metlakatla',
      'America/Yakutat',
      'America/Nome',
      'America/Adak',
      'Pacific/Honolulu',
    ],
  },
  {
    countryCode: 'VE',
    countryName: 'Venezuela',
    languageCode: 'es',
    languageName: 'Spanish',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['America/Caracas'],
  },
  {
    countryCode: 'VN',
    countryName: 'Vietnam',
    languageCode: 'vi',
    languageName: 'Vietnamese',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Ho_Chi_Minh'],
  },
  {
    countryCode: 'YE',
    countryName: 'Yemen',
    languageCode: 'ar',
    languageName: 'Arabic',
    preferredDateFormat: 'dd/MM/yyyy',
    timezones: ['Asia/Aden'],
  },
  {
    countryCode: 'ZA',
    countryName: 'South Africa',
    languageCode: 'en',
    languageName: 'English',
    preferredDateFormat: 'yyyy/MM/dd',
    timezones: ['Africa/Johannesburg'],
  },
]
