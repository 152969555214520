import { PayloadAction } from '@reduxjs/toolkit'
import { difference, omitBy } from 'lodash'
import { ISupplierResponse, IContactResponse } from '../interfaces/supplier-response'
import { SuppliersState } from './index'
import { normalizeSupplier } from '../interfaces/supplier-normalized'

export const setSupplierCase = (state: SuppliersState, action: PayloadAction<ISupplierResponse>) => {
  state.byIds[action.payload.id] = normalizeSupplier(action.payload)
  if (!state.allIds.includes(action.payload.id)) {
    state.allIds.push(action.payload.id)
  }
}

export const setSuppliersCase = (state: SuppliersState, action: PayloadAction<ISupplierResponse[]>) => {
  action.payload.forEach((supplier) => {
    state.byIds[supplier.id] = normalizeSupplier(supplier)
    if (!state.allIds.includes(supplier.id)) state.allIds.push(supplier.id)
  })
}

export const setContactCase = (state: SuppliersState, action: PayloadAction<IContactResponse>) => {
  if (action.payload.supplierId) {
    const supplier = state.byIds[action.payload.supplierId]
    if (supplier && 'contacts' in supplier) {
      supplier.contacts[action.payload.id] = action.payload
      state.byIds[supplier.id] = supplier
    }
  }
}

export const deleteContactCase = (
  state: SuppliersState,
  action: PayloadAction<{
    supplierId: string
    contactId: string
  }>
) => {
  const { supplierId, contactId } = action.payload
  const supplier = state.byIds[supplierId]
  if (supplier && 'contacts' in supplier) {
    supplier.contacts = omitBy(supplier.contacts, { id: contactId })
    const isDefaultContact = supplier.defaultContact?.id === contactId
    if (isDefaultContact) supplier.defaultContact = null
    state.byIds[supplierId] = supplier
  }
}

export const deleteOrganisationCase = (state: SuppliersState, action: PayloadAction<string>) => {
  const supplierIdsToDelete: string[] = []
  state.allIds.forEach((id) => {
    const supplier = state.byIds[id]
    if (supplier.orgId === action.payload) supplierIdsToDelete.push(id)
  })
  state.byIds = omitBy(state.byIds, { orgId: action.payload })
  state.allIds = difference(state.allIds, supplierIdsToDelete)
}
