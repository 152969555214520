import * as OrganisationAPI from '../services/organisation-api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IOrganisationAPI } from '../interfaces/organisation-api'

const makeCreateOrganisation = (organisationAPI: IOrganisationAPI) => {
  return createAsyncThunk(
    'orgs/createOrganisation',
    async (payload: OrganisationAPI.NewOrganisationData) => await organisationAPI.createOrganisation(payload)
  )
}

const makeFetchOrganisation = (organisationAPI: IOrganisationAPI) => {
  return createAsyncThunk(
    'orgs/fetchOrganisation',
    async (orgId: string) => await organisationAPI.fetchOrganisation(orgId)
  )
}

const makeFetchOrganisations = (organisationAPI: IOrganisationAPI) => {
  return createAsyncThunk('orgs/fetchOrganisations', async () => await organisationAPI.fetchOrganisations())
}

const makeUpdateOrganisationInfo = (organisationAPI: IOrganisationAPI) => {
  return createAsyncThunk(
    'orgs/updateOrgainsationInfo',
    async ({ orgId, ...payload }: OrganisationAPI.OrganisationInfoUpdateData & { orgId: string }) =>
      await organisationAPI.updateOrganisationInfo(orgId, payload)
  )
}

const makeDeleteOrganisation = (organisationAPI: IOrganisationAPI) => {
  return createAsyncThunk('orgs/deleteOrganisation', async (orgId: string) => {
    await organisationAPI.deleteOrganisation(orgId)
    return orgId
  })
}

export const DeleteOrganisation = makeDeleteOrganisation(OrganisationAPI)
export const CreateOrganisation = makeCreateOrganisation(OrganisationAPI)
export const FetchOrganisation = makeFetchOrganisation(OrganisationAPI)
export const FetchOrganisations = makeFetchOrganisations(OrganisationAPI)
export const UpdateOrganisationInfo = makeUpdateOrganisationInfo(OrganisationAPI)
