import CircularProgress from '@material-ui/core/CircularProgress'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import FormikInput from './FormikInput'

import { Check, AlertTriangle } from 'react-feather'
import { makeStyles, Theme, TextFieldProps } from '@material-ui/core'
import { useI18n, useNetworkStatus } from '../hooks'
import { Form, Formik, FormikHelpers, FormikConfig } from 'formik'
import { capitalize } from 'lodash'
import { StringMap } from '../types/common'

const AutoSaveInput = ({
  name,
  initialValue,
  onSave,
  label,
  required,
  FormikConfig = {},
  ...TextFieldProps
}: AutoSaveInputProps) => {
  const translations = useTranslations()
  const classes = useStyles()
  const networkStatus = useNetworkStatus({ resetDelayInMS: 2000 })

  const { isIdle, isFulfilled, isPending, isRejected, setStatus } = networkStatus

  const handleSubmit = async (values: any, { setSubmitting, setErrors }: FormikHelpers<any>) => {
    setStatus('pending')
    try {
      await onSave(values)
      setStatus('fulfilled')
      setSubmitting(false)
    } catch (error) {
      setStatus('rejected')
      if (error instanceof Error) return setErrors({ [name]: error.message })
      if (typeof error === 'string') return setErrors({ [name]: error })
      return setErrors({ [name]: translations.appFallbackErrorMessage })
    }
  }

  const inputLabel = label ? `${label}:` : `${capitalize(name)}:`
  let chipLabel = ''
  let chipIcon: null | JSX.Element = null
  let chipColor: 'default' | 'secondary' | 'primary' = 'default'

  if (isPending()) {
    chipLabel = translations.saving
    chipIcon = <CircularProgress size={12} />
  }

  if (isRejected()) {
    chipLabel = translations.error
    chipColor = 'secondary'
    chipIcon = <AlertTriangle size={12} />
  }

  if (isFulfilled()) {
    chipLabel = translations.saved
    chipColor = 'primary'
    chipIcon = <Check size={12} />
  }

  const formikConfig: FormikConfig<any> = {
    ...FormikConfig,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    initialValues: { [name]: initialValue },
  }

  return (
    <Formik {...formikConfig}>
      {({ submitForm, dirty }) => {
        return (
          <Form className={classes.form}>
            <div className={classes.labelContainer}>
              <Typography variant="subtitle2" component="label" className={classes.label}>
                {inputLabel}
                {required && (
                  <Typography component="span" color="textSecondary" variant="caption">
                    (required)
                  </Typography>
                )}
              </Typography>
              {chipLabel && chipIcon && (
                <Chip
                  variant="outlined"
                  label={chipLabel}
                  color={chipColor}
                  size="small"
                  className={classes.chip}
                  icon={chipIcon}
                />
              )}
            </div>

            <FormikInput
              {...TextFieldProps}
              id={name}
              name={name}
              variant="outlined"
              fullWidth
              disabled={!isIdle() || TextFieldProps?.disabled}
              onBlur={dirty ? submitForm : () => {}}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: theme.palette.text.primary,
      cursor: 'not-allowed',
    },
  },
  labelContainer: {
    display: 'flex',
    padding: theme.spacing(0.5, 0),
    alignItems: 'center',
    '& > div ': {
      marginLeft: theme.spacing(2),
    },
  },
  label: {
    fontWeight: 300,
    letterSpacing: theme.spacing(0.1),
  },
  chip: {
    border: 'none',
    '&.MuiChip-colorSecondary': {
      color: theme.palette.error.light,
    },
  },
  helperText: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
}))

type AutoSaveInputProps = TextFieldProps & {
  label?: string
  name: string
  initialValue: string
  onSave: (values: any) => Promise<any>
  disabled?: boolean
  required?: boolean
  FormikConfig?: Partial<FormikConfig<any>>
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translations')
  const translations = t || ({} as StringMap)
  const {
    required = defaults.required,
    saving = defaults.saving,
    error = defaults.error,
    saved = defaults.saved,
    appFallbackErrorMessage = defaults.appFallbackErrorMessage,
  } = translations

  return {
    required,
    saving,
    error,
    saved,
    appFallbackErrorMessage,
  }
}
const defaultTranslations = {
  required: 'Required',
  saving: 'Saving',
  error: 'Error',
  saved: 'Saved',
  appFallbackErrorMessage: 'Oops! Something went wrong',
}
type Translations = typeof defaultTranslations
export default AutoSaveInput
