import * as PermissionsApi from '../services/permissions-api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PermissionsAPI as PermissionApiType } from '../interfaces/permissions-api'
import { SetMembership } from '../../memberships/store'

export const makeFetchMembershipPermissions = (permissionsAPI: PermissionApiType) => {
  return createAsyncThunk('permissions/fetchMembershipPermissions', async (membershipId: string) => {
    return await permissionsAPI.fetchMembershipPermissions(membershipId)
  })
}

const makeUpdateMemberAbilitiesInResources = (permissionsAPI: PermissionApiType) => {
  return createAsyncThunk(
    'memberships/updateMemberAbilitiesInResources',
    async ({ membershipId, ...updateData }: MemberAbilitiesInResourcesUpdateThunkArg) => {
      return await permissionsAPI.updateMemberAbilitiesInResources(membershipId, updateData)
    }
  )
}

const makeUpdateMemberAssociations = (permissionsAPI: PermissionApiType) => {
  return createAsyncThunk(
    'memberships/updateMemberAssociations',
    async ({ membershipId, ...updateData }: MemberAssociationsUpdateThunkArg) => {
      return await permissionsAPI.updateMemberAssociations(membershipId, updateData)
    }
  )
}

const makeUpdateMembershipAbilities = (permissionsAPI: PermissionApiType) => {
  return createAsyncThunk(
    'permissions/updateMembershipAbilities',
    async (updateData: PermissionsApi.MembershipAbilitiesUpdateData) => {
      return await permissionsAPI.updateMembershipAbilities(updateData)
    }
  )
}

const makeCopyMembershipPermissions = (permissionsAPI: PermissionApiType) => {
  return createAsyncThunk(
    'permissions/copyMembershipPermissions',
    async ({ membershipId, ...updateData }: MembershipPermissionsCopyThunkArg, { dispatch }) => {
      const res = await permissionsAPI.copyMembershipPermissions(membershipId, updateData)
      const { membership, membershipPermission } = res
      dispatch(SetMembership(membership))
      return membershipPermission
    }
  )
}

const makeFetchManyMembershipPermissions = (permissionsAPI: PermissionApiType) => {
  return createAsyncThunk(
    'permissions/fetchManyMembershipPermissions',
    async ({ organisationId, membershipIds }: FetchManyMembershipPermissionsThunkArg) => {
      return await permissionsAPI.fetchManyMembershipPermissions(organisationId, {
        params: makeParams(membershipIds),
      })
    }
  )
}

export const FetchMembershipPermissions = makeFetchMembershipPermissions(PermissionsApi)
export const UpdateMemberAbilitiesInResources = makeUpdateMemberAbilitiesInResources(PermissionsApi)
export const UpdateMemberAssociations = makeUpdateMemberAssociations(PermissionsApi)
export const UpdateMembershipAbilities = makeUpdateMembershipAbilities(PermissionsApi)
export const CopyMembershipPermissions = makeCopyMembershipPermissions(PermissionsApi)
export const FetchManyMembershipPermissions = makeFetchManyMembershipPermissions(PermissionsApi)

const makeParams = (ids: string[]) => {
  return new URLSearchParams({ membershipIds: ids.join(',') })
}

type MemberAbilitiesInResourcesUpdateThunkArg = {
  membershipId: string
} & PermissionsApi.MemberAbilitiesInResourcesUpdateData

type MemberAssociationsUpdateThunkArg = {
  membershipId: string
} & PermissionsApi.MemberAssociationsUpdateData

type MembershipPermissionsCopyThunkArg = {
  membershipId: string
} & PermissionsApi.MembershipPermissionsCopyData
type FetchManyMembershipPermissionsThunkArg = {
  organisationId: string
  membershipIds: string[]
}
