import { z } from 'zod'
import { useI18n } from './use-i18n'

let schemas: Record<string, z.Schema> | null = null

export const useZodSchemas = () => {
  const {
    passwordLengthErrorMax,
    passwordLengthErrorMin,
    passwordLowercaseError,
    passwordNumberError,
    passwordUppercaseError,
    emailRequiredError,
    emailInvalidError,
    emailLengthErrorMax,
  } = useTranslations()

  if (schemas !== null) return schemas

  const ZodPassword = z
    .string()
    .min(8, { message: passwordLengthErrorMin })
    .max(128, { message: passwordLengthErrorMax })
    .refine((password) => /[a-z]/.test(password), { message: passwordLowercaseError })
    .refine((password) => /[A-Z]/.test(password), { message: passwordUppercaseError })
    .refine((password) => /[0-9]/.test(password), { message: passwordNumberError })

  const ZodEmail = z.string({ message: emailRequiredError }).email(emailInvalidError).max(256, emailLengthErrorMax)

  schemas = {
    ZodPassword,
    ZodEmail,
  }

  return schemas
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations = {} } = useI18n('translation')

  return {
    passwordLengthErrorMax: translate('passwordLengthErrorMax'),
    passwordLengthErrorMin: translate('passwordLengthErrorMin'),
    passwordLowercaseError: translate('passwordLowercaseError'),
    passwordNumberError: translate('passwordNumberError'),
    passwordUppercaseError: translate('passwordUppercaseError'),
    emailRequiredError: translate('emailRequiredError'),
    emailInvalidError: translate('emailInvalidError'),
    emailLengthErrorMax: translate('emailLengthErrorMax'),
  }

  function translate(key: keyof Translations): string {
    return translations[key] || defaults[key]
  }
}

const defaultTranslations = {
  passwordLengthErrorMin: 'Password must be at least 8 characters long',
  passwordLengthErrorMax: 'Password must be at most 128 characters long',
  passwordLowercaseError: 'Password must contain at least one lowercase letter',
  passwordUppercaseError: 'Password must contain at least one uppercase letter',
  passwordNumberError: 'Password must contain at least one number',
  emailRequiredError: 'Email is required',
  emailInvalidError: 'Invalid email',
  emailLengthErrorMax: 'Email must be at most 256 characters long',
}

type Translations = typeof defaultTranslations
