import { api } from '../../api'
import { ProjectViewModel, makeProjectViewModel } from '../../projects/api/project'
import { ProjectResponse } from '../../projects/api/project-response'
import { TaskViewModel, makeTaskViewModel } from '../../tasks/api/task'
import { TaskResponse } from '../../tasks/api/task-response'
import { TodoViewModel, makeTodoViewModel } from '../../todos/api/todo'
import { TodoResponse } from '../../todos/api/todo-response'
import { Board } from '../entities/board'
import { BoardType } from '../api/board'
import { BoardResponse } from './board-response'
import { BoardViewModel, getBoardDataFromPopulatedBoard, makeBoardViewModel } from './board'
import { makeBoardApiEndpoints } from './endpoints'

const apiEndpoints = makeBoardApiEndpoints()

export const boardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBoard: builder.query<GetBoardReturnData, { id: string; type: BoardType }>({
      query: ({ id, type }) => ({
        url: apiEndpoints.boardItem(id),
        params: { type },
        method: 'GET',
      }),
      transformResponse: (response: GetBoardResponseData) => {
        return {
          board: makeBoardViewModel(response.board),
          tasks: response.tasks.map(makeTaskViewModel),
          todos: response.todos.map(makeTodoViewModel),
        }
      },
      providesTags: (result) => ['Task', 'Todo', { type: 'Board', id: result?.board.id }],
    }),
    addTaskCard: builder.mutation<{ board: BoardViewModel; task: TaskViewModel }, NewCardData>({
      query: ({ boardId, columnId, ...newCardData }) => ({
        url: apiEndpoints.boardTaskCards(columnId, boardId),
        method: 'POST',
        body: { ...newCardData, type: 'task' },
      }),
      transformResponse: (response: { board: BoardResponse; task: TaskResponse }) => ({
        board: makeBoardViewModel(response.board),
        task: makeTaskViewModel(response.task),
      }),
      invalidatesTags: (result) => {
        return ['Task', { type: 'Board', id: result?.board.id }, { type: 'Task', id: result?.task.id }]
      },
    }),
    addTodoCard: builder.mutation<{ board: BoardViewModel; todo: TodoViewModel }, NewCardData>({
      query: ({ boardId, columnId, ...newCardData }) => ({
        url: apiEndpoints.boardTodoCards(columnId, boardId),
        method: 'POST',
        body: { ...newCardData, type: 'todo' },
      }),
      transformResponse: (response: { board: BoardResponse; todo: TodoResponse }) => {
        return {
          board: makeBoardViewModel(response.board),
          todo: makeTodoViewModel(response.todo),
        }
      },
      invalidatesTags: (result) => [
        'Todo',
        { type: 'Board', id: result?.board.id },
        { type: 'Todo', id: result?.todo.id },
      ],
    }),
    moveTodoCard: builder.mutation<{ board: BoardViewModel; todo: TodoViewModel }, MoveCardData>({
      query: ({ boardId, cardId, columnId, ...moveCardData }) => ({
        url: apiEndpoints.boardTodoCardItem(cardId, columnId, boardId),
        method: 'PUT',
        body: moveCardData,
      }),
      transformResponse: (response: { board: BoardResponse; todo: TodoResponse }) => {
        return {
          board: makeBoardViewModel(response.board),
          todo: makeTodoViewModel(response.todo),
        }
      },
      invalidatesTags: (result) => [
        { type: 'Board', id: result?.board.id },
        { type: 'Todo', id: result?.todo.id },
      ],
      onQueryStarted: async ({ boardId, ...moveCardData }, { getState, dispatch, queryFulfilled }) => {
        const state = getState()
        const populatedBoard = selectBoardById(state, boardId)

        if (populatedBoard) {
          const boardData = getBoardDataFromPopulatedBoard(populatedBoard)
          const board = new Board(boardData)
          board.moveCard(moveCardData.cardId, moveCardData.columnId, moveCardData.destColumnId, moveCardData.position)
          const patchResult = dispatch(
            api.util.updateQueryData(
              // @ts-ignore
              'getBoard',
              { id: boardId, type: board.type },
              (draft) => {
                Object.assign(draft, {
                  board: makeBoardViewModel(board.toObject()),
                })
              }
            )
          )
          try {
            await queryFulfilled
          } catch (error) {
            patchResult.undo()
          }
        }
      },
    }),
    moveTaskCard: builder.mutation<MoveTaskCardReturnData, MoveCardData>({
      query: ({ boardId, cardId, columnId, ...moveCardData }) => ({
        url: apiEndpoints.boardTaskCardItem(cardId, columnId, boardId),
        method: 'PUT',
        body: moveCardData,
      }),
      transformResponse: (response: MoveTaskCardResponseData) => {
        return {
          project: makeProjectViewModel(response.project),
          board: makeBoardViewModel(response.board),
          tasks: response.tasks.map(makeTaskViewModel),
        }
      },
      invalidatesTags: (result) => [
        'Task',
        { type: 'Board', id: result?.board.id },
        { type: 'Project', id: result?.project.id },
      ],
      onQueryStarted: async ({ boardId, ...moveCardData }, { getState, dispatch, queryFulfilled }) => {
        const state = getState()
        const populatedBoard = selectBoardById(state, boardId)

        if (populatedBoard) {
          const boardData = getBoardDataFromPopulatedBoard(populatedBoard)
          const board = new Board(boardData)
          board.moveCard(moveCardData.cardId, moveCardData.columnId, moveCardData.destColumnId, moveCardData.position)
          const patchResult = dispatch(
            api.util.updateQueryData(
              // @ts-ignore
              'getBoard',
              { id: boardId, type: board.type },
              (draft) => {
                Object.assign(draft, {
                  board: makeBoardViewModel(board.toObject()),
                })
              }
            )
          )
          try {
            await queryFulfilled
          } catch (error) {
            patchResult.undo()
          }
        }
      },
    }),
    updateColumn: builder.mutation<BoardViewModel, BoardColumnUpdateData>({
      query: ({ boardId, columnId, ...updateData }) => ({
        url: apiEndpoints.boardColumnItem(columnId, boardId),
        method: 'PUT',
        body: updateData,
      }),
      transformResponse: makeBoardViewModel,
      invalidatesTags: (result) => [{ type: 'Board', id: result?.id }],
    }),
    updateColumnName: builder.mutation<BoardViewModel[], BoardColumnNameUpdateData>({
      query: ({ boardId, columnId, ...updateData }) => ({
        url: apiEndpoints.columnName(columnId, boardId),
        method: 'PUT',
        body: updateData,
      }),
      transformResponse: (response: BoardResponse[]) => response.map(makeBoardViewModel),
      invalidatesTags: (result = []) => result.map((board) => ({ type: 'Board', id: board.id })),
    }),
  }),
})

// Sorry about this, did not find a better way to do this
const selectBoardById = (state: any, boardId: string) => {
  let board = null
  const allQueries = state.api.queries
  const getBoardQueryRegex = new RegExp(`getBoard\\(\\{"id":"${boardId}"`)

  for (const queryKey in allQueries) {
    if (getBoardQueryRegex.test(queryKey)) {
      // @ts-ignore
      board = allQueries[queryKey]?.data?.board
      break
    }
  }
  return board
}

export const {
  useGetBoardQuery,
  useAddTaskCardMutation,
  useAddTodoCardMutation,
  useMoveTodoCardMutation,
  useMoveTaskCardMutation,
  useUpdateColumnMutation,
  useUpdateColumnNameMutation,
} = boardApi

export type MoveCardData = {
  boardId: string
  cardId: string
  columnId: string
  destColumnId: string
  position: number
  updateSubtasks?: boolean
}

export type BoardColumnUpdateData = {
  boardId: string
  columnId: string
  isVisible: boolean
}

export type NewCardData = {
  boardId: string
  columnId: string
  title: string
}

export type BoardColumnNameUpdateData = {
  boardId: string
  columnId: string
  name: string
}

type MoveTaskCardResponseData = {
  project: ProjectResponse
  board: BoardResponse
  tasks: TaskResponse[]
}

type MoveTaskCardReturnData = {
  project: ProjectViewModel
  board: BoardViewModel
  tasks: TaskViewModel[]
}

type GetBoardResponseData = {
  board: BoardResponse
  tasks: TaskResponse[]
  todos: TodoResponse[]
}

type GetBoardReturnData = {
  board: BoardViewModel
  tasks: TaskViewModel[]
  todos: TodoViewModel[]
}
