import ResourceManager from '../../components/ResourceManager'
import { useOrgOptions } from '../../options/hooks/use-org-options'
import { StringMap } from '../../types/common'
import { useI18n } from '../../hooks'
import { TodoViewModel } from '../api/todo'
import { useTodoMutations } from '../hooks/use-todo-mutations'

const TodoCustomerWidget = ({ todo, boardId }: TodoCustomerWidgetProps) => {
  const translations = useTranslations()
  const { customerOptions } = useOrgOptions(todo.organisation?.id || '')
  const { updateCustomers } = useTodoMutations()

  const onAddCustomer = (customerIds: string[]) => {
    const customers = todo.customers.map(($r) => $r.id)
    const newCustomers = customers.concat(customerIds)
    updateCustomers(todo.id, { customers: newCustomers, boardId })
  }

  const onRemoveCustomer = (customerId: string) => {
    const customers = todo.customers.filter(($r) => $r.id !== customerId).map(($r) => $r.id)
    updateCustomers(todo.id, { customers, boardId })
  }

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={customerOptions}
      resources={todo.customers}
      onAddResources={onAddCustomer}
      onRemoveResource={onRemoveCustomer}
      canAddResource={todo.canUpdate}
      canRemoveResource={todo.canUpdate}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection
    />
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('todo')
  const translations = (t?.customerWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: 'Customers',
  emptyOptionsMessage: 'No customers available',
  emptyResourcesMessage: 'Todo is not connected to any customers',
}

type Translations = typeof defaultTranslations

type TodoCustomerWidgetProps = {
  todo: TodoViewModel
  boardId?: string
}

export default TodoCustomerWidget
