import ErrorFallback from '../../../components/ErrorFallback'
import { Grid, Theme, Typography, makeStyles } from '@material-ui/core'
import { StringMap } from '../../../types/common'
import { ErrorBoundary } from 'react-error-boundary'
import { Alert, AlertTitle } from '@material-ui/lab'
import { useI18n } from '../../../hooks'
import { useAppDispatch } from '../../../store'
import React from 'react'
import { FetchOrganisation } from '../../../organisations/store/actions'
import { useAsync } from '../../../hooks/use-async'
import Progress from '../../../components/Progress'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppContext } from '../../../hooks/use-app-context'

const useLoader = ({ orgId }: { orgId: string }) => {
  const dispatch = useAppDispatch()
  const loader = React.useCallback(async () => {
    dispatch({ type: `load_org_welcome_route` })
    const result = await dispatch(FetchOrganisation(orgId))
    unwrapResult(result)
  }, [dispatch, orgId])
  return useAsync<void, any>(loader)
}

const OrgWelcomeRoute = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const { appContext } = useAppContext()
  const orgId = appContext.mainContext?.id as string
  const { error, isSuccess } = useLoader({ orgId })

  if (error) {
    return (
      <Alert severity="error" style={{ marginTop: 8 }}>
        <AlertTitle>{translations.error}</AlertTitle>
        {error.message ? error.message : error}
      </Alert>
    )
  }

  if (isSuccess()) {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className={classes.container} data-test="org-welcome-card">
          <Grid direction="column" alignItems="center" justifyContent="center" spacing={2} container>
            <Grid item>
              <Typography variant="h4">{translations.welcomeMessageTitle}</Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {translations.welcomeMessageSubtitle}
              </Typography>
            </Grid>
          </Grid>

          <section className={classes.section}>
            <Typography variant="subtitle1" color="textSecondary">
              {translations.guideMessageTitle}
            </Typography>
          </section>

          <section className={classes.section}>
            <Typography variant="h6" className={classes.sectionHeader}>
              {translations.memberSectionTitle}
            </Typography>
            <Alert severity="info" variant="outlined">
              <Typography color="textSecondary" className={classes.sectionHeader}>
                {translations.memberSubtitle}
              </Typography>
              <Typography variant="subtitle2">{translations.stepsToAddText}</Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step1Text}
              </Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step2Text}
              </Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step3ToAddMemberText}
              </Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step4ToAddMemberText}
              </Typography>
            </Alert>
            <Alert severity="warning" variant="standard" className={classes.pricingAlert}>
              {translations.pricingAlertMessage}
              <a
                href="https://www.starbrix.app/en/pricing"
                target="_blank"
                rel="noreferrer"
                className={classes.ourPricingPlan}
              >
                {translations.ourPricingPlanText}
              </a>
            </Alert>
          </section>

          <section className={classes.section}>
            <Typography variant="h6" className={classes.sectionHeader}>
              {translations.customerSectionTitle}
            </Typography>
            <Alert severity="info" variant="outlined">
              <Typography color="textSecondary" className={classes.sectionHeader}>
                {translations.customerSubtitle}
              </Typography>
              <Typography variant="subtitle2">{translations.stepsToAddText}</Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step1Text}
              </Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step2Text}
              </Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step3ToAddCustomerText}
              </Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step4ToAddCustomerText}
              </Typography>
            </Alert>
          </section>

          <section className={classes.section}>
            <Typography variant="h6" className={classes.sectionHeader}>
              {translations.supplierSectionTitle}
            </Typography>
            <Alert severity="info" variant="outlined">
              <Typography color="textSecondary" className={classes.sectionHeader}>
                {translations.supplierSubtitle}
              </Typography>
              <Typography variant="subtitle2">{translations.stepsToAddText}</Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step1Text}
              </Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step2Text}
              </Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step3ToAddSupplierText}
              </Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step4ToAddSupplierText}
              </Typography>
            </Alert>
          </section>

          <section className={classes.section}>
            <Typography variant="h6" className={classes.sectionHeader}>
              {translations.workspaceSectionTitle}
            </Typography>
            <Alert severity="info" variant="outlined">
              <Typography color="textSecondary" className={classes.sectionHeader}>
                {translations.workspaceSubtitle}
              </Typography>
              <Typography variant="subtitle2">{translations.stepsToAddText}</Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step1Text}
              </Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step2Text}
              </Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step3ToAddWorkspaceText}
              </Typography>
              <Typography variant="caption" component="div" className={classes.stepsToCreate}>
                {translations.step4ToAddWorkspaceText}
              </Typography>
            </Alert>
          </section>
        </div>
      </ErrorBoundary>
    )
  }

  return <Progress label="" />
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translations')
  const { translations: orgTranslations } = useI18n('org')
  const translations = (orgTranslations?.orgWelcomePage || {}) as StringMap
  const { error = defaults.error } = t

  const {
    welcomeMessageTitle = defaults.welcomeMessageTitle,
    welcomeMessageSubtitle = defaults.welcomeMessageSubtitle,
    guideMessageTitle = defaults.guideMessageTitle,
    stepsToAddText = defaults.stepsToAddText,
    step1Text = defaults.step1Text,
    step2Text = defaults.step2Text,

    memberSectionTitle = defaults.memberSectionTitle,
    memberSubtitle = defaults.memberSubtitle,
    pricingAlertMessage = defaults.pricingAlertMessage,
    ourPricingPlanText = defaults.ourPricingPlanText,
    step3ToAddMemberText = defaults.step3ToAddMemberText,
    step4ToAddMemberText = defaults.step4ToAddMemberText,

    customerSectionTitle = defaults.customerSectionTitle,
    customerSubtitle = defaults.customerSubtitle,
    step3ToAddCustomerText = defaults.step3ToAddCustomerText,
    step4ToAddCustomerText = defaults.step4ToAddCustomerText,

    supplierSectionTitle = defaults.supplierSectionTitle,
    supplierSubtitle = defaults.supplierSubtitle,
    step3ToAddSupplierText = defaults.step3ToAddSupplierText,
    step4ToAddSupplierText = defaults.step4ToAddSupplierText,

    workspaceSectionTitle = defaults.workspaceSectionTitle,
    workspaceSubtitle = defaults.workspaceSubtitle,
    step3ToAddWorkspaceText = defaults.step3ToAddWorkspaceText,
    step4ToAddWorkspaceText = defaults.step4ToAddWorkspaceText,
  } = translations

  return {
    welcomeMessageTitle,
    welcomeMessageSubtitle,
    guideMessageTitle,
    stepsToAddText,
    step1Text,
    step2Text,

    memberSectionTitle,
    memberSubtitle,
    pricingAlertMessage,
    ourPricingPlanText,
    step3ToAddMemberText,
    step4ToAddMemberText,

    customerSectionTitle,
    customerSubtitle,
    step3ToAddCustomerText,
    step4ToAddCustomerText,

    supplierSectionTitle,
    supplierSubtitle,
    step3ToAddSupplierText,
    step4ToAddSupplierText,

    workspaceSectionTitle,
    workspaceSubtitle,
    step3ToAddWorkspaceText,
    step4ToAddWorkspaceText,
    error,
  }
}

const defaultTranslations = {
  welcomeMessageTitle: 'Welcome to your new organisation!',
  welcomeMessageSubtitle:
    'You can now create projects, tasks, todos, and calendar events in this organisation and collaborate in these activities with your team.',
  guideMessageTitle:
    'Please go through the following sections to understand the features in our platform and why to use them.',
  stepsToAddText: `Steps to create`,
  step1Text: '1. Open navigation bar on the left',
  step2Text: `2. Expand the 'Resources' group`,

  memberSectionTitle: 'Create members in your organisation',
  memberSubtitle:
    'To collaborate with your team, it is necessary to create members and invite them to join your organisation',
  pricingAlertMessage:
    'Please note that you can invite one member for free. If you want to invite more members, we will ask for the billing information and you will be invoiced monthly according to our pricing plan. However, you can create an unlimited number of uninvited members and you can invite them later.',
  ourPricingPlanText: 'Check pricing plan',
  step3ToAddMemberText: `3. Click the 'Members' tab`,
  step4ToAddMemberText: `4. Click the 'Add Member' button`,

  customerSectionTitle: 'Create customers in your organisation',
  customerSubtitle:
    'It will be easier for your team to monitor customer activities if you create customers and connect them to their activities.',
  step3ToAddCustomerText: `3. Click the 'Customers' tab`,
  step4ToAddCustomerText: `4. Click the 'Add Customer' button`,

  supplierSectionTitle: 'Create suppliers in your organisation',
  supplierSubtitle:
    'It will be easier for your team to monitor supplier activities if you create suppliers and connect them to their activities.',
  step3ToAddSupplierText: `3. Click the 'Suppliers' tab`,
  step4ToAddSupplierText: `4. Click the 'Add Supplier' button`,

  workspaceSectionTitle: 'Create workspaces in your organisation',
  workspaceSubtitle:
    'If your organisation has multiple branches or locations or teams, you can create workspaces to make it easier for them to  monitor there activities.',
  step3ToAddWorkspaceText: `3. Click the 'Workspaces' tab`,
  step4ToAddWorkspaceText: `4. Click the 'Add Workspace' button`,
  error: 'Error',
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.common.white,
    margin: 'auto',
    maxWidth: 740,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(8),
    minHeight: theme.spacing(40),
    padding: theme.spacing(4),
    '& h4': {
      marginBottom: 12,
    },
    '& .actions': {
      marginTop: 18,
    },
  },

  section: {
    padding: theme.spacing(2, 0),
  },

  sectionHeader: {
    marginBottom: theme.spacing(1),
  },

  pricingAlert: {
    marginTop: theme.spacing(2),
  },

  stepsToCreate: {
    paddingLeft: theme.spacing(2),
  },

  ourPricingPlan: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
  },
}))

type Translations = typeof defaultTranslations
export default OrgWelcomeRoute
