import { Button, Dialog, DialogActions, DialogContent, Divider } from '@material-ui/core'
import BillingInformation from './BillingInformation'
import { useI18n } from '../../hooks'

const BillingInformationDialog = ({
  open,
  closeDialog,
  isSkippable = false,
  skipDialog = () => {},
}: BillingInformationDialogProps) => {
  const translations = useTranslations()

  return (
    <Dialog open={open} fullWidth data-test="billing-settings-dialog">
      <DialogContent>
        <BillingInformation />
      </DialogContent>
      <Divider />
      <DialogActions>
        {isSkippable && (
          <Button onClick={skipDialog} color="primary" data-test="skip-billing-information-button">
            {translations.skipButtonLabel}
          </Button>
        )}
        <Button onClick={closeDialog} color="default" data-test="close-billing-information-button">
          {translations.closeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('org')
  const translations = (t.orgBillingPage || {}) as { [k: string]: string }

  const { skipButtonLabel = defaults.skipButtonLabel, closeButtonLabel = defaults.closeButtonLabel } = translations
  return { skipButtonLabel, closeButtonLabel }
}

const defaultTranslations = {
  skipButtonLabel: 'Skip',
  closeButtonLabel: 'Close',
}

type Translations = typeof defaultTranslations

type BillingInformationDialogProps = {
  open: boolean
  closeDialog: () => void
  skipDialog?: () => void
  isSkippable?: boolean
}

export default BillingInformationDialog
