import clsx from 'clsx'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { alpha, makeStyles, Theme } from '@material-ui/core'
import { COLOR_LIGHT } from '../../constants'
import { useI18n } from '../../hooks/use-i18n'
import { StringMap } from '../../types/common'
import { ProjectStateType } from '../types'

const ProjectStateSelect = ({ state, onStateChange, disabled = false }: ProjectStateSelectProps) => {
  const isNotReported = state === 'Not reported'
  const isGreen = state === 'Green'
  const isYellow = state === 'Yellow'
  const isRed = state === 'Red'
  const translations = useTranslations(defaultTranslations)

  return (
    <>
      <div style={{ display: 'flex', gap: 8, marginTop: 8 }}>
        <StyledButton
          className={clsx(isNotReported && 'not_reported')}
          onClick={() => onStateChange('Not reported')}
          disabled={disabled}
        >
          {translations.notReported}
        </StyledButton>
        <StyledButton className={clsx(isGreen && 'green')} onClick={() => onStateChange('Green')} disabled={disabled}>
          <span className="material-symbols-rounded">sentiment_satisfied</span>
          {translations.green}
        </StyledButton>
        <StyledButton
          className={clsx(isYellow && 'yellow')}
          onClick={() => onStateChange('Yellow')}
          disabled={disabled}
        >
          <span className="material-symbols-rounded">sentiment_neutral</span>
          {translations.yellow}
        </StyledButton>
        <StyledButton className={clsx(isRed && 'red')} onClick={() => onStateChange('Red')} disabled={disabled}>
          <span className="material-symbols-rounded">sentiment_dissatisfied</span>
          {translations.red}
        </StyledButton>
      </div>
    </>
  )
}

const StyledButton = (props: ButtonProps) => {
  const classes = useStyles()
  return <Button classes={classes} variant="outlined" {...props} />
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      fontWeight: theme.typography.body1.fontWeight,
      letterSpacing: theme.typography.body1.letterSpacing,
      fontSize: theme.typography.body1.fontSize,
      padding: theme.spacing(1, 2),
      '&.not_reported': {
        backgroundColor: alpha(theme.palette.info.main, 0.1),
        borderColor: theme.palette.info.main,
      },
      '&.green': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.2),
        borderColor: theme.palette.secondary.main,
      },
      '&.yellow': {
        backgroundColor: alpha(theme.palette.warning.main, 0.2),
        borderColor: theme.palette.warning.main,
      },
      '&.red': {
        backgroundColor: alpha(theme.palette.error.main, 0.2),
        borderColor: theme.palette.error.main,
      },
      '& .material-symbols-rounded': {
        marginRight: theme.spacing(),
      },
      '&.Mui-disabled': {
        color: 'unset',
      },
    },
    startIcon: { marginRight: 24 },
    outlined: { border: `2px solid ${COLOR_LIGHT}` },
  }
})

const useTranslations = (defaults = defaultTranslations): typeof defaultTranslations => {
  const { translations: t } = useI18n('project')
  const translations = (t?.projectState || {}) as StringMap

  const {
    notReported = defaults.notReported,
    green = defaults.green,
    yellow = defaults.yellow,
    red = defaults.red,
  } = translations

  return {
    notReported,
    green,
    yellow,
    red,
  }
}

const defaultTranslations = {
  notReported: 'Not reported',
  green: 'Very good',
  yellow: 'Good',
  red: 'Not good',
}

type ProjectStateSelectProps = {
  state: ProjectStateType
  onStateChange: (state: ProjectStateType) => void
  disabled?: boolean
}

export default ProjectStateSelect
