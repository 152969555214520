import { keyBy, omit } from 'lodash'
import { CustomerStatusEnum, CustomerTypeEnum } from '../../types/customer'
import { CustomerItemPermissions, ICustomerResponse } from './customer-response'

export const normalizeCustomer = (customer: ICustomerResponse): ICustomerNormalized => {
  const isActive = customer.status === CustomerStatusEnum.active
  const isPerson = isPersonCustomer(customer)
  const isOrg = isOrgCustomer(customer)
  const fullname = isPerson ? `${customer.firstname} ${customer.lastname}` : isOrg ? customer.name : ''
  const initials = isPerson
    ? `${customer.firstname[0]}${customer.lastname[0]}`.toUpperCase()
    : isOrg
    ? customer.name[0].toUpperCase()
    : ''

  if (isOrgCustomer(customer)) {
    return {
      ...customer,
      contacts: keyBy(customer.contacts, 'id'),
      fullname,
      initials,
      isActive,
      isPersonCustomer: isPerson,
      isOrgCustomer: isOrg,
    } as IOrgCustomerNormalized
  }

  return {
    ...omit(customer, ['contacts']),
    type: customer.type,
    fullname,
    initials,
    isActive,
    isPersonCustomer: isPerson,
    isOrgCustomer: isOrg,
  } as IPersonCustomerNormalized
}

export type ICustomerNormalized = IOrgCustomerNormalized | IPersonCustomerNormalized

export type IPersonCustomerNormalized = CustomerItemPermissions & {
  id: string
  fullname: string
  initials: string
  type: CustomerTypeEnum.person
  createdAt: string
  updatedAt: string
  customerNumber: number
  language: string
  orgId: string
  status: CustomerStatusEnum
  firstname: string
  lastname: string
  phone: string | null
  email: string | null
  personalId: string | null
  homePage: string | null
  isActive: boolean
  isPersonCustomer: true
  isOrgCustomer: false
}

export type IOrgCustomerNormalized = CustomerItemPermissions & {
  id: string
  fullname: string
  initials: string
  type: CustomerTypeEnum.organisation
  createdAt: string
  updatedAt: string
  customerNumber: number
  language: string
  orgId: string
  status: CustomerStatusEnum
  name: string
  businessId: string | null
  vatRegistrationNumber: string | null
  defaultContact: IContactNormalized | null
  contacts: { [id: string]: IContactNormalized }
  homePage: string | null
  isActive: boolean
  isPersonCustomer: false
  isOrgCustomer: true
}

export type IContactNormalized = {
  id: string
  createdAt: string
  updatedAt: string
  firstname: string
  lastname: string
  phone: string | null
  email: string | null
  jobTitle: string | null
  orgId: string
  customerId: string | null
  supplierId: string | null
}

const isPersonCustomer = (customer: { type: CustomerTypeEnum }): customer is IPersonCustomerNormalized => {
  return customer.type === CustomerTypeEnum.person
}

const isOrgCustomer = (customer: { type: CustomerTypeEnum }): customer is IOrgCustomerNormalized => {
  return customer.type === CustomerTypeEnum.organisation
}
