import { RouteComponentProps } from 'react-router-dom'
import { useTask } from '../../../tasks/hooks/use-task'
import { useGetBoardQuery } from '../../../boards/api'
import { useBoardMutations } from '../../../boards/hooks/use-board-mutations'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makePopulatedBoard } from '../../../boards/api/board'
import Board from '../../../boards/components/Board'
import { useOnMoveCard } from '../../../boards/hooks/use-on-move-card'
import { useI18n } from '../../../hooks/use-i18n'
import { StringMap } from '../../../types/common'
import ErrorList from '../../../components/ErrorList'

const queryOptions = { refetchOnMountOrArgChange: true } as const

const TaskKanbanRoute = ({ match }: RouteComponentProps<RouteParams>) => {
  const translations = useTranslations()
  const { taskId } = match.params
  const { task, isLoading: isLoadingTask } = useTask(taskId, queryOptions)
  const { data, isLoading: isLoadingBoard, isError } = useGetBoardQuery({ id: taskId, type: 'task' }, queryOptions)
  const { addTaskCard, addTodoCard, updateColumnName } = useBoardMutations()
  const { onMoveCard } = useOnMoveCard()

  if (isLoadingBoard || isLoadingTask) return <CircularProgress />
  if (isError) return <ErrorList errors={[translations.fetchErrorBoard]} />
  if (!data) return <ErrorList errors={[translations.boardNotFoundError]} />
  if (!task) return <ErrorList errors={[translations.taskNotFoundError]} />
  const { board, tasks, todos } = data
  const populatedBoard = makePopulatedBoard({ board, tasks, todos })

  return (
    <Board
      board={populatedBoard}
      canAddTasks={task.canCreate}
      canAddTodos={task.canCreateTaskTodos}
      onAddTask={addTaskCard}
      onAddTodo={addTodoCard}
      onMoveCard={onMoveCard}
      onSaveColumnName={updateColumnName}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = t || ({} as StringMap)

  const {
    fetchErrorBoard = defaults.fetchErrorBoard,
    boardNotFoundError = defaults.boardNotFoundError,
    taskNotFoundError = defaults.taskNotFoundError,
  } = translations

  return {
    boardNotFoundError,
    taskNotFoundError,
    fetchErrorBoard,
  }
}

const defaultTranslations = {
  fetchErrorBoard: 'Failed to fetch board',
  boardNotFoundError: 'Board not found',
  taskNotFoundError: 'Task not found',
}
type Translations = typeof defaultTranslations
type RouteParams = { taskId: string }
export default TaskKanbanRoute
