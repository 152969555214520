import { IOptionNormalized } from '../../options/interfaces/options-normalized'
import { WorkspaceStatus, WorkspaceStatusType } from '../constants'
import {
  IWorkspaceResponse,
  WorkspaceItemPermissions,
} from './workspace-response'

export type IWorkspaceNormalized = WorkspaceItemPermissions & {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  creatorMembershipId: string
  creatorFullName: string
  orgId: string
  managers: IOptionNormalized[]
  members: IOptionNormalized[]
  status: WorkspaceStatusType
  homePage: null | string
  isActive: boolean
  isInactive: boolean
}

export const normalizeWorkspace = (
  workspace: IWorkspaceResponse
): IWorkspaceNormalized => {
  return {
    ...workspace,
    isActive: workspace.status === WorkspaceStatus.ACTIVE,
    isInactive: workspace.status === WorkspaceStatus.INACTIVE,
  }
}
