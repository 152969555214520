import ResourceManager from '../../components/ResourceManager'
import { StringMap } from '../../types/common'
import { useI18n } from '../../hooks'
import { useOrgOptions } from '../../options/hooks/use-org-options'
import { useTodoMutations } from '../hooks/use-todo-mutations'
import { uniq } from 'lodash'
import { TodoViewModel } from '../api/todo'

const TodoResponsibleWidget = ({ todo, boardId }: TodoResponsibleWidgetProps) => {
  const translations = useTranslations()
  const { membershipOptions } = useOrgOptions(todo.organisation?.id || '')
  const { updateResponsible } = useTodoMutations()

  const onAddResponsible = (responsibleIds: string[]) => {
    const responsible = todo.responsible.map(($r) => $r.id)
    const newResponsibles = uniq(responsible.concat(responsibleIds))
    updateResponsible(todo.id, { responsible: newResponsibles, boardId })
  }
  const onRemoveResponsible = (responsibleId: string) => {
    const responsible = todo.responsible.filter(($r) => $r.id !== responsibleId).map(($r) => $r.id)
    updateResponsible(todo.id, { responsible, boardId })
  }

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={membershipOptions}
      resources={todo.responsible}
      onAddResources={onAddResponsible}
      onRemoveResource={onRemoveResponsible}
      canAddResource={todo.canUpdate}
      canRemoveResource={todo.canUpdate}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection
    />
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('todo')
  const translations = (t?.responsibleWidget || {}) as StringMap
  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: 'Responsible',
  emptyOptionsMessage: 'No members in this organisation',
  emptyResourcesMessage: 'This todo does not have responsible',
}

type Translations = typeof defaultTranslations

type TodoResponsibleWidgetProps = {
  todo: TodoViewModel
  boardId?: string
}

export default TodoResponsibleWidget
