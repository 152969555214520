import * as actions from './actions'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IMembershipPermissionNormalized } from '../interfaces/permission-normalized'
import { MembershipPermissionResponse } from '../interfaces/membership-permission'

const initialState: PermissionsState = {
  byIds: {},
  allIds: [],
}

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.FetchMembershipPermissions.fulfilled, setMembershipPermissionsCase)
    builder.addCase(actions.UpdateMemberAbilitiesInResources.fulfilled, setMembershipPermissionsCase)
    builder.addCase(actions.UpdateMemberAssociations.fulfilled, setMembershipPermissionsCase)
    builder.addCase(actions.UpdateMembershipAbilities.fulfilled, setMembershipsPermissionsCase)
    builder.addCase(actions.CopyMembershipPermissions.fulfilled, setMembershipPermissionsCase)
    builder.addCase(actions.FetchManyMembershipPermissions.fulfilled, setMembershipsPermissionsCase)
  },
})

export default permissionsSlice.reducer

export type PermissionsState = {
  byIds: { [membershipId: string]: IMembershipPermissionNormalized }
  allIds: string[]
}

function setMembershipPermissionsCase(state: PermissionsState, action: PayloadAction<MembershipPermissionResponse>) {
  const membershipId = action.payload.id
  state.byIds[membershipId] = action.payload
  if (!state.allIds.includes(membershipId)) state.allIds.push(membershipId)
}

function setMembershipsPermissionsCase(state: PermissionsState, action: PayloadAction<MembershipPermissionResponse[]>) {
  const membershipIds = action.payload.map((m) => m.id)
  action.payload.forEach((m) => (state.byIds[m.id] = m))
  membershipIds.forEach((id) => {
    if (state.allIds.includes(id)) return
    state.allIds.push(id)
  })
}
