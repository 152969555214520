import { Button, Theme, makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Stack from '../components/Stack'
import { Form, Formik, FormikConfig } from 'formik'
import FormikInput from '../components/FormikInput'
import { useAsync } from '../hooks/use-async'
import { Alert } from '@material-ui/lab'
import LoadingButton from '../components/LoadingButton'
import { useI18n } from '../hooks'
import { useAuthUser } from '../users/hooks/use-auth-user'
import AppLayout from '../components/AppLayout'
import React from 'react'
import { ArrowRight } from 'react-feather'
import { useHistory } from 'react-router-dom'

const supportUrl = process.env.REACT_APP_API_URL + '/support'

const Support = () => {
  const { user } = useAuthUser()
  const classes = useStyles()
  const translations = useTranslations()
  const history = useHistory()
  const { execute: handleSubmit, error, data, reset, status } = useAsync(sendSupportRequest, { immediate: false })

  const initialValues: SupportFormData = {
    emailOrPhone: user?.email || '',
    message: '',
  }

  const formikProps: FormikConfig<SupportFormData> = {
    initialValues,
    onSubmit: handleSubmit,
  }

  const alertMessage = error ? error : data?.message ? data.message : undefined
  const alertSeverity = error ? 'error' : 'success'
  const Wrapper = user ? AppLayout : React.Fragment

  return (
    <Wrapper>
      <div className={classes.container}>
        {!user && (
          <Button
            size="small"
            endIcon={<ArrowRight size={16} />}
            className={classes.homeButton}
            onClick={() => history.push('/home')}
          >
            {translations.home}
          </Button>
        )}
        <Paper variant="outlined">
          <Stack spacing={2}>
            <Typography variant="h4" component="h1" gutterBottom>
              {translations.contactSupport}
            </Typography>
            <Formik {...formikProps}>
              {(props) => {
                const handleReset = () => {
                  reset()
                  props.resetForm()
                }
                return (
                  <Form>
                    <Stack>
                      <FormikInput
                        name="emailOrPhone"
                        label={translations.emailOrPhoneLabel}
                        placeholder={translations.emailOrPhonePlaceholder}
                        variant="outlined"
                        required
                        fullWidth
                      />
                      <FormikInput
                        name="message"
                        label={translations.messageLabel}
                        placeholder={translations.messagePlaceholder}
                        variant="outlined"
                        minRows={4}
                        required
                        multiline
                        fullWidth
                      />
                      {alertMessage && (
                        <Alert severity={alertSeverity} onClose={handleReset}>
                          {alertMessage}
                        </Alert>
                      )}
                      {!alertMessage && (
                        <LoadingButton color="primary" variant="contained" status={status} fullWidth={false}>
                          {translations.send}
                        </LoadingButton>
                      )}
                    </Stack>
                  </Form>
                )
              }}
            </Formik>
          </Stack>
        </Paper>
      </div>
    </Wrapper>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  homeButton: {
    marginBottom: 24,
  },
  container: {
    maxWidth: 650,
    width: '100%',
    margin: '64px auto',
    '& > .MuiPaper-root': {
      width: '100%',
      padding: 24,
    },
    '& form': {
      marginTop: 24,
    },
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations = {} } = useI18n('translation')

  const {
    home = defaults.home,
    contactSupport = defaults.contactSupport,
    emailOrPhoneLabel = defaults.emailOrPhoneLabel,
    emailOrPhonePlaceholder = defaults.emailOrPhonePlaceholder,
    messageLabel = defaults.messageLabel,
    messagePlaceholder = defaults.messagePlaceholder,
    send = defaults.send,
    reset = defaults.reset,
  } = translations

  return {
    home,
    contactSupport,
    emailOrPhoneLabel,
    emailOrPhonePlaceholder,
    messageLabel,
    messagePlaceholder,
    send,
    reset,
  }
}

const defaultTranslations = {
  home: 'Home',
  contactSupport: 'Contact support',
  emailOrPhoneLabel: 'Your email or phone',
  emailOrPhonePlaceholder: 'Enter your email or phone',
  messageLabel: 'Message',
  messagePlaceholder: 'Enter your message',
  send: 'Send',
  reset: 'Reset',
}

async function sendSupportRequest(data: SupportFormData) {
  const requestInit: RequestInit = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  const response = await fetch(supportUrl, requestInit)
  const json = await response.json()
  if (response.ok) return json.data
  throw new Error(json?.error || 'Failed to send message')
}
export default Support

type SupportFormData = { emailOrPhone: string; message: string }
type Translations = typeof defaultTranslations
