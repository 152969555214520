import { useAppSelector } from '../../store'
import { useAuthUser } from '../../users/hooks/use-auth-user'
import { IContextOptionResponse } from '../interfaces/context-options-response'
import { getContextOptions } from '../store/selectors'

export const useContextOptions = () => {
  const { user } = useAuthUser()
  const contextOptions = useAppSelector(getContextOptions)
  const customerContextOptions: IContextOptionResponse[] = []
  const supplierContextOptions: IContextOptionResponse[] = []
  const membershipContextOptions: IContextOptionResponse[] = []
  const userMembershipContextOptions: IContextOptionResponse[] = []
  const workspaceContextOptions: IContextOptionResponse[] = []
  const mainContextOptions: IContextOptionResponse[] = []

  contextOptions.forEach(($contextOption) => {
    if ($contextOption.type === 'customer') {
      customerContextOptions.push($contextOption)
    }
    if ($contextOption.type === 'supplier') {
      supplierContextOptions.push($contextOption)
    }
    if ($contextOption.type === 'workspace') {
      workspaceContextOptions.push($contextOption)
    }
    if ($contextOption.type === 'membership') {
      membershipContextOptions.push($contextOption)
      if ($contextOption.userId && $contextOption.userId === user?.id) {
        userMembershipContextOptions.push($contextOption)
      }
    }
    if ($contextOption.type === 'organisation' || $contextOption.type === 'user') {
      mainContextOptions.push($contextOption)
    }
  })

  return {
    contextOptions,
    customerContextOptions,
    supplierContextOptions,
    workspaceContextOptions,
    membershipContextOptions,
    userMembershipContextOptions,
    mainContextOptions,
  }
}
