import { PayloadAction } from '@reduxjs/toolkit'
import { keyBy, keys, omitBy, uniq } from 'lodash'
import { MembershipsState } from '.'
import { IMembershipResponse } from '../interfaces/membership-response'
import { normalizeMembership } from '../interfaces/membership-normalized'

export function setMembershipCase(state: MembershipsState, action: PayloadAction<IMembershipResponse>) {
  const membership = action.payload
  const { id } = membership
  state.byIds[id] = normalizeMembership(membership)
  if (!state.allIds.includes(id)) state.allIds.push(id)
}

export function setMembershipsCase(state: MembershipsState, action: PayloadAction<IMembershipResponse[]>) {
  const memberships = action.payload.map(normalizeMembership)
  const membershipsMap = keyBy(memberships, 'id')
  const membershipIds = keys(membershipsMap)
  state.byIds = { ...state.byIds, ...membershipsMap }
  state.allIds = uniq([...state.allIds, ...membershipIds])
}

export const deleteOrganisationCase = (state: MembershipsState, action: PayloadAction<string>) => {
  const orgMembershipIds: string[] = []
  state.allIds.forEach((id) => {
    const membership = state.byIds[id]
    if (membership.orgId === action.payload) orgMembershipIds.push(id)
  })
  state.byIds = omitBy(state.byIds, { orgId: action.payload })
  state.allIds = keys(state.byIds)
}
