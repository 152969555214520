import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'

interface UserMenuProps {
  menuOpen: boolean
  anchorEl: HTMLElement | null
  handleClose: () => void
  children: JSX.Element
}

const UserMenuContainer = ({ menuOpen, anchorEl, handleClose, children }: UserMenuProps) => {
  return (
    <Popper open={menuOpen} anchorEl={anchorEl} transition disablePortal placement="bottom-end" style={{ zIndex: 99 }}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper elevation={1}>
            <ClickAwayListener onClickAway={handleClose}>
              <span>{children}</span>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

export default UserMenuContainer
