import Alert from '@material-ui/lab/Alert'
import { isArray, isPlainObject, kebabCase } from 'lodash'

const ErrorList = ({ errors }: { errors: Record<string, string> | string[] }) => {
  const isErrorsValidObject = isPlainObject(errors) || isArray(errors)
  if (!isErrorsValidObject) throw new Error('errors must be an object')
  const isEmptyArray = isArray(errors) && errors.length === 0
  const isEmptyObject = isPlainObject(errors) && Object.keys(errors).length === 0
  const isEmpty = isEmptyArray || isEmptyObject
  if (isEmpty) return null
  const errorMessages = isArray(errors) ? errors : (Object.values(errors) as string[])

  return (
    <>
      {errorMessages.map((message) => (
        <Alert key={kebabCase(message)} severity="error" style={{ marginBottom: 4 }}>
          {message}
        </Alert>
      ))}
    </>
  )
}

export default ErrorList
