import React from 'react'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Form, Formik, FormikConfig } from 'formik'
import { makeStyles, Theme } from '@material-ui/core'
import { getDefaultDateFormat } from '../../utils/date'
import FormikInput from '../../components/FormikInput'
import DatePicker from '../../components/DatePicker'
import { useI18n } from '../../hooks'
import { StringMap } from '../../types/common'
import { MONDAY } from '../../constants'
import { NewProjectData } from '../api'

const initialValues: NewProjectData = {
  title: '',
  description: '',
  plannedEndDate: '',
  plannedStartDate: '',
}

const ProjectForm = ({
  onSubmit,
  dateFormat = getDefaultDateFormat(),
  datePickerCalendarStartDay = MONDAY,
}: ProjectFormProps) => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const [submissionError, setSubmissionError] = React.useState('')

  const handleSubmit = (projectData: NewProjectData) => {
    onSubmit({
      title: projectData.title,
      description: projectData.description,
      plannedEndDate: projectData.plannedEndDate && new Date(projectData.plannedEndDate).toISOString(),
      plannedStartDate: projectData.plannedStartDate && new Date(projectData.plannedStartDate).toISOString(),
    }).catch((error: any) => {
      if (typeof error === 'string') {
        setSubmissionError(error)
      } else {
        setSubmissionError(translations.submissionFailedMessage)
      }
    })
  }

  const formikProps: FormikConfig<NewProjectData> = {
    initialValues,
    onSubmit: handleSubmit,
    initialErrors: { title: translations.projectTitleRequiredMessage },
  }
  return (
    <Formik {...formikProps}>
      {({ isSubmitting, setFieldValue, values, errors }) => {
        const { plannedStartDate, plannedEndDate } = values
        const isFormDisabled = Boolean(isSubmitting || submissionError)
        const startDateTextFieldProps = {
          disabled: isFormDisabled,
          label: translations.projectPlannedStartLabel,
          'data-test': 'project-start-date-input',
        }
        const endDateTextFieldProps = {
          disabled: isFormDisabled,
          label: translations.projectPlannedEndLabel,
          'data-test': 'project-end-date-input',
        }
        const selectedStartDate = plannedStartDate ? new Date(plannedStartDate) : null
        const selectedEndDate = plannedEndDate ? new Date(plannedEndDate) : null
        return (
          <Form>
            <FormikInput
              id="title"
              name="title"
              variant="outlined"
              label={translations.projectTitleLabel}
              autoComplete="off"
              disabled={isFormDisabled}
              className={classes.formControl}
              autoFocus
              data-test="project-title-input"
            />
            <FormikInput
              id="description"
              name="description"
              variant="outlined"
              autoComplete="off"
              label={translations.projectDescriptionLabel}
              disabled={isFormDisabled}
              className={classes.formControl}
              data-test="project-description-input"
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  selected={selectedStartDate}
                  onChange={(d) => setFieldValue('plannedStartDate', d)}
                  className={classes.formControl}
                  textFieldProps={startDateTextFieldProps}
                  calendarStartDay={datePickerCalendarStartDay}
                  dateFormat={dateFormat}
                  placeholderText={dateFormat}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  selected={selectedEndDate}
                  onChange={(d) => setFieldValue('plannedEndDate', d)}
                  className={classes.formControl}
                  textFieldProps={endDateTextFieldProps}
                  calendarStartDay={datePickerCalendarStartDay}
                  dateFormat={dateFormat}
                  placeholderText={dateFormat}
                />
              </Grid>
            </Grid>
            {submissionError && (
              <Alert className={classes.formControl} severity="error">
                {submissionError}
              </Alert>
            )}
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={Boolean(isFormDisabled || errors.title)}
              data-test="create-project-button"
            >
              {translations.createProjectButtonLabel}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('project')
  const translations = (t?.projectForm || {}) as StringMap

  const {
    submissionFailedMessage = defaults.submissionFailedMessage,
    projectTitleRequiredMessage = defaults.projectTitleRequiredMessage,
    projectTitleLabel = defaults.projectTitleLabel,
    projectDescriptionLabel = defaults.projectDescriptionLabel,
    projectPlannedStartLabel = defaults.projectPlannedStartLabel,
    projectPlannedEndLabel = defaults.projectPlannedEndLabel,
    createProjectButtonLabel = defaults.createProjectButtonLabel,
  } = translations

  return {
    submissionFailedMessage,
    projectTitleRequiredMessage,
    projectTitleLabel,
    projectDescriptionLabel,
    projectPlannedStartLabel,
    projectPlannedEndLabel,
    createProjectButtonLabel,
  }
}

const defaultTranslations = {
  submissionFailedMessage: 'Failed to create project',
  projectTitleRequiredMessage: 'Project title is required',
  projectTitleLabel: 'Project title',
  projectDescriptionLabel: 'Project description',
  projectPlannedStartLabel: 'Planned start date',
  projectPlannedEndLabel: 'Planned end date',
  createProjectButtonLabel: 'Create project',
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2.5),
  },
}))

type Translations = typeof defaultTranslations

type ProjectFormProps = {
  onSubmit: (project: NewProjectData) => Promise<void>
  datePickerCalendarStartDay?: DayOfWeek
  dateFormat?: string
}

export default ProjectForm
