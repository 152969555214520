import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'

import { X } from 'react-feather'
import { ConfirmDialogProps } from './types'

const ConfirmDialog = ({ dialog }: ConfirmDialogProps) => {
  const {
    open,
    text,
    title,
    primaryActionButtonLabel,
    secondaryActionButtonLabel,
    handleCancel,
    handleClose,
    handleConfirm,
  } = dialog

  const paragraphs = text.split('\n')

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={handleClose}>
          <X color="black" />
        </IconButton>
      </Box>
      <DialogContent>
        {paragraphs.map((paragraph, index) => (
          <div key={index}>
            <Typography variant="body1">{paragraph}</Typography>
            <br />
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={handleCancel} data-test="confirm-dialog-cancel-button">
          {secondaryActionButtonLabel}
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm} data-test="confirm-dialog-confirm-button">
          {primaryActionButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
