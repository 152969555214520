import ResourceManager from '../../components/ResourceManager'
import { useOrgOptions } from '../../options/hooks/use-org-options'
import { StringMap } from '../../types/common'
import { useI18n } from '../../hooks'
import { TaskViewModel } from '../api/task'
import { useTaskMutations } from '../hooks/use-task-mutations'

const TaskSuppliersWidget = ({ task, boardId }: { task: TaskViewModel; boardId?: string }) => {
  const translations = useTranslations(defaultTranslations)
  const { supplierOptions } = useOrgOptions(task.maintainerId)
  const { addSuppliers, removeSuppliers } = useTaskMutations()

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={supplierOptions}
      resources={task.suppliers}
      canAddResource={task.canUpdateSuppliers}
      canRemoveResource={task.canUpdateSuppliers}
      onAddResource={(id) => addSuppliers(task.id, [id])}
      onRemoveResource={(id) => removeSuppliers(task.id, { supplierIds: [id], boardId })}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('task')
  const translations = (t?.suppliersWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: 'Suppliers',
  emptyOptionsMessage: 'No suppliers in this organisation',
  emptyResourcesMessage: 'No suppliers in this task',
}

type Translations = typeof defaultTranslations

export default TaskSuppliersWidget
