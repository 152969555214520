import AutoSaveInput from '../../components/AutoSaveInput'
import AutoSaveSelect from '../../components/AutoSaveSelect/AutoSaveSelect'

import { makeStyles, Paper, Theme } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { ISupplier } from '../../types/supplier'
import { makeValidateSupplierNumber } from '../utils/valitation'
import { useI18n } from '../../hooks'
import { languageList } from '../../utils/languages'
import { UpdateSupplierInfo } from '../store/actions'
import { ISupplierNormalized } from '../interfaces/supplier-normalized'
import { StringMap } from '../../types/common'

const BasicInfoForm = ({ supplier, canUpdateBasicInfo }: BasicInfoFormProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const translations = useTranslations(defaultTranslations)
  const validateSupplierNumber = makeValidateSupplierNumber({
    orgId: supplier.orgId,
    currentSupplierNumber: `${supplier.supplierNumber}`,
    translations,
  })

  const makeOnSave = (field: keyof ISupplier) => async (values: { [k in keyof ISupplier]: string }) => {
    const action: any = await dispatch(
      UpdateSupplierInfo({
        supplierId: supplier.id,
        [field]: values[field],
      })
    )
    // Need to reject explicitly because even api responds with other codes than 200,
    // the request is resolved not rejected
    if (action?.meta?.requestStatus === 'rejected') {
      return Promise.reject(action.error)
    }
  }

  const disabled = !supplier.isActive || !canUpdateBasicInfo

  return (
    <Paper elevation={0} className={classes.paper}>
      <AutoSaveInput
        name="name"
        label={translations.nameLabel}
        placeholder="e.g. Acme Inc"
        onSave={makeOnSave('name')}
        initialValue={supplier.name}
        disabled={disabled}
        required
        inputProps={{ 'data-test': 'supplier-name-input' }}
      />
      <AutoSaveInput
        name="supplierNumber"
        label={translations.supplierNumberLabel}
        placeholder="e.g. 123"
        initialValue={`${supplier.supplierNumber}` || ''}
        onSave={makeOnSave('supplierNumber')}
        disabled={disabled}
        FormikConfig={{
          validate: validateSupplierNumber,
          validateOnBlur: false,
        }}
        inputProps={{ 'data-test': 'supplier-number-input' }}
      />
      <AutoSaveInput
        name="businessId"
        label={translations.businessIdLabel}
        placeholder="e.g. 1234567-8"
        onSave={makeOnSave('businessId')}
        initialValue={supplier.businessId || ''}
        disabled={disabled}
        inputProps={{ 'data-test': 'business-id-input' }}
      />
      <AutoSaveInput
        name="vatRegistrationNumber"
        label={translations.vatRegistrationNumberLabel}
        placeholder="e.g. FI12345678"
        initialValue={supplier.vatRegistrationNumber || ''}
        onSave={makeOnSave('vatRegistrationNumber')}
        disabled={disabled}
        inputProps={{ 'data-test': 'vat-registration-number-input' }}
      />
      <AutoSaveSelect
        name="language"
        label={translations.languageLabel}
        initialValue={supplier.language}
        onSave={makeOnSave('language')}
        options={languageList}
        disabled={disabled}
        data-test="language-select"
      />
    </Paper>
  )
}

const defaultTranslations = {
  nameLabel: 'Name',
  supplierNumberLabel: 'Supplier number',
  businessIdLabel: 'Business ID',
  vatRegistrationNumberLabel: 'Vat registration number',
  languageLabel: 'Language',
  invalidSupplierNumberError: 'Supplier number is not valid',
  duplicateSupplierNumberError: 'Supplier number already exists',
  numberOnlySupplierNumberError: 'Supplier number can contain numbers only',
  somethingWentWrongError: 'Something went wrong',
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const { translations: supplierTranslations } = useI18n('supplier')
  const basicInfoForm = (supplierTranslations.basicInfoForm || {}) as StringMap
  const supplierForm = (supplierTranslations.supplierForm || {}) as StringMap
  const translations = t || ({} as StringMap)

  const somethingWentWrongError = translations.appFallbackErrorMessage || defaults.somethingWentWrongError
  const {
    nameLabel = defaults.nameLabel,
    supplierNumberLabel = defaults.supplierNumberLabel,
    businessIdLabel = defaults.businessIdLabel,
    vatRegistrationNumberLabel = defaults.vatRegistrationNumberLabel,
    languageLabel = defaults.languageLabel,
  } = basicInfoForm
  const {
    invalidSupplierNumberError = defaults.invalidSupplierNumberError,
    duplicateSupplierNumberError = defaults.duplicateSupplierNumberError,
    numberOnlySupplierNumberError = defaults.numberOnlySupplierNumberError,
  } = supplierForm

  return {
    nameLabel,
    supplierNumberLabel,
    businessIdLabel,
    vatRegistrationNumberLabel,
    languageLabel,
    invalidSupplierNumberError,
    duplicateSupplierNumberError,
    numberOnlySupplierNumberError,
    somethingWentWrongError,
  }
}

type BasicInfoFormProps = {
  supplier: ISupplierNormalized
  canUpdateBasicInfo: boolean
}

type Translations = typeof defaultTranslations

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    '& > form, & > div': {
      padding: theme.spacing(1, 2),
    },
  },
}))

export default BasicInfoForm
