import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { X } from 'react-feather'
import { COLOR_BLACK } from '../../constants'
import StatusDescriptionHistory from './StatusDescriptionHistory'
import type { HistoryEntry } from './StatusDescriptionHistory'

const StatusDescriptionHistoryDialog = ({ entries, isOpen, onClose, title }: HistoryDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ style: { maxHeight: 500 } }}
      aria-labelledby="history-dialog"
      maxWidth="sm"
      fullWidth
    >
      <IconButton onClick={onClose} style={{ position: 'absolute', right: 12, top: 12 }}>
        <X size={20} color={COLOR_BLACK} />
      </IconButton>
      <DialogTitle>{title}</DialogTitle>
      <StatusDescriptionHistory entries={entries} />
    </Dialog>
  )
}

type HistoryDialogProps = {
  onClose: () => void
  isOpen: boolean
  entries: HistoryEntry[]
  title: string
}

export default StatusDescriptionHistoryDialog
