import { useTask } from '../../../tasks/hooks/use-task'
import { useGetTaskPermissionsQuery } from '../../../tasks/api'
import CircularProgress from '@material-ui/core/CircularProgress'
import { paths } from '../../../paths'
import { useGetOrgMembershipsQuery } from '../../../memberships/api'
import TaskPermissionsManagerView from '../../../tasks/components/TaskPermissionsManager'
import { useI18n, useRouter } from '../../../hooks'
import { StringMap } from '../../../types/common'
import ErrorList from '../../../components/ErrorList'

const queryOptions = { refetchOnMountOrArgChange: true } as const

const TaskPermissionsRoute = () => {
  const translations = useTranslations()
  const router = useRouter()
  const { taskId } = router.query as RouteParams
  const { task, isLoading: isLoadingTask, isError: isErrorTask } = useTask(taskId, queryOptions)
  const {
    data: taskPermissions = [],
    isLoading: isLoadingPermissions,
    isError: isErrorPermissions,
  } = useGetTaskPermissionsQuery(taskId, { ...queryOptions, skip: !task || task.isUserTask })
  const {
    data: memberships = [],
    isLoading: isLoadingOrgMemberships,
    isError: isErrorOrgMemberships,
  } = useGetOrgMembershipsQuery(task?.maintainerId || '', {
    ...queryOptions,
    skip: !task || task.isUserTask,
  })

  if (isLoadingTask || isLoadingPermissions || isLoadingOrgMemberships) return <CircularProgress />
  if (isErrorTask) return <ErrorList errors={[translations.fetchErrorTask]} />
  if (!task) return <ErrorList errors={[translations.taskNotFoundError]} />
  if (isErrorPermissions) return <ErrorList errors={[translations.fetchErrorPermissions]} />
  if (isErrorOrgMemberships) return <ErrorList errors={[translations.fetchErrorMemberships]} />
  const taskBasic = `${paths.taskBasic(taskId)}${router.location.search}`

  return (
    <TaskPermissionsManagerView
      task={task}
      taskPermissions={taskPermissions}
      memberships={memberships}
      navigateToTask={() => router.history.push(taskBasic)}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translations')
  const translations = t || ({} as StringMap)

  const {
    taskNotFoundError = defaults.taskNotFoundError,
    fetchErrorTask = defaults.fetchErrorTask,
    fetchErrorMemberships = defaults.fetchErrorMemberships,
    fetchErrorPermissions = defaults.fetchErrorPermissions,
  } = translations

  return {
    taskNotFoundError,
    fetchErrorTask,
    fetchErrorMemberships,
    fetchErrorPermissions,
  }
}
const defaultTranslations = {
  taskNotFoundError: 'Task not found',
  fetchErrorTask: 'Failed to fetch task',
  fetchErrorPermissions: 'Failed to fetch permissions',
  fetchErrorMemberships: 'Failed to fetch memberships',
}
type Translations = typeof defaultTranslations
type RouteParams = { taskId: string }
export default TaskPermissionsRoute
