import { SetContextOption } from '../../context-options/store'
import { makeContextOptionFromSupplier } from '../../context-options/utils'
import type { ISupplierAPI } from '../interfaces/supplier-api'
import * as SupplierAPI from '../supplier-api'
import { createAsyncThunk } from '@reduxjs/toolkit'

const makeFetchSupplierById = (supplierAPI: ISupplierAPI) => {
  return createAsyncThunk(
    'suppliers/fetchSupplierById',
    async (supplierId: string) => {
      return await supplierAPI.fetchSupplierById(supplierId)
    }
  )
}

const makeFetchSuppliers = (supplierAPI: ISupplierAPI) => {
  return createAsyncThunk('suppliers/fetchSuppliers', async (orgId: string) => {
    return await supplierAPI.fetchSuppliers(orgId)
  })
}

const makeCreateSupplier = (supplierAPI: ISupplierAPI) => {
  return createAsyncThunk(
    'suppliers/createSupplier',
    async (
      { orgId, ...supplierData }: CreateSupplierThunkArg,
      { dispatch }
    ) => {
      const supplier = await supplierAPI.createSupplier(orgId, supplierData)
      dispatch(SetContextOption(makeContextOptionFromSupplier(supplier)))
      return supplier
    }
  )
}

const makeUpdateSupplierInfo = (supplierAPI: ISupplierAPI) => {
  return createAsyncThunk(
    'suppliers/updateInfo',
    async ({ supplierId, ...updateData }: UpdateSupplierInfoThunkArg) => {
      return await supplierAPI.updateSupplierInfo(supplierId, updateData)
    }
  )
}

const makeUpdateSupplierStatus = (supplierAPI: ISupplierAPI) => {
  return createAsyncThunk(
    'suppliers/updateStatus',
    async ({ supplierId, ...updateData }: UpdateSupplierStatusThunkArg) => {
      return await supplierAPI.updateSupplierStatus(supplierId, updateData)
    }
  )
}

const makeAddContact = (supplierAPI: ISupplierAPI) => {
  return createAsyncThunk(
    'suppliers/addContact',
    async ({ supplierId, ...contactData }: AddContactThunkArg) => {
      return await supplierAPI.addContact(supplierId, contactData)
    }
  )
}

const makeChangeDefaultContact = (supplierAPI: ISupplierAPI) => {
  return createAsyncThunk(
    'suppliers/changeDefaultContact',
    async ({ supplierId, contactId }: ChangeDefaultContactThunkArg) => {
      return await supplierAPI.changeDefaultContact(supplierId, contactId)
    }
  )
}

const makeUpdateContact = (supplierAPI: ISupplierAPI) => {
  return createAsyncThunk(
    'suppliers/updateContact',
    async ({ supplierId, contactId, ...updateData }: UpdateContactThunkArg) => {
      return await supplierAPI.updateContact(supplierId, contactId, updateData)
    }
  )
}

const makeDeleteContact = (supplierAPI: ISupplierAPI) => {
  return createAsyncThunk(
    'suppliers/deleteContact',
    async ({ supplierId, contactId }: DeleteContactThunkArg) => {
      await supplierAPI.deleteContact(supplierId, contactId)
      return { supplierId, contactId }
    }
  )
}

export const FetchSupplierById = makeFetchSupplierById(SupplierAPI)
export const FetchSuppliers = makeFetchSuppliers(SupplierAPI)
export const CreateSupplier = makeCreateSupplier(SupplierAPI)
export const UpdateSupplierInfo = makeUpdateSupplierInfo(SupplierAPI)
export const UpdateSupplierStatus = makeUpdateSupplierStatus(SupplierAPI)
export const AddSupplierContact = makeAddContact(SupplierAPI)
export const ChangeSupplierDefaultContact =
  makeChangeDefaultContact(SupplierAPI)
export const UpdateSupplierContact = makeUpdateContact(SupplierAPI)
export const DeleteSupplierContact = makeDeleteContact(SupplierAPI)

type CreateSupplierThunkArg = {
  orgId: string
} & SupplierAPI.CreateSupplierData

type UpdateSupplierInfoThunkArg = {
  supplierId: string
} & SupplierAPI.SupplierInfoUpdateData

type UpdateSupplierStatusThunkArg = {
  supplierId: string
} & SupplierAPI.SupplierStatusUpdateData

type AddContactThunkArg = {
  supplierId: string
} & SupplierAPI.AddContactData

type ChangeDefaultContactThunkArg = {
  supplierId: string
  contactId: string
}

type UpdateContactThunkArg = {
  supplierId: string
  contactId: string
} & SupplierAPI.UpdateContactData

type DeleteContactThunkArg = {
  supplierId: string
  contactId: string
}
