import * as workspaceAPI from '../workspace-api'
import { useAppDispatch, useAppSelector } from '../../store'
import { getOrgWorkspaces } from '../store/selectors'
import { UpdateWorkspaceStatus, UpdateWorkspaceInfo } from '../store/actions'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAuthUserMembership } from '../../memberships/hooks/use-auth-user-membership'

export const useOrgWorkspaces = (orgId: string) => {
  const dispatch = useAppDispatch()
  const workspaces = useAppSelector((s) => getOrgWorkspaces(s, orgId))
  const { canUpdateAnyWorkspace } = useAuthUserMembership()
  const activeWorkspaces = workspaces.filter((w) => w.isActive)
  const inactiveWorkspaces = workspaces.filter((w) => w.isInactive)

  const updateWorkspaceStatus = async (
    workspaceId: string,
    updateData: workspaceAPI.WorkspaceStatusUpdateData
  ) => {
    const thunkArg = { workspaceId, ...updateData }
    const result = await dispatch(UpdateWorkspaceStatus(thunkArg))
    unwrapResult(result)
  }

  const updateWorkspaceInfo = async (
    workspaceId: string,
    updateData: workspaceAPI.WorkspaceInfoUpdateData
  ) => {
    const thunkArg = { workspaceId, ...updateData }
    const result = await dispatch(UpdateWorkspaceInfo(thunkArg))
    unwrapResult(result)
  }

  return {
    workspaces,
    activeWorkspaces,
    inactiveWorkspaces,
    canUpdateAnyWorkspace,
    updateWorkspaceStatus,
    updateWorkspaceInfo,
  }
}
