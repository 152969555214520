import { useState } from 'react'
import { useEffect } from 'react'
import { NetworkState } from '../types/common'

type Options = {
  initialStatus?: NetworkState
  onResetStatusAfterSuccess?: (...args: any) => any
  onResetStatusAfterFailure?: (...args: any) => any
  resetDelayInMS?: number
}

const defaultOptions: Options = {
  initialStatus: 'idle',
  resetDelayInMS: 2000,
}

const useNetworkStatus = ({
  initialStatus = 'idle',
  onResetStatusAfterFailure,
  onResetStatusAfterSuccess,
  resetDelayInMS = 2000,
}: Options = defaultOptions) => {
  const [status, setStatus] = useState(initialStatus)
  const isPending = () => status === 'pending'
  const isIdle = () => status === 'idle'
  const isFulfilled = () => status === 'fulfilled'
  const isRejected = () => status === 'rejected'

  useEffect(() => {
    let timeoutId: null | NodeJS.Timeout = null
    /** Reset case for fulfilled request */
    if (isFulfilled()) {
      const resetStatus = () => {
        onResetStatusAfterSuccess && onResetStatusAfterSuccess()
        setStatus('idle')
      }
      timeoutId = defer(resetStatus, resetDelayInMS)
    }

    /** Reset case for rejected request */
    if (isRejected()) {
      const resetStatus = () => {
        onResetStatusAfterFailure && onResetStatusAfterFailure()
        setStatus('idle')
      }
      timeoutId = defer(resetStatus, resetDelayInMS)
    }
    return () => {
      timeoutId && clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return {
    status,
    setStatus,
    isFulfilled,
    isIdle,
    isPending,
    isRejected,
  }
}

export { useNetworkStatus }

type DeferFn = (cb: (...args: any) => any, delay: number) => NodeJS.Timeout
const defer: DeferFn = (cb, delay) => setTimeout(cb, delay) as unknown as NodeJS.Timeout

export type NetworkStatusState = {
  status: NetworkState
  setStatus: (s: NetworkState) => void
  isFulfilled: () => boolean
  isIdle: () => boolean
  isPending: () => boolean
  isRejected: () => boolean
}
