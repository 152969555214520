import FirstDayOfWeekSelectWidget from '../../components/FirstDayOfWeekSelectWidget'
import TimeZoneSelectWidget from '../../components/TimeZoneSelectWidget'
import WeekendDaysSelectWidget from '../../components/WeekendDaysSelectWidget'
import MembersTable from '../../components/MembersTable'
import AbilitiesTable from '../../components/AbilitiesTable/AbilitiesTable'

import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from '@material-ui/core'
import { StringMap } from 'i18next'
import { ArrowLeft, ChevronDown, MinusSquare } from 'react-feather'
import { COLOR_PRIMARY, COLOR_WHITE } from '../../constants'
import { useI18n } from '../../hooks'
import { ChangeEvent, useState } from 'react'
import { ProjectInfoUpdateData } from '../api'
import { concat, sortBy, without } from 'lodash'
import { useAuthUserMembership } from '../../memberships/hooks/use-auth-user-membership'
import { ProjectViewModel } from '../api/project'
import { MembershipViewModel } from '../../memberships/api/membership'
import { ProjectPermissionRecord } from '../api/project-permission-response'
import { useProjectMutations } from '../hooks/use-project-mutations'

type ProjectSettingsViewProps = {
  project: ProjectViewModel
  projectPermissions: ProjectPermissionRecord[]
  memberships: MembershipViewModel[]
  navigateToProject: () => void
}

const useProjectSettingsView = ({
  project,
  projectPermissions: permissions,
  memberships,
}: ProjectSettingsViewProps) => {
  const projectId = project.id
  const projectActions = useProjectMutations()
  const organisationId = project.isOrgProject ? project.maintainerId : undefined
  const { canUpdateAnyMembershipPermissions } = useAuthUserMembership(organisationId)

  const [membersFilter, setMembersFilter] = useState<MembersFilterType>('all')

  const onChangeMembersFilter = (e: ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as MembersFilterType
    setMembersFilter(val)
    setSelectedMembers([])
  }
  const projectCreatorMembership = memberships.find((membership) => membership.userId === project.creatorId)
  const projectResources = [...project.managers, ...project.participants].map(({ id }) => id)
  if (projectCreatorMembership) {
    projectResources.push(projectCreatorMembership.userId)
  }
  const activeMemberRows = memberships
    .filter(($m) => !$m.isDeactivated)
    .map((membership) => ({
      id: membership.id,
      userId: membership.userId,
      initials: membership.userInitials,
      fullname: membership.fullname,
      email: membership.userEmail,
      disabled: membership.isOwner,
    }))
  const memberRowsToShow = sortBy(
    activeMemberRows.filter((row) => {
      const isResource = projectResources.includes(row.id) || projectResources.includes(row.userId)

      if (membersFilter === 'resources') return isResource
      if (membersFilter === 'nonResources') return !isResource
      return true
    }),
    'disabled'
  )
  const editableMembers = memberships.filter(
    (membership) => !membership.isOwner && memberRowsToShow.some((r) => r.id === membership.id)
  )
  const [selectedMembers, setSelectedMembers] = useState<string[]>([])
  const selectedMembersNames = memberships.filter((m) => selectedMembers.includes(m.id)).map((m) => m.fullname)
  const selectedCount = selectedMembers.length
  const editableMembersCount = editableMembers.length
  const selectAllChecked = !!selectedCount && selectedCount === editableMembersCount
  const selectAllIndeterminate = !!selectedCount && selectedCount !== editableMembersCount
  const selectAllDisabled = !editableMembersCount

  const onRowSelect = (id: string) => {
    if (selectedMembers.includes(id)) {
      setSelectedMembers(without(selectedMembers, id))
    } else {
      setSelectedMembers(concat(selectedMembers, id))
    }
  }
  const onSelectAll = () => {
    if (selectedCount === editableMembersCount) {
      setSelectedMembers([])
    } else {
      setSelectedMembers(editableMembers.map((membership) => membership.id))
    }
  }
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const openPermissionsDialog = () => {
    setDialogOpen(true)
  }
  const closePermissionsDialog = () => {
    setDialogOpen(false)
  }
  const onClickManagePermissionsButton = (id?: string) => {
    if (id) setSelectedMembers([id])
    openPermissionsDialog()
  }
  const groupedAbilities: any = groupAbilities(
    permissions.filter(($permission) => selectedMembers.includes($permission.membershipId))
  )

  const onUpdateProjectInfo = async (projectData: ProjectInfoUpdateData) => {
    await projectActions.updateInfo(projectId, projectData)
  }

  const onUpdateAbilities = async ({
    abilitiesToAdd,
    abilitiesToRemove,
  }: {
    abilitiesToAdd?: string[]
    abilitiesToRemove?: string[]
  }) => {
    await projectActions.updatePermissions(projectId, {
      membershipIds: selectedMembers,
      abilitiesToAdd,
      abilitiesToRemove,
    })
  }

  return {
    project,
    groupedAbilities,
    memberRowsToShow,
    membersFilter,
    selectedMembers,
    selectedMembersNames,
    selectAllChecked,
    selectAllDisabled,
    selectAllIndeterminate,
    dialogOpen,
    onRowSelect,
    onSelectAll,
    openPermissionsDialog,
    closePermissionsDialog,
    onChangeMembersFilter,
    onUpdateProjectInfo,
    onClickManagePermissionsButton,
    onUpdateAbilities,
    canUpdateAnyMembershipPermissions,
  }
}

const ProjectSettingsView = (props: ProjectSettingsViewProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { navigateToProject } = props
  const {
    project: projectData,
    memberRowsToShow,
    membersFilter,
    selectedMembers,
    selectedMembersNames,
    selectAllChecked,
    selectAllDisabled,
    selectAllIndeterminate,
    dialogOpen,
    groupedAbilities,
    onSelectAll,
    onRowSelect,
    openPermissionsDialog,
    closePermissionsDialog,
    onChangeMembersFilter,
    onUpdateProjectInfo,
    onClickManagePermissionsButton,
    onUpdateAbilities,
    canUpdateAnyMembershipPermissions,
  } = useProjectSettingsView(props)

  return (
    <Grid container direction="column" style={{ maxWidth: 840, margin: 'auto' }}>
      <Grid item className={classes.pageHeader} data-test="page-header">
        <Button onClick={navigateToProject} size="small" startIcon={<ArrowLeft size={16} />}>
          {`${translations.backButtonLabel}: ${projectData?.title}`}
        </Button>

        <Typography variant="h6" className={classes.pageTitle}>
          {translations.pageTitle}
        </Typography>
      </Grid>
      <Grid item container direction="row" className={classes.daysSection}>
        <WeekendDaysSelectWidget
          weekendDays={projectData.weekendDays || [6, 0]}
          onWeekendDaysChange={onUpdateProjectInfo}
          disabled={!projectData.canUpdateDetails}
        />
        <FirstDayOfWeekSelectWidget
          firstDayOfWeek={projectData.firstDayOfWeek}
          onFirstDayOfWeekChange={onUpdateProjectInfo}
          disabled={!projectData.canUpdateDetails}
        />
        <TimeZoneSelectWidget
          timeZone={projectData.timeZone}
          onTimeZoneChange={onUpdateProjectInfo}
          disabled={!projectData.canUpdateDetails}
        />
      </Grid>
      {projectData.isOrgProject && canUpdateAnyMembershipPermissions && (
        <>
          <Grid item className={classes.pageHeader}>
            <Typography component="header" variant="h6">
              {translations.permissionsSectionTitle}
            </Typography>
            <Typography className="subheader">{translations.permissionsSubheader}</Typography>
          </Grid>
          <Grid item>
            <div className={classes.toolbar}>
              <div className="left">
                <Checkbox
                  checked={selectAllChecked}
                  color="primary"
                  indeterminate={selectAllIndeterminate}
                  indeterminateIcon={<MinusSquare color={COLOR_PRIMARY} />}
                  className="checkbox"
                  onChange={onSelectAll}
                  disabled={selectAllDisabled}
                />
                {!selectedMembers.length && (
                  <Typography variant="subtitle2" component="span">
                    {translations.selectAllCheckboxLabel}
                  </Typography>
                )}
                {!!selectedMembers.length && (
                  <Button color="primary" variant="contained" size="small" onClick={openPermissionsDialog}>
                    {translations.managePermissionsBtnLabel}
                  </Button>
                )}
              </div>
              <div className="right">
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select value={membersFilter} onChange={onChangeMembersFilter} IconComponent={ChevronDown} autoWidth>
                    <MenuItem value="all">{translations.allMembersLabel}</MenuItem>
                    <MenuItem value="resources">{translations.resourcesLabel}</MenuItem>
                    <MenuItem value="nonResources">{translations.nonResourcesLabel}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <MembersTable
              memberRows={memberRowsToShow}
              selectedMemberships={selectedMembers}
              onManagePermissionsClick={onClickManagePermissionsButton}
              onRowSelect={onRowSelect}
            />

            <Dialog open={dialogOpen} onClose={closePermissionsDialog} maxWidth="md">
              <div className={classes.dialogContentWrapper}>
                <DialogTitle>
                  {translations.dialogTitle.replace('{{members}}', selectedMembersNames.join(', '))}
                </DialogTitle>
                <Divider />
                <AbilitiesTable groupedAbilities={groupedAbilities} onUpdateAbilities={onUpdateAbilities} />
              </div>
            </Dialog>
          </Grid>
        </>
      )}
    </Grid>
  )
}

const useTranslations = (defaults = defaultPermissions) => {
  const { translations: t } = useI18n('project')
  const translations = (t.projectSettingsPage || {}) as StringMap

  const {
    pageTitle = defaults.pageTitle,
    permissionsSectionTitle = defaults.permissionsSectionTitle,
    backButtonLabel = defaults.backButtonLabel,
    permissionsSubheader = defaults.permissionsSubheader,
    selectAllCheckboxLabel = defaults.selectAllCheckboxLabel,
    managePermissionsBtnLabel = defaults.managePermissionsBtnLabel,
    allMembersLabel = defaults.allMembersLabel,
    resourcesLabel = defaults.resourcesLabel,
    nonResourcesLabel = defaults.nonResourcesLabel,
    dialogTitle = defaults.dialogTitle,
  } = translations

  return {
    pageTitle,
    permissionsSectionTitle,
    backButtonLabel,
    permissionsSubheader,
    selectAllCheckboxLabel,
    managePermissionsBtnLabel,
    allMembersLabel,
    resourcesLabel,
    nonResourcesLabel,
    dialogTitle,
  }
}

const defaultPermissions = {
  pageTitle: 'Project settings',
  backButtonLabel: 'Back to project',
  permissionsSectionTitle: 'Project permissions',
  permissionsSubheader: `You can manage permissions for project resources from here.
    Also you can give permission to to perform certain actions 
    to other members of the organisation who is not a resource in this project.`,
  selectAllCheckboxLabel: 'Select all',
  managePermissionsBtnLabel: 'Manage permissions',
  allMembersLabel: 'All members',
  resourcesLabel: 'Project resources',
  nonResourcesLabel: 'Not project resources',
  dialogTitle: 'Managing permissions of {{members}}',
}

const useStyles = makeStyles((theme: Theme) => ({
  pageHeader: {
    margin: theme.spacing(1.5, 0),
    '& .headerRow': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: theme.spacing(2, 0),
    },
    '& .subheader': {
      textAlign: 'justify',
    },
  },
  permissions: {
    paddingBottom: theme.spacing(10),
  },
  daysSection: {
    backgroundColor: COLOR_WHITE,
  },
  pageTitle: {
    margin: theme.spacing(2, 0),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'white',
    padding: theme.spacing(1.5),
    borderTopLeftRadius: theme.spacing(),
    borderTopRightRadius: theme.spacing(),
    border: `1px solid ${theme.palette.divider}`,
    borderBottomColor: 'transparent',
    '& .checkbox': {
      marginRight: 20,
    },
  },
  formControl: {
    '& .MuiOutlinedInput-root': {
      background: theme.palette.grey[200],
    },
    '& .MuiSelect-icon': {
      width: 20,
      height: 20,
      color: theme.palette.common.black,
      top: 'calc(50% - 8px)',
    },
  },
  dialogContentWrapper: {
    minWidth: theme.spacing(80),
  },
}))

const groupAbilities = (
  permissions: ProjectPermissionRecord[]
): Array<{ resource: string; abilities: { [key: string]: boolean }[] }> => {
  return [
    {
      resource: 'project',
      abilities: permissions.map(($permission) => ({
        ReadProject: $permission.canRead,
        DeleteProject: $permission.canDelete,
        UpdateProjectDetails: $permission.canUpdateDetails,
        UpdateProjectPlan: $permission.canUpdatePlan,
        UpdateProjectStatus: $permission.canUpdateStatus,
        UpdateProjectManagers: $permission.canUpdateManagers,
        UpdateProjectParticipants: $permission.canUpdateParticipants,
        UpdateProjectCustomers: $permission.canUpdateCustomers,
        UpdateProjectWorkspaces: $permission.canUpdateWorkspaces,
        UpdateProjectSuppliers: $permission.canUpdateSuppliers,
        UpdateProjectPermissions: $permission.canUpdatePermissions,
      })),
    },
    {
      resource: 'task',
      abilities: permissions.map(($permission) => ({
        CreateProjectTasks: $permission.canCreateProjectTasks,
      })),
    },
    {
      resource: 'calendarEvent',
      abilities: permissions.map(($permission) => ({
        CreateProjectCalendarEvents: $permission.canCreateProjectCalendarEvents,
      })),
    },
    {
      resource: 'todo',
      abilities: permissions.map(($permission) => ({
        CreateProjectTodos: $permission.canCreateProjectTodos,
      })),
    },
  ]
}

type MembersFilterType = 'all' | 'resources' | 'nonResources'
export default ProjectSettingsView
