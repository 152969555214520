import { TimeZoneType } from '../../../constants/timezones'
import { IOptionNormalized } from '../../../options/interfaces/options-normalized'
import {
  getCustomerOptions,
  getMembershipOptions,
  getOptionId,
  getOptionName,
  getWorkspaceOptions,
} from '../../../options/utils'
import { DateTimeService } from '../../../services/date-time-service'
import { makeEnumString } from '../../utils'
import { TodoActivityRow } from '../types'
import { isTodoDueDatePastDue } from '../../../todos/utils/is-past-due'
import { TodoViewModel } from '../../../todos/api/todo'

export const makeTodoActivityRow = ({
  todo,
  dateFormat,
  timeZone,
  options,
}: MakeTodoActivityRowProps): TodoActivityRow => {
  const filterOptionByOrganisation = makeFilterOptionByOrganisation(todo)

  const { id, title, description, enableTimeComponent } = todo
  const dateTimeService = new DateTimeService({ dateFormat, timeZone, enableTimeComponent })
  const customers = todo.customers.map((c) => c.id).join(';')
  const workspaces = todo.workspaces.map((w) => w.id).join(';')
  const responsible = todo.responsible.map((r) => r.id).join(';')

  const customerOptions = getCustomerOptions(options).filter(filterOptionByOrganisation)
  const customerNames = customerOptions.map(getOptionName)
  const customerIds = customerOptions.map(getOptionId)
  const customersEnum = makeEnumString(customerNames)
  const customersEnumKeys = makeEnumString(customerIds)

  const workspaceOptions = getWorkspaceOptions(options).filter(filterOptionByOrganisation)
  const workspaceNames = workspaceOptions.map(getOptionName)
  const workspaceIds = workspaceOptions.map(getOptionId)
  const workspacesEnum = makeEnumString(workspaceNames)
  const workspacesEnumKeys = makeEnumString(workspaceIds)

  const responsibleOptions = getMembershipOptions(options).filter(filterOptionByOrganisation)
  const responsibleNames = responsibleOptions.map(getOptionName)
  const responsibleIds = responsibleOptions.map(getOptionId)
  const responsibleEnum = makeEnumString(responsibleNames)
  const responsibleEnumKeys = makeEnumString(responsibleIds)

  return {
    id,
    type: 'todo',
    title,
    description,
    enableTimeComponent,
    plannedEndDate: getPlannedEndDate(),
    plannedEndDateClass: getPlannedEndDateClass(),
    status: todo.completed ? 1 : 0,
    statusType: 'Bool',
    customers,
    customersEnum,
    customersEnumKeys,
    responsible,
    responsibleEnum,
    responsibleEnumKeys,
    workspaces,
    workspacesEnum,
    workspacesEnumKeys,
    activityNumberCanEdit: 0,
    titleCanEdit: todo.canUpdate ? 1 : 0,
    descriptionCanEdit: todo.canUpdate ? 1 : 0,
    plannedStartDateCanEdit: 0,
    plannedEndDateCanEdit: todo.canUpdate ? 1 : 0,
    actualStartDateCanEdit: 0,
    statusCanEdit: todo.canUpdate ? 1 : 0,
    stateCanEdit: 0,
    statusDescriptionCanEdit: 0,
    customersCanEdit: todo.canUpdate && todo.organisation ? 1 : 0,
    responsibleCanEdit: todo.canUpdate && todo.organisation ? 1 : 0,
    participantsCanEdit: 0,
    suppliersCanEdit: 0,
    workspacesCanEdit: todo.canUpdate && todo.organisation ? 1 : 0,
    Height: 48,
    MaxHeight: 48,
  }

  function getPlannedEndDate(): number | string {
    return todo.dueDate ? dateTimeService.removeTimezoneOffset(todo.dueDate, 'UTC').getTime() : ''
  }

  function getPlannedEndDateClass(): string {
    const isPastDue = isTodoDueDatePastDue({ todo, dateTimeService })
    return isPastDue ? 'redText' : ''
  }
}

function makeFilterOptionByOrganisation(todo: TodoViewModel) {
  return function (option: IOptionNormalized) {
    return option.orgId === todo.organisation?.id
  }
}

type MakeTodoActivityRowProps = {
  todo: TodoViewModel
  dateFormat: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
}
