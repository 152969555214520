import { Checkbox, FormControlLabel, makeStyles, Theme } from '@material-ui/core'
import { Check, Square } from 'react-feather'

type InheritPlanWidgetProps = {
  label: string
  checked: boolean
  isReadOnly: boolean
  onChange: () => unknown
}

const InheritPlanWidget = ({ label, checked, isReadOnly, onChange }: InheritPlanWidgetProps) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      className={classes.root}
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          icon={<Square size={14} />}
          checkedIcon={
            <>
              <span className={classes.checkedIcon}>
                <Check size={10} />
              </span>
            </>
          }
        />
      }
      label={label}
      disabled={isReadOnly}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: 4,
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
  checkedIcon: {
    height: 14,
    width: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.main,
    borderRadius: 4,
    transition: theme.transitions.create('background'),
    '& svg': {
      color: theme.palette.common.white,
    },
  },
}))

export default InheritPlanWidget
