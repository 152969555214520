import { find } from 'lodash'
import { BoardCard } from './card'
import { BoardCardData, BoardColumnData } from '../api/board'

export class BoardColumn {
  private cards: BoardCard[]
  readonly id: string
  readonly board: string
  readonly position: number
  name: string | null
  defaultName: string
  isVisible: boolean

  constructor({
    id,
    board,
    defaultName,
    name = null,
    isVisible = true,
    position = 0,
    cards = [],
  }: Optional<BoardColumnData, 'cards' | 'position' | 'name'>) {
    this.id = id
    this.board = board
    this.cards = cards.map((card) => new BoardCard({ ...card }))
    this.defaultName = defaultName
    this.name = name
    this.position = position
    this.isVisible = isVisible
  }

  setName(name: string | null): this {
    this.name = name
    return this
  }

  setDefaultName(name: string): this {
    this.defaultName = name
    return this
  }

  setVisible(isVisible: boolean): this {
    this.isVisible = isVisible
    return this
  }

  getCards(): BoardCard[] {
    return this.cards
  }

  getCardById(id: string): BoardCard | null {
    return find(this.cards, { id }) || null
  }

  addCard({ id, type, position = 0 }: Optional<BoardCardData, 'position'>): void {
    if (position < 0 || position > this.cards.length) throw new Error('Invalid position')
    const card = new BoardCard({ id, type, position })
    // add card to given position
    this.cards.splice(position, 0, card)
    // adjust position of other cards
    this.cards.forEach((card, index) => {
      if (card.position < position) return
      card.position = index
    })
  }

  removeCard(cardId: string): boolean {
    const foundCard = this.getCardById(cardId)
    if (!foundCard) return false
    this.cards.splice(foundCard.position, 1)
    this.cards.forEach((card, idx) => {
      if (card.position < foundCard.position) return
      card.position = idx
    })
    return true
  }

  moveCard(cardId: string, position: number): boolean {
    if (position < 0 || position >= this.cards.length) throw new Error('Invalid position')
    const foundCard = this.getCardById(cardId)
    if (!foundCard) return false
    const removed = this.removeCard(cardId)
    if (removed) {
      this.addCard({ ...foundCard, position })
      return true
    }
    return false
  }

  toObject(): BoardColumnData {
    return {
      ...this,
      cards: this.cards.map((c) => c.toObject()),
    }
  }
}
