import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import WorkspaceSettingsView from '../../../workspaces/views/WorkspaceSettingsView'
import { useAppDispatch } from '../../../store'
import { useAsync } from '../../../hooks/use-async'
import { FetchWorkspaceById } from '../../../workspaces/store/actions'
import { useWorkspace } from '../../../workspaces/hooks/use-workspace'
import { useAppContext } from '../../../hooks/use-app-context'

const useLoader = ({ workspaceId }: RouteParams) => {
  const dispatch = useAppDispatch()
  const loader = React.useCallback(async () => {
    dispatch({ type: 'load_workspace_settings_route' })
    if (workspaceId) {
      await dispatch(FetchWorkspaceById(workspaceId))
    }
  }, [dispatch, workspaceId])
  return useAsync(loader)
}

const WorkspaceSettingsRoute = () => {
  const { appContext } = useAppContext()
  const workspaceId = appContext.subContext?.id as string
  const { error, ...status } = useLoader({ workspaceId })
  const { workspace } = useWorkspace(workspaceId)

  if (error) {
    return <p>Error occured: {error}</p>
  }

  if (status.isSuccess() && workspace) {
    return <WorkspaceSettingsView workspaceId={workspaceId} />
  }

  return <CircularProgress />
}

type RouteParams = {
  workspaceId: string
}

export default WorkspaceSettingsRoute
