import { StringMap } from '../../types/common'
import ResourceManager from '../../components/ResourceManager'
import { useI18n } from '../../hooks'
import { useOrgOptions } from '../../options/hooks/use-org-options'
import { TaskViewModel } from '../api/task'
import { useTaskMutations } from '../hooks/use-task-mutations'

const TaskManagersWidget = ({ task, boardId }: { task: TaskViewModel; boardId?: string }) => {
  const translations = useTranslations(defaultTranslations)
  const { membershipOptions } = useOrgOptions(task.maintainerId)
  const { addManagers, removeManagers } = useTaskMutations()

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={membershipOptions}
      resources={task.managers}
      onAddResources={(ids) => addManagers(task.id, ids)}
      onRemoveResource={(id) => removeManagers(task.id, { managerIds: [id], boardId })}
      canAddResource={task.canUpdateManagers}
      canRemoveResource={task.canUpdateManagers}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('task')
  const translations = (t?.managersWidget || {}) as StringMap
  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: 'Manager',
  emptyOptionsMessage: 'No members in this organisation',
  emptyResourcesMessage: 'No manager in this task',
}

type Translations = typeof defaultTranslations

export default TaskManagersWidget
