import { createSelector } from '@reduxjs/toolkit'
import { filter, values } from 'lodash'
import { RootState } from '../../store'
import { IMembershipResponse } from '../interfaces/membership-response'

export const getMembershipById = (state: RootState, membershipId: string) => {
  return state.memberships.byIds[membershipId]
}

export const getMemberships: (
  state: RootState,
  query: any
) => IMembershipResponse[] = createSelector(
  (state: RootState) => state.memberships.byIds,
  (_state: RootState, predicate: any) => predicate,
  (memberships, predicate) => {
    return filter(values(memberships), predicate)
  }
)

export const getOrgMemberships = createSelector(
  (state: RootState) => state.memberships.byIds,
  (state: RootState, orgId: string) => orgId,
  (memberships, orgId) => {
    return filter(values(memberships), { orgId })
  }
)

export const getAllMemberships = createSelector(
  (state: RootState) => state.memberships.byIds,
  (memberships) => values(memberships)
)
