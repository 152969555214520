import { TreegridGanttUnit } from '../../treegrid/types'
import { api } from '../../api'
import { BoardViewModel, makeBoardViewModel } from '../../boards/api/board'
import { BoardResponse } from '../../boards/api/board-response'
import { ProjectViewModel, makeProjectViewModel } from '../../projects/api/project'
import { ProjectResponse } from '../../projects/api/project-response'
import { GanttDependencyType, Status } from '../../types/common'
import { makeUrlSearchParams } from '../../utils/url'
import { TaskPermissionRecord } from './task-permission-response'
import { TaskResponse } from '../api/task-response'
import { TaskStateType } from '../types'
import { makeTaskApiEndpoints } from './endpoints'
import { TaskViewModel, makeTaskViewModel } from './task'

const apiEndpoints = makeTaskApiEndpoints()

export const taskApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTaskById: builder.query<TaskViewModel, string>({
      query: apiEndpoints.taskItem,
      transformResponse: ({ task }: { task: TaskResponse }) => makeTaskViewModel(task),
      providesTags: (result) => [{ type: 'Task', id: result?.id }],
    }),
    getTasks: builder.query<TaskViewModel[], TasksQueryParams | void>({
      query: (params) => ({
        method: 'GET',
        url: apiEndpoints.tasks(),
        params: params ? makeUrlSearchParams(params) : undefined,
      }),
      transformResponse: ({ tasks }: { tasks: TaskResponse[] }) => tasks.map(makeTaskViewModel),
      providesTags: (result = []) => ['Task', ...result?.map(({ id }) => ({ type: 'Task', id } as const))],
    }),
    getTaskPermissions: builder.query<TaskPermissionRecord[], string>({
      query: apiEndpoints.taskPermissions,
      providesTags: (result, error, taskId) => [{ type: 'TaskPermission', id: taskId }],
    }),
    createUserTask: builder.mutation<CreateTaskReturnData, { userId: string } & NewTaskData>({
      query: ({ userId, ...taskData }) => ({
        method: 'POST',
        url: apiEndpoints.userTasks(userId),
        body: taskData,
      }),
      transformResponse: (responseData: CreateTaskResponseData) => {
        return {
          created: makeTaskViewModel(responseData.created),
          updated: {
            tasks: responseData.updated.tasks.map(makeTaskViewModel),
            project: makeProjectViewModel(responseData.updated.project),
          },
        }
      },
      invalidatesTags: (result) => {
        if (!result) return []
        return [
          'Task',
          ...result.updated.tasks.map(({ id }) => ({ type: 'Task', id } as const)),
          { type: 'Project', id: result.updated.project.id },
        ]
      },
    }),
    createOrgTask: builder.mutation<CreateTaskReturnData, { orgId: string } & NewTaskData>({
      query: ({ orgId, ...taskData }) => ({
        method: 'POST',
        url: apiEndpoints.orgTasks(orgId),
        body: taskData,
      }),
      transformResponse: (responseData: CreateTaskResponseData) => {
        return {
          created: makeTaskViewModel(responseData.created),
          updated: {
            tasks: responseData.updated.tasks.map(makeTaskViewModel),
            project: makeProjectViewModel(responseData.updated.project),
          },
        }
      },
      invalidatesTags: (result) => {
        if (!result) return []
        return [
          'Task',
          ...result.updated.tasks.map(({ id }) => ({ type: 'Task', id } as const)),
          { type: 'Project', id: result.updated.project.id },
        ]
      },
    }),
    copyTask: builder.mutation<CopyTaskReturnData, CopyTaskData>({
      query: (taskData) => ({
        method: 'POST',
        url: apiEndpoints.copyTask(),
        body: taskData,
      }),
      transformResponse: ({ project, tasks }: { project: ProjectResponse; tasks: TaskResponse[] }) => {
        return {
          project: makeProjectViewModel(project),
          tasks: tasks.map(makeTaskViewModel),
        }
      },
      invalidatesTags: (result) => {
        if (!result) return []
        return [
          'Task',
          ...result.tasks.map(({ id }) => ({ type: 'Task', id } as const)),
          { type: 'Project', id: result.project.id },
        ]
      },
    }),
    updateTaskInfo: builder.mutation<TaskViewModel, TaskInfoUpdateData & { taskId: string }>({
      query: ({ taskId, ...taskData }) => ({
        method: 'PUT',
        url: apiEndpoints.taskItem(taskId),
        body: taskData,
      }),
      transformResponse: makeTaskViewModel,
      invalidatesTags: (result) => ['Task', { type: 'Task', id: result?.id }],
    }),
    updateTaskStatusDescription: builder.mutation<TaskViewModel, TaskStatusDescriptionUpdateData & { taskId: string }>({
      query: ({ taskId, ...taskData }) => ({
        method: 'PUT',
        url: apiEndpoints.taskStatusDescription(taskId),
        body: taskData,
      }),
      transformResponse: makeTaskViewModel,
      invalidatesTags: (result) => ['Task', { type: 'Task', id: result?.id }],
    }),
    updateTaskPlan: builder.mutation<UpdatePlanReturnData, TaskPlanUpdateData & { taskId: string }>({
      query: ({ taskId, ...taskData }) => ({
        method: 'PUT',
        url: apiEndpoints.taskPlan(taskId),
        body: taskData,
      }),
      transformResponse: ({ project, tasks }: { project: ProjectResponse; tasks: TaskResponse[] }) => {
        return {
          project: makeProjectViewModel(project),
          tasks: tasks.map(makeTaskViewModel),
        }
      },
      invalidatesTags: (result) => {
        if (!result) return []
        return [
          'Task',
          ...result.tasks.map(({ id }) => ({ type: 'Task', id } as const)),
          { type: 'Project', id: result.project.id },
        ]
      },
    }),
    updateTaskDuration: builder.mutation<UpdatePlanReturnData, TaskDurationUpdateData & { taskId: string }>({
      query: ({ taskId, ...taskData }) => ({
        method: 'PUT',
        url: apiEndpoints.taskDuration(taskId),
        body: taskData,
      }),
      transformResponse: ({ project, tasks }: { project: ProjectResponse; tasks: TaskResponse[] }) => {
        return {
          project: makeProjectViewModel(project),
          tasks: tasks.map(makeTaskViewModel),
        }
      },
      invalidatesTags: (result) => {
        if (!result) return []
        return [
          'Task',
          ...result.tasks.map(({ id }) => ({ type: 'Task', id } as const)),
          { type: 'Project', id: result.project.id },
        ]
      },
    }),
    updateTaskDaysLeft: builder.mutation<UpdatePlanReturnData, TaskDaysLeftUpdateData & { taskId: string }>({
      query: ({ taskId, ...taskData }) => ({
        method: 'PUT',
        url: apiEndpoints.taskDaysLeft(taskId),
        body: taskData,
      }),
      transformResponse: ({ project, tasks }: { project: ProjectResponse; tasks: TaskResponse[] }) => {
        return {
          project: makeProjectViewModel(project),
          tasks: tasks.map(makeTaskViewModel),
        }
      },
      invalidatesTags: (result) => {
        if (!result) return []
        return [
          'Task',
          ...result.tasks.map(({ id }) => ({ type: 'Task', id } as const)),
          { type: 'Project', id: result.project.id },
        ]
      },
    }),
    updateTaskStatus: builder.mutation<UpdateStatusReturnData, TaskStatusUpdateData & { taskId: string }>({
      query: ({ taskId, ...taskData }) => ({
        method: 'PUT',
        url: apiEndpoints.taskStatus(taskId),
        body: taskData,
      }),
      transformResponse: ({ project, tasks, board }: UpdateStatusResponseData) => {
        return {
          project: makeProjectViewModel(project),
          tasks: tasks.map(makeTaskViewModel),
          board: board ? makeBoardViewModel(board) : null,
        }
      },
      invalidatesTags: (result, err, { taskId }) => {
        if (!result) return []
        return [
          'Task',
          ...result.tasks.map(({ id }) => ({ type: 'Task', id } as const)),
          { type: 'Project', id: result.project.id },
          { type: 'Board', id: taskId },
        ]
      },
    }),
    updateTaskPermissions: builder.mutation<TaskPermissionRecord[], TaskPermissionsUpdateData & { taskId: string }>({
      query: ({ taskId, membershipIds, abilitiesToAllow = [], abilitiesToRestrict = [] }) => ({
        method: 'PUT',
        url: apiEndpoints.taskPermissions(taskId),
        body: { membershipIds, abilitiesToAllow, abilitiesToRestrict },
      }),
      invalidatesTags: (result, error, { taskId }) => [{ type: 'TaskPermission', id: taskId }],
    }),
    updateTaskOrder: builder.mutation<UpdateOrderReturnData, TaskOrderUpdateData & { taskId: string }>({
      query: ({ taskId, ...taskData }) => ({
        method: 'PATCH',
        url: apiEndpoints.taskOrder(taskId),
        body: taskData,
      }),
      transformResponse: ({ project, tasks }: UpdateOrderResponseData) => {
        return {
          project: project ? makeProjectViewModel(project) : null,
          tasks: tasks.map(makeTaskViewModel),
        }
      },
      invalidatesTags: (result) => {
        if (!result) return []
        return [
          'Task',
          ...result.tasks.map(({ id }) => ({ type: 'Task', id } as const)),
          { type: 'Project', id: result.project?.id },
        ]
      },
    }),
    addTaskManagers: builder.mutation<TaskViewModel, { taskId: string; managerIds: string[] }>({
      query: ({ taskId, managerIds }) => ({
        method: 'POST',
        url: apiEndpoints.taskManagers(taskId),
        body: { managerIds },
      }),
      transformResponse: makeTaskViewModel,
      invalidatesTags: (result) => ['Task', { type: 'Task', id: result?.id }],
    }),
    addTaskParticipants: builder.mutation<TaskViewModel, { taskId: string; participantIds: string[] }>({
      query: ({ taskId, participantIds }) => ({
        method: 'POST',
        url: apiEndpoints.taskParticipants(taskId),
        body: { participantIds },
      }),
      transformResponse: makeTaskViewModel,
      invalidatesTags: (result) => ['Task', { type: 'Task', id: result?.id }],
    }),
    removeTaskManagers: builder.mutation<
      RemoveTaskResourceReturnData,
      { taskId: string; managerIds: string[]; boardId?: string }
    >({
      query: ({ taskId, managerIds, boardId }) => ({
        method: 'DELETE',
        url: apiEndpoints.taskManagers(taskId),
        body: { managerIds, boardId },
      }),
      transformResponse: ({ task, board }: RemoveTaskResourceResponseData) => {
        return {
          task: makeTaskViewModel(task),
          board: board ? makeBoardViewModel(board) : null,
        }
      },
      invalidatesTags: (result) => [
        'Task',
        { type: 'Task', id: result?.task.id },
        { type: 'Board', id: result?.board?.id },
      ],
    }),
    removeTaskParticipants: builder.mutation<
      RemoveTaskResourceReturnData,
      { taskId: string; participantIds: string[]; boardId?: string }
    >({
      query: ({ taskId, participantIds, boardId }) => ({
        method: 'DELETE',
        url: apiEndpoints.taskParticipants(taskId),
        body: { participantIds, boardId },
      }),
      transformResponse: ({ task, board }: RemoveTaskResourceResponseData) => {
        return {
          task: makeTaskViewModel(task),
          board: board ? makeBoardViewModel(board) : null,
        }
      },
      invalidatesTags: (result) => [
        'Task',
        { type: 'Task', id: result?.task.id },
        { type: 'Board', id: result?.board?.id },
      ],
    }),
    addTaskSuppliers: builder.mutation<TaskViewModel, { taskId: string; supplierIds: string[] }>({
      query: ({ taskId, supplierIds }) => ({
        method: 'POST',
        url: apiEndpoints.taskSuppliers(taskId),
        body: { supplierIds },
      }),
      transformResponse: makeTaskViewModel,
      invalidatesTags: (result) => ['Task', { type: 'Task', id: result?.id }],
    }),
    removeTaskSuppliers: builder.mutation<
      RemoveTaskResourceReturnData,
      { taskId: string; supplierIds: string[]; boardId?: string }
    >({
      query: ({ taskId, supplierIds, boardId }) => ({
        method: 'DELETE',
        url: apiEndpoints.taskSuppliers(taskId),
        body: { supplierIds, boardId },
      }),
      transformResponse: ({ task, board }: RemoveTaskResourceResponseData) => {
        return {
          task: makeTaskViewModel(task),
          board: board ? makeBoardViewModel(board) : null,
        }
      },
      invalidatesTags: (result) => [
        'Task',
        { type: 'Task', id: result?.task.id },
        { type: 'Board', id: result?.board?.id },
      ],
    }),
    addTaskWorkspaces: builder.mutation<TaskViewModel, { taskId: string; workspaceIds: string[] }>({
      query: ({ taskId, workspaceIds }) => ({
        method: 'POST',
        url: apiEndpoints.taskWorkspaces(taskId),
        body: { workspaceIds },
      }),
      transformResponse: makeTaskViewModel,
      invalidatesTags: (result) => ['Task', { type: 'Task', id: result?.id }],
    }),
    removeTaskWorkspaces: builder.mutation<
      RemoveTaskResourceReturnData,
      { taskId: string; workspaceIds: string[]; boardId?: string }
    >({
      query: ({ taskId, workspaceIds, boardId }) => ({
        method: 'DELETE',
        url: apiEndpoints.taskWorkspaces(taskId),
        body: { workspaceIds, boardId },
      }),
      transformResponse: ({ task, board }: RemoveTaskResourceResponseData) => {
        return {
          task: makeTaskViewModel(task),
          board: board ? makeBoardViewModel(board) : null,
        }
      },
      invalidatesTags: (result) => [
        'Task',
        { type: 'Task', id: result?.task.id },
        { type: 'Board', id: result?.board?.id },
      ],
    }),
    addTaskDependency: builder.mutation<AddOrUpdateDependencyReturnData, AddTaskDependencyData & { taskId: string }>({
      query: ({ taskId, ...dependencyData }) => ({
        method: 'POST',
        url: apiEndpoints.taskDependencies(taskId),
        body: dependencyData,
      }),
      transformResponse: ({ tasks, project }: AddOrUpdateDependencyResponseData) => {
        return {
          tasks: tasks.map(makeTaskViewModel),
          project: project ? makeProjectViewModel(project) : null,
        }
      },
      invalidatesTags: (result) => {
        if (!result) return []
        return [
          'Task',
          ...result.tasks.map(({ id }) => ({ type: 'Task', id } as const)),
          { type: 'Project', id: result.project?.id },
        ]
      },
    }),
    removeTaskDependency: builder.mutation<TaskViewModel[], RemoveTaskDependencyData & { taskId: string }>({
      query: ({ taskId, ganttAncestors }) => ({
        method: 'DELETE',
        url: apiEndpoints.taskDependencies(taskId),
        body: { ganttAncestors },
      }),
      transformResponse: (tasks: TaskResponse[]) => tasks.map(makeTaskViewModel),
      invalidatesTags: (result = []) => ['Task', ...result.map(({ id }) => ({ type: 'Task', id } as const))],
    }),
    updateTaskDependency: builder.mutation<
      AddOrUpdateDependencyReturnData,
      TaskDependencyUpdateData & { taskId: string }
    >({
      query: ({ taskId, ...dependencyData }) => ({
        method: 'PATCH',
        url: apiEndpoints.taskDependencies(taskId),
        body: dependencyData,
      }),
      transformResponse: ({ tasks, project }: AddOrUpdateDependencyResponseData) => {
        return {
          tasks: tasks.map(makeTaskViewModel),
          project: project ? makeProjectViewModel(project) : null,
        }
      },
      invalidatesTags: (result) => {
        if (!result) return []
        return [
          'Task',
          ...result.tasks.map(({ id }) => ({ type: 'Task', id } as const)),
          { type: 'Project', id: result.project?.id },
        ]
      },
    }),
    archiveTask: builder.mutation<ArchiveOrUnarchiveTaskReturnData, { taskId: string; boardId?: string }>({
      query: ({ taskId, boardId }) => ({
        method: 'PATCH',
        url: apiEndpoints.archiveTask(taskId),
        body: { boardId },
      }),
      transformResponse: ({ tasks, project }: ArchiveOrUnarchiveTaskResponseData) => {
        return {
          tasks: tasks.map(makeTaskViewModel),
          project: project ? makeProjectViewModel(project) : null,
        }
      },
      invalidatesTags: (result) => {
        if (!result) return []
        return [
          'Task',
          ...result.tasks.map(({ id }) => ({ type: 'Task', id } as const)),
          { type: 'Project', id: result.project?.id },
        ]
      },
    }),
    unarchiveTask: builder.mutation<
      ArchiveOrUnarchiveTaskReturnData,
      { taskId: string; boardId?: string; unarchiveSubtasks?: boolean }
    >({
      query: ({ taskId, boardId, unarchiveSubtasks }) => ({
        method: 'PATCH',
        url: apiEndpoints.unarchiveTask(taskId),
        body: { unarchiveSubtasks: Boolean(unarchiveSubtasks), boardId },
      }),
      transformResponse: ({ tasks, project }: ArchiveOrUnarchiveTaskResponseData) => {
        return {
          tasks: tasks.map(makeTaskViewModel),
          project: project ? makeProjectViewModel(project) : null,
        }
      },
      invalidatesTags: (result) => {
        if (!result) return []
        return [
          'Task',
          ...result.tasks.map(({ id }) => ({ type: 'Task', id } as const)),
          { type: 'Project', id: result.project?.id },
        ]
      },
    }),
    deleteTask: builder.mutation<DeleteTaskReturnData, string>({
      query: (taskId) => ({
        method: 'DELETE',
        url: apiEndpoints.taskItem(taskId),
      }),
      transformResponse: (responseData: DeleteTaskResponseData) => {
        const { updatedTasks, project, deletedTaskIds } = responseData
        return {
          updatedTasks: updatedTasks.map(makeTaskViewModel),
          project: project ? makeProjectViewModel(project) : null,
          deletedTaskIds,
        }
      },
      invalidatesTags: (result, err, taskId) => {
        if (!result) return []
        return [
          'Task',
          'CalendarEvent',
          'Todo',
          { type: 'Task', id: taskId },
          { type: 'Project', id: result.project?.id },
          { type: 'Board', id: taskId },
        ]
      },
    }),
  }),
})

export const {
  useGetTaskByIdQuery,
  useGetTasksQuery,
  useLazyGetTasksQuery,
  useGetTaskPermissionsQuery,
  useCreateUserTaskMutation,
  useCreateOrgTaskMutation,
  useCopyTaskMutation,
  useUpdateTaskInfoMutation,
  useUpdateTaskStatusDescriptionMutation,
  useUpdateTaskPlanMutation,
  useUpdateTaskDurationMutation,
  useUpdateTaskDaysLeftMutation,
  useUpdateTaskStatusMutation,
  useUpdateTaskPermissionsMutation,
  useAddTaskManagersMutation,
  useAddTaskParticipantsMutation,
  useRemoveTaskManagersMutation,
  useRemoveTaskParticipantsMutation,
  useAddTaskSuppliersMutation,
  useRemoveTaskSuppliersMutation,
  useAddTaskWorkspacesMutation,
  useRemoveTaskWorkspacesMutation,
  useUpdateTaskOrderMutation,
  useAddTaskDependencyMutation,
  useRemoveTaskDependencyMutation,
  useUpdateTaskDependencyMutation,
  useArchiveTaskMutation,
  useUnarchiveTaskMutation,
  useDeleteTaskMutation,
} = taskApi

type TasksBaseQuery = { status?: Status | Status[]; archived?: 'true' | 'false' }
type OrgTasksQueryParams = { organisation: string }
type CustomerTasksQueryParams = { customer: string }
type MemberTasksQueryParams = { member: string }
type ProjectTasksQueryParams = { project: string }
type SupplierTasksQueryParams = { supplier: string }
type TaskTasksQueryParams = { task: string }
type WorkspaceTasksQueryParams = { workspace: string }
export type TasksQueryParams = TasksBaseQuery &
  (
    | {}
    | OrgTasksQueryParams
    | CustomerTasksQueryParams
    | MemberTasksQueryParams
    | ProjectTasksQueryParams
    | SupplierTasksQueryParams
    | TaskTasksQueryParams
    | WorkspaceTasksQueryParams
  )

export type NewTaskData = NewUserTaskData | NewOrgTaskData

export type NewUserTaskData = {
  title: string
  projectId: string
  order?: number
  parentTaskId?: string
}

export type NewOrgTaskData = {
  title: string
  orgId: string
  projectId: string
  order?: number
  parentTaskId?: string
  suppliers?: string[]
  workspaces?: string[]
}

export type CopyTaskData = {
  taskId: string
  copyTree?: boolean
  order?: number
}

export type TaskInfoUpdateData = {
  title?: string
  description?: string
  customTaskNumber?: string
}

export type TaskStatusDescriptionUpdateData = {
  state?: TaskStateType
  statusDescription?: string | null
}

export type TaskPlanUpdateData = {
  plannedStartDate?: string
  plannedEndDate?: string
}

export type TaskDurationUpdateData = {
  duration: number
}

export type TaskDaysLeftUpdateData = {
  daysLeft: number
}

export type TaskStatusUpdateData = {
  status?: Status
  actualStartDate?: string
  actualEndDate?: string
  updateSubtasks?: boolean
  boardId?: string
}

export type TaskPermissionsUpdateData = {
  membershipIds: string[]
  abilitiesToAllow?: string[]
  abilitiesToRestrict?: string[]
}

export type TaskOrderUpdateData = {
  projectId: string
  parentTaskId: string | null
  order?: number
}

export type AddTaskDependencyData = {
  ganttAncestorTaskId: string
  type: GanttDependencyType | undefined
  lagTime: number | undefined
  lagUnit: TreegridGanttUnit | undefined
}

export type GanttAncestor = {
  taskId: string
  type: GanttDependencyType
}

export type RemoveTaskDependencyData = {
  ganttAncestors: GanttAncestor[]
}

export type TaskDependencyUpdateData = {
  ganttAncestorTaskId: string
  type: 'ss' | 'sf' | 'ff' | 'fs' | undefined
  lagTime: number | undefined
  lagUnit: TreegridGanttUnit | undefined
}

type CreateTaskResponseData = {
  created: TaskResponse
  updated: {
    tasks: TaskResponse[]
    project: ProjectResponse
  }
}

export type CreateTaskReturnData = {
  created: TaskViewModel
  updated: {
    tasks: TaskViewModel[]
    project: ProjectViewModel
  }
}

export type CopyTaskReturnData = {
  project: ProjectViewModel
  tasks: TaskViewModel[]
}

type UpdatePlanReturnData = {
  project: ProjectViewModel
  tasks: TaskViewModel[]
}

type UpdateStatusResponseData = {
  project: ProjectResponse
  tasks: TaskResponse[]
  board: BoardResponse | null
}

type UpdateStatusReturnData = {
  project: ProjectViewModel
  tasks: TaskViewModel[]
  board: BoardViewModel | null
}

type UpdateOrderResponseData = {
  project: ProjectResponse | null
  tasks: TaskResponse[]
}

type UpdateOrderReturnData = {
  project: ProjectViewModel | null
  tasks: TaskViewModel[]
}

type RemoveTaskResourceResponseData = {
  task: TaskResponse
  board: BoardResponse | null
}

type RemoveTaskResourceReturnData = {
  task: TaskViewModel
  board: BoardViewModel | null
}

type AddOrUpdateDependencyReturnData = {
  tasks: TaskViewModel[]
  project: ProjectViewModel | null
}

type AddOrUpdateDependencyResponseData = {
  tasks: TaskResponse[]
  project: ProjectResponse | null
}

type ArchiveOrUnarchiveTaskReturnData = {
  tasks: TaskViewModel[]
  project: ProjectViewModel | null
}

type ArchiveOrUnarchiveTaskResponseData = {
  tasks: TaskResponse[]
  project: ProjectResponse | null
}

type DeleteTaskReturnData = {
  updatedTasks: TaskViewModel[]
  project: ProjectViewModel | null
  deletedTaskIds: string[]
}

type DeleteTaskResponseData = {
  updatedTasks: TaskResponse[]
  project: ProjectResponse | null
  deletedTaskIds: string[]
}
