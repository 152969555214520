import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { paths } from './paths'
import { withActiveRouteLayout } from './components/ActiveRouteLayout'
import { withInactiveRouteLayout } from './components/InactiveRouteLayout'
import { withPublicRouteLayout } from './components/PublicRouteLayout'

import AuthRequiredRoute from './components/Route'
import RedirectToHome from './components/RedirectToHome'
import AppErrorFallback from './components/AppErrorFallback'

import Login from './routes/login'
import Signup from './routes/signup'
import ForgotPassword from './routes/forgot-password'
import ResetPassword from './routes/reset-password'
import Support from './routes/support'

import AccountVerification from './routes/account-verification'
import NewVerificationCode from './routes/new-verification-code'
import Deactivated from './routes/deactivated'

import UserSettingsRoute from './routes/users/$userId/settings'
import UserWelcomeRoute from './routes/users/$userId/welcome'
import InvitationsRoute from './routes/invitations'

import NewOrganisationRoute from './routes/orgs/new'
import OrgBillingRoute from './routes/orgs/$orgId/billing'
import OrgSettingsRoute from './routes/orgs/$orgId/settings'
import OrgMembershipsRoute from './routes/orgs/$orgId/memberships'
import OrgCustomersRoute from './routes/orgs/$orgId/customers'
import OrgSuppliersRoute from './routes/orgs/$orgId/suppliers'
import OrgWorkspacesRoute from './routes/orgs/$orgId/workspaces'
import OrgNewCustomerRoute from './routes/orgs/$orgId/customers/new'
import OrgNewSupplierRoute from './routes/orgs/$orgId/suppliers/new'
import OrgNewWorkspaceRoute from './routes/orgs/$orgId/workspaces/new'
import OrgNewMemberRoute from './routes/orgs/$orgId/memberships/new'

import MembershipSettingsRoute from './routes/memberships/$membershipId/settings'

import CustomerSettingsRoute from './routes/customers/$customerId/settings'

import TaskLayout from './routes/tasks/$taskId/index'
import TaskPermissionsRoute from './routes/tasks/$taskId/permissions'

import SupplierSettingsRoute from './routes/suppliers/$supplierId/settings'

import WorkspaceMembersRoute from './routes/workspaces/$workspaceId/members'
import WorkspaceSettingsRoute from './routes/workspaces/$workspaceId/settings'
import WorkspaceWelcomeRoute from './routes/workspaces/$workspaceId/welcome'
import OrgWelcomeRoute from './routes/orgs/$orgId/welcome'
import { withOrgRouteLayout } from './components/OrgRouteLayout'

import NewProjectRoute from './routes/projects/new'
import ProjectLayout from './routes/projects/$projectId'
import ProjectSettings from './routes/projects/$projectId/settings'
import TasksView from './routes/tasks'
import ProjectsRoute from './routes/projects'
import CalendarRoute from './routes/calendar'
import TodosRoute from './routes/todos'
import KanbanRoute from './routes/kanban'
import HomeView from './routes/home'

const LoginRoute = withPublicRouteLayout(Login)
const SignupRoute = withPublicRouteLayout(Signup)
const ForgotPasswordRoute = withPublicRouteLayout(ForgotPassword)
const ResetPasswordRoute = withPublicRouteLayout(ResetPassword)
const AccountVerificationRoute = withInactiveRouteLayout(AccountVerification)
const NewVerificationCodeRoute = withInactiveRouteLayout(NewVerificationCode)
const DeactivatedRoute = withInactiveRouteLayout(Deactivated)

const App = () => {
  const { i18n } = useTranslation()

  React.useEffect(() => {
    if (window) {
      window.document.documentElement.lang = i18n.language
    }
  }, [i18n.language])

  return (
    <ErrorBoundary FallbackComponent={AppErrorFallback}>
      <BrowserRouter>
        <Switch>
          <Route path={paths.login()} component={LoginRoute} />
          <Route path={paths.signup()} component={SignupRoute} />
          <Route path={paths.forgotPassword()} component={ForgotPasswordRoute} />
          <Route path={paths.resetPassword()} component={ResetPasswordRoute} />
          <Route path={paths.support()} component={Support} />
          <AuthRequiredRoute path={paths.accountVerification()} component={AccountVerificationRoute} />
          <AuthRequiredRoute path={paths.newVerificationCode()} component={NewVerificationCodeRoute} />
          <AuthRequiredRoute path={paths.deactivated()} component={DeactivatedRoute} />
          <AuthRequiredRoute path={paths.userWelcome()} component={UserWelcomeRoute} />
          <AuthRequiredRoute path={paths.invitations()} component={withActiveRouteLayout(InvitationsRoute)} />
          <AuthRequiredRoute path={paths.userSettings()} component={withActiveRouteLayout(UserSettingsRoute)} />
          <AuthRequiredRoute path={paths.newOrganisation()} component={withActiveRouteLayout(NewOrganisationRoute)} />

          <AuthRequiredRoute
            path={paths.orgNewCustomer()}
            component={withActiveRouteLayout(withOrgRouteLayout(OrgNewCustomerRoute))}
          />
          <AuthRequiredRoute
            path={paths.orgNewSupplier()}
            component={withActiveRouteLayout(withOrgRouteLayout(OrgNewSupplierRoute))}
          />
          <AuthRequiredRoute
            path={paths.orgNewWorkspace()}
            component={withActiveRouteLayout(withOrgRouteLayout(OrgNewWorkspaceRoute))}
          />
          <AuthRequiredRoute
            path={paths.orgNewMember()}
            component={withActiveRouteLayout(withOrgRouteLayout(OrgNewMemberRoute))}
          />
          <AuthRequiredRoute
            path={paths.orgBilling()}
            component={withActiveRouteLayout(withOrgRouteLayout(OrgBillingRoute))}
          />
          <AuthRequiredRoute
            path={paths.orgSettings()}
            component={withActiveRouteLayout(withOrgRouteLayout(OrgSettingsRoute))}
          />
          <AuthRequiredRoute
            path={paths.orgMembers()}
            component={withActiveRouteLayout(withOrgRouteLayout(OrgMembershipsRoute))}
          />
          <AuthRequiredRoute
            path={paths.orgCustomers()}
            component={withActiveRouteLayout(withOrgRouteLayout(OrgCustomersRoute))}
          />
          <AuthRequiredRoute
            path={paths.orgSuppliers()}
            component={withActiveRouteLayout(withOrgRouteLayout(OrgSuppliersRoute))}
          />
          <AuthRequiredRoute
            path={paths.orgWorkspaces()}
            component={withActiveRouteLayout(withOrgRouteLayout(OrgWorkspacesRoute))}
          />
          <AuthRequiredRoute
            path={paths.orgWelcome()}
            component={withActiveRouteLayout(withOrgRouteLayout(OrgWelcomeRoute))}
          />

          <AuthRequiredRoute
            path={paths.membershipSettings()}
            component={withActiveRouteLayout(withOrgRouteLayout(MembershipSettingsRoute))}
          />
          <AuthRequiredRoute
            path={paths.customerSettings()}
            component={withActiveRouteLayout(withOrgRouteLayout(CustomerSettingsRoute))}
          />
          <AuthRequiredRoute
            path={paths.newProject()}
            component={withActiveRouteLayout(withOrgRouteLayout(NewProjectRoute))}
          />
          <AuthRequiredRoute
            path={paths.taskPermissions()}
            component={withActiveRouteLayout(withOrgRouteLayout(TaskPermissionsRoute))}
          />
          <AuthRequiredRoute
            path={paths.supplierSettings()}
            component={withActiveRouteLayout(withOrgRouteLayout(SupplierSettingsRoute))}
          />
          <AuthRequiredRoute
            path={paths.workspaceMembers()}
            component={withActiveRouteLayout(withOrgRouteLayout(WorkspaceMembersRoute))}
          />
          <AuthRequiredRoute
            path={paths.workspaceSettings()}
            component={withActiveRouteLayout(withOrgRouteLayout(WorkspaceSettingsRoute))}
          />
          <AuthRequiredRoute
            path={paths.workspaceWelcome()}
            component={withActiveRouteLayout(withOrgRouteLayout(WorkspaceWelcomeRoute))}
          />
          {/* ----------------------------------- */}
          <AuthRequiredRoute path={paths.projectSettings()} component={withActiveRouteLayout(ProjectSettings)} />
          <AuthRequiredRoute path={'/projects/:projectId'} component={withActiveRouteLayout(ProjectLayout)} />
          <AuthRequiredRoute path={paths.taskPermissions()} component={withActiveRouteLayout(TaskPermissionsRoute)} />
          <AuthRequiredRoute path={'/tasks/:taskId'} component={withActiveRouteLayout(TaskLayout)} />
          {/* ----------------------------------------------------------------- */}

          <AuthRequiredRoute path={paths.tasks()} component={withActiveRouteLayout(withOrgRouteLayout(TasksView))} />
          <AuthRequiredRoute
            path={paths.projects()}
            component={withActiveRouteLayout(withOrgRouteLayout(ProjectsRoute))}
          />
          <AuthRequiredRoute
            path={paths.calendar()}
            component={withActiveRouteLayout(withOrgRouteLayout(withOrgRouteLayout(CalendarRoute)))}
          />
          <AuthRequiredRoute path={paths.todos()} component={withActiveRouteLayout(withOrgRouteLayout(TodosRoute))} />
          <AuthRequiredRoute path={paths.kanban()} component={withActiveRouteLayout(withOrgRouteLayout(KanbanRoute))} />
          <AuthRequiredRoute path={paths.home()} component={withActiveRouteLayout(withOrgRouteLayout(HomeView))} />

          <AuthRequiredRoute path="*" component={RedirectToHome} />
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  )
}

export default App
