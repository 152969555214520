import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { makeStyles, Theme } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useI18n, useNetworkStatus } from '../../hooks'
import { AlertTriangle } from 'react-feather'
import { useConfirmDialog } from '../../components/ConfirmDialog'
import { UpdateWorkspaceStatus } from '../store/actions'
import { IWorkspaceResponse } from '../interfaces/workspace-response'
import { StringMap } from '../../types/common'
import { WorkspaceStatus } from '../constants'
import { Alert, AlertTitle } from '@material-ui/lab'

const WorkspaceStatusWidget = ({ workspace }: WorkspaceStatusWidgetProps) => {
  const translations = useTranslations()

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="subtitle1">{translations.updateWorkspaceStatusTitle}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          {translations.updateWorkspaceStatusSubTitle}
        </Typography>
      </Grid>
      <Grid item>
        <DeleteWorkspaceButton workspace={workspace} />
      </Grid>
    </Grid>
  )
}

const DeleteWorkspaceButton = ({ workspace }: WorkspaceStatusWidgetProps) => {
  const translations = useTranslations()
  const dispatch = useDispatch()
  const activationStatus = useNetworkStatus()
  const isActive = () => workspace.status === WorkspaceStatus.ACTIVE
  const isInactive = () => workspace.status === WorkspaceStatus.INACTIVE
  const classes = useStyles({ isActive: isActive() })

  const onChangeStatus = async () => {
    const newStatus = isActive() ? WorkspaceStatus.INACTIVE : WorkspaceStatus.ACTIVE
    return dispatch(UpdateWorkspaceStatus({ workspaceId: workspace.id, status: newStatus }))
  }

  const confirmChangeStatusDialog = useConfirmDialog({
    onConfirm: onChangeStatusConfirmed,
    title: isActive() ? translations.deactivationConfirmDialogTitle : translations.activationConfirmDialogTitle,
    text: isActive() ? translations.deactivationConfirmDialogText : translations.activationConfirmDialogText,
  })

  async function onChangeStatusConfirmed() {
    activationStatus.setStatus('pending')
    try {
      const action: any = await onChangeStatus()
      const requestStatus = action?.meta?.requestStatus || 'rejected'
      activationStatus.setStatus(requestStatus)
    } catch (error) {
      activationStatus.setStatus('rejected')
    }
  }

  let alertTitle = ''
  let alertMessage = ''
  let changeStatusButtonLabel = isActive()
    ? translations.deactivateButtonLabel_idle
    : translations.activateButtonLabel_idle

  let activationButtonIcon: null | JSX.Element = null
  let progressIcon = <CircularProgress size={16} />

  if (activationStatus.isPending()) {
    changeStatusButtonLabel = isInactive()
      ? translations.activateButtonLabel_pending
      : translations.deactivateButtonLabel_pending
    activationButtonIcon = progressIcon
  }

  if (activationStatus.isRejected()) {
    activationButtonIcon = <AlertTriangle size={20} />

    changeStatusButtonLabel = isInactive()
      ? translations.activateButtonLabel_rejected
      : translations.deactivateButtonLabel_rejected

    alertTitle = isInactive() ? translations.activationFailedAlertTitle : translations.deactivationFailedAlertTitle

    alertMessage = isInactive()
      ? translations.activationFailedAlertMessage
      : translations.deactivationFailedAlertMessage
  }

  return (
    <>
      {alertMessage && (
        <Alert severity="error">
          <AlertTitle>{alertTitle}</AlertTitle>
          {alertMessage}
        </Alert>
      )}
      <Button
        variant="outlined"
        onClick={confirmChangeStatusDialog.confirm}
        startIcon={activationButtonIcon}
        className={classes.statusButton}
        disabled={!workspace.canUpdate}
        data-test={isActive() ? 'deactivate-workspace-button' : 'activate-workspace-button'}
      >
        {changeStatusButtonLabel}
      </Button>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  statusButton: {
    color: (props: { isActive: boolean }) => (props.isActive ? theme.palette.error.main : theme.palette.secondary.main),
    borderColor: (props: { isActive: boolean }) =>
      props.isActive ? theme.palette.error.main : theme.palette.secondary.main,
    marginTop: theme.spacing(2.5),
  },
  successMessage: {
    marginTop: '-80px',
    background: theme.palette.common.white,
    padding: theme.spacing(2, 0),
  },
  redirecting: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      padding: theme.spacing(2, 2, 2, 0),
      '&::last-child': {
        paddingLeft: theme.spacing(2),
      },
    },
  },
}))

const defaultTranslations = {
  updateWorkspaceStatusTitle: 'Deactivate or re-activate this workspace',
  updateWorkspaceStatusSubTitle: 'Do not worry. Any resource connected to this workspace will not be effected.',
  activateButtonLabel_idle: 'Activate',
  activateButtonLabel_pending: 'Activating',
  activateButtonLabel_rejected: 'Failed',
  activationFailedAlertMessage: 'Failed to deactivate',
  activationFailedAlertTitle: 'Error',
  activationConfirmDialogText: `Do you want to re-activate this workspace`,
  activationConfirmDialogTitle: 'Activate workspace',
  deactivateButtonLabel_idle: 'Deactivate',
  deactivateButtonLabel_pending: 'Deactivating',
  deactivateButtonLabel_rejected: 'Failed',
  deactivationFailedAlertMessage: 'Failed to deactivate',
  deactivationFailedAlertTitle: 'Error',
  deactivationConfirmDialogText: `You will still be able to view this workspace 
    in deactivated workspaces list and you will be able to reactivate
    this workspace later if needed.`,
  deactivationConfirmDialogTitle: 'Deactivate workspace',
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('workspace')
  const translations = (t.workspaceSettingsPage || {}) as StringMap

  const {
    updateWorkspaceStatusTitle = defaults.updateWorkspaceStatusTitle,
    updateWorkspaceStatusSubTitle = defaults.updateWorkspaceStatusSubTitle,

    activateButtonLabel_idle = defaults.activateButtonLabel_idle,
    activateButtonLabel_pending = defaults.activateButtonLabel_pending,
    activateButtonLabel_rejected = defaults.activateButtonLabel_rejected,
    activationFailedAlertTitle = defaults.activationFailedAlertTitle,
    activationFailedAlertMessage = defaults.activationFailedAlertMessage,
    activationConfirmDialogTitle = defaults.activationConfirmDialogTitle,
    activationConfirmDialogText = defaults.activationConfirmDialogText,

    deactivateButtonLabel_idle = defaults.deactivateButtonLabel_idle,
    deactivateButtonLabel_pending = defaults.deactivateButtonLabel_pending,
    deactivateButtonLabel_rejected = defaults.deactivateButtonLabel_rejected,
    deactivationFailedAlertTitle = defaults.deactivationFailedAlertTitle,
    deactivationFailedAlertMessage = defaults.deactivationFailedAlertMessage,
    deactivationConfirmDialogTitle = defaults.deactivationConfirmDialogTitle,
    deactivationConfirmDialogText = defaults.deactivationConfirmDialogText,
  } = translations

  return {
    updateWorkspaceStatusTitle,
    updateWorkspaceStatusSubTitle,
    activateButtonLabel_idle,
    activateButtonLabel_pending,
    activateButtonLabel_rejected,
    activationFailedAlertTitle,
    activationFailedAlertMessage,
    activationConfirmDialogTitle,
    activationConfirmDialogText,
    deactivateButtonLabel_idle,
    deactivateButtonLabel_pending,
    deactivateButtonLabel_rejected,
    deactivationFailedAlertTitle,
    deactivationFailedAlertMessage,
    deactivationConfirmDialogTitle,
    deactivationConfirmDialogText,
  }
}

type WorkspaceStatusWidgetProps = {
  workspace: IWorkspaceResponse
}
type Translations = typeof defaultTranslations
export default WorkspaceStatusWidget
