import { Paper, Theme, makeStyles } from '@material-ui/core'
import { useI18n } from '../../hooks'
import AutoSaveInput from '../AutoSaveInput'
import AutoSaveSelect from '../AutoSaveSelect'
import { languageList } from '../../utils/languages'

const BillingContactPerson = ({ billingContactPerson, onChange }: BillingContactPersonProps) => {
  const translations = useTranslations()
  const classes = useStyles()

  return (
    <Paper elevation={0} className={classes.paper}>
      <AutoSaveInput
        name="firstname"
        label={translations.firstnameLabel}
        placeholder={translations.firstnameInputPlaceholder}
        onSave={onChange}
        initialValue={billingContactPerson.firstname}
        disabled={false}
        inputProps={{ 'data-test': 'contact-firstname-input' }}
      />
      <AutoSaveInput
        name="lastname"
        label={translations.lastnameLabel}
        placeholder={translations.lastnameInputPlaceholder}
        onSave={onChange}
        initialValue={billingContactPerson.lastname}
        disabled={false}
        inputProps={{ 'data-test': 'contact-lastname-input' }}
      />
      <AutoSaveInput
        name="email"
        label={translations.emailLabel}
        placeholder={translations.emailInputPlaceholder}
        onSave={onChange}
        initialValue={billingContactPerson.email}
        disabled={false}
        inputProps={{ 'data-test': 'contact-email-input' }}
      />
      <AutoSaveInput
        name="phone"
        label={translations.phoneLabel}
        placeholder={translations.phoneInputPlaceholder}
        onSave={onChange}
        initialValue={billingContactPerson.phone}
        disabled={false}
        inputProps={{ 'data-test': 'contact-phone-input' }}
      />
      <AutoSaveSelect
        name="language"
        label={translations.languageLabel}
        onSave={onChange}
        initialValue={billingContactPerson.language}
        disabled={false}
        options={languageList}
        data-test="contact-language-select"
      />
    </Paper>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('org')
  const translations = (t.orgBillingPage || {}) as { [k: string]: string }

  const {
    firstnameLabel = defaults.firstnameLabel,
    firstnameInputPlaceholder = defaults.firstnameInputPlaceholder,
    lastnameLabel = defaults.lastnameLabel,
    lastnameInputPlaceholder = defaults.lastnameInputPlaceholder,
    emailLabel = defaults.emailLabel,
    emailInputPlaceholder = defaults.emailInputPlaceholder,
    phoneLabel = defaults.phoneLabel,
    phoneInputPlaceholder = defaults.phoneInputPlaceholder,
    languageLabel = defaults.languageLabel,
  } = translations

  return {
    firstnameLabel,
    firstnameInputPlaceholder,
    lastnameLabel,
    lastnameInputPlaceholder,
    emailLabel,
    emailInputPlaceholder,
    phoneLabel,
    phoneInputPlaceholder,
    languageLabel,
  }
}

const defaultTranslations = {
  firstnameLabel: 'First name',
  firstnameInputPlaceholder: 'Enter first name of the contact person',
  lastnameLabel: 'Last name',
  lastnameInputPlaceholder: 'Enter last name of the contact person',
  emailLabel: 'Email',
  emailInputPlaceholder: 'Enter the email address of the contact person',
  phoneLabel: 'Phone',
  phoneInputPlaceholder: 'Enter the phone number of the contact person',
  languageLabel: 'Language',
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    '& > form, & > div': {
      padding: theme.spacing(1, 2),
    },
  },
}))

type Translations = typeof defaultTranslations
type BillingContactPersonProps = {
  billingContactPerson: {
    firstname: string
    lastname: string
    email: string
    phone: string
    language: string
  }
  onChange: (value: { [key: string]: string }) => Promise<void>
}

export default BillingContactPerson
