export const TASK_STATE = {
  NOT_REPORTED: 'Not reported',
  GREEN: 'Green',
  RED: 'Red',
  YELLOW: 'Yellow',
} as const

export const TASK_STATE_ICON_NAME = {
  [TASK_STATE.NOT_REPORTED]: '',
  [TASK_STATE.GREEN]: 'sentiment_satisfied',
  [TASK_STATE.RED]: 'sentiment_dissatisfied',
  [TASK_STATE.YELLOW]: 'sentiment_neutral',
}
