import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useI18n } from '../../../../hooks'
import { StringMap } from '../../../../types/common'
import { Plus } from 'react-feather'
import { useAppContext } from '../../../../hooks/use-app-context'
import { paths } from '../../../../paths'
import OrgCustomersTable from '../../../../organisations/components/OrgCustomersTable'
import { useAppDispatch } from '../../../../store'
import { FetchCustomers } from '../../../../customers/store'
import { useAsync } from '../../../../hooks/use-async'
import { unwrapResult } from '@reduxjs/toolkit'
import Progress from '../../../../components/Progress'
import { useAuthUserMembership } from '../../../../memberships/hooks/use-auth-user-membership'
import { useUrlWithContext } from '../../../../hooks/use-url-with-context'
import { FetchContextOptions } from '../../../../context-options/store/actions'

const useLoader = ({ orgId }: { orgId: string }) => {
  const dispatch = useAppDispatch()
  const loader = React.useCallback(async () => {
    dispatch({ type: `load_org_customers_route` })
    const dispatchResults = await Promise.all([dispatch(FetchCustomers(orgId)), dispatch(FetchContextOptions())])
    dispatchResults.forEach(unwrapResult)
  }, [dispatch, orgId])
  return useAsync<void, any>(loader)
}

const CustomersRoute = () => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const { appContext } = useAppContext()
  const { createPathWithContext } = useUrlWithContext()
  const { mainContext } = appContext
  const orgId = mainContext?.id as string
  const { error, isSuccess, isPending } = useLoader({ orgId })
  const { canCreateCustomers } = useAuthUserMembership()
  if (!mainContext || mainContext.type === 'user') return null
  const createCustomerButtonHref = createPathWithContext(paths.orgNewCustomer(), {
    withSubContext: false,
  })

  return (
    <div className={classes.pageContainer}>
      <Grid container className={classes.header} justifyContent="space-between">
        <Grid item>
          <Typography variant="h6" component="header" data-test="page-header">
            <Box component="span" color="text.secondary">
              {`${mainContext.name} - `}
            </Box>
            {translations.customers}
          </Typography>
        </Grid>
        <Grid item>
          {canCreateCustomers && (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={createCustomerButtonHref}
              endIcon={<Plus size={20} />}
              data-test="add-customer-button"
            >
              {translations.addBtnLabel}
            </Button>
          )}
        </Grid>
      </Grid>
      {isPending() && <Progress label="" />}
      {isSuccess() && <OrgCustomersTable orgId={mainContext.id} />}
      {error && <p>{error?.message || error}</p>}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    padding: theme.spacing(0, 2),
    maxWidth: 1440,
    margin: 'auto',
  },
  header: {
    margin: theme.spacing(0.75, 0, 2, 0),
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('customer')
  const translations = (t?.customersPage || {}) as StringMap

  const { customers = defaults.customers, addBtnLabel = defaults.addBtnLabel } = translations

  return {
    customers,
    addBtnLabel,
  }
}

const defaultTranslations = {
  customers: 'Customers',
  addBtnLabel: 'Add customer',
}

type Translations = typeof defaultTranslations

export default CustomersRoute
