import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import MuiPopper, { PopperProps as MuiPopperProps } from '@material-ui/core/Popper'

import { makeStyles } from '@material-ui/core'
import React from 'react'

/** I had to create this component because of type issue
 *  CustomPopperMenu had a union type with any for some reason,
 *  that effectively makes the prop type any, which is like having no type at all
 */

/** Popper component with clickaway listener and some transition */

const Popper = ({ open, anchorEl, onClose, children, ...props }: React.PropsWithChildren<PopperProps>) => {
  const classes = useStyles()

  return (
    <MuiPopper
      container={anchorEl}
      className={classes.popper}
      open={open}
      anchorEl={anchorEl}
      transition
      placement="top-start"
      {...props}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper elevation={0} className={classes.paper}>
            <ClickAwayListener onClickAway={onClose}>
              <span>{children}</span>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </MuiPopper>
  )
}

const useStyles = makeStyles((theme) => ({
  popper: {
    width: '100%',
    maxHeight: theme.spacing(30),
    overflow: 'auto',
  },
  paper: {
    width: '100%',
  },
}))

type PopperProps = MuiPopperProps & {
  open: boolean
  anchorEl: HTMLElement | null
  onClose: () => void
}

export default Popper
