import Calendar from './Calendar'
import CalendarViewHeader from './CalendarViewHeader'
import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import {
  adaptCalendarEvent,
  adaptTask,
  adaptTodo,
  CalendarEventData,
  CalendarTaskData,
  CalendarTodoData,
  EventType,
} from '../utils'
import { useLocalStorage } from '../../hooks'
import { useTaskMutations } from '../../tasks/hooks/use-task-mutations'
import { useCalendarEventMutations } from '../hooks/use-calendar-event-mutations'
import { useTodoMutations } from '../../todos/hooks/use-todo-mutations'
import { keyBy } from 'lodash'
import { CalendarEventViewModel } from '../api/calendar-event'
import { TaskViewModel } from '../../tasks/api/task'
import { TodoViewModel } from '../../todos/api/todo'

const eventsKey = 'calendar_show_events'
const tasksKey = 'calendar_show_tasks'
const todosKey = 'calendar_show_todos'

const useCalendarView = () => {
  const calendarEventActions = useCalendarEventMutations()
  const taskActions = useTaskMutations()
  const todoActions = useTodoMutations()
  const [showEvents, setShowEvents] = useLocalStorage(eventsKey, true)
  const [showTasks, setShowTasks] = useLocalStorage(tasksKey, true)
  const [showTodos, setShowTodos] = useLocalStorage(todosKey, true)
  const toggleCalendarEvents = () => setShowEvents(!showEvents)
  const toggleTasks = () => setShowTasks(!showTasks)
  const toggleTodos = () => setShowTodos(!showTodos)

  return {
    showEvents,
    showTasks,
    showTodos,
    toggleCalendarEvents,
    toggleTasks,
    toggleTodos,
    updateCalendarEvent: calendarEventActions.updateInfo,
    updateTask: taskActions.updatePlan,
    updateTodo: todoActions.updateInfo,
  }
}

const CalendarView = ({
  title,
  tasks,
  calendarEvents,
  todos,
  canAddCalendarEvents,
  canAddTasks,
  canAddTodos,
  onAddCalendarEvent,
  onAddTask,
  onAddTodo,
  calendarWeekendDays,
  showCaption = false,
}: CalendarViewProps) => {
  const classes = useStyles()
  const {
    showEvents,
    showTasks,
    showTodos,
    toggleCalendarEvents,
    toggleTasks,
    toggleTodos,
    updateCalendarEvent,
    updateTask,
    updateTodo,
  } = useCalendarView()

  const { events, entitiesById } = React.useMemo(() => {
    const resultEvents: EventType[] = []

    if (showEvents) {
      calendarEvents.forEach((event) => resultEvents.push(adaptCalendarEvent(event)))
    }

    if (showTasks) {
      tasks.forEach((task) => {
        if (task.plannedEndDate || task.plannedStartDate) {
          resultEvents.push(adaptTask(task))
        }
      })
    }

    if (showTodos) {
      todos.forEach((todo) => {
        if (todo.dueDate) {
          resultEvents.push(adaptTodo(todo))
        }
      })
    }

    return {
      events: resultEvents,
      entitiesById: keyBy([...calendarEvents, ...tasks, ...todos], 'id'),
    }
  }, [tasks, calendarEvents, todos, showEvents, showTodos, showTasks])

  return (
    <div className={classes.viewContainer}>
      <CalendarViewHeader
        titlePrefix={title}
        showEvents={showEvents}
        showTasks={showTasks}
        showTodos={showTodos}
        toggleCalendarEvents={toggleCalendarEvents}
        toggleTasks={toggleTasks}
        toggleTodos={toggleTodos}
      />
      <Calendar
        events={events}
        entitiesById={entitiesById}
        canAddCalendarEvents={canAddCalendarEvents}
        onAddCalendarEvent={onAddCalendarEvent}
        weekendDays={calendarWeekendDays}
        onUpdateCalendarEvent={updateCalendarEvent}
        onUpdateTask={updateTask}
        onUpdateTodo={updateTodo}
        canAddTasks={canAddTasks}
        onAddTask={onAddTask}
        canAddTodos={canAddTodos}
        onAddTodo={onAddTodo}
        showCaption={showCaption}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  viewContainer: {
    marginTop: theme.spacing(0.75),
    '& > :first-child': {
      marginBottom: theme.spacing(1.125),
    },
  },
}))

type CalendarViewProps = {
  title: string
  calendarEvents: CalendarEventViewModel[]
  tasks: TaskViewModel[]
  todos: TodoViewModel[]
  canAddCalendarEvents: boolean
  canAddTasks: boolean
  canAddTodos: boolean
  onAddCalendarEvent: (calendarEvent: CalendarEventData) => void
  onAddTask: (task: CalendarTaskData) => void
  onAddTodo: (task: CalendarTodoData) => void
  calendarWeekendDays: DayOfWeek[]
  showCaption?: boolean
}

export default CalendarView
