import React from 'react'
import OrgMembershipsView from '../../../../organisations/views/OrgMembershipsView'
import Progress from '../../../../components/Progress'

import { unwrapResult } from '@reduxjs/toolkit'
import { FetchOrgMemberships, FetchUserMemberships } from '../../../../memberships/store'
import { FetchOrganisation } from '../../../../organisations/store/actions'
import { useAppDispatch } from '../../../../store'
import { useAsync } from '../../../../hooks/use-async'
import { FetchContextOptions } from '../../../../context-options/store/actions'
import { useAppContext } from '../../../../hooks/use-app-context'

const useLoader = ({ orgId }: { orgId: string }) => {
  const dispatch = useAppDispatch()
  const loader = React.useCallback(async () => {
    dispatch({ type: 'load_org_memberships_route' })
    if (orgId) {
      const dispatchResults = await Promise.all([
        dispatch(FetchUserMemberships()),
        dispatch(FetchOrganisation(orgId)),
        dispatch(FetchOrgMemberships(orgId)),
        dispatch(FetchContextOptions()),
      ])
      dispatchResults.forEach(unwrapResult)
    }
  }, [dispatch, orgId])
  return useAsync<void, any>(loader)
}

const OrgMembershipsRoute = () => {
  const { appContext } = useAppContext()
  const orgId = appContext.mainContext?.id as string
  const { error, status } = useLoader({ orgId })
  const errorMessage = error?.message ? error.message : error

  if (status === 'error') return <p>{errorMessage} </p>
  if (status === 'success') return <OrgMembershipsView orgId={orgId} />

  return <Progress label="" />
}

export default OrgMembershipsRoute
