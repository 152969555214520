import doublet from '../../utils/doublet'
import * as actions from '../store/actions'
import * as organisationApi from '../services/organisation-api'
import { useSnackbar } from 'notistack'
import { useAppDispatch } from '../../store'
import { unwrapResult } from '@reduxjs/toolkit'

export const useOrganisationActions = () => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const unwrapResultOrShowError: UnwrapResultOrShowErrorFn = async (dispatchResult) => {
    const [error, data] = await doublet(unwrapResult, dispatchResult)
    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      return null
    }
    return data
  }

  const udpateOrgInfo = async (orgId: string, payload: organisationApi.OrganisationInfoUpdateData) => {
    const dispatchResult = await dispatch(actions.UpdateOrganisationInfo({ orgId, ...payload }))
    return unwrapResultOrShowError(dispatchResult)
  }

  const deleteOrganisation = async (orgId: string) => {
    const dispatchResult = await dispatch(actions.DeleteOrganisation(orgId))
    return unwrapResultOrShowError(dispatchResult)
  }

  return {
    udpateOrgInfo,
    deleteOrganisation,
  }
}

type UnwrappableAction = {
  payload: any
  meta?: any
  error?: any
}

type UnwrapResultOrShowErrorFn = <R extends UnwrappableAction>(
  dispatchResult: R
) => Promise<ReturnType<typeof unwrapResult<R>> | null>
