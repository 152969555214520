import { RouteComponentProps } from 'react-router-dom'
import { useTask } from '../../../tasks/hooks/use-task'
import { useGetTodosQuery } from '../../../todos/api'
import { useDateFormat } from '../../../users/hooks/use-date-format'
import { useUserTimeZone } from '../../../hooks/use-user-time-zone'
import { useFirstDayOfWeek } from '../../../hooks/use-first-day-of-week'
import { useOrgOptions } from '../../../options/hooks/use-org-options'
import { useTodoMutations } from '../../../todos/hooks/use-todo-mutations'
import CircularProgress from '@material-ui/core/CircularProgress'
import TodoList from '../../../treegrid/todo-list/TodoList'
import { useI18n } from '../../../hooks'
import { StringMap } from 'i18next'
import ErrorList from '../../../components/ErrorList'

const queryOptions = { refetchOnMountOrArgChange: true } as const

const TaskTodosRoute = ({ match }: RouteComponentProps<RouteParams>) => {
  const translations = useTranslations()
  const { taskId } = match.params
  const { task, isLoading: isLoadingTask, isError: isErrorTask } = useTask(taskId, queryOptions)

  const {
    data: todos = [],
    isLoading: isLoadingTodos,
    isError: isErrorTodos,
  } = useGetTodosQuery({ task: taskId }, queryOptions)
  const { dateFormat, dateSeparator } = useDateFormat()
  const userTimeZone = useUserTimeZone()
  const firstDayOfWeek = useFirstDayOfWeek()
  const { options } = useOrgOptions(task?.maintainerId || '')
  const todoActions = useTodoMutations()

  if (isLoadingTask || isLoadingTodos) return <CircularProgress />
  if (isErrorTask) return <ErrorList errors={[translations.fetchErrorTask]} />
  if (isErrorTodos) return <ErrorList errors={[translations.fetchErrorTodos]} />
  if (!task) return <ErrorList errors={[translations.taskNotFoundError]} />

  return (
    <TodoList
      gridInfo={`${task?.title} - Todos`}
      dateFormat={dateFormat}
      dateSeparator={dateSeparator}
      firstDayOfWeek={firstDayOfWeek}
      timeZone={userTimeZone}
      options={options}
      todos={todos}
      canCreateTodos={task.canCreateTaskTodos}
      onAddTodo={(todoData) => todoActions.createTodo({ ...todoData, task: taskId })}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = t || ({} as StringMap)

  const {
    fetchErrorTodos = defaults.fetchErrorTodos,
    fetchErrorTask = defaults.fetchErrorTask,
    taskNotFoundError = defaults.taskNotFoundError,
  } = translations

  return {
    fetchErrorTask,
    fetchErrorTodos,
    taskNotFoundError,
  }
}

const defaultTranslations = {
  fetchErrorTask: 'Failed to fetch task',
  fetchErrorTodos: 'Failed to fetch todos',
  taskNotFoundError: 'Task not found',
}
type Translations = typeof defaultTranslations
type RouteParams = { taskId: string }
export default TaskTodosRoute
