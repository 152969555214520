import { getCustomerNumberMetadata } from '../customer-api'

export const makeValidateCustomerNumber: MakeValidateCustomerNumber = ({
  orgId,
  currentCustomerNumber = '',
  translations,
}) => {
  return async ({ customerNumber }) => {
    let message = translations.somethingWentWrongError
    if (!customerNumber || customerNumber === currentCustomerNumber.toString()) {
      return {}
    }

    try {
      const data = await getCustomerNumberMetadata(orgId, customerNumber.toString())
      if (data.error) {
        message = translations.invalidCustomerNumberError

        const error = data.error.toLowerCase()
        const isDuplicate = error.includes('duplicate')
        const isNotNumber = error.includes('integer')

        if (isDuplicate) message = translations.duplicateCustomerNumberError
        if (isNotNumber) message = translations.numberOnlyCustomerNumberError
        return { customerNumber: message }
      }
      return {}
    } catch (error: any) {
      return { customerNumber: message }
    }
  }
}

type MakeValidateCustomerNumber = (params: {
  orgId: string
  currentCustomerNumber?: string
  translations: {
    invalidCustomerNumberError: string
    duplicateCustomerNumberError: string
    numberOnlyCustomerNumberError: string
    somethingWentWrongError: string
  }
}) => (params: { customerNumber: string }) => Promise<{ customerNumber?: string }>
