import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch, useAppSelector } from '../../store'
import { UpdateUserInfoPayload } from '../user-api'
import { DeleteUserAccount, UpdateUserInfo, VerifyAccount } from '../store/actions'
import { getAuthUser } from '../store/selectors'
import { useCalendarEventMutations } from '../../calendar-events/hooks/use-calendar-event-mutations'
import { useTodoMutations } from '../../todos/hooks/use-todo-mutations'
import { NewCalendarEventData } from '../../calendar-events/api'
import { NewTodoData } from '../../todos/api'

export const useAuthUser = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getAuthUser)
  const calendarEventActions = useCalendarEventMutations()
  const todoActions = useTodoMutations()

  const onUpdateUserInfo = (userData: UpdateUserInfoPayload) => {
    dispatch(UpdateUserInfo(userData))
  }

  const onAddCalendarEvent = (event: NewCalendarEventData) => {
    return calendarEventActions.createCalendarEvent({
      ...event,
      description: event.description || null,
      organisation: null,
    })
  }

  const onAddTodo = (todoData: NewTodoData) => {
    return todoActions.createTodo({
      ...todoData,
      description: todoData.description || null,
      dueDate: todoData.dueDate || null,
      organisation: null,
    })
  }

  const onVerifyAccount = async (code: string) => {
    if (user) {
      unwrapResult(await dispatch(VerifyAccount({ user: user.id, code })))
    }
  }

  const onDeleteAccount = async (payload: { confirmPassword: string }) => {
    return unwrapResult(await dispatch(DeleteUserAccount(payload)))
  }

  const getDateFormat = (): string => {
    return user?.dateFormat
      ?.split('.')
      .join(user?.dateFormatSeparator)
      .replace('DD', 'dd')
      .replace('YYYY', 'yyyy') as string
  }

  return {
    user,
    onUpdateUserInfo,
    onAddCalendarEvent,
    onAddTodo,
    onVerifyAccount,
    onDeleteAccount,
    getDateFormat,
    dateFormat: getDateFormat(),
  }
}
