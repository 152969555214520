import api from '../services/api-service'
import { makeOrganisationApiRoutes } from '../organisations/api-routes'
import type { AxiosRequestConfig } from 'axios'
import type { RoleResponse } from '../organisations/interfaces/role-response'

const organisationApiRoutes = makeOrganisationApiRoutes()

export const fetchOrgRoles = (
  orgId: string,
  config: AxiosRequestConfig = {}
) => {
  return api.get<RoleResponse[]>(organisationApiRoutes.orgRoles(orgId), config)
}

export const updateRoleAbilities = (
  roleId: string,
  updateData: RoleAbilityUpdateData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<RoleResponse>(
    organisationApiRoutes.orgRoleAbilities(roleId),
    updateData,
    config
  )
}

export const applyRoleAbilitiesToAll = (
  roleId: string,
  config: AxiosRequestConfig = {}
) => {
  return api.put<boolean>(
    organisationApiRoutes.orgRoleAbilitiesApplyToAll(roleId),
    {},
    config
  )
}

export type RoleAbilityUpdateData = {
  abilitiesToAdd?: string[]
  abilitiesToRemove?: string[]
}
