import { differenceBy } from 'lodash'
import { makeStyles, Theme } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import { X } from 'react-feather'
import { useLocation } from 'react-router-dom'
import CalendarForm from './CalendarForm'
import {
  CalendarEventData,
  CalendarEventUpdates,
  CalendarTaskData,
  CalendarTaskUpdates,
  CalendarTodoData,
  CalendarTodoUpdates,
  EventType,
} from '../utils'
import { DateTimeService } from '../../services/date-time-service'
import { useAuthUser } from '../../users/hooks/use-auth-user'
import { useDateFormat } from '../../users/hooks/use-date-format'
import TaskCardTitle from '../../tasks/components/TaskCardTitle'
import TodoCardTitle from '../../todos/components/TodoCardTitle'
import CalendarEventCardTitle from './CalendarEventCardTitle'
import TaskDetails from '../../tasks/components/TaskDetails'
import TodoDetails from '../../todos/components/TodoDetails'
import CalendarEventDetails from './CalendarEventDetails'
import { TaskViewModel } from '../../tasks/api/task'
import { CalendarEventViewModel } from '../api/calendar-event'
import { TodoViewModel } from '../../todos/api/todo'
import { useI18n } from '../../hooks'
import { useDateTranslations } from '../../hooks/use-date-translations'

const Calendar = ({
  id = '__scheduler__v2',
  events,
  entitiesById,
  canAddCalendarEvents,
  canAddTasks,
  canAddTodos,
  onAddCalendarEvent,
  onAddTask,
  onAddTodo,
  onUpdateCalendarEvent,
  onUpdateTask,
  onUpdateTodo,
  weekendDays = [],
  dateTimeService,
  showCaption = false,
}: CalendarProps) => {
  const { user } = useAuthUser()
  const { dateFormat } = useDateFormat()
  const defaultDateTimeService = React.useMemo(() => {
    return (
      dateTimeService ||
      new DateTimeService({
        dateFormat,
        timeZone: user?.timeZone,
      })
    )
  }, [dateTimeService, dateFormat, user?.timeZone])

  const timeZonedEvents = events.map((event: EventType) => {
    const { start_date, end_date } = event
    const zonedStartDate = defaultDateTimeService.removeTimezoneOffset(start_date)
    const zonedEndDate = defaultDateTimeService.removeTimezoneOffset(end_date)
    return { ...event, start_date: zonedStartDate, end_date: zonedEndDate }
  })

  const classes = useStyles()
  const translations = useTranslations()
  const location = useLocation()
  const calendarInstanceRef = React.useRef<SchedulerStatic | null>(null)
  const lightBoxRootElRef = React.useRef<HTMLDivElement | null>(null)
  const eventFormTitleInputRef = React.useRef<HTMLInputElement | null>(null)
  const [startDate, setStartDate] = React.useState<string | null>(null)
  const [endDate, setEndDate] = React.useState<string | null>(null)
  const [currentEventId, setCurrentEventId] = React.useState<string | null>(null)
  const currentEvent = timeZonedEvents.find((evt) => evt.id === currentEventId)
  const onBeforeEventChanged = (event: EventType, _: any, isNew: boolean) => {
    const eventEndDate = defaultDateTimeService.addTimezoneOffset(event.end_date).toISOString()
    const eventStartDate = defaultDateTimeService.addTimezoneOffset(event.start_date).toISOString()
    if (isNew) {
      // sync startDate and endDate with dates of new event
      setStartDate(eventStartDate)
      setEndDate(eventEndDate)
      return true // returning falsy from here will cancel this event and cause undesired behaviour
    } else if (event.type === 'task') {
      return onUpdateTask(event.id, {
        plannedEndDate: eventEndDate,
        plannedStartDate: eventStartDate,
      })
    } else if (event.type === 'todo') {
      const dueDate = eventEndDate
      let enableTimeComponent = false
      if (dueDate && !defaultDateTimeService.hasMidnightTime(dueDate)) enableTimeComponent = true
      return onUpdateTodo(event.id, { dueDate, enableTimeComponent })
    } else if (event.type === 'calendarEvent') {
      return onUpdateCalendarEvent(event.id, {
        startDate: eventStartDate,
        endDate: eventEndDate,
      })
    }
    throw new Error('Unexpected error')
  }

  const onEventAdded = (id: string) => {
    calendarInstanceRef.current?.deleteEvent(id)
  }

  const showLightBox = (id: string) => {
    if (canAddTasks || canAddTodos || canAddCalendarEvents) {
      if (calendarInstanceRef.current && lightBoxRootElRef.current) {
        calendarInstanceRef.current.startLightbox(id, lightBoxRootElRef.current)
        eventFormTitleInputRef.current?.focus()
      }
    }
  }

  const closeForm = (save: boolean) => {
    calendarInstanceRef.current?.endLightbox(save, lightBoxRootElRef.current as HTMLElement)
    // reset state of start and end date
    setStartDate(null)
    setEndDate(null)
    setCurrentEventId(null)
  }

  const handleBeforeEventChange = React.useCallback(onBeforeEventChanged, [
    onUpdateTask,
    defaultDateTimeService,
    onUpdateTodo,
    onUpdateCalendarEvent,
  ])
  const handleShowLightBox = React.useCallback(showLightBox, [canAddTasks, canAddTodos, canAddCalendarEvents])
  const handleEventAdded = React.useCallback(onEventAdded, [])

  React.useEffect(() => {
    function eventTextTemplate(start: Date, end: Date, event: EventType) {
      return `<span class="dhx_calendar_title">${event.text}</span>`
    }
    if (!calendarInstanceRef.current) {
      const schedulerConfig = {
        container: id,
        config: {
          readonly: !canAddTasks && !canAddTodos && !canAddCalendarEvents,
          details_on_create: true,
          details_on_dblclick: true,
          header: ['day', 'week', 'month', 'minicalendar', 'date', 'prev', 'today', 'next'],
          scroll_hour: new Date().getHours(),
          mark_now: true,
          now_date: new Date(),
        },
        data: { timeZonedEvents },
        templates: {
          event_text: eventTextTemplate,
        },
      }
      // Initialize the calendar instance
      calendarInstanceRef.current = Scheduler.getSchedulerInstance(
        //@ts-ignore
        schedulerConfig
      )
      const calendar = calendarInstanceRef.current
      /**
       * Calendar plugins
       */
      // @ts-ignore
      calendar.plugins({
        limit: true,
        minical: true,
        tooltip: true,
      })

      /** Calendar timeZonedEvents */
      calendar.attachEvent('onBeforeEventChanged', handleBeforeEventChange)
      calendar.attachEvent('onEventAdded', handleEventAdded)
      calendar.attachEvent('onClick', (id: string) => {
        setCurrentEventId(id)
        calendar.showLightbox(id)
      })
      calendar.attachEvent('onDblClick', (id: string) => {
        setCurrentEventId(id)
        calendar.showLightbox(id)
        calendar.showLightbox(id)
      })

      /** Calendar methods */
      calendar.showLightbox = handleShowLightBox

      /** Calendar template customization */
      calendar.templates.week_date_class = function (start, end, event) {
        const weekends = user?.weekendDays || weekendDays
        const dayOfWeek = start.getDay() as DayOfWeek
        return weekends.includes(dayOfWeek) ? 'weekend' : ''
      }
      calendar.templates.tooltip_text = function (start, end, event) {
        const isMultipleDays = start.getDate() !== end.getDate()
        const startHours = start.getHours() < 10 ? `0${start.getHours()}` : `${start.getHours()}`
        const startMinutes = start.getMinutes() < 10 ? `0${start.getMinutes()}` : `${start.getMinutes()}`
        const startTime = `${startHours}:${startMinutes}`
        const startTimeToShow = isMultipleDays ? `${defaultDateTimeService.format(start)} ${startTime}` : startTime

        const endHours = end.getHours() < 10 ? `0${end.getHours()}` : `${end.getHours()}`
        const endMinutes = end.getMinutes() < 10 ? `0${end.getMinutes()}` : `${end.getMinutes()}`
        const endTime = `${endHours}:${endMinutes}`
        const endTimeToShow = isMultipleDays ? `${defaultDateTimeService.format(end)} ${endTime}` : endTime
        const participants = event.participants.join(', ')
        const text = event.details || event.text
        return `<span class="dhx_calendar_caption">${text}</span><br><span class="dhx_calendar_caption">${startTimeToShow} - ${endTimeToShow}<span><br><span class="dhx_calendar_caption">${participants}</span>`
      }

      /** Calendar week_start customization */
      calendar.date.week_start = function (date) {
        let shift = date.getDay()
        if (shift === 0) {
          shift = 2
        } else {
          const firstDayOfWeek = user?.firstDayOfWeek === 0 || user?.firstDayOfWeek ? user.firstDayOfWeek : 1
          shift = shift - firstDayOfWeek
        }
        return this.date_part(this.add(date, -1 * shift, 'day'))
      }
    }

    const currentEvents: EventType[] = calendarInstanceRef.current?.getEvents()
    const removed = differenceBy(currentEvents, timeZonedEvents, 'id')
    removed.forEach((evt) => calendarInstanceRef.current?.deleteEvent(evt.id))
    calendarInstanceRef.current?.parse(timeZonedEvents)
  }, [
    canAddTasks,
    canAddTodos,
    canAddCalendarEvents,
    timeZonedEvents,
    handleBeforeEventChange,
    handleShowLightBox,
    handleEventAdded,
    id,
    weekendDays,
    user?.firstDayOfWeek,
    showCaption,
    defaultDateTimeService,
    user?.weekendDays,
  ])

  React.useEffect(() => {
    return () => {
      // destroy the calendar instance
      if (calendarInstanceRef.current) {
        // @ts-ignore
        calendarInstanceRef.current.destructor()
        calendarInstanceRef.current = null
      }
    }
  }, [location.pathname])

  React.useEffect(() => {
    if (calendarInstanceRef.current) {
      const calendarTranslations = makeCalendarTranslations(translations)
      // @ts-ignore
      calendarInstanceRef.current.i18n.setLocale(calendarTranslations)
      // @ts-ignore
      calendarInstanceRef.current.render()
    }
  }, [translations])

  /**
   * This effect ends the light box
   * when an event gets deleted from calendar
   */
  React.useEffect(() => {
    if (currentEventId && !currentEvent) {
      setCurrentEventId(null)
      calendarInstanceRef.current?.endLightbox(false, lightBoxRootElRef.current as HTMLElement)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZonedEvents, currentEventId])

  const onCreateTodo = (todo: CalendarTodoData) => {
    const dueDate = todo.dueDate
    let enableTimeComponent = false
    if (dueDate && !defaultDateTimeService.hasMidnightTime(dueDate)) enableTimeComponent = true
    onAddTodo({ ...todo, enableTimeComponent })
  }

  function showMiniCalendar() {
    if (calendarInstanceRef.current?.isCalendarVisible()) {
      calendarInstanceRef.current?.destroyCalendar()
    } else {
      calendarInstanceRef.current?.renderCalendar({
        position: 'dhx_minical_icon',
        date: calendarInstanceRef.current?.date,
        navigation: true,
        handler: function (date: Date) {
          calendarInstanceRef.current?.setCurrentView(date)
          calendarInstanceRef.current?.destroyCalendar()
        },
      })
    }
  }

  return (
    <div data-test="calendar-container">
      <div style={{ height: 800 }}>
        <div id={id} className="dhx_cal_container" style={{ width: '100%', height: '100%' }}>
          <div className="dhx_cal_navline">
            <div className="dhx_cal_prev_button">&nbsp;</div>
            <div className="dhx_cal_next_button">&nbsp;</div>
            <div className="dhx_cal_today_button"></div>
            <div className="dhx_cal_date"></div>
            <div className="dhx_minical_icon" id="dhx_minical_icon" onClick={() => showMiniCalendar()}>
              &nbsp;
            </div>
            {/* @ts-ignore */}
            <div className="dhx_cal_tab" name="day_tab"></div>
            {/* @ts-ignore */}
            <div className="dhx_cal_tab" name="week_tab"></div>
            {/* @ts-ignore */}
            <div className="dhx_cal_tab" name="month_tab"></div>
          </div>
          <div className="dhx_cal_header"></div>
          <div className="dhx_cal_data"></div>
        </div>
      </div>

      <div ref={lightBoxRootElRef} className={classes.lightBoxRootElement}>
        {!currentEventId && (
          <CalendarForm
            canAddCalendarEvents={canAddCalendarEvents}
            canAddTasks={canAddTasks}
            canAddTodos={canAddTodos}
            onAddCalendarEvent={onAddCalendarEvent}
            onAddAsTask={onAddTask}
            onAddAsTodo={onCreateTodo}
            startDate={startDate}
            endDate={endDate}
            closeForm={closeForm}
            titleInputRef={eventFormTitleInputRef}
          />
        )}
        {currentEventId &&
          currentEvent &&
          (currentEvent.type === 'task' || currentEvent.type === 'todo' || currentEvent.type === 'calendarEvent') && (
            <Dialog
              open
              fullWidth
              maxWidth="md"
              PaperProps={{
                style: { overflow: 'hidden', padding: 12, minHeight: 600 },
              }}
            >
              <div className={classes.cardHeader}>
                <span className={classes.cardTitle}>
                  {currentEvent.type === 'task' && (
                    <TaskCardTitle task={entitiesById[currentEventId] as TaskViewModel} />
                  )}
                  {currentEvent.type === 'todo' && (
                    <TodoCardTitle todo={entitiesById[currentEventId] as TodoViewModel} />
                  )}
                  {currentEvent.type === 'calendarEvent' && (
                    <CalendarEventCardTitle calendarEvent={entitiesById[currentEventId] as CalendarEventViewModel} />
                  )}
                </span>
                <IconButton className={classes.closeIcon} onClick={() => closeForm(false)}>
                  <X size={24} color={'#616161'} />
                </IconButton>
              </div>
              <div style={{ padding: '0 20px' }}>
                {currentEvent.type === 'task' && <TaskDetails taskId={currentEventId} />}
                {currentEvent.type === 'todo' && <TodoDetails todo={entitiesById[currentEventId] as TodoViewModel} />}
                {currentEvent.type === 'calendarEvent' && (
                  <CalendarEventDetails calendarEvent={entitiesById[currentEventId] as CalendarEventViewModel} />
                )}
              </div>
            </Dialog>
          )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  lightBoxRootElement: {
    position: 'absolute',
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%)',
    zIndex: 10001,
    display: 'none', // important to hide the light box initially
    background: theme.palette.common.white,
    transition: theme.transitions.create('height'),
    borderRadius: theme.spacing(0.5),
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-bewteen',
  },
  cardTitle: {
    flex: 1,
    padding: theme.spacing(1, 1.5),
  },
  closeIcon: {
    marginRight: 16,
  },
}))

type CalendarProps = {
  id?: string
  events: EventType[]
  entitiesById: Record<string, TaskViewModel | CalendarEventViewModel | TodoViewModel>
  canAddCalendarEvents: boolean
  canAddTasks: boolean
  canAddTodos: boolean
  onAddCalendarEvent: (calendarEvent: CalendarEventData) => void
  onAddTask: (task: CalendarTaskData) => void
  onAddTodo: (task: CalendarTodoData) => void
  onUpdateCalendarEvent: (eventId: string, updates: CalendarEventUpdates) => void
  onUpdateTask: (taskId: string, updates: CalendarTaskUpdates) => void
  onUpdateTodo: (todoId: string, updates: CalendarTodoUpdates) => void
  weekendDays?: DayOfWeek[]
  dateTimeService?: DateTimeService
  showCaption?: boolean
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations } = useI18n('translation')
  const dateTranslations = useDateTranslations()

  const { todayLabel = defaults.todayLabel } = translations
  const { dayLabel, weekLabel, monthLabel, fullDayNames, abbreviatedDayNames, fullMonthNames, shortMonthsNames } =
    dateTranslations

  return {
    todayLabel,
    dayLabel,
    weekLabel,
    monthLabel,
    fullDayNames,
    abbreviatedDayNames,
    fullMonthNames,
    shortMonthsNames,
  }
}

const defaultTranslations = {
  todayLabel: 'Today',
  dayLabel: 'Day',
  weekLabel: 'Week',
  monthLabel: 'Month',
  fullDayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  abbreviatedDayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  fullMonthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  shortMonthsNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
}
type Translations = typeof defaultTranslations

const makeCalendarTranslations = (translations: Translations) => {
  const {
    todayLabel,
    dayLabel,
    weekLabel,
    monthLabel,
    fullDayNames,
    abbreviatedDayNames,
    fullMonthNames,
    shortMonthsNames,
  } = translations
  return {
    date: {
      month_full: fullMonthNames,
      month_short: shortMonthsNames,
      day_full: fullDayNames,
      day_short: abbreviatedDayNames,
    },
    labels: {
      dhx_cal_today_button: todayLabel,
      day_tab: dayLabel,
      week_tab: weekLabel,
      month_tab: monthLabel,
      day: dayLabel,
      week: weekLabel,
      month: monthLabel,
    },
  }
}

export default Calendar
