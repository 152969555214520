import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { makeStyles, Theme } from '@material-ui/core'
import { useI18n, useRouter } from '../../hooks'

const TasksViewSelect = () => {
  const router = useRouter()
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)

  const onChangeView = (view: TasksViewOptions) => {
    const resultUrl = new URL(window.location.origin)
    resultUrl.pathname = router.location.pathname
    resultUrl.search = router.location.search
    resultUrl.searchParams.set('view', view)
    router.push(resultUrl.pathname + resultUrl.search)
  }

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        labelId="task-view-select"
        id="task-view-select"
        // @ts-expect-error
        value={router.query.view || 'currentAndFuture'}
        onChange={(e) => onChangeView(e.target.value as TasksViewOptions)}
      >
        <MenuItem value="all">{translations.allMenuItemLabel}</MenuItem>
        <MenuItem value="currentAndFuture">{translations.currentAndFutureMenuItemLabel}</MenuItem>
        <MenuItem value="completed">{translations.completedMenuItemLabel}</MenuItem>
        <MenuItem value="rejected">{translations.rejectedMenuItemLabel}</MenuItem>
        <MenuItem value="archived">{translations.archivedMenuItemLabel}</MenuItem>
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: theme.spacing(28),
    '& .MuiSelect-root': {
      fontSize: 14,
      padding: 6,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('task')
  const translations = t?.taskViewSelect || {}

  const {
    allMenuItemLabel = defaults.allMenuItemLabel,
    currentAndFutureMenuItemLabel = defaults.currentAndFutureMenuItemLabel,
    completedMenuItemLabel = defaults.completedMenuItemLabel,
    rejectedMenuItemLabel = defaults.rejectedMenuItemLabel,
    archivedMenuItemLabel = defaults.archivedMenuItemLabel,
  } = translations

  return {
    allMenuItemLabel,
    currentAndFutureMenuItemLabel,
    completedMenuItemLabel,
    rejectedMenuItemLabel,
    archivedMenuItemLabel,
  }
}

const defaultTranslations = {
  allMenuItemLabel: 'All tasks',
  currentAndFutureMenuItemLabel: 'Current and future tasks',
  completedMenuItemLabel: 'Completed tasks',
  rejectedMenuItemLabel: 'Rejected tasks',
  archivedMenuItemLabel: 'Archived tasks',
}

type Translations = typeof defaultTranslations
export type TasksViewOptions = 'all' | 'currentAndFuture' | 'completed' | 'rejected' | 'archived'

export default TasksViewSelect
