import { addDays, startOfDay, subDays } from 'date-fns'
import { LinkType } from '../tasks/api/task-response'

export const adaptTask = (task: TaskLike): EventType => {
  if (!task.plannedEndDate && !task.plannedStartDate) {
    throw new Error('Either start date or end date is required')
  }
  let start_date = null
  let end_date = null

  if (!task.plannedStartDate) {
    if (!task.enableTimeComponent) {
      end_date = startOfDay(addDays(new Date(task.plannedEndDate), 1))
      start_date = subDays(end_date, 1)
    } else {
      end_date = new Date(task.plannedEndDate)
      start_date = end_date
    }
  } else if (!task.plannedEndDate) {
    if (!task.enableTimeComponent) {
      start_date = startOfDay(new Date(task.plannedStartDate))
      end_date = addDays(start_date, 1)
    } else {
      start_date = new Date(task.plannedStartDate)
      end_date = start_date
    }
  } else {
    start_date = new Date(task.plannedStartDate)
    end_date = new Date(task.plannedEndDate)

    if (end_date.getTime() - start_date.getTime() === 86399999) {
      end_date = startOfDay(addDays(end_date, 1))
    }
  }

  const text = `${task.taskNumber}: ${task.title || '(Untitled task)'}`
  const participants = task.managers.map((manager) => manager.name)
  let entirePath = `${task.projectLink.label}`
  const ancestorPath = task.ancestorTaskLinks.map((ancestor) => ancestor.label).join(' > ')
  if (task.ancestorTaskLinks.length) entirePath = entirePath.concat(` > ${ancestorPath}`)
  entirePath = entirePath.concat(` > ${text}`)

  return {
    id: task.id,
    type: 'task',
    start_date,
    end_date,
    text: text,
    details: entirePath,
    participants,
  }
}

export const adaptTodo = (todo: TodoLike): EventType => {
  if (!todo.dueDate) throw new Error('Due date is required ')
  const startDate = todo.enableTimeComponent ? new Date(todo.dueDate) : startOfDay(new Date(todo.dueDate))
  const endDate = todo.enableTimeComponent ? startDate : addDays(startDate, 1)
  const participants = todo.responsible.map((responsible) => responsible.name)
  const text = `${todo.title || '(Untitled todo)'}`
  const details = todo.project ? `${todo.project.title} > ${text}` : text

  return {
    id: todo.id,
    type: 'todo',
    start_date: startDate,
    end_date: endDate,
    text: todo.title || '(Untitled todo)',
    participants,
    details,
  }
}

export const adaptCalendarEvent = (event: CalendarEventLike): EventType => {
  if (!event.startDate || !event.endDate) {
    throw new Error('start date and end date is required')
  }
  const participants = event.participants.map((participant) => participant.name)
  const text = `${event.title || '(Untitled event)'}`
  const details = event.project ? `${event.project.title} > ${text}` : text

  return {
    id: event.id,
    text,
    start_date: new Date(event.startDate),
    end_date: new Date(event.endDate),
    type: 'calendarEvent',
    details,
    participants,
  }
}

export type EventTypeType = 'calendarEvent' | 'task' | 'todo'
export type CardTypeType = EventTypeType | 'newEvent'
export type CalendarTaskData = {
  title: string
  description: string
  plannedStartDate: string
  plannedEndDate: string
}

export type CalendarTaskUpdates = {
  plannedStartDate: string
  plannedEndDate: string
}

export type CalendarTodoData = {
  title: string
  description: string
  dueDate: string | null
  enableTimeComponent?: boolean
}

export type CalendarEventData = {
  title: string
  description: string
  startDate: string
  endDate: string
}

export type CalendarTodoUpdates = {
  dueDate: string
  enableTimeComponent: boolean
}

export type EventType = {
  type: EventTypeType
  id: string
  start_date: Date
  end_date: Date
  text: string
  details?: string
  participants: string[]
}

type TodoLike = {
  id: string
  title: string
  dueDate: string | null
  enableTimeComponent: boolean
  responsible: { id: string; name: string }[]
  project: { id: string; title: string } | null
}

type TaskLike = {
  id: string
  taskNumber: string
  title: string
  plannedStartDate: string
  plannedEndDate: string
  enableTimeComponent: boolean
  projectLink: { label: string }
  ancestorTaskLinks: LinkType[]
  managers: { id: string; name: string }[]
}

type CalendarEventLike = {
  id: string
  title: string
  startDate: string
  endDate: string
  project: { id: string; title: string } | null
  participants: { id: string; name: string }[]
}

export type CalendarEventUpdates = {
  startDate: string
  endDate: string
}
