import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import AcceptedInvitationCard from '../components/AcceptedInvitationCard'
import Stack from '../../components/Stack'
import PendingInvitationCard from '../components/PendingInvitationCard'
import { Theme, makeStyles } from '@material-ui/core'
import { IInvitationResponse } from '../../invitations/interfaces/invitation-response'
import { useUserInvitations } from '../hooks/use-user-invitations'
import { useI18n } from '../../hooks'
import { StringMap } from '../../types/common'

const InvitationsView = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const { pendingInvitations } = useUserInvitations()
  const [acceptedInvitation, setAcceptedInvitation] = React.useState<IInvitationResponse | null>(null)
  const hasInvitations = pendingInvitations.length || acceptedInvitation

  return (
    <Stack container direction="column" className={classes.pageContainer}>
      <Typography variant="h6" className={classes.pageHeader} data-test="page-header">
        {translations.pageHeader}
      </Typography>
      {!hasInvitations && (
        <Paper className={classes.noInvitations} variant="outlined">
          <img src="/empty-mailbox.svg" alt="Empty mailbox svg illustration" />
          <Typography variant="h4" component="h4">
            {translations.noInvitationsMessage}
          </Typography>
        </Paper>
      )}
      {hasInvitations && (
        <ul className={classes.listContainer}>
          {acceptedInvitation && <AcceptedInvitationCard invitation={acceptedInvitation} />}
          {pendingInvitations.map(($invitation: IInvitationResponse) => (
            <PendingInvitationCard
              key={$invitation.id}
              invitation={$invitation}
              setAcceptedInvitation={setAcceptedInvitation}
            />
          ))}
        </ul>
      )}
    </Stack>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    maxWidth: 800,
    margin: 'auto',
  },
  pageHeader: {
    margin: theme.spacing(2, 0),
  },
  noInvitations: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4, 0),
    '& > img': {
      width: 300,
      padding: theme.spacing(4),
    },
    '& > h4': {
      marginTop: theme.spacing(4),
    },
  },
  listContainer: {
    margin: 0,
    padding: 0,
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = (t?.invitationsPage || {}) as StringMap

  const { pageHeader = defaults.pageHeader, noInvitationsMessage = defaults.noInvitationsMessage } = translations

  return {
    pageHeader,
    noInvitationsMessage,
  }
}

const defaultTranslations = {
  pageHeader: 'Your invitations',
  noInvitationsMessage: 'You have no pending invitations.',
}

type Translations = typeof defaultTranslations
export default InvitationsView
