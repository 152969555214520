import Board from './Board'
import BoardColumnChooser from './BoardColumnChooser'
import Typography from '@material-ui/core/Typography'

import { makeStyles, Theme } from '@material-ui/core'
import { useI18n } from '../../hooks'
import { StringMap } from '../../types/common'
import { BoardColumnNameUpdateData, MoveCardData, NewCardData } from '../api'
import { BoardPopulated } from '../api/board'

const BoardView = ({ title, board, ...boardProps }: BoardViewProps) => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)

  return (
    <>
      <div className={classes.header}>
        <h6 style={{ margin: 0 }}>
          <Typography variant="h6" color="textSecondary" component="span">
            {title}
          </Typography>
          <Typography variant="h6" component="span" style={{ marginLeft: 4, marginRight: 4 }}>
            -
          </Typography>
          <Typography variant="h6" component="span">
            {translations.pageTitle}
          </Typography>
        </h6>
        <BoardColumnChooser boardId={board.id} boardType={board.type} />
      </div>
      <Board board={board} {...boardProps} />
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    margin: theme.spacing(0.75, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

const useTranslations = (defaults: Translations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = (t?.boardView || {}) as StringMap

  const { pageTitle = defaults.pageTitle } = translations

  return {
    pageTitle,
  }
}

const defaultTranslations = {
  pageTitle: 'Kanban board',
}

type Translations = typeof defaultTranslations

type BoardViewProps = {
  title: string
  board: BoardPopulated
  canAddTasks: boolean
  canAddTodos: boolean
  onAddTask: (cardData: NewCardData) => unknown
  onAddTodo: (cardData: NewCardData) => unknown
  onMoveCard: (moveCardData: MoveCardData & { board: BoardPopulated }) => unknown
  onSaveColumnName: (colName: BoardColumnNameUpdateData) => unknown
}

export default BoardView
