import api from '../services/api-service'
import { AxiosRequestConfig } from 'axios'
import { IAuthResponse } from './interfaces/auth-response'
import { IUserResponse } from './interfaces/user-response'
import { TimeZoneType } from '../constants/timezones'
import { DateFormatOptionType, DateFormatSeparatorOptionType } from '../constants/date-format'

/** Api routes */
export const makeUserApiRoutes = (userId = ':userId') => {
  const routes = {
    users: () => '/users',
    userItem: (id = '') => `${routes.users()}/${id || userId}`,
  }
  return routes
}
const userApiRoutes = makeUserApiRoutes()
/************ */

export const signup = (newUserData: NewUserData, config: AxiosRequestConfig = {}) => {
  return api.post<AuthResponseData>('/users', newUserData, config)
}

export const deleteUserAccount = (
  userId: string,
  payload: DeleteUserAccountPayload,
  config: AxiosRequestConfig = {}
) => {
  return api.delete<boolean>(`/users/${userId}`, {
    ...config,
    data: payload,
  })
}

export const fetchAuthUser = (config: AxiosRequestConfig = {}) => {
  return api.get<IUserResponse>('/auth/userinfo', config)
}

export const verifyAccount = (verificationData: VerifyAccountData, config: AxiosRequestConfig = {}) => {
  return api.put<IUserResponse>(`/users/account-verification`, verificationData, config)
}

export const requestVerificationCode = (
  { email, language }: { email: string; language: string },
  config: AxiosRequestConfig = {}
) => {
  return api.get(`/users/account-verification`, {
    ...config,
    params: Object.assign({ email, language }, config.params),
  })
}

export const requestPasswordResetToken = (
  { email, language }: { email: string; language: string },
  config: AxiosRequestConfig = {}
) => {
  return api.get('/users/password-reset', {
    ...config,
    params: Object.assign({ email, language }, config.params),
  })
}

export const resetPassword = (passwordData: ResetPasswordData, config: AxiosRequestConfig = {}) => {
  return api.put('/users/password-reset', passwordData, config)
}

export const updateUserInfo = (userId: string, payload: UpdateUserInfoPayload, config: AxiosRequestConfig = {}) => {
  return api.put<IUserResponse>(userApiRoutes.userItem(userId), payload, config)
}

export type NewUserData = {
  firstname: string
  lastname: string
  email: string
  password: string
  language: string
}

type AuthResponseData = {
  user: IUserResponse
  auth: IAuthResponse
}

export type VerifyAccountData = {
  user: string
  code: string
}

export type ResetPasswordData = {
  password: string
  token: string
}

export type UpdateUserInfoPayload = {
  firstname?: string
  lastname?: string
  email?: string
  weekendDays?: DayOfWeek[]
  firstDayOfWeek?: DayOfWeek
  timeZone?: TimeZoneType
  dateFormatSeparator?: DateFormatSeparatorOptionType
  dateFormat?: DateFormatOptionType
}

export type DeleteUserAccountPayload = {
  confirmPassword: string
}
