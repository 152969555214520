import UserMenuComponent from '../UserMenu'
import { useState } from 'react'

const UserMenu = () => {
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const toggleMenu = () => setOpen(!open)
  return <UserMenuComponent menuOpen={open} toggleUserMenu={toggleMenu} handleClose={handleClose} />
}

export default UserMenu
