import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ContactInfo from './ContactInfo'

import { User } from 'react-feather'
import { alpha, CircularProgress, makeStyles, Theme } from '@material-ui/core'
import { IContact } from '../../types/contact'
import { useAsync } from '../../hooks/use-async'

const ContactDetails = ({
  contact,
  onMakeDefaultContact,
  onDeleteContact,
  onEditContact,
  editable = true,
}: ContactDetailsProps) => {
  const classes = useStyles()
  const fullname = `${contact.firstname} ${contact.lastname}`
  const { status: deleteStatus, execute: handleDeleteContact } = useAsync(onDeleteContact, { immediate: false })
  const { status: defaultContactStatus, execute: handleMakeDefaultContact } = useAsync(onMakeDefaultContact, {
    immediate: false,
  })

  const makeOnSave = (field: keyof IContact) => async (values: { [k in keyof IContact]: string }) => {
    const action: any = await onEditContact({
      [field]: values[field],
    })

    if (action?.meta?.requestStatus === 'rejected') {
      return Promise.reject(action.error)
    }
  }

  const isDeleting = deleteStatus === 'pending'
  const isMakingDefaultContact = defaultContactStatus === 'pending'

  return (
    <Box py={2} px={4}>
      <Box display="flex" alignItems="center">
        <Box component="span" display="flex" className={classes.iconContainer}>
          <User size={20} />
        </Box>
        <Typography variant="h5">{fullname}</Typography>
      </Box>
      <Box className={classes.formContainer} my={4}>
        <ContactInfo
          contact={contact}
          editable={editable}
          onFirstnameSave={makeOnSave('firstname')}
          onLastnameSave={makeOnSave('lastname')}
          onEmailSave={makeOnSave('email')}
          onPhoneSave={makeOnSave('phone')}
          onJobTitleSave={makeOnSave('jobTitle')}
        />
      </Box>
      {editable && (
        <Grid container spacing={2}>
          <Grid item>
            <Button
              className={clsx([classes.actionButton, 'delete'])}
              onClick={handleDeleteContact}
              disabled={isDeleting}
              endIcon={isDeleting ? <CircularProgress size={16} /> : null}
              data-test="delete-contact-button"
            >
              Delete contact
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.actionButton}
              onClick={handleMakeDefaultContact}
              disabled={isMakingDefaultContact}
              endIcon={isMakingDefaultContact ? <CircularProgress size={16} /> : null}
              data-test="make-default-contact-button"
            >
              Make default contact
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    marginRight: theme.spacing(2.5),
    border: `1px dotted ${theme.palette.grey[600]}`,
    padding: theme.spacing(0.875),
    borderRadius: theme.spacing(10),
    '& svg': {
      color: theme.palette.grey[600],
      strokeWidth: 2.5,
    },
  },
  formContainer: {
    '& form': {
      marginBottom: theme.spacing(2),
    },
  },
  actionButton: {
    background: alpha(theme.palette.primary.main, 0.05),
    transition: theme.transitions.create('background'),
    '&.Mui-disabled': {
      color: `${theme.palette.grey[400]} !important`,
    },
    '&:hover': {
      background: alpha(theme.palette.primary.main, 0.1),
    },
    '&.delete': {
      color: theme.palette.error.main,
      background: alpha(theme.palette.error.main, 0.05),
      '&:hover': {
        background: alpha(theme.palette.error.main, 0.1),
      },
    },
  },
}))

type ContactDetailsProps = {
  contact: IContact
  onMakeDefaultContact: () => Promise<void>
  onDeleteContact: () => Promise<void>
  onEditContact: (props: { [k in keyof IContact]?: string }) => void
  editable?: boolean
}

export default ContactDetails
