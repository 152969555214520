import ResourceManager from '../../components/ResourceManager'
import { useI18n } from '../../hooks'
import { useOrgOptions } from '../../options/hooks/use-org-options'
import { ProjectViewModel } from '../api/project'
import { StringMap } from '../../types/common'
import { useProjectMutations } from '../hooks/use-project-mutations'

const ProjectManagersWidget = ({ project }: { project: ProjectViewModel }) => {
  const translations = useTranslations(defaultTranslations)
  const { membershipOptions } = useOrgOptions(project.maintainerId)
  const { addManagers, removeManagers } = useProjectMutations()

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={membershipOptions}
      resources={project.managers}
      onAddResources={(ids) => addManagers(project.id, ids)}
      onRemoveResource={(id) => removeManagers(project.id, [id])}
      canAddResource={project.canUpdateManagers}
      canRemoveResource={project.canUpdateManagers}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection
    />
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('project')
  const translations = (t?.managersWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: 'Manager',
  emptyOptionsMessage: 'No members in this organisation',
  emptyResourcesMessage: 'No manager in this project',
}

type Translations = typeof defaultTranslations

export default ProjectManagersWidget
