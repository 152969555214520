import { createSelector } from '@reduxjs/toolkit'
import { filter, values } from 'lodash'
import { RootState } from '../../store'

export const getWorkspaceById = (state: RootState, workspaceId: string) => {
  return state.workspaces.byIds[workspaceId]
}

export const getOrgWorkspaces = createSelector(
  (state: RootState) => state.workspaces.byIds,
  (state: RootState, orgId: string) => orgId,
  (workspaces, orgId) => {
    return filter(values(workspaces), { orgId })
  }
)

export const getAllWorkspaces = createSelector(
  (state: RootState) => state.workspaces.byIds,
  (workspaces) => values(workspaces)
)
