import { useAuthUserMembership } from '../../memberships/hooks/use-auth-user-membership'
import { useAppSelector } from '../../store'
import { getOrganisationById } from '../../organisations/store/selectors'
import { OrganisationInfoUpdateData } from '../services/organisation-api'
import { useOrganisationActions } from './use-organisation-actions'
import { useTodoMutations } from '../../todos/hooks/use-todo-mutations'
import { useCalendarEventMutations } from '../../calendar-events/hooks/use-calendar-event-mutations'
import { NewCalendarEventData } from '../../calendar-events/api'
import { NewTodoData } from '../../todos/api'

export const useOrganisation = (orgId: string) => {
  const organisation = useAppSelector((s) => getOrganisationById(s, orgId))
  const calendarEventActions = useCalendarEventMutations()
  const todoActions = useTodoMutations()
  const orgActions = useOrganisationActions()
  const { canCreateCalendarEvents, canCreateTodos, canUpdateOrganisationDetails } = useAuthUserMembership()

  const onAddCalendarEvent = (event: NewCalendarEventData) => {
    return calendarEventActions.createCalendarEvent({
      ...event,
      description: event.description || null,
      organisation: organisation.id,
    })
  }

  const onAddTodo = (todoData: NewTodoData) => {
    return todoActions.createTodo({
      ...todoData,
      organisation: organisation.id,
    })
  }

  const updateOrgInfo = (payload: OrganisationInfoUpdateData) => {
    return orgActions.udpateOrgInfo(orgId, payload)
  }

  return {
    organisation,
    canAddCalendarEvents: canCreateCalendarEvents,
    canAddTodos: canCreateTodos,
    canUpdateOrgInfo: canUpdateOrganisationDetails,
    updateOrgInfo,
    onAddCalendarEvent,
    onAddTodo,
  }
}
