import ResourceManager from '../../components/ResourceManager'
import { useCalendarEventMutations } from '../hooks/use-calendar-event-mutations'
import { useI18n } from '../../hooks/use-i18n'
import { useOrgOptions } from '../../options/hooks/use-org-options'
import { StringMap } from '../../types/common'
import { CalendarEventViewModel } from '../api/calendar-event'

const EventWorkspacesWidget = ({ calendarEvent }: EventWorkspacesWidgetProps) => {
  const translations = useTranslations(defaultTranslations)
  const { updateWorkspaces } = useCalendarEventMutations()
  const { workspaceOptions } = useOrgOptions(calendarEvent.organisation?.id || '')

  const onAddWorkspaces = (workspaceIds: string[]) => {
    const workspaces = calendarEvent.workspaces.map(($w) => $w.id).concat(workspaceIds)
    updateWorkspaces(calendarEvent.id, workspaces)
  }
  const onRemoveWorkspace = (workspace: string) => {
    const workspaces = calendarEvent.workspaces.filter(($w) => $w.id !== workspace).map(($w) => $w.id)
    updateWorkspaces(calendarEvent.id, workspaces)
  }

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={workspaceOptions}
      resources={calendarEvent.workspaces}
      canAddResource={calendarEvent.canUpdate}
      canRemoveResource={calendarEvent.canUpdate}
      onAddResources={onAddWorkspaces}
      onRemoveResource={onRemoveWorkspace}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection
    />
  )
}

const useTranslations = (defaults = defaultTranslations): EventWorkspacesWidgetTranslations => {
  const { translations: t } = useI18n('calendarEvent')
  const translations = (t?.workspacesWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: 'Workspaces',
  emptyOptionsMessage: 'No workspaces in this organisation',
  emptyResourcesMessage: 'This event is not connected to any workspace',
}

type EventWorkspacesWidgetTranslations = typeof defaultTranslations
type EventWorkspacesWidgetProps = { calendarEvent: CalendarEventViewModel }
export default EventWorkspacesWidget
