export const makeCalendarEventApiEndpoints = (eventId = ':eventId') => {
  const endpoints = {
    events: () => `/calendar-events`,
    orgEvents: (orgId = ':orgId') => `/orgs/${orgId}${endpoints.events()}`,
    eventItem: (id = '') => `${endpoints.events()}/${id || eventId}`,
    projectRelation: (id = '') => `${endpoints.eventItem(id)}/project`,
    taskRelation: (id = '') => `${endpoints.eventItem(id)}/task`,
    customerRelations: (id = '') => `${endpoints.eventItem(id)}/customers`,
    participantRelations: (id = '') => `${endpoints.eventItem(id)}/participants`,
    supplierRelations: (id = '') => `${endpoints.eventItem(id)}/suppliers`,
    workspaceRelations: (id = '') => `${endpoints.eventItem(id)}/workspaces`,
  }
  return endpoints
}
