import api from '../services/api-service'
import { AxiosRequestConfig } from 'axios'
import {
  IContactResponse,
  ICustomerNumberMetadataResponse,
  ICustomerResponse,
} from './interfaces/customer-response'
import { CustomerStatusEnum, CustomerTypeEnum } from '../types/customer'

export const fetchCustomerById = (
  customerId: string,
  config: AxiosRequestConfig = {}
) => {
  return api.get<ICustomerResponse>(`/customers/${customerId}`, config)
}

export const fetchCustomers = (
  orgId: string,
  config: AxiosRequestConfig = {}
) => {
  return api.get<ICustomerResponse[]>(`/orgs/${orgId}/customers`, config)
}

export const createCustomer = (
  orgId: string,
  customerData: CreateCustomerData,
  config: AxiosRequestConfig = {}
) => {
  return api.post<ICustomerResponse>(
    `/orgs/${orgId}/customers`,
    customerData,
    config
  )
}

export const updateCustomerInfo = (
  customerId: string,
  updateData: CustomerInfoUpdateData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<ICustomerResponse>(
    `/customers/${customerId}`,
    updateData,
    config
  )
}

export const updateCustomerStatus = (
  customerId: string,
  updateData: CustomerStatusUpdateData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<ICustomerResponse>(
    `/customers/${customerId}/status`,
    updateData,
    config
  )
}

export const addContact = (
  customerId: string,
  contactData: AddContactData,
  config: AxiosRequestConfig = {}
) => {
  return api.post<IContactResponse>(
    `/customers/${customerId}/contacts`,
    contactData,
    config
  )
}

export const changeDefaultContact = (
  customerId: string,
  contactId: string,
  config: AxiosRequestConfig = {}
) => {
  return api.put<ICustomerResponse>(
    `/customers/${customerId}/default-contact`,
    { contactId },
    config
  )
}

export const updateContact = (
  customerId: string,
  contactId: string,
  updateData: UpdateContactData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<IContactResponse>(
    `/customers/${customerId}/contacts/${contactId}`,
    updateData,
    config
  )
}

export const deleteContact = (
  customerId: string,
  contactId: string,
  config: AxiosRequestConfig = {}
) => {
  return api.delete<IContactResponse>(
    `/customers/${customerId}/contacts/${contactId}`,
    config
  )
}

export const getCustomerNumberMetadata = (
  orgId: string,
  customerNumber: string,
  config: AxiosRequestConfig = {}
) => {
  return api.get<ICustomerNumberMetadataResponse>(
    `/orgs/${orgId}/customer-numbers/${customerNumber}/metadata`,
    config
  )
}

export type AddContactData = {
  firstname: string
  lastname: string
  phone?: string
  email?: string
  jobTitle?: string
}

type CreateOrgCustomerData = {
  type: CustomerTypeEnum.organisation
  name?: string
  customerNumber?: number
}

type CreatePersonCustomerData = {
  type: CustomerTypeEnum.person
  firstname?: string
  lastname?: string
}

export type CreateCustomerData =
  | CreateOrgCustomerData
  | CreatePersonCustomerData

type OrgCustomerInfoUpdateData = {
  type: CustomerTypeEnum.organisation
  name?: string
  customerNumber?: number
  businessId?: string
  vatRegistrationNumber?: string
  language?: string
}

type PersonCustomerInfoUpdateData = {
  type: CustomerTypeEnum.person
  firstname?: string
  lastname?: string
  customerNumber?: number
  personalId?: string
  phone?: string
  email?: string
  language?: string
}

export type CustomerInfoUpdateData =
  | OrgCustomerInfoUpdateData
  | PersonCustomerInfoUpdateData

export type CustomerStatusUpdateData = {
  status: CustomerStatusEnum
}

export type UpdateContactData = {
  firstname?: string
  lastname?: string
  phone?: string
  email?: string
  jobTitle?: string
}
