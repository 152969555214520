import { Form, Formik, FormikHelpers } from 'formik'
import { CustomerTypeEnum, PersonCustomerPayload } from '../../types/customer'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import { CreateCustomer } from '../../customers/store/actions'
import { Button, CircularProgress, Grid, makeStyles, Theme } from '@material-ui/core'
import { Plus } from 'react-feather'
import { paths } from '../../paths'
import { useI18n, useNetworkStatus } from '../../hooks'
import InputLabel from '../../components/InputLabel'
import FormikInput from '../../components/FormikInput'
import FormErrorAlert from '../../components/FormErrorAlert'
import { makeValidateCustomerNumber } from '../utils/validate-customer-number'
import { useAppContext } from '../../hooks/use-app-context'
import { useUrlWithContext } from '../../hooks/use-url-with-context'
import { StringMap } from '../../types/common'

const PersonForm = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const dispatch = useDispatch()
  const handleError = useErrorHandler()
  const history = useHistory()
  const networkStatus = useNetworkStatus({ resetDelayInMS: 3000 })
  const { appContext } = useAppContext()
  const { createPathWithGivenContext } = useUrlWithContext()
  const orgId = appContext.mainContext?.id as string
  const { setStatus, isRejected, isIdle, isPending } = networkStatus

  const createPayload = (values: PersonCustomerFormProps) => {
    const { firstname, lastname, customerNumber } = values
    const customerType = CustomerTypeEnum.person

    let payload = {
      type: customerType,
    } as PersonCustomerPayload
    if (firstname) payload = { ...payload, firstname }
    if (lastname) payload = { ...payload, lastname }
    if (customerNumber) payload = { ...payload, customerNumber: Number(customerNumber) }

    return payload
  }

  const handleClickSubmit = async (
    values: PersonCustomerFormProps,
    { setSubmitting }: FormikHelpers<PersonCustomerFormProps>
  ) => {
    try {
      setStatus('pending')
      const payload = createPayload(values)
      const thunkParams = { orgId, ...payload }
      const actionResult: any = await dispatch(CreateCustomer(thunkParams))
      const requestStatus = actionResult.meta.requestStatus
      setStatus(requestStatus)
      setSubmitting(false)
      if (requestStatus === 'fulfilled') {
        const customerId = (actionResult.payload as any).id
        const customerHomeURLWithContext = createPathWithGivenContext({
          path: paths.customerSettings(),
          mainContextId: orgId,
          subContextId: customerId,
        })
        history.push(`${customerHomeURLWithContext}&created=now`)
      }
    } catch (error: any) {
      handleError(error)
    }
  }

  const formikProps = {
    initialValues: {
      firstname: '',
      lastname: '',
      customerNumber: '',
    },
    onSubmit: handleClickSubmit,
    validate: makeValidate(orgId, translations),
  }

  let createButtonStartIcon: null | JSX.Element = null
  let createButtonEndIcon: null | JSX.Element = <Plus size={16} />
  let submitButtonLabel = translations.submitButtonLabel_idle

  if (isPending()) {
    createButtonStartIcon = <CircularProgress size={16} />
    createButtonEndIcon = null
    submitButtonLabel = translations.submitButtonLabel_pending
  }

  return (
    <Formik {...formikProps}>
      {({ errors, values }) => {
        const shouldDisableInput = !isIdle()
        const shouldDisableButton = Boolean(
          values.firstname === '' ||
            values.lastname === '' ||
            errors.firstname ||
            errors.lastname ||
            errors.customerNumber ||
            !isIdle()
        )

        return (
          <Form>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <InputLabel label={translations.firstNameLabel} isRequired={true} />
                <FormikInput
                  name="firstname"
                  variant="outlined"
                  placeholder="e.g. Steve"
                  className={classes.input}
                  disabled={shouldDisableInput}
                  data-test="customer-firstname-input"
                />
              </Grid>
              <Grid item>
                <InputLabel label={translations.lastNameLabel} isRequired={true} />
                <FormikInput
                  name="lastname"
                  variant="outlined"
                  placeholder="e.g. Smith"
                  className={classes.input}
                  disabled={shouldDisableInput}
                  data-test="customer-lastname-input"
                />
              </Grid>
              <Grid item>
                <InputLabel label={translations.customerNumberLabel} isRequired={true} />
                <FormikInput
                  name="customerNumber"
                  variant="outlined"
                  placeholder="e.g. 123456789"
                  disabled={shouldDisableInput}
                  className={classes.input}
                  data-test="customer-number-input"
                />
              </Grid>
              <Grid item className={classes.errorMessage}>
                {isRejected() && (
                  <FormErrorAlert
                    title={translations.errorAlertTitle}
                    description={translations.errorAlertDescription}
                  />
                )}
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={createButtonStartIcon}
                  endIcon={createButtonEndIcon}
                  disabled={shouldDisableButton}
                  data-test="submit-button"
                >
                  {submitButtonLabel}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const { translations: customerTranslations } = useI18n('customer')
  const customerForm = (customerTranslations.customerForm || {}) as StringMap
  const translations = t || ({} as StringMap)

  const somethingWentWrongError = translations.appFallbackErrorMessage || defaults.somethingWentWrongError
  const {
    firstNameLabel = defaults.firstNameLabel,
    lastNameLabel = defaults.lastNameLabel,
    customerNumberLabel = defaults.customerNumberLabel,
    submitButtonLabel_idle = defaults.submitButtonLabel_idle,
    submitButtonLabel_pending = defaults.submitButtonLabel_pending,
    errorAlertTitle = defaults.errorAlertTitle,
    errorAlertDescription = defaults.errorAlertDescription,
    invalidCustomerNumberError = defaults.invalidCustomerNumberError,
    duplicateCustomerNumberError = defaults.duplicateCustomerNumberError,
    numberOnlyCustomerNumberError = defaults.numberOnlyCustomerNumberError,
  } = customerForm

  return {
    firstNameLabel,
    lastNameLabel,
    customerNumberLabel,
    submitButtonLabel_idle,
    submitButtonLabel_pending,
    errorAlertTitle,
    errorAlertDescription,
    invalidCustomerNumberError,
    duplicateCustomerNumberError,
    numberOnlyCustomerNumberError,
    somethingWentWrongError,
  }
}

const defaultTranslations = {
  firstNameLabel: 'First name',
  lastNameLabel: 'Last name',
  customerNumberLabel: 'Customer number',
  submitButtonLabel_idle: 'Create customer',
  submitButtonLabel_pending: 'Creating customer...',
  errorAlertTitle: 'Error',
  errorAlertDescription: 'Failed to create customer',
  invalidCustomerNumberError: 'Customer number is not valid',
  duplicateCustomerNumberError: 'Customer number already exists',
  numberOnlyCustomerNumberError: 'Customer number can contain numbers only',
  somethingWentWrongError: 'Something went wrong',
}

const useStyles = makeStyles((theme: Theme) => ({
  errorMessage: {
    marginBottom: theme.spacing(3),
  },
  input: {
    width: '100%',
  },
}))

type Translations = typeof defaultTranslations
type PersonCustomerFormProps = {
  firstname: string
  lastname: string
  customerNumber: string
}
type ValidationErrors = { [k in keyof PersonCustomerFormProps]: string }

const makeValidate = (orgId: string, translations: Translations) => {
  const validateCustomerNumber = makeValidateCustomerNumber({ orgId, translations })
  return async (values: PersonCustomerFormProps) => {
    const errors = {} as ValidationErrors
    const { customerNumber, firstname, lastname } = values

    if (!firstname || typeof firstname !== 'string') {
      errors.firstname = 'Customer firstname is required'
    }

    if (!lastname || typeof lastname !== 'string') {
      errors.lastname = 'Customer lastname is required'
    }

    /** Server side validation of customer number field */
    if (customerNumber) {
      const validationResult = await validateCustomerNumber({ customerNumber })
      if (validationResult.customerNumber) errors.customerNumber = validationResult.customerNumber
    }
    return errors
  }
}

export default PersonForm
