import * as actions from './actions'
import * as orgActions from '../../organisations/store/actions'
import { createSlice } from '@reduxjs/toolkit'
import { IInvitationNormalized } from '../interfaces/invitation-normalized'
import { removeInvitationCase, setUserInvitationsCase, deleteOrganisationCase } from './case-reducers'

const initialState: InvitationsState = {
  byIds: {},
  allIds: [],
}

const invitationsSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: { SetUserInvitations: setUserInvitationsCase, RemoveInvitation: removeInvitationCase },
  extraReducers({ addCase }) {
    addCase(actions.FetchUserInvitations.fulfilled, setUserInvitationsCase)
    addCase(orgActions.DeleteOrganisation.fulfilled, deleteOrganisationCase)
  },
})

export const { SetUserInvitations, RemoveInvitation } = invitationsSlice.actions
export default invitationsSlice.reducer

export type InvitationsState = {
  byIds: { [id: string]: IInvitationNormalized }
  allIds: string[]
}
