import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { useSnackbar } from 'notistack'
import { X } from 'react-feather'
import { TimeZoneType } from '../../constants/timezones'
import { makeTreeGridTask } from '../task-tree-grid/tree-grid-task'
import { syncDataFromServerToGrid } from '../utils/tree-grid'
import { useTaskMutations } from '../../tasks/hooks/use-task-mutations'
import { TaskViewModel } from '../../tasks/api/task'

export const useTaskArchiveFeedback = ({ gridId, dateFormat, timeZone }: Args) => {
  const taskActions = useTaskMutations()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const onArchiveSuccess = (taskId: string, message: string) => {
    const onUndoArchive = async () => {
      const grid = window.Grids[gridId]
      if (!grid) return
      const unarchiveResult = await taskActions.unarchiveTask(taskId, { unarchiveSubtasks: true })
      if (!unarchiveResult) return

      const { unarchivedTasks, updatedTasks } = unarchiveResult.tasks.reduce(
        (acc, task) => {
          if (task.ancestors.includes(taskId) || task.id === taskId) {
            acc.unarchivedTasks.push(task)
          } else {
            acc.updatedTasks.push(task)
          }
          return acc
        },
        { unarchivedTasks: [] as TaskViewModel[], updatedTasks: [] as TaskViewModel[] }
      )
      const addedRows = unarchivedTasks.map((task) => makeTreeGridTask({ task, dateFormat, timeZone }))
      const updatedRows = updatedTasks.map((task) => makeTreeGridTask({ task, dateFormat, timeZone }))

      addedRows.forEach((addedRow) => {
        const parentRow = addedRow.parentTaskId ? grid.GetRowById(addedRow.parentTaskId) : undefined
        let nextRow = addedRow.parentTaskId ? parentRow?.firstChild : grid.GetFirst()
        while (nextRow) {
          // @ts-ignore
          if (nextRow.order === addedRow.order) break
          nextRow = nextRow.nextSibling
        }
        grid.AddRow(parentRow, nextRow, 0, addedRow.id)
      })

      syncDataFromServerToGrid(grid, [...addedRows, ...updatedRows])
      addedRows.forEach((addedRow) => grid.ShowRow(grid.GetRowById(addedRow.id)))
      closeSnackbar(taskId)
    }

    enqueueSnackbar(message, {
      key: taskId,
      action: makeArchiveSuccessAction({
        onUndo: onUndoArchive,
        onDismiss: closeSnackbar,
      }),
    })
  }

  return {
    onArchiveSuccess,
  }
}

const makeArchiveSuccessAction = ({ onUndo, onDismiss }: ArchiveSuccessActionProps) => {
  return (taskId: string) => {
    return (
      <>
        <Button color="secondary" onClick={onUndo}>
          Undo
        </Button>
        <IconButton color="inherit" onClick={() => onDismiss(taskId)}>
          <X size={16} />
        </IconButton>
      </>
    )
  }
}

type ArchiveSuccessActionProps = {
  onUndo: () => void
  onDismiss: (snackbarId: string) => void
}

type Args = {
  gridId: string
  dateFormat: string
  timeZone: TimeZoneType
}
